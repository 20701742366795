import React, { useState, useContext, useEffect } from "react";
import {
  Badge,
  Avatar,
  Drawer,
  Input,
  Card,
  Dropdown,
  Menu,
  message,
  Spin,
  Skeleton,
  Space,
  Button,
} from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  SearchOutlined,
  UserOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  SwitcherOutlined,
  CloseOutlined,
  CheckOutlined,
  SwitcherTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CloseSquareTwoTone,
  CheckSquareTwoTone,
  BellOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { MyContext } from "../../Context";
import Loading from "../../lotties/LoadingAnimation";
const FriendList = () => {
  const {
    socket,
    currentUserObj,
    allUserList,
    unreadInteractionList,
    selectedFriend,
    setSelectedFriend,
    setSelectedGroup,
    setSelectedSupport,
    setcurrentUserList,
    setuserReadUnreadList,
    setUnreadInteractionList,
    setmessagearray,
    // setFriendsLoading,
    messageLoading,
    friendsLoading,
    setMessageLoading,
    onlineUsers,
    chatBoxArr,
    setChatBoxArr,
    selectedSupport,
    selectedAdminSupport,
    selectedGroup,
    activeTab,
    setActiveTab,
    setSelectedAdminSupport,
    userObj,
    app_id,
    getUsers,

    hidePrivate,
    setHidePrivate,
    setBlankPage,
    handleTabClick,
    selectedForwardMessages,
    handleForward,
    showFullname,
    setShowFullname,
    selectedFriendChats,
    setSelectedFriendChats,
    globalColors,
    conditionalBadge,
  } = useContext(MyContext);

  const [selectedItem, setSelectedItem] = useState({});
  const [itemType, setItemType] = useState("");

  const [query, setQuery] = useState("");
  const [visibleListUsers, setVisibleListUsers] = useState(false);
  const [disableAddToChat, setDisableAddToChat] = useState(false);

  let filteredUsersSearch = allUserList.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.username + item.first_name).toLowerCase().indexOf(lowquery) >= 0
    );
  });

  useEffect(() => {
    if (selectedGroup !== null) {
      setSelectedItem(selectedGroup);
      setItemType("group");
    } else if (selectedFriend !== null) {
      setSelectedItem(selectedFriend);
      setItemType("personal");
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedItem(selectedAdminSupport);
      setItemType("adminSupport");
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      setSelectedItem(selectedSupport);
      setItemType("support");
    }
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  const addToChat = (chosenuser) => {
    console.log("addToChat_chosenuser", userObj, chosenuser);
    setDisableAddToChat(true);
    socket.emit(
      "check_user_interaction_list",
      userObj,
      chosenuser.username,
      app_id,
      (response) => {
        console.log(
          "addToChat_check_interaction_list",
          userObj,
          app_id,
          response
        );
        socket.emit(
          "get_user_interaction_list_read_unread_list",
          userObj,
          app_id,
          async (data) => {
            console.log("addToChat_interaction_list", data);
            setcurrentUserList(data.interacting_list);
            let tempArr = [];
            await Promise.all(
              data.read_unread_list.map((item) => {
                tempArr.push(item.sender);
              })
            );
            console.log("fffff", tempArr);

            setuserReadUnreadList(tempArr);
            setUnreadInteractionList(data.newInteractionList);

            const newUser = data.newInteractionList.find(
              (o) => o.username === chosenuser.username
            );
            createNewTab({
              app_code: "teamchat",
              avatar: chosenuser.avatar,
              bio: chosenuser.bio,
              chat_type: "chats.io",
              chatsio_id: chosenuser.chatsio_id,
              email: chosenuser.email,
              first_name: chosenuser.first_name,
              id: chosenuser.id,
              last_name: chosenuser.last_name,
              timezone: chosenuser.timezone,
              username: chosenuser.username,
              thread_id: response,
            });
            setVisibleListUsers(false);
            setDisableAddToChat(false);
          }
        );
      }
    );

    // app_code: "teamchat",
    //           avatar:
    //             "https://chatsgx.s3-us-east-2.amazonaws.com/ichiranjeeb@outlook.com/1599221915479.png",
    //           bio: "Blah",
    //           chat_type: "chats.io",
    //           chatsio_id: "13afe816-7f17-4d82-a958-ac81ea828999",
    //           email: "ichiranjeeb@outlook.com",
    //           first_name: "Chiran",
    //           id: "e5bbb8f0-720c-4df2-9d60-33dcb8ce91cd",
    //           last_name: "Jena",
    //           msg_timestamp: 1625303104321,
    //           sender: "a0a65848-c224-42c5-9c7a-24d70ecbde55",
    //           thread_id: "d95e9b58-a5e6-4a1a-9d82-806ff261a14c",
    //           timezone: "Asia/Calcutta",
    //           unread_count: 0,
    //           username: "ichiranjeeb7029",
    //           _id: "5f5230bce7e13b57171b2aeb",

    // socket.emit(
    //   "check_user_interaction_list",
    //   userObj,
    //   chosenuser.username,
    //   "thread_id not available here",
    //   (response) => {
    //     console.log("user---", response);
    //     socket.emit(
    //       "get_user_interaction_list_read_unread_list",
    //       currentUserObj,
    //       async (data) => {
    //         console.log("ddddddddddddd", data);
    //         setcurrentUserList(data.interacting_list);
    //         let tempArr = [];
    //         await Promise.all(
    //           data.read_unread_list.map((item) => {
    //             tempArr.push(item.sender);
    //           })
    //         );
    //         console.log("fffff", tempArr);
    //         setuserReadUnreadList(tempArr);
    //         setUnreadInteractionList(data.newInteractionList);

    //         const newUser = data.newInteractionList.find(
    //           (o) => o.username === chosenuser.username
    //         );
    //         createNewTab({
    //           email: chosenuser.email,
    //           username: chosenuser.username,
    //           timestamp: chosenuser.timestamp,
    //           thread_id: response,
    //         });
    //         setVisibleListUsers(false);
    //         // setmessagearray([]);
    //         // setSelectedFriend({
    //         //   email: chosenuser.email,
    //         //   username: chosenuser.username,
    //         //   timestamp: chosenuser.timestamp,
    //         //   thread_id: response,
    //         // });
    //         // setSelectedGroup(null);
    //         // setSelectedSupport(null);

    //         // if (newUser) {
    //         //   setSelectedFriend(newUser);
    //         //   setVisibleListUsers(false);
    //         // } else {
    //         //   setSelectedFriend(chosenuser);
    //         //   setSelectedGroup(null);
    //         //   setSelectedSupport(null);
    //         //   setVisibleListUsers(false);

    //         // setVisibleListUsers(false);
    //       }
    //     );
    //   }
    // );
  };
  const MarkAsRead = (item) => {
    socket.emit(
      "new_direct_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedj", response);
        let tempList = unreadInteractionList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        tempList[foundIndex].unread_count = 0;
        setUnreadInteractionList([...tempList]);
      }
    );
  };

  const handleFriendSelection = (item) => {
    socket.emit(
      "new_direct_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedj", response);
        let tempList = unreadInteractionList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        tempList[foundIndex].unread_count = 0;
        setUnreadInteractionList([...tempList]);
      }
    );

    if (chatBoxArr.find((o) => o.data["email"] === item.email)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["username"] === item.username)
      );
    } else {
      console.log(item, "kjwbedkjwbefkwbef");
      // setSelectedFriend(item.data);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTab(item);
    }

    // if (chatBoxArr.length > 1) {

    // } else {
    //   setSelectedSupport(null);
    //   setSelectedAdminSupport(null);
    //   setSelectedFriend(null);
    //   setSelectedGroup(item);
    //   setChatBoxArr([{ data: item, type: "personal" }]);
    // }

    setBlankPage(false);
  };

  // const handleFriendSelection = (item) => {
  //   setBlankPage(false);

  //   socket.emit(
  //     "new_direct_message_read",
  //     userObj.username,
  //     item.thread_id,
  //     app_id,
  //     (response) => {
  //       console.log("jhsdjwhvedj", response);
  //     }
  //   );
  //   setSelectedFriend(item);
  //   setSelectedGroup(null);
  //   setSelectedSupport(null);
  //   setSelectedAdminSupport(null);
  //   setMessageLoading(true);
  //   setmessagearray([]);
  //   if (chatBoxArr.length > 1) {
  //     setChatBoxArr([...chatBoxArr, { data: item, type: "personal" }]);
  //   } else {
  //     setChatBoxArr([{ data: item, type: "personal" }]);
  //   }
  // };

  const createNewTab = (item) => {
    if (chatBoxArr.find((o) => o.data["username"] === item.username)) {
      message.error("This User is already in one of your tabs.");
    } else {
      setSelectedFriend(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "personal" }]);
    }
  };

  const handleSelection = (item) => {
    if (selectedFriendChats.find((o) => o === item.thread_id)) {
      var newArr = selectedFriendChats.filter((o) => o !== item.thread_id);
      setSelectedFriendChats([...newArr]);
    } else {
      setSelectedFriendChats([...selectedFriendChats, item.thread_id]);
    }
  };

  const MarkAllAsRead = () => {
    socket.emit(
      "mark_multiple_direct_chats_as_read",
      userObj.username,
      selectedFriendChats,
      app_id,
      (response) => {
        console.log("jhsdjwhvedjfriend", response);
        getUsers();
        setSelectedFriendChats([]);
        // let tempList = unreadInteractionList;
        // var foundIndex = tempList.findIndex(
        //   (x) => x.thread_id == item.thread_id
        // );
        // tempList[foundIndex].unread_count = 0;
        // setUnreadInteractionList([...tempList]);
      }
    );
  };

  return (
    <>
      <div
        style={{
          color: globalColors.darkMode ? "#919191" : "lightgray",
          padding: "10px 0px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          <UserOutlined />
          &nbsp;&nbsp;FRIENDS ({unreadInteractionList.length})
        </span>
        <span>
          {hidePrivate ? (
            <CaretDownOutlined
              onClick={(e) => setHidePrivate(!hidePrivate)}
              style={{ paddingRight: "10px", cursor: "pointer" }}
            />
          ) : (
            <CaretUpOutlined
              onClick={(e) => setHidePrivate(!hidePrivate)}
              style={{ paddingRight: "10px", cursor: "pointer" }}
            />
          )}
          <PlusOutlined
            onClick={(e) => setVisibleListUsers(true)}
            style={{ cursor: "pointer" }}
          />
        </span>
      </div>

      {selectedFriendChats.length > 0 ? (
        <Space style={{ padding: "5px 10px", paddingBottom: "10px" }}>
          <Button
            onClick={MarkAllAsRead}
            size="small"
            type="primary"
            ghost
            icon={<BellOutlined />}
            shape="round"
          >
            Mark as read
          </Button>
          <Button
            size="small"
            shape="round"
            type="danger"
            ghost
            icon={<CloseOutlined />}
            onClick={(e) => setSelectedFriendChats([])}
          >
            Dismiss
          </Button>
          {/* <BellOutlined style={{ fontSiz: "20px" }} /> */}
        </Space>
      ) : (
        ""
      )}

      {!hidePrivate ? (
        <div style={{ marginTop: "8px" }}>
          {!friendsLoading ? (
            unreadInteractionList.map((item, index) => {
              return (
                <Dropdown
                  overlay={
                    <Menu
                      // theme="dark"
                      style={{
                        border: "solid 1px rgba(128, 128, 128, 0.1)",
                        borderRadius: "10px",
                      }}
                    >
                      <Menu.Item key="1" onClick={(e) => createNewTab(item)}>
                        <SwitcherTwoTone />
                        &nbsp;&nbsp;Open in new tab
                      </Menu.Item>
                      <Menu.Divider style={{ margin: "0px" }} />
                      {item.unread_count > 0 ? (
                        <>
                          <Menu.Item key="2" onClick={(e) => MarkAsRead(item)}>
                            <CheckSquareTwoTone />
                            &nbsp;&nbsp;Mark as Read
                          </Menu.Item>
                          <Menu.Divider style={{ margin: "0px" }} />
                        </>
                      ) : (
                        ""
                      )}
                      <Menu.Item key="3" onClick={(e) => handleSelection(item)}>
                        <UnorderedListOutlined />
                        &nbsp;&nbsp;Select Chat
                      </Menu.Item>
                      {/* <Menu.Item key="3">
                        <CloseSquareTwoTone />
                        &nbsp;&nbsp;Dismiss
                      </Menu.Item> */}
                    </Menu>
                  }
                  trigger={["contextMenu"]}
                >
                  <div
                    key={index}
                    className={
                      selectedFriend
                        ? selectedFriend.email === item.email
                          ? "selected-friend"
                          : "friend-name"
                        : selectedFriendChats.length > 0
                        ? selectedFriendChats.find((o) => o === item.thread_id)
                          ? "selected-chat1"
                          : "friend-name"
                        : "friend-name"
                    }
                    // onClick={(e) =>
                    //   selectedForwardMessages.length > 0
                    //     ? handleForward(item, "friend")
                    //     : chatBoxArr.length > 0
                    //     ? !messageLoading
                    //       ? handleFriendSelection(item)
                    //       : ""
                    //     : handleFriendSelection(item)
                    // }

                    onClick={(e) => {
                      selectedFriendChats.length > 0
                        ? handleSelection(item)
                        : handleFriendSelection(item);
                    }}

                    // style={{ display: "flex" }}
                  >
                    {/* <img
                src={require(`../../images/user.jpg`)}
                alt=""
                style={{ width: "30px", borderRadius: "5px" }}
              /> */}
                    <Space
                      style={{ paddingRight: "5px" }}
                      onMouseEnter={(e) => setShowFullname(index)}
                      onMouseLeave={(e) => setShowFullname(null)}
                    >
                      <Avatar src={item.avatar} />
                      {onlineUsers.indexOf(item.username) !== -1 ? (
                        <Badge color={"#70CC16"} />
                      ) : (
                        <Badge color={"lightgray"} />
                      )}
                      {showFullname === index ? (
                        item.first_name
                      ) : (
                        <span>
                          {item.first_name.length > 18 ? (
                            <span>{item.first_name.substring(0, 18)}...</span>
                          ) : (
                            item.first_name
                          )}
                        </span>
                      )}
                      {/* <span>
                        {item.first_name.length > 12 ? (
                          <>{item.first_name.substring(0, 12)}...</>
                        ) : (
                          item.first_name
                        )}
                      </span> */}
                    </Space>

                    {conditionalBadge(item)}
                  </div>
                </Dropdown>
              );
            })
          ) : (
            <Skeleton avatar paragraph={{ rows: 4 }} />
          )}
        </div>
      ) : (
        ""
      )}
      {/* all user list draw */}

      <Drawer
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Select User</span>
        }
        // bodyStyle={{ backgroundColor: "#196AB4" }}
        autoFocus
        // style={{ zIndex: "999" }}
        // closable={true}
        width={window.innerWidth > 600 ? 380 : window.innerWidth}
        placement="right"
        onClose={(e) => setVisibleListUsers(false)}
        visible={visibleListUsers}
      >
        <Input
          className={
            globalColors.darkMode ? "search-input-dark" : "search-input-light"
          }
          autoFocus
          size="large"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          type="text"
          style={{ marginBottom: "10px" }}
          placeholder="Search User"
          suffix={<SearchOutlined style={{ color: "lightgray" }} />}
        />
        {filteredUsersSearch.length > 0 && query.length > 1 ? (
          filteredUsersSearch.map((item, index) => {
            if (
              item.username !== userObj.username &&
              index < 100 &&
              query.length > 1
            )
              return (
                <>
                  {!disableAddToChat ? (
                    <Card
                      key={index}
                      onClick={(e) => addToChat(item)}
                      hoverable
                      bodyStyle={{
                        padding: "10px",
                        background: globalColors.darkMode
                          ? globalColors.chatMenuBackground
                          : "",
                        color: globalColors.chatText,
                      }}
                      style={{
                        margin: "10px 0px",
                        borderRadius: "5px",
                        borderColor: globalColors.darkMode
                          ? globalColors.chatMenuBackground
                          : "lightgray",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: "8px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            size="large"
                            src={item.avatar}
                            style={{
                              backgroundColor: "lightgray",
                            }}
                          />
                          {/* <img src={item.avatar} alt="" width="40px" /> */}
                          &nbsp;&nbsp;
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              {item.first_name}&nbsp;{item.last_name}
                            </div>
                            <small>{item.username}</small>
                          </div>
                        </div>
                        {/*   <p style={{ margin: "0px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {item.username}
                        </span>{" "}
                        &nbsp;
                        {onlineUsers.indexOf(item.username) !== -1 ? (
                          <i
                            class="fa fa-circle"
                            style={{ color: "#76FF03", fontSize: "8px" }}
                          ></i>
                        ) : (
                          <></>
                        )} 
                      </p>*/}

                        <p style={{ margin: "0px" }}>
                          <PlusOutlined style={{ color: "#196AB4" }} />
                        </p>
                      </div>
                    </Card>
                  ) : (
                    <Loading />
                  )}
                </>
              );
          })
        ) : (
          <p style={{ color: "lightgray" }}>
            <UserOutlined /> Search a user to start conversation
          </p>
        )}
      </Drawer>
    </>
  );
};

export default FriendList;

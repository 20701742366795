import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Avatar,
  message,
  Menu,
  Dropdown,
  Divider,
  Empty,
  Drawer,
  Button,
  Tag,
  Modal,
  Skeleton,
  Card,
  Select,
  Space,
} from "antd";
import {
  LoadingOutlined,
  PushpinOutlined,
  PushpinTwoTone,
  RollbackOutlined,
  EditOutlined,
  EditTwoTone,
  DeleteOutlined,
  DeleteTwoTone,
  CloseOutlined,
  MessageOutlined,
  MessageTwoTone,
  SmileOutlined,
  SmileTwoTone,
  CopyOutlined,
  CopyTwoTone,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  InteractionTwoTone,
  DiffTwoTone,
  RetweetOutlined,
} from "@ant-design/icons";
import { Picker } from "emoji-mart";
import { MyContext } from "../../Context";
import EditorThread from "./EditorThread";
const { Option } = Select;
// import ReactPlayer from "react-player";

const AlwaysScrollToBottom = () => {
  const { typingFlag } = useContext(MyContext);

  const elementRef = useRef();

  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const AllChats = () => {
  const {
    socket,
    // usersocket,
    bodyRef,
    bodyRef1,
    messagearray,
    setmessagearray,
    setgroupMembers,
    selectedFriend,
    selectedGroup,
    selectedSupport,
    currentUserObj,
    unreadInteractionList,
    setUnreadInteractionList,
    setSelectedReplyMessage,
    // userReadUnreadList,
    setuserReadUnreadList,
    renderMessage,
    setcurrentUserList,
    // setVisibleListUsers,
    messageLoading,
    setMessageLoading,
    selectedAdminSupport,
    setSelectedAdminSupport,
    typingUser,
    setTypingUser,
    typingFlag,
    selectedType,
    setSelectedType,
    setTypingFlag,
    adminUsernames,
    setAdminUsernames,
    activeTab,
    userObj,
    app_id,
    refreshGroup,
    setRefreshGroup,
    pinnedMessages,
    setPinnedMessages,
    conditionalMessageView,
    showConditionalDate,
    threadItem,
    setThreadItem,
    setSelectedReplyMessage1,
    memberUsernames,
    setMemberUsernames,
    selectedEditMessage,
    setSelectedEditMessage,
    formatAMPM,
    chatBoxArr,
    threadMessagearray,
    setThreadMessagearray,
    groupReadUnreadList,
    setgroupReadUnreadList,
    editorText,
    setEditorText,
    setEditorTextThread,
    timeDifference,
    showReactions,
    setShowReactions,
    addReaction,
    selectedItem,
    setSelectedItem,
    addToReaction,
    goToBottom,
    setGoToBottom,
    unreadCount,
    setUnreadCount,
    activeKey,
    scrollRef,
    blankPage,
    groupMembers,
    selectedForwardMessages,
    setSelectedForwardMessages,
    selectedDeleteMessages,
    setSelectedDeleteMessages,
    globalColors,
    getPinnedMessage,
    submitLoading,
    submitLoading1,
  } = useContext(MyContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noMessages, setNoMessages] = useState(false);

  const [showThread, setShowThread] = useState(false);
  const [showMessageDetails, setShowMessageDetails] = useState(false);
  const [showGroupMessageDetails, setShowGroupMessageDetails] = useState(false);
  const [showGroupMessageDetails1, setShowGroupMessageDetails1] =
    useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedGroupMember, setSelectedGroupMember] = useState(null);

  useEffect(() => {
    console.log(threadItem, "kjkjwvjhwvej");
    if (threadItem) {
      setThreadMessagearray([]);
      if (threadItem.msgtype === "group") {
        socket.emit(
          "get_group_chat_reply_thread_messages",
          threadItem._id,
          app_id,
          (res) => {
            console.log(res.payload, "kjkjwvjhwvej");
            if (res.status) {
              setThreadMessagearray([...res.payload]);
            }
          }
        );
      } else if (threadItem.msgtype === "onetoone") {
        socket.emit(
          "get_direct_chat_reply_thread_messages",
          threadItem._id,
          app_id,
          (res) => {
            console.log(res.payload, "kjkjwvjhwvej");
            if (res.status) {
              setThreadMessagearray([...res.payload]);
            }
          }
        );
      } else {
        socket.emit(
          "get_support_group_chat_reply_thread_messages",
          threadItem._id,
          app_id,
          (res) => {
            console.log(res.payload, "kjkjwvjhwvej");
            if (res.status) {
              setThreadMessagearray([...res.payload]);
            }
          }
        );
      }
    }
  }, [showThread]);

  useEffect(() => {
    if (selectedGroup !== null) {
      setSelectedItem(selectedGroup);
      getPinnedMessage(selectedGroup);
    } else if (selectedFriend !== null) {
      setSelectedItem(selectedFriend);
      getPinnedMessage(selectedFriend);
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedItem(selectedAdminSupport);
      getPinnedMessage(selectedAdminSupport);
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      setSelectedItem(selectedSupport);
      getPinnedMessage(selectedSupport);
    }
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  // useEffect(() => {
  //   getPinnedMessage();
  // }, [selectedItem]);

  // useEffect(() => {
  //   socket.on("msg_notify", (data) => {
  //     console.log("dataaa", data);
  //   });
  //   return () => {
  //     socket.off("msg_notify");
  //   };
  // });

  // Msg Notify.. set messagearray
  useEffect(() => {
    console.log("dataaa", "kjkjkj");

    if (selectedFriend !== null) {
      socket.on("msg_notify", (data) => {
        console.log("kjkjkj", data);

        if (data.thread_id === selectedFriend.thread_id) {
          setmessagearray([...messagearray, data]);
        }
      });
      return () => {
        socket.off("msg_notify");
      };
    } else if (selectedGroup !== null) {
      socket.on("msg_notify", (data) => {
        // console.log("dataaa", data);
        if (data.thread_id === selectedGroup.thread_id) {
          // console.log(messagearray);

          if (messagearray[messagearray.length - 1] !== data) {
            setmessagearray([...messagearray, data]);
            setUnreadCount(unreadCount + 1);
            console.log(unreadCount, "kjkjkj");
          }
        }
      });
      return () => {
        socket.off("msg_notify");
      };
    } else if (selectedSupport !== null || selectedAdminSupport !== null) {
      socket.on("msg_notify", (data) => {
        // console.log("dataaa", data);
        if (data.thread_id === selectedSupport.thread_id) {
          // console.log(messagearray, "hjjhwcw");
          if (messagearray[messagearray.length - 1] !== data) {
            setmessagearray([...messagearray, data]);
          }
        }
      });
      return () => {
        socket.off("msg_notify");
      };
    } else {
      socket.on("msg_notify", (data) => {
        console.log("dataaa", data);

        // if (data.thread_id === selectedFriend.thread_id) {
        //   setmessagearray([...messagearray, data]);

        //   // if (messagearray[messagearray.length - 1] !== data) {

        //   // }
        // }
      });
      return () => {
        socket.off("msg_notify");
      };
    }
  }, [messagearray]);

  // useEffect(() => {
  //   console.log(
  //     "get_support_group_chat_history",
  //     selectedAdminSupport,
  //     selectedSupport
  //   );
  //   setMessageLoading(true);
  //   if (selectedSupport !== null && selectedAdminSupport === null) {
  //     socket.emit(
  //       "get_user_support_group_interaction_list",
  //       userObj,
  //       app_id,
  //       (response) => {
  //         // console.log("support group", response);

  //         socket.emit(
  //           "get_support_group_chat_history",
  //           response[0].group_name,
  //           response[0].thread_id,
  //           app_id,
  //           (resp) => {
  //             setMessageLoading(false);
  //             let chat_history;
  //             chat_history = resp;
  //             console.log("supportxxx", chat_history);

  //             // setthreadId(item.thread_id);
  //             // setchatsection(true);
  //             setmessagearray([...resp.reverse()]);
  //           }
  //         );
  //       }
  //     );
  //   }
  // }, [selectedSupport]);

  // useEffect(() => {
  //   if (!showThread) {
  //     setThreadMessagearray([]);
  //   }
  // }, [showThread]);

  useEffect(() => {
    console.log(selectedAdminSupport, "chat_history");
    if (selectedAdminSupport !== null && selectedSupport !== null) {
      console.log("get_support_chat_history_v2", selectedAdminSupport);
      setMessageLoading(true);
      socket.emit(
        "get_support_chat_history_v2",
        selectedAdminSupport.thread_id,
        app_id,
        (resp) => {
          console.log("get_support_chat_history_v2", selectedAdminSupport);
          setMessageLoading(false);
          setmessagearray([...resp.reverse()]);
          console.log(resp, "hjjhwcw");
        }
      );
    }
  }, [selectedAdminSupport]);

  useEffect(() => {
    if (selectedFriend !== null) {
      setSelectedType(selectedFriend);
    } else if (selectedGroup !== null) {
      setSelectedType(selectedGroup);
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedType(selectedAdminSupport);
    } else if (selectedAdminSupport === null && selectedSupport !== null) {
      setSelectedType(selectedSupport);
    }
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  //useeffect to notify that other party which the current user is interaction is typing
  // useEffect(() => {
  //   if (selectedType) {
  //     socket.on("someone_typing", (typing_user, thread_id1) => {
  //       console.log("typing thread: ", thread_id1, typing_user);
  //       if (thread_id1 === selectedType.thread_id) {
  //         console.log("typing");
  //         setTypingUser(typing_user);
  //         setTypingFlag(true);
  //         setTimeout(() => {
  //           setTypingFlag(false);
  //         }, 2000);
  //       } else {
  //         setTypingFlag(false);
  //       }
  //     });
  //   }

  //   return () => {
  //     socket.off("someone_typing");
  //   };
  // }, [selectedType, selectedAdminSupport]);

  const showOneToOneOldMessage = (threadId) => {
    setLoading(true);
    console.log("something");
    setPageNumber(pageNumber + 1);
    socket.emit(
      "direct_message_archive_history",
      { thread_id: threadId, page: pageNumber + 1, app_id },
      (response) => {
        console.log("chat_history", response);

        console.log("chat_history", response);
        let chat_history = [...response].reverse();
        if (!chat_history.length > 0) {
          setNoMessages(true);
        }
        console.log("something", chat_history);
        setmessagearray([...chat_history, ...messagearray]);
        console.log([...chat_history, ...messagearray]);
        // setsetFlag(true);
        setLoading(false);
      }
    );
  };

  const showGroupOldMessage = (threadId) => {
    setLoading(true);
    console.log("something", threadId, pageNumber);
    setPageNumber(pageNumber + 1);
    socket.emit(
      "group_chat_archive_history",
      { thread_id: threadId, page: pageNumber + 1, app_id },
      app_id,
      (response) => {
        console.log("chat_history", response);
        let chat_history = [...response].reverse();
        if (!chat_history.length > 0) {
          setNoMessages(true);
        }
        console.log("something", chat_history);
        setmessagearray([...chat_history, ...messagearray]);
        let tempStorage = JSON.parse(localStorage.getItem("storedChat"));
        var foundStorageIndex = tempStorage.findIndex(
          (x) => x.chatObj.thread_id == selectedGroup.thread_id
        );
        console.log(tempStorage[foundStorageIndex].messages, "khgckgekfgef");
        tempStorage[foundStorageIndex].messages = [
          ...tempStorage[foundStorageIndex].messages,
          ...chat_history,
        ];
        localStorage.setItem("storedChat", JSON.stringify(tempStorage));
        //
        // console.log(chat_history, tempStorage[0].messages, "kjhwefjhvwjefh");
        // localStorage.setItem(
        //   "storedChat",
        //   JSON.stringify([...chat_history, ...tempStorage[0].messages])
        // );
        // console.log([...chat_history, ...messagearray]);
        // setsetFlag(true);
        setLoading(false);
      }
    );
  };

  const showSupportOldMessage = (threadId) => {
    setLoading(true);
    console.log("something");
    setPageNumber(pageNumber + 1);
    socket.emit(
      "support_chat_archive_history_v2",
      { thread_id: threadId, page: pageNumber + 1 },
      app_id,
      (response) => {
        console.log("chat_history", response);
        let chat_history = [...response].reverse();
        if (!chat_history.length > 0) {
          setNoMessages(true);
        }
        console.log("something", chat_history);
        setmessagearray([...chat_history, ...messagearray]);
        console.log([...chat_history, ...messagearray]);
        // setsetFlag(true);
        setLoading(false);
      }
    );
  };

  const conditionalShowOldMessages = () => {
    if (selectedSupport === null) {
      if (selectedFriend !== null) {
        showOneToOneOldMessage(selectedFriend.thread_id);
      } else if (selectedGroup !== null) {
        showGroupOldMessage(selectedGroup.thread_id);
      }
    } else {
      showSupportOldMessage(selectedSupport.thread_id);
    }
  };

  const handleDeleteOneToOneChat = (item, username) => {
    console.log("being deleted", item, username);
    if (item.sender.username === userObj.username) {
      socket.emit(
        "delete_oneonechat_message",
        item,
        username,
        app_id,
        (response) => {
          console.log(response);
          let newArr = [...messagearray.filter((o) => o._id !== item._id)];
          setmessagearray(newArr);
          var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
          if (tempStorage) {
            var foundObjIndex = tempStorage.findIndex(
              (o) => o.chatObj["email"] === selectedFriend.email
            );
            if (foundObjIndex >= 0) {
              var filteredMsgArr = tempStorage[foundObjIndex].messages.filter(
                (o) => o._id !== item._id
              );
              tempStorage[foundObjIndex].messages = filteredMsgArr;
              localStorage.setItem(
                "storedChat",
                JSON.stringify([...tempStorage])
              );

              // localStorage.setItem("storedChat", JSON.stringify(tempdata));
            }
          }
        }
      );
    } else {
      message.error("You Can't delete Other's Message");
    }
  };

  const refetchAllPrivateChats = () => {
    socket.emit(
      "get_direct_chat_reply_thread_messages",
      threadItem._id,
      app_id,
      (res) => {
        if (res.status) {
          setThreadMessagearray([...res.payload]);
        }
      }
    );
    setEditorTextThread("");
    // setEditorTextThread("");

    // threadItem.thread_messages_count = threadItem.thread_messages_count + 1;
    // console.log(messagearray[foundIndex], "jhgdqd");
  };

  const refetchAllGroupChats = () => {
    console.log("chat_historyqqqq");

    socket.emit(
      "get_group_chat_reply_thread_messages",
      threadItem._id,
      app_id,
      (res) => {
        if (res.status) {
          setThreadMessagearray([...res.payload]);
        }
      }
    );
    console.log(messagearray, "kjgldjwdgqw");
    var tempArr = messagearray;

    var foundIndex = tempArr.findIndex((x) => x._id == threadItem._id);
    console.log(foundIndex, tempArr[foundIndex], "kjgldjwdgqw");
    if (tempArr[foundIndex].thread_messages_count == NaN) {
      tempArr[foundIndex].thread_messages_count = 1;
    } else {
      tempArr[foundIndex].thread_messages_count =
        tempArr[foundIndex].thread_messages_count + 1;
    }

    console.log(tempArr, messageLoading, "kjgldjwdgqw");

    setmessagearray([...tempArr]);
    // threadItem.thread_messages_count = threadItem.thread_messages_count + 1;

    // if (messagearray[foundIndex].thread_messages_count > 0) {
    //   messagearray[foundIndex].thread_messages_count =
    //     messagearray[foundIndex].thread_messages_count + 1;
    // } else if (messagearray[foundIndex].thread_messages_count === 0) {
    //   console.log(messagearray, "kjgldjwdgqw");
    //   messagearray[foundIndex].thread_messages_count = 1;
    // }
  };

  const handleDeleteGroupChat = (item, username) => {
    console.log("being deleted", item, username);
    if (item.sender.username === userObj.username) {
      socket.emit(
        "delete_groupchat_message",
        item,
        username,
        app_id,
        (response) => {
          console.log(response);
          if (threadItem) {
            refetchAllGroupChats();
          } else {
            let newArr = [...messagearray.filter((o) => o._id !== item._id)];
            setmessagearray(newArr);
            var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
            if (tempStorage) {
              var foundObjIndex = tempStorage.findIndex(
                (o) => o.chatObj["group_name"] === selectedGroup.group_name
              );
              if (foundObjIndex >= 0) {
                var filteredMsgArr = tempStorage[foundObjIndex].messages.filter(
                  (o) => o._id !== item._id
                );
                tempStorage[foundObjIndex].messages = filteredMsgArr;
                localStorage.setItem(
                  "storedChat",
                  JSON.stringify([...tempStorage])
                );

                // localStorage.setItem("storedChat", JSON.stringify(tempdata));
              }
            }
          }
        }
      );
    } else {
      message.error("You Can't delete Other's Message");
    }
  };

  const handleDeleteSupportChat = (item, username) => {
    //delete_supportgroupchat_message
    if (item.sender.username === userObj.username) {
      socket.emit(
        "delete_supportgroupchat_message",
        item,
        username,
        app_id,
        (response) => {
          console.log(response);
          if (threadItem) {
            refetchAllPrivateChats();
          } else {
            let newArr = [...messagearray.filter((o) => o._id !== item._id)];
            setmessagearray(newArr);
          }
        }
      );
    } else {
      message.error("You Can't delete Other's Message");
    }
    // message.error("Delete Message is not done yet for Support Chat");
  };

  const conditionalDeleteChat = (item, prevUsr) => {
    console.log(item, prevUsr, "deletedata");
    if (selectedSupport === null) {
      if (selectedFriend !== null) {
        handleDeleteOneToOneChat(
          item,
          prevUsr ? prevUsr.sender.username : "1stMsg"
        );
      } else if (selectedGroup !== null) {
        handleDeleteGroupChat(
          item,
          prevUsr ? prevUsr.sender.username : "1stMsg"
        );
      }
    } else {
      handleDeleteSupportChat(
        item,
        prevUsr ? prevUsr.sender.username : "1stMsg"
      );
    }
  };

  const pinMessage = (item) => {
    console.log("jhVJWVJWE", item);
    if (selectedFriend) {
      socket.emit("pin_one_to_one_message", item._id, app_id, async (data) => {
        console.log("ddddddddddddd", data);
        if (data.status) {
          getPinnedMessage(item);
        }
      });
    } else if (selectedGroup) {
      socket.emit("pin_group_message", item._id, app_id, async (data) => {
        console.log("ddddddddddddd", data);
        if (data.status) {
          getPinnedMessage(item);
        }
      });
    }
  };

  // console.log(formatAMPM(new Date()));

  //   message object sample

  // message: "ok"
  // sender: "techkrtk"
  // thread_id: "8b35c5c1-f39c-4121-9915-d36a2dc587a7"
  // timestamp: 1590123118442
  // _id: "5ec75a6df019376c423f52d5"'

  useEffect(() => {
    setNoMessages(false);
    setPageNumber(1);
  }, [selectedFriend, selectedGroup, selectedSupport]);

  const handleCopy = (item) => {
    var text = item.message;
    navigator.clipboard.writeText(text).then(
      function () {
        message.success("Mesage is copied", 2);
      },
      function (err) {
        message.error("Mesage could not be copied", 2);
      }
    );
  };

  // useEffect(() => {
  //   if (messagearray.length > 1) {
  //     console.log(pageNumber, messagearray, "kjwgedkwegkw");
  //     if (pageNumber === 1) {
  //       var objDiv = document.getElementById("your_div");
  //       console.log(
  //         "top: ",
  //         objDiv.scrollTop,
  //         "height: ",
  //         objDiv.scrollHeight,
  //         "kjwgedkwegkw"
  //       );
  //       objDiv.scrollTop = objDiv.scrollHeight;
  //       console.log(objDiv, "top:");
  //     }
  //   }
  // }, [goToBottom, messagearray, activeKey, chatBoxArr, activeTab]);

  const handleShowMessageDetails = (item) => {
    console.log(item, "kwjebdkjwbekwbdek");
    if (item.msgtype === "group") {
      socket.emit(
        `group_chat_message_tracker`,
        { msg_id: item._id, app_id: app_id },
        (res) => {
          console.log(res, "khwvedkhjwvejhioioigroup");
          setSelectedMessage(res.payload);
          setShowGroupMessageDetails(true);
        }
      );
    } else if (item.msgtype === "onetoone") {
      socket.emit(
        `direct_chat_message_tracker`,
        { msg_id: item._id, app_id: app_id },
        (res) => {
          console.log(res, "khwvedkhjwvejhioioi");

          setSelectedMessage(res.payload);
          setShowMessageDetails(true);
        }
      );
    }
  };

  // useEffect(() => {
  //   if (messagearray.length > 1) {
  //     if (pageNumber === 1) {
  //       var objDiv = document.getElementById("element_within_div");
  //       var topPos = objDiv.offsetTop;
  //       document.getElementById("your_div").scrollTop = topPos;
  //     }
  //   }
  // }, [goToBottom, messagearray, activeKey]);

  // useEffect(() => {
  //   if (messagearray.length > 1) {
  //     if (pageNumber === 1) {
  //       scrollRef.current.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  // }, [messagearray]);

  const showConditionalShowOldMessageButton = () => {
    if (messagearray.length > 29) {
      if (!noMessages) {
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) => conditionalShowOldMessages()}
          >
            <div
              style={{
                backgroundColor: "#EEEEEE",
                cursor: "pointer",
                borderRadius: "50px",
                padding: "8px",
              }}
            >
              {loading ? <LoadingOutlined /> : ""}&nbsp; Show Older Messages
            </div>
          </div>
        );
      }
    }
  };

  const handleMovetoBottom = () => {
    // console.log("jhweigwefwe");
    if (
      pageNumber > 1 ||
      selectedForwardMessages.length > 0 ||
      selectedDeleteMessages.length > 0 ||
      editorText.length > 0 ||
      typingUser ||
      showReactions
    ) {
      return null;
    } else {
      return <AlwaysScrollToBottom />;
    }
  };

  const handleMemberChange = (value) => {
    selectedMessage.users_message_tracker.map((item) => {
      if (item.username === JSON.parse(value).username) {
        console.log(item, "timestampvalues");
        setSelectedGroupMember({ timestamp: item, details: JSON.parse(value) });
      }
    });

    // console.log(JSON.parse(value), "kjdkjwbdkhwqd");
    // setSelectedGroupMember(JSON.parse(value));
    // setShowGroupMessageDetails(false);
    // setShowGroupMessageDetails1(true);
  };

  const handleForwardMessages = (item) => {
    if (selectedForwardMessages.find((o) => o === item._id)) {
      var newArr = selectedForwardMessages.filter((o) => o !== item._id);
      setSelectedForwardMessages([...newArr]);
    } else {
      setSelectedForwardMessages([...selectedForwardMessages, item._id]);
    }
  };

  const handleDeleteMessages = (item) => {
    if (item.sender.username === userObj.username) {
      if (selectedDeleteMessages.find((o) => o === item._id)) {
        var newArr = selectedDeleteMessages.filter((o) => o !== item._id);
        setSelectedDeleteMessages([...newArr]);
      } else {
        setSelectedDeleteMessages([...selectedDeleteMessages, item._id]);
      }
    } else {
      message.warning("You can not delete other user's messages");
    }
  };

  const handleSelectedMessages = (item) => {
    if (item.sender.username === userObj.username) {
      if (selectedDeleteMessages.find((o) => o === item._id)) {
        var newArr = selectedDeleteMessages.filter((o) => o !== item._id);
        setSelectedDeleteMessages([...newArr]);
      } else {
        setSelectedDeleteMessages([...selectedDeleteMessages, item._id]);
      }
      if (selectedForwardMessages.find((o) => o === item._id)) {
        var newArr = selectedForwardMessages.filter((o) => o !== item._id);
        setSelectedForwardMessages([...newArr]);
      } else {
        setSelectedForwardMessages([...selectedForwardMessages, item._id]);
      }
    } else {
      if (selectedForwardMessages.find((o) => o === item._id)) {
        var newArr = selectedForwardMessages.filter((o) => o !== item._id);
        setSelectedForwardMessages([...newArr]);
      } else {
        setSelectedForwardMessages([...selectedForwardMessages, item._id]);
      }
    }

    // console.log(selectedActionMessages, item, "knwlkwneldkwnelkd");
  };

  // useEffect(() => {
  //   console.log(selectedActionMessages, "knwlkwneldkwnelkd");
  // }, [selectedActionMessages]);

  return (
    <>
      <div
        // className="gray-scroll"
        className={
          localStorage.getItem("mode") === "dark"
            ? "hidden-sidebar black-scroll2"
            : ""
        }
        // id="your_div"
        style={{
          padding: "20px",
          width: "100%",
          // paddingBottom: "60px",
          overflowY: "scroll",
          height:
            chatBoxArr.length > 0
              ? window.innerHeight - 160
              : window.innerHeight - 120,
        }}
      >
        {showConditionalShowOldMessageButton()}
        {/* {console.log(messagearray, "kjkdgwewqdq")} */}
        {!messageLoading ? (
          messagearray.length > 0 ? (
            messagearray.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    // onmouse={(e) =>
                    //   (e.target.style.background = globalColors.chatHoover)
                    // }
                    // onMouseLeave={(e) =>
                    //   (e.target.style.background = globalColors.chatBackground)
                    // }
                    // onMouseOut={(e) =>
                    //   (e.target.style.background = globalColors.chatBackground)
                    // }
                  >
                    {showConditionalDate(item, index, messagearray[index - 1])}
                    {item.delete ? (
                      item.sender.email === userObj.email ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: globalColors.chatText,
                          }}
                          className={
                            selectedEditMessage
                              ? selectedEditMessage.timestamp === item.timestamp
                                ? globalColors.darkMode
                                  ? "selected-chat-dark"
                                  : "selected-chat"
                                : globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                              : selectedForwardMessages.length > 0
                              ? selectedForwardMessages.find(
                                  (o) => o === item._id
                                )
                                ? globalColors.darkMode
                                  ? "selected-chat-dark"
                                  : "selected-chat"
                                : globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                              : selectedDeleteMessages.length > 0
                              ? selectedDeleteMessages.find(
                                  (o) => o === item._id
                                )
                                ? globalColors.darkMode
                                  ? "selected-chat-dark"
                                  : "selected-chat"
                                : globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                              : globalColors.darkMode
                              ? "each-chat-dark"
                              : "each-chat"
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              padding: timeDifference(item, index, messagearray)
                                ? "2px 0px"
                                : "10px 0px",
                              marginTop: timeDifference(
                                item,
                                index,
                                messagearray
                              )
                                ? "-10px"
                                : "",
                            }}
                          >
                            {timeDifference(item, index, messagearray) ? (
                              ""
                            ) : (
                              <div>
                                {typeof item.sender === "object" &&
                                item.sender !== null ? (
                                  <Avatar src={item.sender.avatar} />
                                ) : (
                                  <Avatar
                                    style={{
                                      color: "#f56a00",
                                      backgroundColor: "#fde3cf",
                                      // margin: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    S
                                  </Avatar>
                                )}
                              </div>
                            )}

                            <div style={{ paddingLeft: "10px" }}>
                              {timeDifference(item, index, messagearray) ? (
                                ""
                              ) : (
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        localStorage.getItem("mode") === "dark"
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {selectedSupport !== null
                                      ? adminUsernames.length > 0
                                        ? adminUsernames.indexOf(
                                            typeof item.sender === "object" &&
                                              item.sender !== null
                                              ? item.sender.first_name
                                              : item.sender
                                          ) > -1
                                          ? "Support"
                                          : typeof item.sender === "object" &&
                                            item.sender !== null
                                          ? item.sender.first_name
                                          : "Support"
                                        : ""
                                      : item.sender.first_name}
                                  </span>{" "}
                                  &nbsp;
                                  <span style={{ color: " #8D8D8D" }}>
                                    <small>
                                      {formatAMPM(new Date(item.timestamp))}
                                      {/* {new Date(item.timestamp).toLocaleString()} */}
                                      &nbsp; {item.edit ? <EditOutlined /> : ""}{" "}
                                      &nbsp;{" "}
                                      {item.forward ? (
                                        <Space>
                                          <RetweetOutlined /> Forwarded
                                        </Space>
                                      ) : (
                                        ""
                                      )}
                                    </small>
                                  </span>
                                </div>
                              )}

                              <div
                                style={{
                                  paddingTop: "5px",
                                  paddingLeft: timeDifference(
                                    item,
                                    index,
                                    messagearray
                                  )
                                    ? "31px"
                                    : "",
                                  color:
                                    localStorage.getItem("mode") === "dark"
                                      ? "white"
                                      : "",
                                }}
                              >
                                <div>This Message has been deleted</div>
                                {/* {conditionalMessageView(item)} */}

                                {/* {item.thread_messages_count > 0 ? (
                                  <div
                                    style={{
                                      paddingTop: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      onClick={(e) => {
                                        setThreadItem(item);
                                        setShowThread(!showThread);
                                      }}
                                    >
                                      Reply
                                    </Button>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>
                                      <MessageOutlined />
                                      &nbsp; {item.thread_messages_count}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      <Dropdown
                        overlay={
                          <Menu
                            style={{
                              border: "solid 1px rgba(128, 128, 128, 0.1)",
                              borderRadius: "10px",
                            }}
                          >
                            <Menu.Item
                              key="0"
                              onClick={(e) => {
                                setSelectedItem(item);
                                // pinMessage(item);
                                setShowReactions(true);
                              }}
                            >
                              <SmileTwoTone />
                              &nbsp;&nbsp;React
                            </Menu.Item>
                            <Menu.Divider style={{ margin: "0px" }} />
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                handleCopy(item);
                              }}
                            >
                              <CopyTwoTone />
                              &nbsp;&nbsp;Copy Message
                            </Menu.Item>

                            <Menu.Divider style={{ margin: "0px" }} />
                            <Menu.Item
                              key="2"
                              onClick={(e) => {
                                setSelectedReplyMessage(item);
                                bodyRef.current.focus();
                              }}
                            >
                              <InteractionTwoTone />
                              &nbsp;&nbsp;Quote & Reply
                            </Menu.Item>
                            <Menu.Divider style={{ margin: "0px" }} />
                            <Menu.Item
                              key="3"
                              onClick={(e) => {
                                setThreadItem(item);
                                setShowThread(true);
                                // bodyRef1.current.focus();
                              }}
                            >
                              <MessageTwoTone />
                              &nbsp;&nbsp;Reply in thread
                            </Menu.Item>
                            <Menu.Divider style={{ margin: "0px" }} />
                            {!item.type ? (
                              item.sender.username && userObj ? (
                                item.sender.username === userObj.username ? (
                                  <>
                                    <Menu.Item
                                      key="4"
                                      onClick={(e) => {
                                        bodyRef.current.focus();
                                        setEditorText(item.message);
                                        // bodyRef.current.innerHTML = JSON.parse(
                                        //   item.message
                                        // );
                                        setSelectedEditMessage(item);
                                      }}
                                    >
                                      <EditTwoTone />
                                      &nbsp;&nbsp;Edit Message
                                    </Menu.Item>
                                    <Menu.Divider style={{ margin: "0px" }} />
                                  </>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            <Menu.Item
                              key="5"
                              onClick={(e) => {
                                handleShowMessageDetails(item);
                              }}
                            >
                              <InfoCircleTwoTone />
                              &nbsp;&nbsp;Message Details
                            </Menu.Item>
                            <Menu.Divider style={{ margin: "0px" }} />
                            <Menu.Item
                              key="6"
                              onClick={(e) => {
                                pinMessage(item);
                              }}
                            >
                              <PushpinTwoTone />
                              &nbsp;&nbsp;Pin Message
                            </Menu.Item>
                            <Menu.Divider style={{ margin: "0px" }} />
                            <Menu.Item
                              key="7"
                              onClick={(e) => {
                                setSelectedDeleteMessages([]);
                                handleForwardMessages(item);
                              }}
                            >
                              <DiffTwoTone />
                              &nbsp;&nbsp;Forward Message
                            </Menu.Item>

                            {item.sender.username && userObj && !item.delete ? (
                              item.sender.username === userObj.username ? (
                                <>
                                  <Menu.Divider style={{ margin: "0px" }} />
                                  <Menu.Item
                                    style={{ color: "red" }}
                                    key="8"
                                    onClick={(e) => {
                                      setSelectedForwardMessages([]);
                                      handleDeleteMessages(item);
                                    }}
                                  >
                                    <DeleteTwoTone twoToneColor="red" />
                                    &nbsp;&nbsp;Delete Message
                                  </Menu.Item>
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            {/* <Menu.Divider style={{ margin: "0px" }} /> */}
                            {/* <Menu.Item key="8">
                            <CloseOutlined />
                            &nbsp;Dismiss
                          </Menu.Item> */}
                          </Menu>
                        }
                        trigger={["contextMenu"]}
                      >
                        <div
                          onClick={(e) => {
                            if (selectedDeleteMessages.length > 0) {
                              handleDeleteMessages(item);
                            } else if (selectedForwardMessages.length > 0)
                              handleForwardMessages(item);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: globalColors.chatText,
                          }}
                          className={
                            selectedEditMessage
                              ? selectedEditMessage.timestamp === item.timestamp
                                ? globalColors.darkMode
                                  ? "selected-chat-dark"
                                  : "selected-chat"
                                : globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                              : selectedForwardMessages.length > 0
                              ? selectedForwardMessages.find(
                                  (o) => o === item._id
                                )
                                ? globalColors.darkMode
                                  ? "selected-chat-dark"
                                  : "selected-chat"
                                : globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                              : selectedDeleteMessages.length > 0
                              ? selectedDeleteMessages.find(
                                  (o) => o === item._id
                                )
                                ? globalColors.darkMode
                                  ? "selected-chat-dark"
                                  : "selected-chat"
                                : globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                              : globalColors.darkMode
                              ? "each-chat-dark"
                              : "each-chat"
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              padding: timeDifference(item, index, messagearray)
                                ? "2px 0px"
                                : "10px 0px",
                              marginTop: timeDifference(
                                item,
                                index,
                                messagearray
                              )
                                ? "-10px"
                                : "",
                            }}
                          >
                            {timeDifference(item, index, messagearray) ? (
                              ""
                            ) : (
                              <div>
                                {typeof item.sender === "object" &&
                                item.sender !== null ? (
                                  <Avatar src={item.sender.avatar} />
                                ) : (
                                  <Avatar
                                    style={{
                                      color: "#f56a00",
                                      backgroundColor: "#fde3cf",
                                      // margin: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    S
                                  </Avatar>
                                )}
                              </div>
                            )}

                            <div style={{ paddingLeft: "10px" }}>
                              {timeDifference(item, index, messagearray) ? (
                                ""
                              ) : (
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        localStorage.getItem("mode") === "dark"
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {selectedSupport !== null
                                      ? adminUsernames.length > 0
                                        ? adminUsernames.indexOf(
                                            typeof item.sender === "object" &&
                                              item.sender !== null
                                              ? item.sender.first_name
                                              : item.sender
                                          ) > -1
                                          ? "Support"
                                          : typeof item.sender === "object" &&
                                            item.sender !== null
                                          ? item.sender.first_name
                                          : "Support"
                                        : ""
                                      : item.sender.first_name}
                                  </span>{" "}
                                  &nbsp;
                                  <span style={{ color: " #8D8D8D" }}>
                                    <small>
                                      {formatAMPM(new Date(item.timestamp))}
                                      {/* {new Date(item.timestamp).toLocaleString()} */}
                                      &nbsp; {item.edit ? <EditOutlined /> : ""}{" "}
                                      &nbsp;{" "}
                                      {item.forward ? (
                                        <Space>
                                          <RetweetOutlined /> Forwarded
                                        </Space>
                                      ) : (
                                        ""
                                      )}
                                    </small>
                                  </span>
                                  &nbsp;{" "}
                                  {submitLoading1 &&
                                  index + 1 === messagearray.length ? (
                                    <span style={{ color: " #8D8D8D" }}>
                                      <LoadingOutlined
                                        style={{
                                          fontSize: "10px",
                                          paddingTop: "3px",
                                        }}
                                      />
                                      &nbsp; &nbsp; Uploading...
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}

                              <div
                                style={{
                                  paddingTop: "5px",
                                  paddingLeft: timeDifference(
                                    item,
                                    index,
                                    messagearray
                                  )
                                    ? "31px"
                                    : "",
                                  color:
                                    localStorage.getItem("mode") === "dark"
                                      ? "white"
                                      : "",
                                }}
                              >
                                {conditionalMessageView(item)}

                                {/* {timeDifference(item, index) ? (
                                <>
                                  <small>
                                    {formatAMPM(new Date(item.timestamp))}
                                  </small>
                                  &nbsp;&nbsp;
                                  {item.edit ? (
                                    <>
                                      <EditOutlined className="hidden-timestamp" />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )} */}

                                {item.thread_messages_count > 0 ? (
                                  <div
                                    style={{
                                      paddingTop: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      onClick={(e) => {
                                        setThreadItem(item);
                                        setShowThread(!showThread);
                                      }}
                                    >
                                      Reply
                                    </Button>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>
                                      <MessageOutlined />
                                      &nbsp; {item.thread_messages_count}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropdown>
                    )}
                  </div>
                </>
              );
            })
          ) : (
            <Empty />
          )
        ) : !blankPage ? (
          <div
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              width: "100%",
            }}
          >
            <Skeleton avatar paragraph={{ rows: 2 }} />
            <Skeleton avatar paragraph={{ rows: 4 }} />
            <Skeleton avatar paragraph={{ rows: 2 }} />
            <Skeleton avatar paragraph={{ rows: 4 }} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              fontSize: "18px",
              fontWeight: "bold",
              color: "gray",
            }}
          >
            Please select a friend/a group from the list to start chatting
          </div>
        )}
        {handleMovetoBottom()}

        {/* <div
          style={{
            padding: "20px",
            paddingBottom: "10px",
            overflowY: "scroll",
            // marginTop: "-15vh",
          }}
        >
          {typingFlag ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
                // backgroundColor: "#EEEEEE",
                padding: "5px 25px",
                // color: "white",
                borderRadius: "20px",
                textAlign: "justify",
                color: "gray",
              }}
            >
              {typingUser}&nbsp;is typing&nbsp;&nbsp;&nbsp;
              <div class="ticontainer">
                <div class="tiblock">
                  <div class="tidot"></div>
                  <div class="tidot"></div>
                  <div class="tidot"></div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div> */}
        <div
          id="my_div"
          style={{ float: "left", clear: "both" }}
          ref={scrollRef}
        ></div>
      </div>

      <Drawer
        bodyStyle={{
          background: globalColors.drawerBackground,
          marging: "0px",
          padding: "10px",
          paddingBottom: "40px",
          height: "84vh",
          overflowY: "scroll",
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={<span style={{ color: globalColors.chatText }}>Thread</span>}
        // bodyStyle={{
        //   marging: "0px",
        //   padding: "0px",
        //   paddingBottom: "40px",
        //   height: "84vh",
        //   overflowY: "scroll",
        // }}

        placement="right"
        width={640}
        // height=""
        closable={true}
        onClose={(e) => {
          setShowThread(false);
          setThreadItem(null);
          setThreadMessagearray([]);
        }}
        visible={showThread}

        // style={{
        //   display: "flex",
        //   flexDirection: "column",
        //   justifyContent: "space-between",
        // }}
      >
        {threadItem ? (
          <>
            <div
              className="white-scroll"
              style={{ color: globalColors.chatText }}
            >
              <Divider
                style={{
                  color: globalColors.darkMode ? "gray" : "lightgray",
                }}
              >
                {new Date(threadItem.timestamp).toDateString()}
              </Divider>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="0"
                      onClick={(e) => {
                        setSelectedItem(threadItem);
                        // pinMessage(item);
                        setShowReactions(true);
                      }}
                    >
                      <SmileOutlined />
                      &nbsp;React
                    </Menu.Item>
                    <Menu.Item
                      key="1"
                      onClick={(e) => {
                        pinMessage(threadItem);
                      }}
                    >
                      <PushpinOutlined />
                      &nbsp;Pin Message
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={(e) => {
                        setSelectedReplyMessage1(threadItem);
                        bodyRef1.current.focus();
                      }}
                    >
                      <RollbackOutlined />
                      &nbsp;Quote & Reply
                    </Menu.Item>

                    <Menu.Divider />
                    <Menu.Item key="3">Dismiss</Menu.Item>
                  </Menu>
                }
                trigger={["contextMenu"]}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className={
                    globalColors.darkMode ? "each-chat-dark" : "each-chat"
                  }
                >
                  <div style={{ display: "flex", padding: "10px 0px" }}>
                    <div>
                      {typeof threadItem.sender === "object" &&
                      threadItem.sender !== null ? (
                        <Avatar src={threadItem.sender.avatar} />
                      ) : (
                        <Avatar
                          style={{
                            color: "#f56a00",
                            backgroundColor: "#fde3cf",
                            // margin: "10px",
                            cursor: "pointer",
                          }}
                        >
                          S
                        </Avatar>
                      )}
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <div>
                        <span
                          style={{
                            fontWeight: "bold",
                            color:
                              localStorage.getItem("mode") === "dark"
                                ? "white"
                                : "",
                          }}
                        >
                          {selectedSupport !== null
                            ? adminUsernames.length > 0
                              ? adminUsernames.indexOf(
                                  typeof threadItem.sender === "object" &&
                                    threadItem.sender !== null
                                    ? threadItem.sender.username
                                    : threadItem.sender
                                ) > -1
                                ? "Support"
                                : typeof threadItem.sender === "object" &&
                                  threadItem.sender !== null
                                ? threadItem.sender.username
                                : "Support"
                              : ""
                            : threadItem.sender.username}
                        </span>{" "}
                        &nbsp;
                        <span style={{ color: " #8D8D8D" }}>
                          <small>
                            {formatAMPM(new Date(threadItem.timestamp))}
                            {/* {new Date(item.timestamp).toLocaleString()} */}
                            &nbsp; {threadItem.edit ? <EditOutlined /> : ""}
                          </small>
                        </span>
                      </div>
                      <div
                        style={{
                          paddingTop: "5px",
                          color:
                            localStorage.getItem("mode") === "dark"
                              ? "white"
                              : "",
                        }}
                      >
                        {conditionalMessageView(threadItem)}
                        {/* {threadItem.reply_thread ? (
                          <div
                            style={{
                              paddingTop: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={(e) => {
                                setThreadItem(threadItem);
                                setShowThread(!showThread);
                              }}
                            >
                              Reply
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div>
                            
                              &nbsp;{" "}
                              {threadItem
                                ? threadItem.reply_thread_data.length
                                : ""}
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown>
              {threadMessagearray
                ? threadMessagearray.map((item, index) => {
                    return (
                      <div key={index}>
                        {showConditionalDate(
                          item,
                          index,
                          threadMessagearray[index - 1]
                        )}
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="0"
                                onClick={(e) => {
                                  setSelectedItem(item);
                                  // pinMessage(item);
                                  setShowReactions(true);
                                }}
                              >
                                <SmileOutlined />
                                &nbsp;React
                              </Menu.Item>
                              <Menu.Item
                                key="1"
                                onClick={(e) => {
                                  pinMessage(item);
                                }}
                              >
                                <PushpinOutlined />
                                &nbsp;Pin Message
                              </Menu.Item>
                              <Menu.Item
                                key="2"
                                onClick={(e) => {
                                  setSelectedReplyMessage1(item);
                                  bodyRef1.current.focus();
                                }}
                              >
                                <RollbackOutlined />
                                &nbsp;Quote & Reply
                              </Menu.Item>
                              <Menu.Item
                                key="3"
                                onClick={(e) => {
                                  bodyRef1.current.focus();
                                  setEditorTextThread(item.message);
                                  // bodyRef.current.innerHTML = JSON.parse(
                                  //   item.message
                                  // );
                                  setSelectedEditMessage(item);
                                }}
                              >
                                <EditOutlined />
                                &nbsp;Edit Message
                              </Menu.Item>
                              <Menu.Item
                                key="4"
                                onClick={(e) =>
                                  conditionalDeleteChat(
                                    item,
                                    messagearray.length > 1
                                      ? messagearray[index - 1]
                                      : messagearray[index]
                                  )
                                }
                              >
                                <DeleteOutlined />
                                &nbsp;Delete
                              </Menu.Item>

                              <Menu.Divider />
                              <Menu.Item key="5">Dismiss</Menu.Item>
                            </Menu>
                          }
                          trigger={["contextMenu"]}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className={
                              globalColors.darkMode
                                ? "each-chat-dark"
                                : "each-chat"
                            }
                          >
                            <div
                              style={{ display: "flex", padding: "10px 0px" }}
                            >
                              <div>
                                {typeof item.sender === "object" &&
                                item.sender !== null ? (
                                  <Avatar src={item.sender.avatar} />
                                ) : (
                                  <Avatar
                                    style={{
                                      color: "#f56a00",
                                      backgroundColor: "#fde3cf",
                                      // margin: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    S
                                  </Avatar>
                                )}
                              </div>
                              <div style={{ paddingLeft: "10px" }}>
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        localStorage.getItem("mode") === "dark"
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {selectedSupport !== null
                                      ? adminUsernames.length > 0
                                        ? adminUsernames.indexOf(
                                            typeof item.sender === "object" &&
                                              item.sender !== null
                                              ? item.sender.username
                                              : item.sender
                                          ) > -1
                                          ? "Support"
                                          : typeof item.sender === "object" &&
                                            item.sender !== null
                                          ? item.sender.username
                                          : "Support"
                                        : ""
                                      : item.sender.username}
                                  </span>{" "}
                                  &nbsp;
                                  <span style={{ color: " #8D8D8D" }}>
                                    <small>
                                      {formatAMPM(new Date(item.timestamp))}
                                    </small>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    paddingTop: "5px",
                                    color:
                                      localStorage.getItem("mode") === "dark"
                                        ? "white"
                                        : "",
                                  }}
                                >
                                  {conditionalMessageView(item)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div style={{ width: "640px" }}>
              <EditorThread />
            </div>
          </>
        ) : (
          ""
        )}
      </Drawer>
      <Modal
        className="reaction-modal"
        style={{ backgroundColor: "transparent", zIndex: "9999999" }}
        width={338}
        footer={null}
        closable={false}
        // title="Basic Modal"
        visible={showReactions}
        // onOk={handleOk}
        onCancel={(e) => setShowReactions(false)}
      >
        {/* Hiiii */}
        {/* <Picker set="apple" /> */}
        <Picker onSelect={addReaction} skin={2} title="Teamforce" />
      </Modal>
      <Drawer
        width={300}
        title="Message Details"
        placement="right"
        closable={false}
        onClose={(e) => setShowMessageDetails(false)}
        visible={showMessageDetails}
        bodyStyle={{ margin: "0px", padding: "0px" }}
      >
        <div style={{ paddingLeft: "25px", paddingTop: "20px" }}>
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sender</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{ border: "solid 1px gray" }}
              size="large"
              src={selectedMessage ? selectedMessage.sender.avatar : ""}
            />
            <div style={{ paddingLeft: "10px" }}>
              <div>
                {selectedMessage ? (
                  <span style={{ fontWeight: "bold" }}>
                    {selectedMessage.sender.first_name}&nbsp;
                    {selectedMessage.sender.last_name}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>{selectedMessage ? selectedMessage.sender.email : ""}</div>
            </div>
          </div>
        </div>
        {selectedFriend ? (
          <div
            style={{
              paddingLeft: "25px",
              paddingTop: "25px",
              paddingBottom: "10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>Receiver</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                style={{ border: "solid 1px gray" }}
                size="large"
                src={userObj ? userObj.avatar : ""}
              />
              <div style={{ paddingLeft: "10px" }}>
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    {userObj ? userObj.first_name : ""}&nbsp;
                    {userObj ? userObj.last_name : ""}
                  </span>
                </div>
                <div>{userObj ? userObj.email : ""}</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Divider />
        <div>
          <div style={{ paddingLeft: "25px" }}>
            <Avatar
              style={{ border: "solid 1px lightgray" }}
              shape="square"
              size="small"
              src={selectedMessage ? selectedMessage.sender.avatar : ""}
            />
            &nbsp;
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              {selectedMessage ? selectedMessage.sender.first_name : ""}&nbsp;
              {selectedMessage ? selectedMessage.sender.last_name : ""}
            </span>
          </div>
          <div
            style={{
              margin: "10px 25px",
              // paddingLeft: "25px",
              padding: "10px",

              border: "solid 1px rgba(128, 128, 128, .2)",
            }}
            // title="Default size card"
            // extra={<a href="#">More</a>}
          >
            <div style={{ fontWeight: "bold" }}>Sends Message</div>
            <small>
              {selectedMessage
                ? new Date(selectedMessage.sent_at).toUTCString()
                : ""}
            </small>
          </div>
        </div>
        <div style={{ paddingTop: "20px" }}>
          <div style={{ paddingLeft: "25px" }}>
            <Avatar
              style={{ border: "solid 1px lightgray" }}
              shape="square"
              size="small"
              src={userObj ? userObj.avatar : ""}
            />
            &nbsp;
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              {userObj ? userObj.first_name : ""}&nbsp;
              {userObj ? userObj.last_name : ""}
            </span>
          </div>
          <div
            style={{
              margin: "10px 25px",
              // paddingLeft: "25px",
              padding: "10px",
              border: "solid 1px lightgray",
            }}
            // title="Default size card"
            // extra={<a href="#">More</a>}
          >
            <div style={{ fontWeight: "bold" }}>Message Is Delivered</div>
            <small>
              {selectedMessage
                ? new Date(selectedMessage.delivered_at).toUTCString()
                : ""}
            </small>
          </div>
        </div>
        {selectedMessage ? (
          selectedMessage.ignored_at ? (
            <div style={{ paddingTop: "20px" }}>
              <div style={{ paddingLeft: "25px" }}>
                <Avatar
                  style={{ border: "solid 1px lightgray" }}
                  shape="square"
                  size="small"
                  src={userObj ? userObj.avatar : ""}
                />
                &nbsp;
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                  {userObj ? userObj.first_name : ""}&nbsp;
                  {userObj ? userObj.last_name : ""}
                </span>
              </div>
              <div
                style={{
                  margin: "10px 25px",
                  // paddingLeft: "25px",
                  padding: "10px",
                  border: "solid 1px lightgray",
                }}
                // title="Default size card"
                // extra={<a href="#">More</a>}
              >
                <div style={{ fontWeight: "bold" }}>Ignores Message</div>
                <small>
                  {selectedMessage
                    ? new Date(selectedMessage.ignored_at).toUTCString()
                    : ""}
                </small>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {selectedMessage ? (
          selectedMessage.seen_at ? (
            <div style={{ paddingTop: "20px" }}>
              <div style={{ paddingLeft: "25px" }}>
                <Avatar
                  style={{ border: "solid 1px lightgray" }}
                  shape="square"
                  size="small"
                  src={userObj ? userObj.avatar : ""}
                />
                &nbsp;
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                  {userObj ? userObj.first_name : ""}&nbsp;
                  {userObj ? userObj.last_name : ""}
                </span>
              </div>
              <div
                style={{
                  margin: "10px 25px",
                  // paddingLeft: "25px",
                  padding: "10px",
                  border: "solid 1px lightgray",
                }}
                // title="Default size card"
                // extra={<a href="#">More</a>}
              >
                <div style={{ fontWeight: "bold" }}>Reads Message</div>
                <small>
                  {selectedMessage
                    ? new Date(selectedMessage.seen_at).toUTCString()
                    : ""}
                </small>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Drawer>

      {/* <Drawer
        width={300}
        title="Message Details"
        placement="right"
        closable={false}
        onClose={(e) => setShowGroupMessageDetails(false)}
        visible={showGroupMessageDetails}
        bodyStyle={{ margin: "0px", padding: "20px" }}
      >
        <p>Select Group Member:</p>
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={handleMemberChange}
          // onFocus={onFocus}
          // onBlur={onBlur}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {groupMembers.map((item) => {
            return (
              <Option value={JSON.stringify(item)}>
                <Avatar
                  src={item.avatar}
                  size="small"
                  style={{
                    color: "white",
                    backgroundColor: "white",
                    cursor: "pointer",
                    border: "solid 1px gray",
                  }}
                />
                &nbsp;&nbsp;
                {item.first_name}&nbsp;{item.last_name}
              </Option>
            );
          })}
        </Select>
      </Drawer> */}

      <Drawer
        destroyOnClose
        width={300}
        title="Message Details"
        placement="right"
        closable={false}
        onClose={(e) => {
          setShowGroupMessageDetails(false);
          setSelectedGroupMember(null);
        }}
        visible={showGroupMessageDetails}
        bodyStyle={{ margin: "0px", padding: "0px" }}
      >
        <div style={{ paddingLeft: "25px", paddingTop: "20px" }}>
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sender</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{ border: "solid 1px gray" }}
              size="large"
              src={selectedMessage ? selectedMessage.sender.avatar : ""}
            />
            <div style={{ paddingLeft: "10px" }}>
              <div>
                {selectedMessage ? (
                  <span style={{ fontWeight: "bold" }}>
                    {selectedMessage.sender.first_name}&nbsp;
                    {selectedMessage.sender.last_name}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>{selectedMessage ? selectedMessage.sender.email : ""}</div>
            </div>
          </div>
        </div>

        <div
          style={{
            paddingLeft: "25px",
            paddingTop: "25px",
            paddingBottom: "10px",
            paddingRight: "25px",
          }}
        >
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>Receiver</p>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={handleMemberChange}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {groupMembers.map((item) => {
              return (
                <Option value={JSON.stringify(item)}>
                  <Avatar
                    src={item.avatar}
                    size="small"
                    style={{
                      color: "white",
                      backgroundColor: "white",
                      cursor: "pointer",
                      border: "solid 1px gray",
                    }}
                  />
                  &nbsp;&nbsp;
                  {item.first_name}&nbsp;{item.last_name}
                </Option>
              );
            })}
          </Select>
        </div>

        <Divider />
        {selectedGroupMember ? (
          <>
            <div>
              <div style={{ paddingLeft: "25px" }}>
                <Avatar
                  style={{ border: "solid 1px lightgray" }}
                  shape="square"
                  size="small"
                  src={selectedMessage ? selectedMessage.sender.avatar : ""}
                />
                &nbsp;
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                  {selectedMessage ? selectedMessage.sender.first_name : ""}
                  &nbsp;
                  {selectedMessage ? selectedMessage.sender.last_name : ""}
                </span>
              </div>
              <div
                style={{
                  margin: "10px 25px",
                  // paddingLeft: "25px",
                  padding: "10px",

                  border: "solid 1px rgba(128, 128, 128, .2)",
                }}
                // title="Default size card"
                // extra={<a href="#">More</a>}
              >
                <div style={{ fontWeight: "bold" }}>Sends Message</div>
                <small>
                  {selectedMessage
                    ? new Date(selectedMessage.timestamp).toUTCString()
                    : ""}
                </small>
              </div>
            </div>
            {selectedGroupMember.timestamp.delivered_at ? (
              <div style={{ paddingTop: "20px" }}>
                <div style={{ paddingLeft: "25px" }}>
                  <Avatar
                    style={{ border: "solid 1px lightgray" }}
                    shape="square"
                    size="small"
                    src={selectedGroupMember.details.avatar}
                  />
                  &nbsp;
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    {selectedGroupMember.details.first_name}&nbsp;
                    {selectedGroupMember.details.last_name}
                  </span>
                </div>
                <div
                  style={{
                    margin: "10px 25px",
                    // paddingLeft: "25px",
                    padding: "10px",
                    border: "solid 1px lightgray",
                  }}
                  // title="Default size card"
                  // extra={<a href="#">More</a>}
                >
                  <div style={{ fontWeight: "bold" }}>Message Is Delivered</div>
                  <small>
                    {selectedMessage
                      ? new Date(
                          selectedGroupMember.timestamp.delivered_at
                        ).toUTCString()
                      : ""}
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}

            {selectedGroupMember.timestamp.ignored_at ? (
              <div style={{ paddingTop: "20px" }}>
                <div style={{ paddingLeft: "25px" }}>
                  <Avatar
                    style={{ border: "solid 1px lightgray" }}
                    shape="square"
                    size="small"
                    src={selectedGroupMember.details.avatar}
                  />
                  &nbsp;
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    {selectedGroupMember.details.first_name}&nbsp;
                    {selectedGroupMember.details.last_name}
                  </span>
                </div>
                <div
                  style={{
                    margin: "10px 25px",
                    // paddingLeft: "25px",
                    padding: "10px",
                    border: "solid 1px lightgray",
                  }}
                  // title="Default size card"
                  // extra={<a href="#">More</a>}
                >
                  <div style={{ fontWeight: "bold" }}>Ignores Message</div>
                  <small>
                    {new Date(
                      selectedGroupMember.timestamp.ignored_at
                    ).toUTCString()}
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}
            {selectedGroupMember.timestamp.seen_at ? (
              <div style={{ paddingTop: "20px" }}>
                <div style={{ paddingLeft: "25px" }}>
                  <Avatar
                    style={{ border: "solid 1px lightgray" }}
                    shape="square"
                    size="small"
                    src={selectedGroupMember.details.avatar}
                  />
                  &nbsp;
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    {selectedGroupMember.details.first_name}&nbsp;
                    {selectedGroupMember.details.last_name}
                  </span>
                </div>
                <div
                  style={{
                    margin: "10px 25px",
                    // paddingLeft: "25px",
                    padding: "10px",
                    border: "solid 1px lightgray",
                  }}
                  // title="Default size card"
                  // extra={<a href="#">More</a>}
                >
                  <div style={{ fontWeight: "bold" }}>Reads Message</div>
                  <small>
                    {new Date(
                      selectedGroupMember.timestamp.seen_at
                    ).toUTCString()}
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Drawer>
    </>
  );
};

export default AllChats;

import React, { useState, useEffect } from "react";
import { Card } from "antd";

const Setting = ({ setVisibleControlPanel, setVisibleSetting }) => {
  const handleThemeSelection = (item) => {
    localStorage.setItem("theme", item);
    console.log(item, "theme");
    setVisibleControlPanel(false);
    setVisibleSetting(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Card
            onClick={(e) => handleThemeSelection("rocket-chat")}
            hoverable
            bodyStyle={{ padding: "0px" }}
            style={{
              border:
                localStorage.getItem("theme") === "rocket-chat"
                  ? "solid 2px blue"
                  : "none",
            }}
          >
            <img
              src={require("../../images/themes/rocket-chat.png")}
              alt=""
              // style={{ borderRadius: "10px 10px 0 0" }}
              width="300px"
            />
            <div
              style={{ fontSize: "18px", fontWeight: "bold", padding: "10px" }}
            >
              Theme - 1
            </div>
          </Card>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div style={{ textAlign: "center" }}>
          <Card
            style={{
              border:
                localStorage.getItem("theme") === "telegram"
                  ? "solid 2px blue"
                  : "none",
            }}
            onClick={(e) => handleThemeSelection("telegram")}
            hoverable
            bodyStyle={{ padding: "0px" }}
            // style={{ borderRadius: "10px" }}
          >
            <img
              src={require("../../images/themes/telegram.png")}
              alt=""
              // style={{ borderRadius: "10px 10px 0 0" }}
              width="300px"
            />
            <div
              style={{ fontSize: "18px", fontWeight: "bold", padding: "10px" }}
            >
              Theme - 2
            </div>
          </Card>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div style={{ textAlign: "center" }}>
          <Card
            style={{
              border:
                localStorage.getItem("theme") === "whatsapp"
                  ? "solid 2px blue"
                  : "none",
            }}
            onClick={(e) => handleThemeSelection("whatsapp")}
            hoverable
            bodyStyle={{ padding: "0px" }}
            // style={{ borderRadius: "10px" }}
          >
            <img
              src={require("../../images/themes/whatsapp.png")}
              alt=""
              // style={{ borderRadius: "10px 10px 0 0" }}
              width="300px"
            />
            <div
              style={{ fontSize: "18px", fontWeight: "bold", padding: "10px" }}
            >
              Theme - 3
            </div>
          </Card>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <div
          onClick={(e) => {
            localStorage.setItem("mode", "light");
            setVisibleControlPanel(false);
            setVisibleSetting(false);
          }}
          style={{
            border:
              localStorage.getItem("mode") === "light"
                ? " solid 2px blue"
                : "none",
            padding: "10px",
            background: "white",
            color: "black",
          }}
        >
          Light Mode
        </div>
        <div
          onClick={(e) => {
            localStorage.setItem("mode", "dark");
            setVisibleControlPanel(false);
            setVisibleSetting(false);
          }}
          style={{
            border:
              localStorage.getItem("mode") === "dark"
                ? " solid 2px blue"
                : "none",
            padding: "10px",
            background: "black",
            color: "white",
          }}
        >
          Dark Mode
        </div>
      </div>
    </div>
  );
};

export default Setting;

import React, { useState, useContext, useRef } from "react";
import urlParser from "js-video-url-parser";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { Popover, message, Modal, Spin, Mentions, Tooltip, List } from "antd";
import {
  CloseOutlined,
  SmileOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseCircleFilled,
  FileTwoTone,
  FileImageTwoTone,
  VideoCameraTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import S3 from "aws-s3";
import { MyContext } from "../../Context";
import { useEffect } from "react";
import Avatar from "antd/lib/avatar/avatar";

const { Option } = Mentions;

const EditorThread = () => {
  const {
    socket,
    usersocket,
    groupReadUnreadList,
    setgroupReadUnreadList,
    supportgroupReadUnreadList,
    setsupportgroupReadUnreadList,
    userReadUnreadList,
    setuserReadUnreadList,
    currentUserObj,
    selectedFriend,
    groupMembers,
    // setSelectedFriend,
    selectedGroup,
    // setSelectedGroup,
    selectedReplyMessage1,
    setSelectedReplyMessage1,
    selectedSupport,
    // setSelectedSupport,
    selectedAdminSupport,
    messagearray,
    setmessagearray,
    unreadInteractionList,
    setUnreadInteractionList,
    renderMessage,
    bodyRef1,
    getSupportList,
    selectedType,
    setSelectedType,
    activeTab,
    chatBoxArr,
    userObj,
    app_id,
    tokenValid,
    setTokenValid,
    threadItem,
    setThreadItem,
    threadMessagearray,
    setThreadMessagearray,
    editorTextThread,
    setEditorTextThread,
    allUserList,
    selectedEditMessage,
    setSelectedEditMessage,
    messageLoading,
    setallUserList,
    globalColors,
    handleUploadFile,
    submitLoading,
  } = useContext(MyContext);

  const uploadVideoRef = useRef();
  const uploadImageRef = useRef();
  const uploadFileRef = useRef();

  const [clipImage, setClipImage] = useState("");
  const [visiblePasteImage, setVisiblePasteImage] = useState(false);
  const [emojiShow, setemojiShow] = useState(false);
  // const [selectedReplyMessage1, setSelectedReplyMessage1] = useState({});
  const [loadingImage, setLoadingImage] = useState(false);
  const [memberUsernames, setMemberUsernames] = useState([]);
  const [submitLoadingThread, setSubmitLoadingThread] = useState(false);

  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  useEffect(() => {
    var tempData = [];
    groupMembers.map((item) => {
      tempData.push(item.username);
    });
    console.log(tempData, "jhjdwedfq");
    const index = tempData.indexOf(userObj.username);
    if (index > -1) {
      tempData.splice(index, 1);
    }
    console.log(tempData, "jhjdwedfq");
    setMemberUsernames([...tempData]);
  }, []);

  // const findMessageType = () => {
  //   let obj = urlParser.parse(editorTextThread);
  //   console.log("mediatype", obj.mediaType);
  //   if (obj !== undefined) {
  //     if (obj.mediaType === "video") {
  //       return "video";
  //     }
  //   }
  // };

  const refetchAllPrivateChats = () => {
    socket.emit(
      "get_direct_chat_reply_thread_messages",
      threadItem._id,
      app_id,
      (res) => {
        if (res.status) {
          setThreadMessagearray([...res.payload]);
        }
      }
    );
    setEditorTextThread("");
    // setEditorTextThread("");

    // threadItem.thread_messages_count = threadItem.thread_messages_count + 1;
    // console.log(messagearray[foundIndex], "jhgdqd");
    setLoadingImage(false);
  };

  const refetchAllGroupChats = () => {
    console.log("chat_historyqqqq");

    socket.emit(
      "get_group_chat_reply_thread_messages",
      threadItem._id,
      app_id,
      (res) => {
        if (res.status) {
          setThreadMessagearray([...res.payload]);
        }
      }
    );
    console.log(messagearray, "kjgldjwdgqw");
    var tempArr = messagearray;

    var foundIndex = tempArr.findIndex((x) => x._id == threadItem._id);
    console.log(foundIndex, tempArr[foundIndex], "kjgldjwdgqw");
    if (tempArr[foundIndex].thread_messages_count == NaN) {
      tempArr[foundIndex].thread_messages_count = 1;
    } else {
      tempArr[foundIndex].thread_messages_count =
        tempArr[foundIndex].thread_messages_count + 1;
    }

    console.log(tempArr, messageLoading, "kjgldjwdgqw");

    setmessagearray([...tempArr]);
    // threadItem.thread_messages_count = threadItem.thread_messages_count + 1;

    // if (messagearray[foundIndex].thread_messages_count > 0) {
    //   messagearray[foundIndex].thread_messages_count =
    //     messagearray[foundIndex].thread_messages_count + 1;
    // } else if (messagearray[foundIndex].thread_messages_count === 0) {
    //   console.log(messagearray, "kjgldjwdgqw");
    //   messagearray[foundIndex].thread_messages_count = 1;
    // }
    setLoadingImage(false);
  };

  // useEffect(() => {
  //   let tempList = messagearray;
  //   var foundIndex = tempList.findIndex(
  //     (x) => x.thread_id == threadItem.thread_id
  //   );
  //   console.log(
  //     foundIndex,
  //     tempList[foundIndex],
  //     tempList[foundIndex].thread_messages_count,
  //     "jbjhdwvejdhwvedjhw"
  //   );
  //   messagearray[foundIndex].thread_messages_count =
  //     messagearray[foundIndex].thread_messages_count + 1;
  //   // setgroupReadUnreadList([...tempList]);
  // }, [threadMessagearray]);

  const refetchAllSupportChats = () => {
    socket.emit(
      "get_support_chat_history_v2",
      selectedAdminSupport.thread_id,
      app_id,
      (resp) => {
        console.log("get_support_chat_history_v2", selectedAdminSupport);
        // setMessageLoading(false);
        setmessagearray([...resp.reverse()]);
        console.log(resp, "hjjhwcw");
        resp.map((item) => {
          if (item._id === threadItem._id) {
            setThreadItem(item);
          }
        });
        // setsetFlag(true);
        setEditorTextThread("");
      }
    );
    setLoadingImage(false);
  };

  useEffect(() => {
    setEditorTextThread("");
  }, [activeTab]);

  const handleSubmitPrivateChat = (e) => {
    console.log("hahah", userObj, selectedFriend);
    let obj = urlParser.parse(editorTextThread);
    console.log(obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorTextThread.length > 0) {
          let notification_data = {
            sender: userObj.username,
            receiver: selectedFriend.username,
          };

          usersocket.emit("new_message", app_id, notification_data);
          let tempArr = [...userReadUnreadList];
          tempArr = tempArr.filter(function (item) {
            return item !== selectedFriend.username;
          });
          setuserReadUnreadList([...tempArr]);
          socket.emit(
            "reply_thread_direct_message",
            threadItem._id,
            selectedFriend.username,
            {
              message: editorTextThread,
              type: "video",
              thread_id: selectedFriend.thread_id,
              sender: userObj,
              timestamp: Date.now(),
            },
            app_id,
            (response) => {
              if (response.status) {
                setSubmitLoadingThread(false);
                console.log(response, "kkbkwbdk");
                var foundIndex = messagearray.findIndex(
                  (x) => x._id == threadItem._id
                );
                console.log(messagearray, "kjgldjwdgqw");
                if (messagearray[foundIndex].thread_messages_count > 0) {
                  messagearray[foundIndex].thread_messages_count =
                    messagearray[foundIndex].thread_messages_count + 1;
                } else if (
                  messagearray[foundIndex].thread_messages_count === 0
                ) {
                  messagearray[foundIndex].thread_messages_count = 1;
                }
                refetchAllPrivateChats();
                setEditorTextThread("");

                // setInputText("");
                //for pushing msg =user to top
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorTextThread.length > 0) {
        let notification_data = {
          sender: userObj.username,
          receiver: selectedFriend.username,
        };

        usersocket.emit("new_message", app_id, notification_data);
        let tempArr = [...userReadUnreadList];
        tempArr = tempArr.filter(function (item) {
          return item !== selectedFriend.username;
        });
        setuserReadUnreadList([...tempArr]);
        socket.emit(
          "reply_thread_direct_message",
          threadItem._id,
          selectedFriend.username,
          {
            message: editorTextThread,
            thread_id: selectedFriend.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          app_id,
          (response) => {
            if (response.status) {
              setSubmitLoadingThread(false);
              console.log(response, "sfsrf");
              console.log(messagearray, "kjgldjwdgqw");
              var foundIndex = messagearray.findIndex(
                (x) => x._id == threadItem._id
              );
              if (messagearray[foundIndex].thread_messages_count > 0) {
                messagearray[foundIndex].thread_messages_count =
                  messagearray[foundIndex].thread_messages_count + 1;
              } else if (messagearray[foundIndex].thread_messages_count === 0) {
                messagearray[foundIndex].thread_messages_count = 1;
              }
              refetchAllPrivateChats();
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
  };

  const handleSubmitReplyPrivateChat = (e) => {
    // e.preventDefault();

    let obj = urlParser.parse(editorTextThread);

    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorTextThread.length > 0) {
          let notification_data = {
            sender: userObj.username,
            receiver: selectedFriend.username,
          };

          usersocket.emit("new_message", app_id, notification_data);
          let tempArr = [...userReadUnreadList];
          tempArr = tempArr.filter(function (item) {
            return item !== selectedFriend.username;
          });
          setuserReadUnreadList([...tempArr]);

          socket.emit(
            "new_reply_to_thread_direct_message",
            threadItem._id,
            selectedFriend.username,
            {
              message: editorTextThread,
              type: "video",
              thread_id: selectedFriend.thread_id,
              sender: userObj,
              replied_msg: true,
              replied_to_data: selectedReplyMessage1,
              timestamp: Date.now(),
            },
            app_id,
            (response) => {
              if (response.status) {
                console.log(response);
                refetchAllPrivateChats();
                setEditorTextThread("");
                setSubmitLoadingThread(false);
                // setInputText("");
                //for pushing msg =user to top
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      console.log("please reply", selectedReplyMessage1);
      if (editorTextThread.length > 0) {
        let notification_data = {
          sender: userObj.username,
          receiver: selectedFriend.username,
        };

        usersocket.emit("new_message", app_id, notification_data);
        let tempArr = [...userReadUnreadList];
        tempArr = tempArr.filter(function (item) {
          return item !== selectedFriend.username;
        });
        setuserReadUnreadList([...tempArr]);
        socket.emit(
          "new_reply_to_thread_direct_message",
          threadItem._id,
          selectedFriend.username,
          {
            message: editorTextThread,
            thread_id: selectedFriend.thread_id,
            sender: userObj,
            replied_msg: true,
            replied_to_data: selectedReplyMessage1,
            timestamp: Date.now(),
          },
          app_id,
          (response) => {
            console.log("please reply", response);
            if (response.status) {
              console.log(response);
              refetchAllPrivateChats();
              setEditorTextThread("");
              setSubmitLoadingThread(false);
              // setInputText("");
              //for pushing msg =user to top
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
    setSelectedReplyMessage1(null);
  };

  const handleSubmitGroupChat = (e) => {
    // e.preventDefault();
    console.log("inside submit group chat", selectedGroup);
    let obj = urlParser.parse(editorTextThread);
    console.log("url parsed", obj, editorTextThread);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorTextThread.length > 0) {
          let tempArr = [...groupReadUnreadList];
          const objIndex = tempArr.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          tempArr[objIndex].last_messaged_user = userObj.username;
          console.log(tempArr);
          setgroupReadUnreadList(tempArr);

          let notification_data = {
            sender: userObj.username,
            receiver: selectedGroup.group_name,
            group: true,
            group_type: "group",
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "reply_thread_group_message",
            threadItem._id,
            memberUsernames,
            {
              message: editorTextThread,
              type: "video",
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              timestamp: Date.now(),
            },
            app_id,
            (response) => {
              console.log(response, "kjbwekjfwefh");
              if (response.status) {
                refetchAllGroupChats();
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorTextThread.length > 0) {
        let tempArr = [...groupReadUnreadList];
        const objIndex = tempArr.findIndex(
          (obj) => obj.group_name === selectedGroup.group_name
        );
        console.log(
          groupReadUnreadList,
          objIndex,
          tempArr[objIndex],
          "kjwbkcwek"
        );
        if (tempArr[objIndex] !== undefined) {
          tempArr[objIndex].last_messaged_user = userObj.username;
          console.log(tempArr);
          setgroupReadUnreadList(tempArr);
        }

        let notification_data = {
          sender: userObj.username,
          receiver: selectedGroup.group_name,
          group: true,
          group_type: "group",
        };

        usersocket.emit("new_message", app_id, notification_data);

        socket.emit(
          "reply_thread_group_message",
          threadItem._id,
          memberUsernames,
          {
            message: editorTextThread,
            thread_id: selectedGroup.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          app_id,
          (response) => {
            if (response.status) {
              // setInputText("");
              setEditorTextThread("");
              refetchAllGroupChats();
              setSubmitLoadingThread(false);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
  };

  const handleSubmitReplyGroupChat = (e) => {
    // e.preventDefault();

    let obj = urlParser.parse(editorTextThread);
    console.log(obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorTextThread.length > 0) {
          let tempArr = [...groupReadUnreadList];
          const objIndex = tempArr.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          tempArr[objIndex].last_messaged_user = userObj.username;
          console.log(tempArr);
          setgroupReadUnreadList([...tempArr]);

          let notification_data = {
            sender: userObj.username,
            receiver: selectedGroup.group_name,
            group: true,
            group_type: "group",
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "new_reply_thread_group_message",
            threadItem._id,
            memberUsernames,
            {
              message: editorTextThread,
              type: "video",
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              replied_msg: true,
              replied_to_data: selectedReplyMessage1,
              timestamp: Date.now(),
            },
            app_id,
            (response) => {
              if (response.status) {
                console.log(response);
                refetchAllGroupChats();
                // setInputText("");
                setEditorTextThread("");
                setSubmitLoadingThread(false);
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorTextThread.length > 0) {
        let tempArr = [...groupReadUnreadList];
        const objIndex = tempArr.findIndex(
          (obj) => obj.group_name === selectedGroup.group_name
        );
        tempArr[objIndex].last_messaged_user = userObj.username;
        console.log(tempArr);
        setgroupReadUnreadList(tempArr);

        let notification_data = {
          sender: userObj.username,
          receiver: selectedGroup.group_name,
          group: true,
          group_type: "group",
        };

        usersocket.emit("new_message", app_id, notification_data);

        socket.emit(
          "new_reply_thread_group_message",
          threadItem._id,
          memberUsernames,
          {
            message: editorTextThread,
            thread_id: selectedGroup.thread_id,
            sender: userObj,
            replied_msg: true,
            replied_to_data: selectedReplyMessage1,
            timestamp: Date.now(),
          },

          app_id,
          (response) => {
            if (response.status) {
              console.log(response);
              refetchAllGroupChats();
              // setInputText("");
              setEditorTextThread("");
              setSubmitLoadingThread(false);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
    setSelectedReplyMessage1(null);
  };

  const handleSubmitSupportChat = (e) => {
    console.log("Inside handle sumit support", editorTextThread);
    let obj = urlParser.parse(editorTextThread);
    // console.log("url parsed", obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorTextThread.length > 0) {
          let notification_data = {
            sender: userObj.username,
            receiver: selectedSupport.group_name,
            group: true,
            group_type: "group",
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "new_reply_thread_support_group_message",
            threadItem._id,
            selectedSupport.username,
            {
              message: editorTextThread,
              type: "video",
              thread_id: selectedSupport.thread_id,
              sender: userObj,
              timestamp: Date.now(),
            },
            app_id,
            (response) => {
              if (response.status) {
                console.log(response);
                refetchAllSupportChats();
                setEditorTextThread("");
                setSubmitLoadingThread(false);
                // setInputText("");
                //for pushing msg =user to top
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorTextThread.length > 0) {
        let notification_data = {
          sender: userObj.username,
          receiver: selectedSupport.group_name,
          group: true,
        };

        usersocket.emit("new_group_message", app_id, notification_data);

        socket.emit(
          "new_reply_thread_support_group_message",
          threadItem._id,
          selectedSupport.username,
          {
            message: editorTextThread,
            thread_id: selectedSupport.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          app_id,
          (response) => {
            if (response.status) {
              console.log(response);
              refetchAllSupportChats();
              setEditorTextThread("");
              setSubmitLoadingThread(false);
              // setInputText("");
              //for pushing msg =user to top
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
  };

  const handleSubmitReplySupportChat = (e) => {
    // e.preventDefault();

    let obj = urlParser.parse(editorTextThread);
    console.log(obj, selectedReplyMessage1, app_id, "kjhkdjfwbefkwjhefkwe");
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorTextThread.length > 0) {
          let notification_data = {
            sender: userObj,
            receiver: selectedSupport.group_name,
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "new_reply_thread_support_group_message",
            threadItem._id,
            selectedSupport.username,
            {
              message: editorTextThread,
              type: "video",
              thread_id: selectedSupport.thread_id,
              sender: userObj,
              replied_msg: true,
              replied_to_data: selectedReplyMessage1,
              timestamp: Date.now(),
            },
            app_id,
            (response) => {
              if (response.status) {
                getSupportList();
                console.log(response);
                refetchAllSupportChats();
                // setInputText("");
                setEditorTextThread("");
                setSubmitLoadingThread(false);
                //for pushing msg =user to top
              } else {
                console.log(response, "jkajkambjwhe");
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      console.log("please reply", selectedReplyMessage1);
      if (editorTextThread.length > 0) {
        let notification_data = {
          sender: userObj,
          receiver: selectedSupport.group_name,
        };

        usersocket.emit("new_message", app_id, notification_data);
        let tempArr = [...supportgroupReadUnreadList];

        // tempArr = tempArr.filter(function (item) {
        //   return item !== selectedSupport; //Doubt
        // });
        // setsupportgroupReadUnreadList([...tempArr]);
        socket.emit(
          "new_reply_thread_support_group_message",
          threadItem._id,
          selectedSupport.username,
          {
            message: editorTextThread,
            thread_id: selectedSupport.thread_id,
            sender: userObj,
            replied_msg: true,
            replied_to_data: selectedReplyMessage1,
            timestamp: Date.now(),
          },
          app_id,
          (response) => {
            console.log("please reply", response);
            if (response.status) {
              refetchAllSupportChats();
              // setInputText("");
              setEditorTextThread("");
              setSubmitLoadingThread(false);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
    setSelectedReplyMessage1(null);
  };

  const submitPrivateChatUpdate = (e) => {
    if (editorTextThread.length > 0) {
      let notification_data = {
        sender: userObj.username,
        receiver: selectedFriend.username,
      };

      usersocket.emit("new_message", app_id, notification_data);
      let tempArr = [...userReadUnreadList];
      tempArr = tempArr.filter(function (item) {
        return item !== selectedFriend.username;
      });
      setuserReadUnreadList([...tempArr]);
      socket.emit(
        "edit_direct_message",
        selectedEditMessage._id,
        editorTextThread,
        app_id,
        (response) => {
          if (response) {
            console.log(response, "sfsrf");
            var tempArr = threadMessagearray;
            var foundIndex = tempArr.findIndex(
              (x) => x._id == selectedEditMessage._id
            );
            tempArr[foundIndex].message = editorTextThread;
            threadMessagearray[foundIndex].message = editorTextThread;

            setEditorTextThread("");

            setSubmitLoadingThread(false);

            setSelectedEditMessage(null);
          } else {
            console.log(response);
          }
        }
      );
    } else {
      message.destroy();
      message.info("Please type something!", 2);
    }
  };

  const submitGroupChatUpdate = (e) => {
    // e.preventDefault();
    console.log("inside submit group chat", selectedGroup);
    let obj = urlParser.parse(editorTextThread);
    // console.log("url parsed", obj);

    if (editorTextThread.length > 0) {
      let tempArr = [...groupReadUnreadList];
      const objIndex = tempArr.findIndex(
        (obj) => obj.group_name === selectedGroup.group_name
      );
      tempArr[objIndex].last_messaged_user = userObj.username;
      console.log(tempArr);
      setgroupReadUnreadList(tempArr);

      let notification_data = {
        sender: userObj.username,
        receiver: selectedGroup.group_name,
        group: true,
        group_type: "group",
      };

      usersocket.emit("new_message", app_id, notification_data);

      socket.emit(
        "edit_group_message",
        selectedEditMessage._id,
        editorTextThread,
        app_id,
        (response) => {
          // console.log(response, "jhkjfwekfjwekjf");
          if (response.status) {
            // console.log(response, "jhkjfwekfjwekjf");
            console.log(response, "sfsrf");
            var tempArr = threadMessagearray;
            var foundIndex = tempArr.findIndex(
              (x) => x._id == selectedEditMessage._id
            );
            tempArr[foundIndex].message = editorTextThread;
            threadMessagearray[foundIndex].message = editorTextThread;
            // setInputText("");
            setEditorTextThread("");
            // bodyRef.current.innerHTML = "";
            setSubmitLoadingThread(false);

            setSelectedEditMessage(null);
          } else {
            console.log(response);
          }
        }
      );
    } else {
      message.destroy();
      message.info("Please type something!", 2);
    }
  };

  const conditionalSubmit = () => {
    console.log(
      "allselected",
      selectedFriend,
      selectedGroup,
      selectedSupport,
      selectedAdminSupport
    );
    if (tokenValid) {
      if (selectedSupport === null) {
        if (selectedFriend !== null) {
          if (selectedReplyMessage1 !== null) {
            console.log("1-2-1 reply fired");
            handleSubmitReplyPrivateChat();
          } else {
            if (selectedEditMessage) {
              submitPrivateChatUpdate();
            } else {
              handleSubmitPrivateChat();
            }
          }
        } else if (selectedGroup !== null) {
          if (selectedReplyMessage1 !== null) {
            handleSubmitReplyGroupChat();
          } else {
            if (selectedEditMessage) {
              submitGroupChatUpdate();
            } else {
              handleSubmitGroupChat();
            }
          }
        }
      } else {
        if (selectedReplyMessage1 !== null) {
          handleSubmitReplySupportChat();
        } else {
          handleSubmitSupportChat();
        }
      }
    }
  };

  const pluButtonList = [
    { icon: <VideoCameraTwoTone />, text: "Upload Video" },
    { icon: <FileImageTwoTone />, text: "Upload Image" },
    { icon: <FileTwoTone />, text: "Upload File" },
  ];

  const pasteImageHandler = (event) => {
    // use event.originalEvent.clipboard for newer chrome versions
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    console.log(items);
    console.log(JSON.stringify(items)); // will give you the mime types
    // find pasted image among pasted items
    var blob = null;

    for (var i = 0; i < items.length; i++) {
      console.log("url obj", items[i]);
      if (items[i].type.indexOf("image") === 0) {
        blob = items[i].getAsFile();
      }
    }
    // load image if there is a pasted image
    if (blob !== null) {
      var reader = new FileReader();
      reader.onload = function (event) {
        // console.log(event.target.result); // data url!
        setClipImage(event.target.result);
        // document.getElementById("pastedImage").src = event.target.result;
      };
      reader.readAsDataURL(blob);
      setVisiblePasteImage(true);
      setLoadingImage(false);
    }
  };

  const uploadOneToOneImageThread = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", app_id, notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_reply_to_thread_direct_message",
      threadItem._id,
      {
        ...message_data,
      },
      app_id,
      (response) => {
        if (response.status) {
          console.log("sent to DB", response, message_data);
          refetchAllPrivateChats();
        } else {
          console.log(response);
        }
      }
    );
    setSelectedReplyMessage1(null);
    // setselectedFile(null);
  };

  const uploadOneToOneReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    const messageWithReply = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage1.message,
        threadId: selectedReplyMessage1.threadId,
        sender: selectedReplyMessage1.sender,
        timestamp: selectedReplyMessage1.timestamp,
        type: selectedReplyMessage1.type ? selectedReplyMessage1.type : null,
        location: selectedReplyMessage1.location
          ? selectedReplyMessage1.location
          : null,
      },
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", app_id, notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_reply_to_thread_direct_message",
      threadItem._id,
      {
        ...messageWithReply,
      },
      app_id,
      (response) => {
        if (response.status) {
          console.log(response);
          refetchAllPrivateChats();
          setEditorTextThread("");

          // setInputText("");
          //for pushing msg =user to top
        } else {
          console.log(response);
        }
      }
    );

    setSelectedReplyMessage1(null);
    // setselectedFile(null);
  };

  const uploadGroupImageThread = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_reply_thread_group_message",
      threadItem._id,
      {
        ...message_data,
      },
      app_id,
      (response) => {
        if (response.status) {
          console.log("sent to DB", response, message_data);
          refetchAllGroupChats();
        } else {
          console.log(response);
        }
      }
    );
    setSelectedReplyMessage1(null);
  };

  const uploadGroupReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage1.message,
        threadId: selectedReplyMessage1.threadId,
        sender: selectedReplyMessage1.sender,
        timestamp: selectedReplyMessage1.timestamp,
        type: selectedReplyMessage1.type ? selectedReplyMessage1.type : null,
        location: selectedReplyMessage1.location
          ? selectedReplyMessage1.location
          : null,
      },
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_reply_thread_group_message",
      threadItem._id,
      message_data,
      app_id,
      (response) => {
        if (response.status) {
          refetchAllGroupChats();
        } else {
          message.error("Error in sending message");
          console.log(response.status);
        }
      }
    );
    setSelectedReplyMessage1(null);
  };

  const uploadSupportGroupImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      // replied_msg: false,
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedSupport.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", notification_data);

    socket.emit(
      "new_reply_thread_support_group_message",
      threadItem._id,
      message_data,
      app_id,
      (response) => {
        if (response.status) {
          console.log(response);
          refetchAllSupportChats();
          setEditorTextThread("");
          // setInputText("");
          //for pushing msg =user to top
        } else {
          console.log(response);
        }
      }
    );
  };

  const uploadSupportGroupReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    const messageWithReply = {
      message: Date.now().toString(),
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage1.message,
        threadId: selectedReplyMessage1.threadId,
        sender: selectedReplyMessage1.sender,
        timestamp: selectedReplyMessage1.timestamp,
        type: selectedReplyMessage1.type ? selectedReplyMessage1.type : null,
        location: selectedReplyMessage1.location
          ? selectedReplyMessage1.location
          : null,
      },
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedSupport.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_reply_thread_support_group_message",
      threadItem._id,
      messageWithReply,
      app_id,
      (response) => {
        if (response.status) {
          console.log(response);
          refetchAllSupportChats();
          setEditorTextThread("");
          // setInputText("");
          //for pushing msg =user to top
        } else {
          console.log(response);
        }
      }
    );

    setSelectedReplyMessage1(null);
  };

  const conditionalImageUpload = () => {
    setLoadingImage(true);
    if (selectedSupport === null) {
      if (selectedFriend !== null) {
        if (selectedReplyMessage1) {
          uploadOneToOneReplyImage();
        } else {
          uploadOneToOneImageThread();
        }
      } else if (selectedGroup !== null) {
        console.log(selectedGroup, "kwkjc,wbjkwje");
        if (selectedReplyMessage1) {
          console.log(selectedGroup, "kwkjc,wbjkwjegroupreply");
          uploadGroupReplyImage();
        } else {
          console.log(selectedGroup, "kwkjc,wbjkwjegroup");
          uploadGroupImageThread();
        }
      }
    } else {
      if (selectedReplyMessage1) {
        console.log(selectedGroup, "kwkjc,wbjkwjesupportreply");
        uploadSupportGroupReplyImage();
      } else {
        console.log(selectedGroup, "kwkjc,wbjkwjesupport");
        uploadSupportGroupImage();
      }
    }
  };

  const handleShiftEnter = (e) => {
    // console.log(e.key, "kjwkfwef");
    var x = document.getElementById("demo");
    if (e.key == "Enter" && e.shiftKey) {
      // x.innerHTML = "SHIFT + ENTER key was pressed!";
      //   // alert("add new div");
    } else if (!submitLoadingThread && e.key == "Enter") {
      // console.log("got into submit");
      setSubmitLoadingThread(true);
      e.preventDefault();
      conditionalSubmit();
      setEditorTextThread("");
    } else if (e.key == "Esc") {
      setSelectedEditMessage(null);
      setEditorTextThread("");
    }
  };

  const addEmoji = (e) => {
    //console.log(e.native)
    let emoji = e.native;
    setEditorTextThread(editorTextThread + " " + emoji);
    setemojiShow(false);
    // setInputText(inputText + " " + emoji);
  };

  return (
    <>
      <div
        style={{
          paddingTop: 30,
          position: "fixed",
          bottom: 0,
          width: "inherit",
        }}
      >
        {selectedReplyMessage1 !== null ? (
          <div
            className="shadow"
            style={{
              padding: "10px",
              margin: "10px",
              // backgroundColor: "",
              background:
                localStorage.getItem("mode") === "light"
                  ? "#EEEEEE"
                  : localStorage.getItem("mode") === "dark"
                  ? "#121212"
                  : "",
              color:
                localStorage.getItem("mode") === "light"
                  ? "black"
                  : localStorage.getItem("mode") === "dark"
                  ? "lightgray"
                  : "",

              borderRadius: "10px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingBottom: "5px" }}>
                {selectedReplyMessage1.sender.username}
              </div>
              <CloseOutlined
                style={{ cursor: "pointer" }}
                onClick={(e) => setSelectedReplyMessage1(null)}
              />
            </div>
            <div>{renderMessage(selectedReplyMessage1)}</div>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            background:
              localStorage.getItem("mode") === "light"
                ? "#EDEDED"
                : localStorage.getItem("mode") === "dark"
                ? "#121212"
                : "",

            // background: "#EDEDED",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
              paddingRight: "10px",
              color: "#8D8D8D",
            }}
          >
            <Popover
              placement="topLeft"
              // title="Emoji"
              content={<Picker onSelect={addEmoji} skin={2} title="Nvest" />}
              trigger="click"
            >
              <SmileOutlined
                type="smile"
                style={{
                  // color: "rgba(0,0,0,.45)",
                  // marginTop: "9px"
                  fontSize: "18px",
                }}
                onClick={(e) => setemojiShow(!emojiShow)}
              />
            </Popover>
          </div>

          <Mentions
            autoSize={{ minRows: 1, maxRows: 6 }}
            onKeyPress={(e) => {
              if (!submitLoadingThread) {
                handleShiftEnter(e);
              }
            }}
            style={{
              background: globalColors.editorBackground,
              borderColor: globalColors.editorBorder,
              color: globalColors.chatText,
            }}
            // className={globalColors.darkMode ? "editor-style" : ""}
            className="editor-style"
            // style={{
            //   // position: "fixed",
            //   // width: "10%",
            //   background: submitLoadingThread ? "lightgray" : "white",
            // }}
            onPaste={pasteImageHandler}
            ref={bodyRef1}
            // className={
            //   selectedEditMessage !== null
            //     ? "selected-chat-input"
            //     : "chat-input"
            // }
            value={editorTextThread}
            onChange={(value) => {
              setEditorTextThread(value);

              socket.emit(
                "typing",
                app_id,
                userObj.first_name,
                selectedType.thread_id
              );
            }}
            // onSelect={onSelect}
            // defaultValue="Type Something..."
            placeholder="Type Something..."
          >
            {selectedGroup
              ? groupMembers.map((item) => {
                  return (
                    <Option value={item.first_name}>
                      <Avatar
                        src={item.avatar}
                        size="small"
                        style={{
                          color: "white",
                          backgroundColor: "white",
                          cursor: "pointer",
                          border: "solid 1px gray",
                        }}
                      />
                      &nbsp;&nbsp;{item.first_name}
                    </Option>
                  );
                })
              : allUserList.map((item) => {
                  return (
                    <Option value={item.first_name}>
                      <Avatar
                        src={item.avatar}
                        size="small"
                        style={{
                          color: "white",
                          backgroundColor: "white",
                          cursor: "pointer",
                          border: "solid 1px gray",
                        }}
                      />
                      &nbsp;&nbsp;{item.first_name}
                    </Option>
                  );
                })}
            <Option value="all">all</Option>
          </Mentions>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
              paddingRight: "5px",
              color: "#8D8D8D",
            }}
            className="popover-upload"
          >
            <input
              type="file"
              id="file"
              accept="video/*"
              ref={uploadVideoRef}
              style={{ display: "none" }}
              onChange={(e) =>
                handleUploadFile(uploadVideoRef.current.files[0])
              }
            />
            <input
              type="file"
              id="file"
              accept="image/*"
              ref={uploadImageRef}
              style={{ display: "none" }}
              onChange={(e) =>
                handleUploadFile(uploadImageRef.current.files[0])
              }
            />
            <input
              type="file"
              id="file"
              // accept="image/png, image/gif, image/jpeg"
              ref={uploadFileRef}
              style={{ display: "none" }}
              onChange={(e) => handleUploadFile(uploadFileRef.current.files[0])}
            />
            {selectedEditMessage ? (
              <Tooltip title="Get out of Edit Mode">
                <CloseCircleFilled
                  onClick={(e) => {
                    setSelectedEditMessage(null);
                    setEditorTextThread("");
                  }}
                  style={{
                    paddingRight: "15px",
                    // fontWeight: "bold",
                    fontSize: "18px",
                    opacity: 0.8,
                  }}
                />
              </Tooltip>
            ) : (
              <Popover
                placement="topRight"
                title={null}
                content={
                  <List
                    style={{ width: "200px" }}
                    size="small"
                    header={null}
                    footer={null}
                    bordered
                    dataSource={pluButtonList}
                    // renderItem={(item) => <List.Item>{item}</List.Item>}
                    renderItem={(item, index) => (
                      <List.Item
                        onClick={(e) => {
                          if (index === 0) {
                            uploadVideoRef.current.click();
                          } else if (index === 1) {
                            uploadImageRef.current.click();
                          } else if (index === 2) {
                            uploadFileRef.current.click();
                          }
                        }}
                        // actions={[
                        //   <a key="list-loadmore-edit">edit</a>,
                        //   <a key="list-loadmore-more">more</a>,
                        // ]}
                      >
                        <List.Item.Meta
                          avatar={item.icon}
                          title={<div>{item.text}</div>}
                          // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                        />
                        {/* <div>content</div> */}
                      </List.Item>
                    )}
                  />
                }
                trigger="click"
              >
                <PlusCircleFilled
                  style={{
                    paddingRight: "15px",
                    // fontWeight: "bold",
                    fontSize: "18px",
                    opacity: 0.8,
                  }}
                />
              </Popover>
            )}
            {submitLoading ? (
              <Spin />
            ) : (
              <img
                style={{ cursor: "pointer" }}
                src={require("../../images/send.svg")}
                alt=""
                width="20px"
                onClick={conditionalSubmit}
              />
            )}
          </div>
        </div>
      </div>

      {/* On Paste Image this appears */}
      <Modal
        width={300}
        bodyStyle={{ padding: "0px" }}
        title="Upload Image?"
        visible={visiblePasteImage}
        onOk={conditionalImageUpload}
        onCancel={(e) => setVisiblePasteImage(false)}
      >
        {loadingImage ? (
          <div
            style={{
              backgroundImage: `url(${clipImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              height: "200px",
              opacity: "0.5",
            }}
          >
            <LoadingOutlined />
          </div>
        ) : (
          <div
            style={{
              backgroundImage: `url(${clipImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              height: "200px",
              // opacity: "0.5",
            }}
          >
            &nbsp;
          </div>
        )}
        {/* <div style={{ padding: "10px" }}>
          <Input
            type="text"
            placeholder="Name of the Image ??"
            value={imageName}
            onChange={e => setImageName(e.target.value)}
          />
        </div> */}
      </Modal>
    </>
  );
};

export default EditorThread;

import React, { useState } from "react";
import moment from "moment";
import {
  Tag,
  Row,
  Col,
  Divider,
  Popover,
  Input,
  DatePicker,
  Button,
  Modal,
  Menu,
  Dropdown,
  message,
} from "antd";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  PlusSquareOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;

const TodoList = () => {
  const [visibleAddTask, setVisibleAddTask] = useState(false);
  const [visibleEditTask, setVisibleEditTask] = useState(false);

  const [showUpcoming, setShowUpcoming] = useState(true);
  const [showTodayWork, setShowTodayWork] = useState(true);

  const [selectedTask, setSelectedTask] = useState(null);

  const [todo, setTodo] = useState(null);
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("Waiting");
  const [todoList, setTodoList] = useState([
    {
      task: "Create demo for ecommerce app",
      status: "Approved",
      finished: true,
      date: "today",
    },
    {
      task: "Google analytics integration for ecommerce app",
      status: "In Process",
      finished: true,
      date: "today",
    },
    {
      task: "Website demo from Human resource management system",
      status: "Approved",
      finished: true,
      date: "today",
    },
    {
      task: "Create demo for ecommerce app",
      status: "Rejected",
      finished: false,
      date: "today",
    },
    {
      task: "Create demo for ecommerce app",
      status: "In Coming",
      finished: false,
      date: "today",
    },
    {
      task: "Create demo for ecommerce app",
      status: "Waiting",
      finished: false,
      date: "tomorrow",
    },
    {
      task: "Create demo for ecommerce app",
      status: "Waiting",
      finished: false,
      date: "tomorrow",
    },
    {
      task: "Create demo for ecommerce app",
      status: "Waiting",
      finished: false,
      date: "tomorrow",
    },
    {
      task: "Create demo for ecommerce app",
      status: "Waiting",
      finished: false,
      date: "tomorrow",
    },
    {
      task: "Create demo for ecommerce app",
      status: "Waiting",
      finished: false,
      date: "tomorrow",
    },
  ]);
  const colorSelection = (status) => {
    if (status === "Approved") {
      return "green";
    } else if (status === "Rejected") {
      return "red";
    } else if (status === "In Coming") {
      return "gold";
    } else if (status === "In Process") {
      return "blue";
    } else if (status === "Waiting") {
      return "purple";
    }
  };

  const handleChangeStatus = (stat, item, index) => {
    const newArr = [...todoList];
    newArr[index].status = stat;
    setTodoList(newArr);
  };

  const handleSubmitTask = (e) => {
    e.preventDefault();
    setTodoList([
      ...todoList,
      {
        task: todo,
        status: status,
        finished: false,
        date: dueDate,
      },
    ]);
    setTodo("");
    setDueDate("");
    setVisibleAddTask(false);
    // console.log(todoList);
  };

  const handleRemove = (index) => {
    const newTodoList = todoList.filter((_, i) => i !== index);
    setTodoList(newTodoList);
  };

  const handleCheck = (item, index) => {
    const newArr = [...todoList];

    if (item.finished === true) {
      newArr[index].finished = false;
      setTodoList(newArr);
    } else {
      newArr[index].finished = true;
      setTodoList(newArr);
    }
  };

  const handleEdit = () => {
    let taskIndex = todoList.indexOf(selectedTask);
    console.log(taskIndex);
    const newArr = [...todoList];
    newArr[taskIndex].todo = todo ? todo : newArr[taskIndex].todo;
    newArr[taskIndex].status = status ? status : newArr[taskIndex].status;
    newArr[taskIndex].date = dueDate ? dueDate : newArr[taskIndex].dueDate;
    setTodoList(newArr);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current <= moment().endOf("day");
  };

  return (
    <>
      <div className="white-scroll todo-style">
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ margin: "0px", fontWeight: "bold" }}>
              Today's Work&nbsp;
              {showTodayWork ? (
                <CaretDownOutlined
                  style={{ color: "lightgray" }}
                  onClick={(e) => setShowTodayWork(!showTodayWork)}
                />
              ) : (
                <CaretRightOutlined
                  style={{ color: "lightgray" }}
                  onClick={(e) => setShowTodayWork(!showTodayWork)}
                />
              )}
            </p>
            <div>
              <PlusSquareOutlined
                onClick={(e) => setVisibleAddTask(true)}
                style={{ fontSize: "18px", color: "#15D4A1" }}
              />
              &nbsp;&nbsp;&nbsp;
              <MoreOutlined />
            </div>
          </div>
        </div>
        <Divider style={{ margin: "15px 0px" }} />
        {showTodayWork
          ? todoList.map((item, index) => {
              if (item.date === "today") {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "8px 0px",
                    }}
                  >
                    <div>
                      {item.finished === true ? (
                        <img
                          onClick={(e) => handleCheck(item, index)}
                          style={{ cursor: "pointer" }}
                          src={require("../../../images/checked.svg")}
                          alt=""
                          width="22px"
                        />
                      ) : (
                        <img
                          onClick={(e) => handleCheck(item, index)}
                          style={{ cursor: "pointer" }}
                          src={require("../../../images/unchecked.svg")}
                          alt=""
                          width="22px"
                        />
                      )}
                      &nbsp;&nbsp;
                      {item.task}
                    </div>
                    <div>
                      {/* <img
                        src={require("../images/checked.svg")}
                        alt=""
                        width="22px"
                      /> */}
                      <EditOutlined
                        onClick={(e) => {
                          message.warning(
                            "Edit not done yet because it deals with timestamp"
                          );
                        }}
                        style={{
                          fontSize: "15px",
                          color: "lightgray",
                          cursor: "pointer",
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      {/* <img
                        src={require("../images/checked.svg")}
                        alt=""
                        width="22px"
                      /> */}
                      <DeleteOutlined
                        onClick={(e) => handleRemove(index)}
                        style={{
                          fontSize: "15px",
                          color: "red",
                          opacity: "0.5",
                          cursor: "pointer",
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="0"
                              style={{ color: colorSelection("Approved") }}
                              onClick={(e) => {
                                // setSelectedTask(item);
                                handleChangeStatus("Approved", item, index);
                              }}
                            >
                              Approved
                            </Menu.Item>
                            <Menu.Item
                              key="1"
                              style={{ color: colorSelection("In Process") }}
                              onClick={(e) => {
                                // setSelectedTask("In Process", item, index);
                                handleChangeStatus("In Process", item, index);
                              }}
                            >
                              In Process
                            </Menu.Item>
                            {/* <Menu.Divider /> */}
                            <Menu.Item
                              key="2"
                              style={{ color: colorSelection("Rejected") }}
                              onClick={(e) => {
                                // setSelectedTask(item);
                                handleChangeStatus("Rejected", item, index);
                              }}
                            >
                              Rejected
                            </Menu.Item>
                            <Menu.Item
                              key="3"
                              style={{ color: colorSelection("In Coming") }}
                              onClick={(e) => {
                                // setSelectedTask(item);
                                handleChangeStatus("In Coming", item, index);
                              }}
                            >
                              In Coming
                            </Menu.Item>
                            <Menu.Item
                              key="4"
                              style={{ color: colorSelection("Waiting") }}
                              onClick={(e) => {
                                // setSelectedTask(item);
                                handleChangeStatus("Waiting", item, index);
                              }}
                            >
                              Waiting
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <Tag
                          color={colorSelection(item.status)}
                          style={{
                            width: "75px",
                            textAlign: "center",
                            borderRadius: "10px",
                          }}
                        >
                          {item.status}
                        </Tag>
                      </Dropdown>
                    </div>
                  </div>
                );
              }
            })
          : ""}
        <div style={{ paddingTop: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ margin: "0px", fontWeight: "bold" }}>
              Upcoming &nbsp;
              {showUpcoming ? (
                <CaretDownOutlined
                  style={{ color: "lightgray" }}
                  onClick={(e) => setShowUpcoming(!showUpcoming)}
                />
              ) : (
                <CaretRightOutlined
                  style={{ color: "lightgray" }}
                  onClick={(e) => setShowUpcoming(!showUpcoming)}
                />
              )}
            </p>
            <div>
              <PlusSquareOutlined
                style={{ fontSize: "18px", color: "#15D4A1" }}
              />
              &nbsp;&nbsp;&nbsp;
              <MoreOutlined />
            </div>
          </div>
        </div>
        <Divider style={{ margin: "15px 0px" }} />
        {showUpcoming
          ? todoList.map((item, index) => {
              if (item.date !== "today") {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "8px 0px",
                    }}
                  >
                    <div>
                      <img
                        src={require("../../../images/upcoming.svg")}
                        alt=""
                        width="22px"
                      />
                      &nbsp;&nbsp;
                      {item.task}
                    </div>
                    <div>
                      {/* <img
                        src={require("../images/checked.svg")}
                        alt=""
                        width="22px"
                      /> */}
                      <EditOutlined
                        style={{ fontSize: "15px", color: "lightgray" }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      {/* <img
                        src={require("../images/checked.svg")}
                        alt=""
                        width="22px"
                      /> */}
                      <DeleteOutlined
                        onClick={(e) => handleRemove(index)}
                        style={{
                          fontSize: "15px",
                          color: "red",
                          opacity: "0.5",
                          cursor: "pointer",
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Tag
                        color={colorSelection(item.status)}
                        style={{
                          width: "70px",
                          textAlign: "center",
                          borderRadius: "10px",
                        }}
                      >
                        {item.status}
                      </Tag>
                    </div>
                  </div>
                );
              }
            })
          : ""}
      </div>
      <Modal
        visible={visibleAddTask}
        title={
          <h3
            style={{
              fontWeight: "bold",
              margin: "0px",
              //   padding: "10px 20px",
            }}
          >
            Add Your Task:
          </h3>
        }
        footer={null}
        onCancel={(e) => setVisibleAddTask(false)}
      >
        <div
        //   style={{ padding: "10px 20px", backgroundColor: "lightgray" }}
        >
          <TextArea
            style={{ marginBottom: "10px" }}
            placeholder="Enter Task"
            allowClear
            value={todo}
            onChange={(e) => setTodo(e.target.value)}
          />

          <DatePicker
            onChange={(value) => setDueDate(Date.parse(value._d))}
            style={{ width: "100%", marginBottom: "10px" }}
            // format="YYYY-MM-DD HH:mm:ss"
            disabledDate={disabledDate}
          />
          <Button block type="primary" onClick={handleSubmitTask}>
            Add Task
          </Button>
        </div>
      </Modal>

      <Modal
        visible={visibleEditTask}
        title={
          <h3
            style={{
              fontWeight: "bold",
              margin: "0px",
            }}
          >
            Edit Your Task:
          </h3>
        }
        footer={null}
        onCancel={(e) => setVisibleEditTask(false)}
      >
        <div
        //   style={{ padding: "10px 20px", backgroundColor: "lightgray" }}
        >
          {selectedTask ? (
            <>
              <TextArea
                style={{ marginBottom: "10px" }}
                placeholder={selectedTask.task}
                allowClear
                value={todo}
                onChange={(e) => setTodo(e.target.value)}
              />

              <DatePicker
                onChange={(value) => setDueDate(Date.parse(value._d))}
                style={{ width: "100%", marginBottom: "10px" }}
                // format="YYYY-MM-DD HH:mm:ss"
                disabledDate={disabledDate}
              />
              <Button block type="primary" onClick={handleEdit}>
                Add Task
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </>
  );
};

export default TodoList;

import React, { useContext, useState } from "react";
import { Drawer, Card, Avatar, Input, Row, Col, Tooltip, Divider } from "antd";
import {
  LeftCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { MyContext } from "../../../Context";
import TodoList from "./TodoList";

const Profile = () => {
  const {
    newLogin,
    setNewLogin,
    getCUO,
    currentUserObj,
    visibleVault,
    setVisibleVault,
    visibleProfile,
    setVisibleProfile,
    visibleControlPanel,
    setVisibleControlPanel,
    globalColors,
  } = useContext(MyContext);
  return (
    <>
      <Drawer
        bodyStyle={{
          padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        // bodyStyle={{ padding: "0px", backgroundColor: "#15172B" }}
        // title="Basic Drawer"
        placement={"bottom"}
        closable={false}
        onClose={(e) => setVisibleProfile(false)}
        visible={visibleProfile}
        height="100vh"
        // bodyStyle={{ backgroundColor: "#192A50" }}
      >
        <Row>
          <Col
            span={1}
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              background: "white",
              borderRight: "1px solid #EEEEEE",
            }}
          >
            <div style={{ paddingTop: "20px" }}>
              <LeftCircleOutlined
                onClick={(e) => setVisibleProfile(false)}
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "lightgray",
                }}
              />
            </div>
            <div>
              <div>
                <Tooltip placement="right" title={<>Add New Account</>}>
                  <PlusCircleOutlined
                    onClick={(e) => setNewLogin(true)}
                    style={{
                      fontSize: "35px",
                      cursor: "pointer",
                      color: "lightgray",
                    }}
                  />
                </Tooltip>
              </div>
              {localStorage.getItem("accountArr")
                ? JSON.parse(localStorage.getItem("accountArr")).map((item) => {
                    return (
                      <div
                        style={{ padding: "5px 0px" }}
                        onClick={(e) => {
                          console.log("accont change");
                          localStorage.setItem("user_account", item.email);
                          localStorage.setItem("token", item.token);
                          getCUO();
                          window.location.reload();
                        }}
                      >
                        <Tooltip placement="right" title={<>{item.email}</>}>
                          <Avatar
                            style={{
                              backgroundColor:
                                "#" +
                                (((1 << 24) * Math.random()) | 0).toString(16),
                              verticalAlign: "middle",
                              cursor: "pointer",
                            }}
                            size={35}
                          >
                            {item.email[0].toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      </div>
                    );
                  })
                : ""}
              <div>
                <Tooltip placement="right" title={<>Home</>}>
                  <img
                    onClick={(e) => {
                      setVisibleProfile(false);
                      setVisibleControlPanel(false);
                      setTimeout(() => {
                        setNewLogin(false);
                      }, 1000);
                    }}
                    src={require("../../../images/chat_blue.png")}
                    alt=""
                    width={35}
                    style={{ padding: "15px 0px", cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            </div>
          </Col>

          <Col
            span={7}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // padding: "10vh 7vw",
              height: "100vh",
            }}
          >
            <div style={{ width: "20vw" }}>
              <div
                style={{ fontSize: "30px", color: "white", fontWeight: "bold" }}
              >
                Hi {currentUserObj ? currentUserObj.username : ""}
              </div>
              <div>
                <small style={{ color: "white" }}>
                  Welcome To Your Chats Universe
                </small>
              </div>
              <div style={{ padding: "5vh 0px", color: "white" }}>
                <Input
                  className="profile-search"
                  type="text"
                  placeholder="Search your project or task..."
                  prefix={<SearchOutlined style={{ color: "white" }} />}
                />
              </div>
              <div style={{ paddingTop: "5vh", color: "white" }}>
                Projects <span style={{ color: "lightgray" }}>(18)</span>
              </div>
              <div style={{ display: "flex" }}>
                <Card
                  style={{
                    background:
                      "linear-gradient(48.55deg, #0026FF -30.41%, #5885FF 123.18%)",
                    border: "none",
                  }}
                  className="card-style"
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  PT
                </Card>
                <Card
                  style={{
                    background:
                      "gradient(71.76deg, rgba(255, 255, 255, 0) 51.71%, #FFFFFF 102.87%)",
                    border: "none",
                  }}
                  className="card-style"
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  PT
                </Card>
                <Card
                  style={{
                    background:
                      "linear-gradient(48.55deg, #0026FF -30.41%, #5885FF 123.18%)",
                    border: "none",
                  }}
                  className="card-style"
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  PT
                </Card>
              </div>
              <div style={{ display: "flex" }}>
                <Card
                  style={{
                    background:
                      "gradient(71.76deg, rgba(255, 255, 255, 0) 51.71%, #FFFFFF 102.87%)",
                    border: "none",
                  }}
                  className="card-style"
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  PT
                </Card>
                <Card
                  style={{
                    background:
                      "linear-gradient(48.55deg, #0026FF -30.41%, #5885FF 123.18%)",
                    border: "none",
                  }}
                  className="card-style"
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  PT
                </Card>
                <Card
                  style={{
                    background:
                      "gradient(71.76deg, rgba(255, 255, 255, 0) 51.71%, #FFFFFF 102.87%)",
                    border: "none",
                  }}
                  className="card-style"
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  PT
                </Card>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                height: "97vh",
                margin: "10px",
                padding: "50px 80px",
              }}
            >
              <div style={{ paddingBottom: "20px", display: "flex" }}>
                <div style={{ paddingRight: "10vw" }}>
                  <h2 style={{ fontWeight: "bold" }}>Development Crm</h2>
                  <small style={{ color: "lightgray" }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </small>
                </div>
                <div style={{ display: "flex" }}>
                  <Avatar style={{ color: "white", backgroundColor: "black" }}>
                    A
                  </Avatar>
                  <Avatar
                    style={{
                      color: "white",
                      backgroundColor: "gray",
                      marginLeft: "-10px",
                    }}
                  >
                    B
                  </Avatar>
                  <Avatar
                    style={{
                      color: "gray",
                      backgroundColor: "lightgary",
                      marginLeft: "-10px",
                    }}
                  >
                    C
                  </Avatar>
                  &nbsp;&nbsp;{" "}
                  <PlusCircleOutlined
                    style={{ color: "lightgray", fontSize: "30px" }}
                  />
                </div>
              </div>

              <TodoList />
            </div>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default Profile;

import React, { useContext, useState } from "react";
import {
  Drawer,
  Card,
  Avatar,
  Input,
  Row,
  Col,
  Tooltip,
  Divider,
  message,
  Form,
  Upload,
  Button,
  Space,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import S3 from "aws-s3";
import { MyContext } from "../../Context";
import Login from "../../pages/Login";
import Profile from "./Profile/Profile";
// import Vault from "./Vault/Vault";
// import Market from "./Market/Market";
import Setting from "./Setting";
// import Actions from "./Actions/Actions";
// import MailKings from "./MailKings/MailKings";

import { ReactComponent as Trending_img } from "../../images/trending_cp.svg";
import { ReactComponent as Vault_img } from "../../images/vault_cp.svg";
import { ReactComponent as Game_img } from "../../images/game_cp.svg";
import { ReactComponent as Market_img } from "../../images/market_cp.svg";
import { ReactComponent as Logout_img } from "../../images/logout.svg";
import Modal from "antd/lib/modal/Modal";
import socket from "socket.io-client/lib/socket";

const Search = Input;

const ControlPanel = () => {
  const {
    newLogin,
    setNewLogin,
    getCUO,
    currentUserObjn,
    visibleVault,
    setVisibleVault,
    visibleProfile,
    setVisibleProfile,
    visibleControlPanel,
    setVisibleControlPanel,
    visibleMarket,
    setVisibleMarket,
    visibleSetting,
    setVisibleSetting,
    visibleActions,
    setVisibleActions,
    visibleMailKings,
    setVisibleMailKings,
    selectedChatType,
    constants,
    loading,
    setLoading,
    userObj,
    app_id,
    socket,
    globalColors,
    showEditUserInfo,
    setShowEditUserInfo,
  } = useContext(MyContext);

  const [userAvatarUrl, setUserAvatarUrl] = useState("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeUserAvatar = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const uploadUserAvatar = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data, "uploaded");
      setUserAvatarUrl(uploaded_data.location);
      message.destroy();
      message.success("File Upload Success", 2);
      // window.location.reload();
    } catch (e) {
      // console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  const editUserDetails = () => {
    socket.emit(
      `update_user_details_in_application`,
      userObj.email,
      {
        first_name: firstName ? firstName : userObj.first_name,
        last_name: lastName ? lastName : userObj.last_name,
        avatar: userAvatarUrl ? userAvatarUrl : userObj.avatar,
        // bio: userBio ? userBio : userObj.bio,
        // timezone:
      },
      app_id,
      (res) => {
        console.log(res, "usrdetailsupdated");
        if (res.status) {
          setShowEditUserInfo(false);
          message.success("User deatils updated successfully");
          setFirstName("");
          setLastName("");
          setUserAvatarUrl("");
          window.location.reload();
        }
      }
    );
  };

  const conditionalView = () => {
    if (newLogin && !visibleSetting && !visibleActions) {
      return (
        <Col span={22}>
          <div>
            <Login />
          </div>
        </Col>

        // <Col
        //   // span={22}
        //   style={{
        //     height: "100vh",
        //     color: "white",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     // background: visibleSetting ? "#E0E0E0" : constants.login_background,
        //   }}
        // >
        //   <Login />
        // </Col>
      );
    } else if (!newLogin && visibleSetting && !visibleActions) {
      return (
        <Col
          span={22}
          style={{
            height: "100vh",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: visibleSetting ? "#E0E0E0" : "",
          }}
        >
          <Setting
            setVisibleControlPanel={setVisibleControlPanel}
            setVisibleSetting={setVisibleSetting}
          />
        </Col>
      );
    } else if (!newLogin && !visibleSetting && visibleActions) {
      return <div>{/* <Actions /> */}</div>;
    } else if (visibleMailKings) {
      return <div>{/* <MailKings /> */}</div>;
    } else {
      return (
        <Col
          span={22}
          style={{
            height: "100vh",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: visibleSetting ? "#E0E0E0" : "",
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              onClick={(e) => setVisibleControlPanel(false)}
              src={constants.logo}
              alt=""
              style={{ width: "50%" }}
            />

            <Search
              className={
                globalColors.darkMode
                  ? "search-input-dark"
                  : "search-input-light"
              }
              suffix={<SearchOutlined />}
              size="large"
              placeholder="Search Anything..."
              onSearch={(value) => console.log(value)}
              style={{
                width: 650,
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                marginTop: "40px",
              }}
            />
            <br />
            <div style={{ display: "flex" }}>
              <Card
                onClick={(e) => setVisibleMarket(true)}
                className="card-style"
                style={{ borderColor: globalColors.chatBackground }}
                bodyStyle={{
                  background: globalColors.chatBackground,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Trending_img fill={constants.primary_color} stroke="none" />
                {/* <img src={require("../../images/trending_cp.svg")} alt="" /> */}
                <span
                  style={{
                    color: constants.primary_color,
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Trending
                </span>
              </Card>

              <Card
                // onClick={(e) => setVisibleVault(true)}
                className="card-style"
                style={{ borderColor: globalColors.chatBackground }}
                bodyStyle={{
                  background: globalColors.chatBackground,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Vault_img fill={constants.primary_color} stroke="none" />

                <span
                  style={{
                    color: constants.primary_color,
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Vaults
                </span>
              </Card>
              <Card
                className="card-style"
                style={{ borderColor: globalColors.chatBackground }}
                bodyStyle={{
                  background: globalColors.chatBackground,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Game_img fill={constants.primary_color} stroke="none" />
                <span
                  style={{
                    color: constants.primary_color,
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Games
                </span>
              </Card>
              <Card
                onClick={(e) => setVisibleMarket(true)}
                className="card-style"
                style={{ borderColor: globalColors.chatBackground }}
                bodyStyle={{
                  background: globalColors.chatBackground,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Market_img fill={constants.primary_color} stroke="none" />
                <span
                  style={{
                    color: constants.primary_color,
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Markets
                </span>
              </Card>
            </div>
            {/* <div style={{ display: "flex" }}>
              <Card
                onClick={(e) => setVisibleActions(true)}
                className="card-style"
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img src={require("../../images/draw_image6.png")} alt="" />
                <span
                  style={{
                    color: "#186AB4",
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Actions
                </span>
              </Card>

              <Card
                onClick={(e) => setVisibleMailKings(true)}
                className="card-style"
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require("../../images/mailkings.svg")}
                  alt=""
                  width="33px"
                />
                <span
                  style={{
                    color: "#186AB4",
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  MailKings
                </span>
              </Card>

              <Card
                onClick={(e) => setVisibleMarket(true)}
                className="card-style"
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img src={require("../../images/draw_image4.png")} alt="" />
                <span
                  style={{
                    color: "#186AB4",
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Markets
                </span>
              </Card>

              <Card
                onClick={(e) => setVisibleVault(true)}
                className="card-style"
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img src={require("../../images/draw_image2.png")} alt="" />
                <span
                  style={{
                    color: "#186AB4",
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Vaults
                </span>
              </Card>

              <Card
                className="card-style"
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={require("../../images/draw_image3.png")} alt="" />
                <span
                  style={{
                    color: "#186AB4",
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Games
                </span>
              </Card>
              <Card
                onClick={(e) => setVisibleProfile(true)}
                className="card-style"
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img src={require("../../images/draw_image5.png")} alt="" />
                <span
                  style={{
                    color: "#186AB4",
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  Profile
                </span>
              </Card>
            </div> */}
          </div>
        </Col>
      );
    }
  };

  return (
    <>
      <Drawer
        bodyStyle={{
          padding: "0px",
          background: globalColors.chatMenuBackground,
        }}
        // title="Basic Drawer"
        placement={"bottom"}
        closable={false}
        onClose={(e) => setVisibleControlPanel(false)}
        visible={visibleControlPanel}
        height="100vh"
        // bodyStyle={{ backgroundColor: "#192A50" }}
      >
        <Row>
          <Col
            span={1}
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              background: globalColors.orgListBackground,
            }}
          >
            <div style={{ paddingTop: "20px" }}>
              <SettingOutlined
                style={{ fontSize: "35px", color: "gray" }}
                onClick={(e) => setShowEditUserInfo(true)}
              />
            </div>
            <div>
              <div>
                <Tooltip placement="right" title={<>Add New Account</>}>
                  <PlusCircleOutlined
                    onClick={(e) => {
                      setNewLogin(true);
                      setVisibleSetting(false);
                      setVisibleActions(false);
                    }}
                    style={{
                      fontSize: "40px",
                      cursor: "pointer",
                      // color: "lightgray",
                      color: selectedChatType.colour_code[1],
                    }}
                  />
                </Tooltip>
              </div>
              {localStorage.getItem("accountArr")
                ? JSON.parse(localStorage.getItem("accountArr")).map((item) => {
                    return (
                      <div
                        style={{ padding: "5px 0px" }}
                        onClick={(e) => {
                          console.log("accont change");
                          localStorage.setItem("user_account", item.email);
                          localStorage.setItem("token", item.token);
                          getCUO();
                          window.location.reload();
                        }}
                      >
                        <Tooltip placement="right" title={<>{item.email}</>}>
                          <Avatar
                            style={{
                              color: "white",
                              backgroundColor: constants.primary_color,
                              verticalAlign: "middle",
                              cursor: "pointer",
                            }}
                            size="large"
                          >
                            {item.email[0].toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      </div>
                    );
                  })
                : ""}
              <div>
                <Tooltip placement="right" title={<>Home</>}>
                  <img
                    onClick={(e) => {
                      setVisibleControlPanel(false);
                      setTimeout(() => {
                        setNewLogin(false);
                        setVisibleSetting(false);
                        setVisibleActions(false);
                      }, 1000);
                    }}
                    src={constants.icon}
                    alt=""
                    size="large"
                    style={{ padding: "15px 0px", cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            </div>
          </Col>
          {conditionalView()}

          <Col
            span={1}
            style={{
              height: "100vh",
              display: !visibleActions ? "flex" : "none",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              background: visibleSetting ? "#E0E0E0" : "",
            }}
          >
            <Tooltip placement="left" title={<>Logout</>}>
              <Logout_img
                onClick={(e) => {
                  localStorage.clear();
                  // localStorage.removeItem("token");
                  window.location.reload();
                }}
                fill={constants.primary_color}
                stroke="none"
                // width={35}
                style={{ marginBottom: "15px", cursor: "pointer" }}
              />
              {/* <img
               
                src={require("../../images/logout_red.svg")}
                alt=""
                
              /> */}
            </Tooltip>
          </Col>
        </Row>
      </Drawer>

      {/* Show Market */}
      {/* <Market /> */}
      {/* Show Vault */}
      {/* <Vault /> */}
      {/* Show Profile */}
      <Profile />

      <Modal
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>
            Edit User Details
          </span>
        }
        visible={showEditUserInfo}
        // onOk={handleOk}
        footer={null}
        onCancel={(e) => setShowEditUserInfo(false)}
      >
        <Form>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Space>
              <Upload
                accept="image/*"
                customRequest={uploadUserAvatar}
                onChange={handleOnChangeUserAvatar}
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                defaultFileList={defaultFileList}
              >
                {userAvatarUrl ? (
                  <img
                    src={userAvatarUrl}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : userObj ? (
                  userObj.avatar ? (
                    <img
                      src={userObj.avatar}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )
                ) : (
                  ""
                )}
              </Upload>
              <div>
                <Space>
                  <Input
                    className={
                      globalColors.darkMode
                        ? "search-input-dark"
                        : "search-input-light"
                    }
                    autoFocus
                    size="large"
                    value={
                      firstName ? firstName : userObj ? userObj.first_name : ""
                    }
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Input
                    className={
                      globalColors.darkMode
                        ? "search-input-dark"
                        : "search-input-light"
                    }
                    autoFocus
                    size="large"
                    value={
                      lastName ? lastName : userObj ? userObj.last_name : ""
                    }
                    placeholder="Last name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Space>
                <Button
                  onClick={editUserDetails}
                  type="primary"
                  ghost
                  size="large"
                  block
                  style={{ marginTop: "20px" }}
                >
                  UPDATE USER DETAILS
                </Button>
              </div>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ControlPanel;

import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  message,
  Upload,
  Input,
  Button,
  Empty,
  Skeleton,
  Space,
  Alert,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ChatOrgList from "../components/ChatOrgList";
import ChatMenu from "../components/ChatMenu/ChatMenu";
import ChatBox from "../components/ChatBox/ChatBox";
import UserInfo from "../components/UserInfo";
import constants from "../constants";

import { MyContext } from "../Context";

import Axios from "axios";
import S3 from "aws-s3";
import disconnectedImg from "../images/disconnect.png";

const Home = () => {
  // const [menuOpen, setMenuOpen] = useState(false);

  const {
    visibleUserInfo,
    url,
    unreadInteractionList,
    selectedChatType,
    allOrgs,
    hideSidebar,
    setHideSidebar,
    friendsLoading,
    groupsLoading,
    supportLoading,
    loadAll,
    showDisconnected,
    setShowDisconnected,
    globalColors,
    timeLeft,
    setTimeLeft,
  } = useContext(MyContext);
  const [loading, setLoading] = useState(false);

  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  // useEffect(() => {
  //   if (showDisconnected) {
  //     if (timeLeft === 0) {
  //       console.log("TIME LEFT IS 0");
  //       setTimeLeft(null);
  //       localStorage.removeItem("socketDisconnected");
  //       window.location.reload();
  //     }

  //     if (!timeLeft) return;

  //     const intervalId = setInterval(() => {
  //       setTimeLeft(timeLeft - 1);
  //     }, 1000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [showDisconnected, timeLeft]);

  // const conditionalThemeMenu = () => {
  //   switch (localStorage.getItem("theme")) {
  //     case "rocket-chat":
  //       return <ChatMenu />;
  //       break;
  //     case "telegram":
  //       return <ChatMenuTelegram />;
  //       break;
  //     case "whatsapp":
  //       return <ChatMenuWhatsapp />;
  //       break;
  //     default:
  //       return <ChatMenu />;
  //       break;
  //   }
  // };

  // const conditionalChatBox = () => {
  //   // console.log(allOrgs, "ljkhdfkjEFJWEKF");
  //   if (allOrgs) {
  //     if (allOrgs.length > 0) {
  //       return <ChatBox />;
  //     } else {
  //       return (
  //         <div style={{ height: "100vh", paddingTop: "22vh" }}>
  //           <Empty />
  //         </div>
  //       );
  //     }
  //   } else {
  //     return (
  //       <div
  //         style={{
  //           height: "30vh",
  //           // width: "100vw",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Skeleton avatar paragraph={{ rows: 4 }} />
  //       </div>
  //     );
  //   }
  // };
  // const conditionalThemeChatBox = () => {
  //   switch (localStorage.getItem("theme")) {
  //     case "rocket-chat":
  //       return conditionalChatBox();
  //       break;
  //     case "telegram":
  //       return <ChatBoxTelegram />;
  //       break;
  //     case "whatsapp":
  //       return <ChatBoxWhatsapp />;
  //       break;
  //     default:
  //       return conditionalChatBox();
  //       break;
  //   }
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("theme") !== "") {
  //     conditionalThemeMenu();
  //   }
  // }, [localStorage.getItem("theme")]);

  // const getNewData = () => {
  //   Axios.post(
  //     `https://testchatsioapi.globalxchange.io/get_user`,
  //     {
  //       email: localStorage.getItem("user_account"),
  //       // token: localStorage.getItem("token"),
  //       app_id: "07f312d8-e05d-4bf1-bcc8-40dacc7927bc",
  //     },
  //     {
  //       headers: {
  //         email: localStorage.getItem("user_account"),
  //         token: localStorage.getItem("token"),
  //         app_id: "07f312d8-e05d-4bf1-bcc8-40dacc7927bc",
  //       },
  //     }
  //   ).then((res) => {
  //     if (!res.data.payload.app_code) {
  //       // getDefaultAppApp();
  //       setVisibleMoreInfo(true);
  //     } else {
  //       setUserObj(res.data.payload);
  //     }
  //   });
  // };

  // const createDefaultApp = () => {
  //   Axios.post(
  //     `https://testchatsioapi.globalxchange.io/add_application`,
  //     {
  //       name: "GX Chat",
  //       description: "Chat App for all GX Users",
  //       code: "gxc",
  //       admins: ["chiranjib.jena@gmail.com"],
  //     },
  //     {
  //       headers: {
  //         email: localStorage.getItem("user_account"),
  //         token: localStorage.getItem("token"),
  //       },
  //     }
  //   ).then((res) => {
  //     if (res.data.status) {
  //       message.success("Default App Created");
  //     } else {
  //       message.error("App Creation failed");
  //     }
  //   });
  // };

  const conditionalBackground = () => {
    if (
      localStorage.getItem("theme") === "whatsapp" &&
      localStorage.getItem("mode") === "light"
    ) {
      return "#E0E0E0";
    } else if (
      localStorage.getItem("theme") === "whatsapp" &&
      localStorage.getItem("mode") === "dark"
    ) {
      return "#2E2E2E";
    } else if (
      localStorage.getItem("theme") === "telegram" &&
      localStorage.getItem("mode") === "dark"
    ) {
      return "#2E2E2E";
    } else if (
      localStorage.getItem("theme") === "telegram" &&
      localStorage.getItem("mode") === "light"
    ) {
      return "white";
    } else if (
      localStorage.getItem("theme") === "rocket-chat" &&
      localStorage.getItem("mode") === "dark"
    ) {
      return "#2E2E2E";
    } else if (
      localStorage.getItem("theme") === "rocket-chat" &&
      localStorage.getItem("mode") === "light"
    ) {
      return "white";
    }
  };

  const showData = () => {
    // console.log(loadAll, "loadalllll");
    if (loadAll === true || loadAll === null) {
      return (
        <>
          <Row className="myFont">
            <Col
              xs={1}
              md={1}
              sm={2}
              lg={1}
              className="hidden-sidebar"
              style={{
                // background: "blue",
                height: "100vh",

                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "space-between",
                // alignItems: "center",
                background: conditionalBackground(),

                // justifyContent: "space-around",
              }}
            >
              {selectedChatType !== null ? <ChatOrgList /> : ""}
            </Col>

            {!hideSidebar ? <ChatMenu /> : ""}

            {/* {unreadInteractionList.length > 0 ? conditionalThemeChatBox() : ""} */}
            {/* {conditionalChatBox()} */}
            <ChatBox />
            {visibleUserInfo ? (
              <Col
                span={4}
                className={globalColors.darkMode ? "" : "light-shadow-left"}
                style={{
                  height: "100vh",
                  background: globalColors.drawerBackground,
                  color: globalColors.chatText,
                }}
                // bodyStyle={{
                //   padding: "0px",
                //   background: globalColors.drawerBackground,
                // }}
                // headerStyle={{
                //   background: globalColors.drawerBackground,
                // }}
                // title={
                //   <span style={{ color: globalColors.chatText }}>Group Members</span>
                // }
              >
                <UserInfo />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {/* {showDisconnected ? (
            <Alert
              banner
              message="The app is disconnected from the server. Click the reconnect button to connect again."
              type="warning"
              action={
                <Space>
                  <Button
                    size="small"
                    type="ghost"
                    onClick={(e) => {
                      window.location.reload();
                      localStorage.removeItem("socketDisconnected");
                    }}
                  >
                    Reconnect
                  </Button>
                </Space>
              } 
          // closable /> ) : ( "" )}*/}
        </>
      );
    } else {
      return (
        <div className="full-loading-wrapper">
          <img src={constants.logo} alt="" className="full-loading-logo" />
        </div>
      );
    }
  };

  useEffect(() => {
    console.log(loadAll, "hwejwhef");
    showData();
  }, [loadAll]);

  // useEffect(() => {
  //   if (showDisconnected) {
  //     localStorage.removeItem("socketDisconnected");
  //     window.location.reload();
  //   }
  // }, [showDisconnected]);

  return (
    <>
      {showData()}
      <Modal
        // title="Basic Modal"
        footer={null}
        visible={showDisconnected}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <Empty
          image={disconnectedImg}
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              The App has been disconnected from server. Click the buttons below
              to reconnect or logout.
            </span>
          }
        >
          <Space>
            <Button
              style={{ width: "100px" }}
              type="primary"
              onClick={(e) => {
                localStorage.removeItem("socketDisconnected");
                window.location.reload();
              }}
            >
              Reconnect
            </Button>
            <Button
              style={{ width: "100px" }}
              type="danger"
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Logout
            </Button>
          </Space>
        </Empty>
      </Modal>
    </>
  );
};

export default Home;

/* <Col
          span={22}
          style={{
            height: "100vh",
          }}
        >
          <div
            className="no-outline"
            // className="light-shadow-top"
            style={{
              position: "fixed",
              bottom: 0,
              width: "90%",
            }}
          >
            <div className="mx-auto image-wrap no-outline" tabIndex={0}>
              <div className={`image${menuOpen ? " active" : ""}`}>
                <img
                  onClick={() => setMenuOpen(!menuOpen)}
                  src={require("../images/asset_GXT.svg")}
                  alt=""
                  width={80}
                />
              </div>
              <div className="overlay" />
              <ul>
                <li className="menu-item">
                  <i>
                    <img
                      src={require("../images/asset_BAT.svg")}
                      alt=""
                      width={50}
                    />
                     <span>Vaults</span> 
                  </i>
                </li>
                <li className="menu-item">
                  <i>
                    <img
                      src={require("../images/asset_USDC.svg")}
                      alt=""
                      width={50}
                    />
                     <span>Share</span> 
                  </i>
                </li>
                <li className="menu-item">
                  <i>
                    <img
                      src={require("../images/asset_REP.svg")}
                      alt=""
                      width={50}
                    />
                     <span>Trending</span> 
                  </i>
                </li>
                <li className="menu-item">
                  <i>
                    <img
                      src={require("../images/asset_ZRX.svg")}
                      alt=""
                      width={50}
                    />
                     <span>I'll Bet</span> 
                  </i>
                </li>
                <li className="menu-item">
                  <a>
                    <img
                      src={require("../images/asset_BAT.svg")}
                      alt=""
                      width={50}
                    />
                    <span>Logout</span> 
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      */

import React, { useState, useEffect, useRef } from "react";

import S3 from "aws-s3";
// import "./App.css";
import "antd/dist/antd.css";
import "./css/myStyle.scss";
import "react-awesome-lightbox/build/style.css";
// import "./css/footer.scss";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { ReactTinyLink, useScrapper } from "react-tiny-link";
import Lightbox from "react-awesome-lightbox";
import { HashRouter, Switch, Route } from "react-router-dom";
import {
  message,
  Modal,
  Card,
  Upload,
  Button,
  Input,
  Divider,
  Avatar,
  Tooltip,
  Badge,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  FileOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import ioClient from "socket.io-client";
import axios from "axios";

import Login from "./pages/Login";
import Home from "./pages/Home";
import fetch from "./fetchurl.js";
import { MyContext } from "./Context";
import ReactPlayer from "react-player";

import gamerchat from "./images/gamer_chats.svg";
import teamforce from "./images/teamforce_logo.svg";
import classrooms from "./images/classrooms.svg";
import chatsio from "./images/chat_name.png";

import gamerchat_icon from "./images/gamer_chats.svg";
import teamforce_icon from "./images/teamforce_logoonly.svg";
import classrooms_icon from "./images/classroom_icon.png";
import chatsio_icon from "./images/chat_blue.png";
import constants from "./constants";
import Splash from "./pages/Splash";

// const socketRef = useRef();
// const usersocketRef = useRef();
let socket;
let usersocket;
let url = "https://testchatsioapi.globalxchange.io/";

const socketTokenValidation = () => {
  return new Promise(async (resolve, reject) => {
    let res = await axios.post(
      `https://gxauth.apimachine.com/gx/user/refresh/tokens`,
      {
        refreshToken: localStorage.getItem("refresh_token"),
        device_key: localStorage.getItem("device_key"),
      }
    );

    console.log(res, "jhfhfj refrehtoken called");
    if (res.data.status) {
      localStorage.setItem("validationTime", new Date().getTime());

      localStorage.setItem("token", res.data.idToken);
      localStorage.setItem("access_token", res.data.accessToken);

      // setTokenValid(true);
    } else {
      localStorage.clear();
      window.location.reload();
    }
    resolve(res.data.idToken);
  });
};

socket = ioClient("https://testsockchatsio.globalxchange.io", {
  reconnection: true,
  reconnectionAttempts: Infinity,
  autoConnect: false,
  query: {
    email: localStorage.getItem("user_account"),
    token: localStorage.getItem("token"),
    // token: "1625e62re",
    tokfortest: "nvestisgx",
  },
});
console.log(socket, "jhfhfjf");

socket.connect();

// socket.on("disconnect", async (abc) => {
//   console.log("yayy disconnected", abc, "jhfhfjf");
//   // usersocket.connect();
//   // console.log(usersocket, "jhfhfjf");
//   var difference =
//     new Date().getTime() - localStorage.getItem("validationTime");
//   // console.log(difference, "kjediwuediwuegdi");
//   var minutesDifference = Math.floor(difference / 1000 / 60);
//   console.log(minutesDifference, "jhfhfjf");
//   if (minutesDifference < 30) {
//     console.log("inside difference", "jhfhfjf");
//     // usersocket.query.token = localStorage.getItem("token");
//     setTimeout(() => {
//       console.log("inside timeout", "jhfhfjf");
//       socket.connect();
//       console.log(socket, "jhfhfjf");
//     }, 1000);
//   } else {
//     console.log("inside else statement", "jhfhfjf");
//     socket.query.token = await socketTokenValidation();
//     console.log(socket, "jhfhfjf");
//     socket.connect();
//     console.log(socket, "jhfhfjf");
//   }
// });

usersocket = ioClient("https://testsockchatsio.globalxchange.io/user", {
  reconnection: true,
  reconnectionAttempts: Infinity,
  autoConnect: false,
  query: {
    email: localStorage.getItem("user_account"),
    token: localStorage.getItem("token"),
    tokfortest: "nvestisgx",
  },
});
console.log(usersocket, "jhfhfjf");
usersocket.connect();

// usersocket.on("disconnect", async (abc) => {
//   console.log("yayy disconnected", abc, "jhfhfjf");
//   // usersocket.connect();
//   // console.log(usersocket, "jhfhfjf");
//   var difference =
//     new Date().getTime() - localStorage.getItem("validationTime");
//   // console.log(difference, "kjediwuediwuegdi");
//   var minutesDifference = Math.floor(difference / 1000 / 60);
//   console.log(minutesDifference, "jhfhfjf");
//   if (minutesDifference < 30) {
//     console.log("inside difference", "jhfhfjf");
//     // usersocket.query.token = localStorage.getItem("token");
//     setTimeout(() => {
//       console.log("inside timeout", "jhfhfjf");
//       usersocket.connect();
//       console.log(usersocket, "jhfhfjf");
//     }, 1000);
//   } else {
//     console.log("inside else statement", "jhfhfjf");
//     usersocket.query.token = await socketTokenValidation();
//     console.log(usersocket, "jhfhfjf");
//     usersocket.connect();
//     console.log(usersocket, "jhfhfjf");
//   }
// });

socket.on("reconnect", (e) => {
  console.log(`It took ${e} tries to reconnect`);
  var difference =
    new Date().getTime() - localStorage.getItem("validationTime");

  var minutesDifference = Math.floor(difference / 1000 / 60);
  if (minutesDifference < 30) {
    console.log("inside difference", minutesDifference, "jhfhfjf");
  } else {
    console.log("getting new token", minutesDifference, "jhfhfjf");
    socketTokenValidation();
  }

  console.log(socket, "jhfhfjf");
});

usersocket.on("reconnect", (e) => {
  console.log(`It took ${e} tries to reconnect`);
  var difference =
    new Date().getTime() - localStorage.getItem("validationTime");

  var minutesDifference = Math.floor(difference / 1000 / 60);
  if (minutesDifference < 30) {
    console.log("inside difference", minutesDifference, "jhfhfjf");
  } else {
    console.log("getting new token", minutesDifference, "jhfhfjfuser");
    socketTokenValidation();
  }

  console.log(socket, "jhfhfjfuser");
});

socket.emit("ping_test", (res) => {
  console.log(res, "pongtestttt");
});

// if ("test" === "test1") {
//   socket = ioClient("http://localhost:3569", {
//     reconnection: false,
//     query: {
//       email: localStorage.getItem("user_account"),
//       token: localStorage.getItem("token"),
//       tokfortest: "nvestisgx",
//     },
//   });
//   usersocket = ioClient("http://localhost:3569/user", {
//     reconnection: false,
//     query: {
//       email: localStorage.getItem("user_account"),
//       token: localStorage.getItem("token"),
//       tokfortest: "nvestisgx",
//     },
//   });
// } else {
//   socket = ioClient("https://testsockchatsio.globalxchange.io", {
//     reconnection: false,
//     query: {
//       email: localStorage.getItem("user_account"),
//       token: localStorage.getItem("token"),
//     },
//   });
//   usersocket = ioClient("https://testsockchatsio.globalxchange.io/user", {
//     reconnection: false,
//     query: {
//       email: localStorage.getItem("user_account"),
//       token: localStorage.getItem("token"),
//     },
//   });
// }

const { TextArea } = Input;

const App = ({
  selectedOrg,
  setSelectedOrg,
  switchOrg,
  app_id,
  adminEmails,
}) => {
  // console.log("allaksfnvlenvlernv", app_id);
  let bodyRef = useRef();
  let bodyRef1 = useRef();
  let scrollRef = useRef();

  const [loadAll, setLoadAll] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [hidePrivate, setHidePrivate] = useState(false);
  const [hideGroup, setHideGroup] = useState(false);
  const [hideSupport, setHideSupport] = useState(false);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [visibleUserInfo, setVisibleUserInfo] = useState(false);
  // const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [groupReadUnreadList, setgroupReadUnreadList] = useState([]);
  const [supportgroupReadUnreadList, setsupportgroupReadUnreadList] = useState(
    []
  );
  const [selectedGroup, setSelectedGroup] = useState({});
  const [currentUserObj, setCurrentUserObj] = useState({});
  const [userReadUnreadList, setuserReadUnreadList] = useState([]);
  const [unreadInteractionList, setUnreadInteractionList] = useState([]);
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [messagearray, setmessagearray] = useState([]);
  const [groupMembers, setgroupMembers] = useState([]);
  const [droppedFile, setDroppedFile] = useState(null);
  const [clipImage, setClipImage] = useState("");
  const [clipVideo, setClipVideo] = useState("");
  const [visibleDroppedImage, setVisibleDroppedImage] = useState(false);
  const [visibleDroppedVideo, setVisibleDroppedVideo] = useState(false);
  const [visibleDroppedFile, setVisibleDroppedFile] = useState(false);
  // const [redirect, setRedirect] = useState(false);
  // const [selectedReplyMessage, setSelectedReplyMessage] = useState({});
  const [selectedReplyMessage, setSelectedReplyMessage] = useState(null);

  const [imageToShow, setImageToShow] = useState(null);
  const [visibleShowImage, setVisibleShowImage] = useState(false);

  const [allUserList, setallUserList] = useState([]);
  const [currentUserList, setcurrentUserList] = useState([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [friendsLoading, setFriendsLoading] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [supportLoading, setSupportLoading] = useState(false);

  const [selectedAdminSupport, setSelectedAdminSupport] = useState(null);
  const [selectedAdminSupport1, setSelectedAdminSupport1] = useState(null);
  const [archiveList, setArchiveList] = useState([]);
  const [typingFlag, setTypingFlag] = useState(false);
  const [typingUser, setTypingUser] = useState(null);

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [adminUsernames, setAdminUsernames] = useState([]);

  const [visibleControlPanel, setVisibleControlPanel] = useState(false);
  const [visibleSwitcher, setVisibleSwitcher] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [visibleVault, setVisibleVault] = useState(false);
  const [visibleMarket, setVisibleMarket] = useState(false);
  const [visibleActions, setVisibleActions] = useState(false);

  const [chatBoxArr, setChatBoxArr] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const [visibleSetting, setVisibleSetting] = useState(false);
  const [visibleMailKings, setVisibleMailKings] = useState(false);
  // const [selectedTheme, setSelectedTheme] =

  //Multi Account Support

  const [newLogin, setNewLogin] = useState(false);
  const [accountArr, setAccountArr] = useState(
    localStorage.getItem("accountArr")
      ? JSON.parse(localStorage.getItem("accountArr"))
      : ""
  );

  //New Info Addition

  const [visibleMoreInfo, setVisibleMoreInfo] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [oldUserObj, setOldUserObj] = useState(null);
  const [userObj, setUserObj] = useState(null);

  const [defaultApp, setDefaultApp] = useState(null);
  const [registered, setRegistered] = useState(false);

  const [refreshGroup, setRefreshGroup] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);

  const [chatTypes, setChatTypes] = useState([]);
  const [selectedChatType, setSelectedChatType] = useState(null);

  const [allOrgs, setAllOrgs] = useState(null);
  const [tokenValid, setTokenValid] = useState(null);
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [threadItem, setThreadItem] = useState(null);
  const [selectedReplyMessage1, setSelectedReplyMessage1] = useState(null);

  const [loadingImage, setLoadingImage] = useState(false);
  const [memberUsernames, setMemberUsernames] = useState([]);
  const [selectedEditMessage, setSelectedEditMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [threadMessagearray, setThreadMessagearray] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [editorTextThread, setEditorTextThread] = useState("");
  const [showReactions, setShowReactions] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [droppedVideo, setDroppedVideo] = useState(null);

  const [runNotify, setRunNotify] = useState(false);
  const [goToBottom, setGoToBottom] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [linkArr, setLinkArr] = useState([]);
  const [webLinkPreviews, setWebLinkPreviews] = useState([]);
  const [imageLinkPreviews, setImageLinkPreviews] = useState([]);

  const [blankPage, setBlankPage] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [foundSavedObj, setFoundSavedObj] = useState(null);
  const [validationTime, setValidationTime] = useState(null);

  const [selectedDeleteMessages, setSelectedDeleteMessages] = useState([]);
  const [selectedForwardMessages, setSelectedForwardMessages] = useState([]);
  const [showForwardMessageUI, setShowForwardMessageUI] = useState(false);
  const [showFullname, setShowFullname] = useState(null);
  const [selectedGroupChats, setSelectedGroupChats] = useState([]);
  const [selectedFriendChats, setSelectedFriendChats] = useState([]);
  const [globalColors, setGlobalColors] = useState({
    chatMenuBackground: "white",
  });
  const [showDisconnected, setShowDisconnected] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const [groupLists, setGroupLists] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const [showUnread, setShowUnread] = useState(null);
  const [showFav, setShowFav] = useState(null);
  const [favList, setFavList] = useState([]);
  const [supportFav, setSupportFav] = useState([]);
  const [friendFav, setFriendFav] = useState([]);
  const [groupFav, setGroupFav] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);

  const [allGroupRoles, setAllGroupRoles] = useState([]);

  const [sendMessageState, setSendMessageState] = useState(false);
  const [deleteMessageState, setDeleteMessageState] = useState(false);
  const [addUserState, setAddUserState] = useState(false);
  const [removeUserState, setRemoveUserState] = useState(false);
  const [addRoleState, setAddRoleState] = useState(false);
  const [removeRoleState, setRemoveRoleState] = useState(false);
  const [visiblePasteImage, setVisiblePasteImage] = useState(false);

  const [showEditUserInfo, setShowEditUserInfo] = useState(false);

  useEffect(() => {
    if (socket.connected === false) {
      console.log("socket iss disconnected");
      // console.log("jhfhfjf socket false");
      // localStorage.setItem("socketDisconnected", true);
      setShowDisconnected(true);
      setTimeLeft(5);
    } else {
      console.log("socket iss connected");
      // console.log("jhfhfjf socket true");
      // localStorage.setItem("socketDisconnected", false);
      setShowDisconnected(false);
    }
  }, [socket.connected]);

  // useEffect(() => {
  //   if (localStorage.getItem("socketDisconnected")) {
  //     console.log("jhfhfjf inside useeffect socket false");
  //     setShowDisconnected(true);
  //   } else {
  //     console.log("jhfhfjf inside useeffect socket true");
  //     setShowDisconnected(false);
  //   }
  // }, [localStorage.getItem("socketDisconnected")]);

  const changeMode = () => {
    // console.log(checked, "kefkwekfwkef");
    if (!globalColors.darkMode) {
      setGlobalColors({
        darkMode: true,
        orgListBackground: "#505050",
        // chatMenuBackground: "#131927",
        chatMenuBackground: "#2B2B2B",
        chatBackground: "#1B1B1B",
        chatHoover: "green",
        chatText: "lightgray",
        editorBackground: "#2B2B2B",
        drawerBackground: "#505050",
        // editorBorder: "blue",
      });
      localStorage.setItem("DarkMode", true);
    } else {
      setGlobalColors({
        darkMode: false,
        orgListBackground: "white",
        chatMenuBackground: "white",
        chatBackground: "transparent",
        chatHoover: "purple",
        chatText: "black",
        editorBackground: "white",

        drawerBackground: "white",
        // editorBorder: "lightgray",
      });
      localStorage.setItem("DarkMode", false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("globalColors")) {
      setGlobalColors(JSON.parse(localStorage.getItem("globalColors")));
      console.log(
        JSON.parse(localStorage.getItem("globalColors")),
        "kjebfjhwef"
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("globalColors", JSON.stringify(globalColors));
  }, [globalColors]);

  const conditionalReadMessage = (item) => {
    // if (userObj) {
    //   if ((item.type = "group")) {
    //     socket.emit(
    //       "new_group_chat_message_read",
    //       userObj.username,
    //       selectedGroup.thread_id,
    //       app_id,
    //       (response) => {
    //         console.log("jhsdjwhvedj", response);
    //         let tempList = groupReadUnreadList;
    //         var foundIndex = tempList.findIndex(
    //           (x) => x.thread_id == selectedGroup.thread_id
    //         );
    //         tempList[foundIndex].unread_count = 0;
    //         setgroupReadUnreadList([...tempList]);
    //       }
    //     );
    //   } else if (selectedFriend) {
    //     socket.emit(
    //       "new_direct_message_read",
    //       userObj.username,
    //       selectedFriend.thread_id,
    //       app_id,
    //       (response) => {
    //         console.log("jhsdjwhvedj", response);
    //         let tempList = unreadInteractionList;
    //         var foundIndex = tempList.findIndex(
    //           (x) => x.thread_id == selectedFriend.thread_id
    //         );
    //         tempList[foundIndex].unread_count = 0;
    //         setUnreadInteractionList([...tempList]);
    //       }
    //     );
    //   }
    // }
  };

  const handleRefetchData = () => {
    setMessageLoading(true);
    var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
    if (tempStorage) {
      if (selectedFriend) {
        var foundObj = tempStorage.find(
          (o) => o.chatObj["email"] === selectedFriend.email
        );
        if (foundObj) {
          var tempdata = tempStorage.filter(
            (item) => item.chatObj.username !== selectedFriend.username
          );
          localStorage.setItem("storedChat", JSON.stringify(tempdata));
        }

        socket.emit(
          "check_user_interaction_list",
          userObj ? userObj : JSON.parse(localStorage.getItem("userObj")),
          selectedFriend.username,
          // selectedFriend.thread_id,
          app_id,
          (response) => {
            setMessageLoading(false);
            console.log("jkfgkasbjgkaefjw", response);
            let chat_history = [...response];
            setmessagearray([...chat_history.reverse()]);
            setGoToBottom(true);
            handleStoreData("personal", response, selectedFriend);
            // setsetFlag(true);
          }
        );
      } else if (selectedGroup) {
        var foundObj = tempStorage.find(
          (o) => o.chatObj["group_name"] === selectedGroup.group_name
        );
        if (foundObj) {
          var tempdata = tempStorage.filter(
            (item) => item.chatObj.group_name !== selectedGroup.group_name
          );
          localStorage.setItem("storedChat", JSON.stringify(tempdata));
        }

        socket.emit(
          "group_details",
          selectedGroup.thread_id,
          app_id,
          (resp) => {
            setMessageLoading(false);
            console.log(resp, "chat_history");
            setmessagearray([...resp.payload.chat_history.reverse()]);

            handleStoreData("group", resp.payload.chat_history, selectedGroup);
            setGoToBottom(true);
            setgroupMembers([...resp.payload.group_details.participants_info]);
            var tempData = [];
            resp.payload.group_details.participants_info.map((item) => {
              tempData.push(item.username);
            });
            console.log(tempData, "jhjdwedfq");
            const index = tempData.indexOf(
              userObj
                ? userObj.username
                : JSON.parse(localStorage.getItem("userObj")).username
            );
            if (index > -1) {
              tempData.splice(index, 1);
            }
            console.log(tempData, "jhjdwedfq");
            setMemberUsernames([...tempData]);
            setRefetchData(false);
          }
        );
      }
    }
  };

  const conditionalBadge = (item) => {
    if (selectedFriend) {
      if (selectedFriend.username !== item.username) {
        return (
          <Badge
            count={item.unread_count ? item.unread_count : 0}
            style={{
              backgroundColor: "red",
              color: "white",
              // boxShadow: "0 0 0 1px #d9d9d9 inset",
            }}
          />
        );
      }
    } else if (selectedGroup) {
      if (selectedGroup.group_name !== item.group_name) {
        return (
          <Badge
            count={item.unread_count ? item.unread_count : 0}
            style={{
              backgroundColor: "red",
              color: "white",
              // boxShadow: "0 0 0 1px #d9d9d9 inset",
            }}
          />
        );
      }
    } else if (selectedSupport) {
      if (selectedSupport.group_name !== item.group_name) {
        return (
          <Badge
            count={item.unread_count ? item.unread_count : 0}
            style={{
              backgroundColor: "red",
              color: "white",
              // boxShadow: "0 0 0 1px #d9d9d9 inset",
            }}
          />
        );
      }
    }
  };

  useEffect(() => {
    socket.on(`message_deleted`, (res) => {
      console.log(
        res.thread_id,
        selectedGroup,
        selectedFriend,
        "jbkjwdbkwjebdkwjbeee"
      );
      if (
        selectedFriend
          ? selectedFriend.thread_id === res.thread_id
          : "" || selectedGroup
          ? selectedGroup.thread_id === res.thread_id
          : ""
      ) {
        handleRefetchData();
      }
      // thread_id,msg_ids:ids,type:'group'
    });
    return () => {
      socket.off("message_deleted");
    };
  });

  useEffect(() => {
    var x = document.getElementById("focushere");
    if (x !== null) {
      x.focus();
      bodyRef.current.focus();
      console.log(x, document.activeElement, "kjwgckjwec");
    }
    if (document.activeElement.id.includes("dontfocus")) {
      // window.onblur = function () {
      document.activeElement.blur();
      // };
      var x = document.getElementById("focushere");
      if (x !== null) {
        x.focus();
        bodyRef.current.focus();
        console.log(x, document.activeElement, "kjwgckjwec");
      }
    }
  }, [submitLoading]);

  // useEffect(() => {});

  // useEffect(() => {
  //   if (document.activeElement.id.includes("dontfocus")) {
  //     document.getElementsByTagName("body").blur();
  //     console.log(document.getElementsByTagName("body"), "kjwbkjwbekfbewf");
  //     // const x = document.getElementById("focushere");
  //     // if (x !== null) {
  //     //   setTimeout(() => {
  //     //     x.focus();
  //     //     console.log("kjwbkjwbekfbewf", x, document.activeElement);
  //     //   }, 1000);
  //     // }
  //   }
  // }, [document.activeElement]);

  const focusAssistant = () => {
    // const x = document.getElementById("focushere");
    // if (x !== null) {
    //   window.setTimeout(() => x.focus(), 0);
    //   // x.focus();
    //   console.log("kjwbkjwbekfbewf", x, document.activeElement);
    // }
    // setTimeout(function () {
    //   if (document.getElementById("focushere")) {
    //     document.getElementById("focushere").focus();
    //   }
    // }, 0);
  };

  // useEffect(() => {

  // }, [document.getElementById("focushere")]);

  useEffect(() => {
    localStorage.setItem("validationTime", new Date().getTime());
  }, []);

  useEffect(() => {
    if (userObj !== null && userObj === undefined) {
      console.log("hohohohoho");
      tokenValidation();
    }
  }, [userObj]);

  useEffect(() => {
    if (userObj && selectedGroup !== null) {
      console.log(selectedGroup, "kjbwkejbc");
      socket.emit(
        "new_group_chat_message_read",
        userObj.username,
        selectedGroup.thread_id,
        app_id,
        (response) => {
          console.log("jhsdjwhvedjkk", response);
          let tempList = groupReadUnreadList;
          var foundIndex = tempList.findIndex(
            (x) => x.thread_id == selectedGroup.thread_id
          );
          if (tempList[foundIndex]) {
            tempList[foundIndex].unread_count = 0;
          }

          setgroupReadUnreadList([...tempList]);
        }
      );
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (userObj && selectedFriend !== null) {
      socket.emit(
        "new_direct_message_read",
        userObj.username,
        selectedFriend.thread_id,
        app_id,
        (response) => {
          console.log("jhsdjwhvedj", response);
          let tempList = unreadInteractionList;
          var foundIndex = tempList.findIndex(
            (x) => x.thread_id == selectedFriend.thread_id
          );
          if (tempList[foundIndex]) {
            tempList[foundIndex].unread_count = 0;
          }
          setUnreadInteractionList([...tempList]);
        }
      );
    }
  }, [selectedFriend]);

  const handleTabClick = (activeKey) => {
    setTypingUser(null);

    setSelectedDeleteMessages([]);
    setSelectedForwardMessages([]);
    if (activeKey !== activeTab || !localStorage.getItem("storedChat")) {
      console.log(activeKey, "jhjdwedfq");
      setGoToBottom(true);
      setActiveTab(activeKey);
      setSelectedFriend(null);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);

      chatBoxArr.map((item, index) => {
        console.log(index, activeKey, "jkgfckjsfgkwef");
        if (index === Number(activeKey)) {
          console.log("insideselector", index, activeKey, item);
          if (item.type === "group") {
            console.log(item.type, item.data, "jkgfckjsfgkwef888");
            conditionalReadMessage(item);
            setSelectedGroup(item.data);
            setSelectedFriend(null);

            var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
            // if (chatBoxArr.length > 1) {
            //   if (tempStorage) {
            //     var foundObj = tempStorage.find(
            //       (o) => o.chatObj["group_name"] === item.data.group_name
            //     );
            //     if (!foundObj) {
            //       setMessageLoading(true);
            //       setmessagearray([]);
            //     }
            //   }
            // }
            if (tempStorage) {
              var foundObj = tempStorage.find(
                (o) => o.chatObj["group_name"] === item.data.group_name
              );

              if (!foundObj) {
                setMessageLoading(true);
                setmessagearray([]);
              } else {
                console.log("insideselector", foundObj.messages);
                setmessagearray([...foundObj.messages.reverse()]);
                setGoToBottom(true);
              }
            }

            setSelectedAdminSupport(null);
          } else if (item.type === "personal") {
            console.log(item, "jkgfckjsfgkwef6565");
            conditionalReadMessage(item);
            setSelectedFriend(item.data);
            setSelectedGroup(null);
            setSelectedAdminSupport(null);

            var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
            console.log(tempStorage, "jkgfckjsfgkwef6565");
            if (tempStorage) {
              var foundObj = tempStorage.find(
                (o) => o.chatObj["email"] === item.data.email
              );
              if (!foundObj) {
                setMessageLoading(true);
                setmessagearray([]);
              } else {
                console.log(foundObj.messages, "kjqwkdgwkegded");
                setmessagearray([...foundObj.messages.reverse()]);
                setGoToBottom(true);
              }
            }
          } else if (item.type === "adminSupport") {
            console.log(item.type, "jkgfckjsfgkwef");
            conditionalReadMessage(item);
            setSelectedAdminSupport(item.data);
            setSelectedSupport(item.data);
            setSelectedGroup(null);
            setSelectedFriend(null);
            setMessageLoading(true);
            setmessagearray([]);
          } else if (item.type === "support") {
            conditionalReadMessage(item);
            console.log(item.type, "jkgfckjsfgkwef");
            setSelectedAdminSupport(item.data);
            setSelectedSupport(item.data);
            setSelectedGroup(null);
            setSelectedFriend(null);

            var tempStorage = JSON.parse(localStorage.getItem("storedChat"));

            if (tempStorage) {
              var foundObj = tempStorage.find(
                (o) => o.chatObj["group_name"] === item.data.group_name
              );
              if (!foundObj) {
                setMessageLoading(true);
                setmessagearray([]);
              } else {
                setmessagearray([...foundObj.messages.reverse()]);
              }
            }
          }
        } else {
          console.log("jkgfckjsfgkwef", "nothing");
          setMessageLoading(false);
          setmessagearray([]);
        }
      });
    }
  };

  // useEffect(() => {
  //   socket.on("connect", function () {
  //     console.log("check 2", socket.connected);
  //   });
  // }, []);

  // useEffect(() => {
  //   console.log(chatBoxArr, "jkwkweg");
  //   if(localStorage.getItem("tabArr") && chatBoxArr.l)
  //   localStorage.setItem("tabArr", JSON.stringify(chatBoxArr));
  // }, [chatBoxArr]);

  // useEffect(() => {
  //   console.log(JSON.parse(localStorage.getItem("tabArr")), "jkwkweg");
  //   var newArr1 = JSON.parse(localStorage.getItem("tabArr"));
  //   if (newArr1.length > 0) {
  //     setChatBoxArr([...newArr1]);
  //   }
  // }, [localStorage.getItem("tabArr")]);

  useEffect(() => {
    if (!localStorage.getItem("activeTab")) {
      setActiveTab(0);
    }
    if (activeTab !== null) {
      localStorage.setItem("activeTab", activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    if (userObj) {
      usersocket.on("disconnect", async (abc) => {
        usersocket.emit("user_left_in_app", userObj.username, app_id, (res) => {
          console.log(res, "Offline usersssx");
        });

        usersocket.on(`${app_id}_user_online_notification`, (res) => {
          console.log(res, "Offline usersssx");
        });
      });
    }
  }, [userObj]);

  const getThumbnail = (url) => {
    if (url.indexOf("youtube.com") > 0 || url.indexOf("youtu.be") > 0) {
      var videoid = url.match(
        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
      );
      if (videoid != null) {
        // console.log("video id = ", videoid[1]);
        var vid = videoid[1].substring(0, videoid[1].length);
        var final = `https://img.youtube.com/vi/${
          vid.length > 11 ? vid.substring(0, 11) : vid
        }/0.jpg`;
        // console.log(vid, final, "kwjkweb");
        return final;
      } else {
        return "https://i.pinimg.com/originals/27/13/9b/27139b573b1d9d5fe66e7e27e7127563.jpg";
      }
    } else {
      return "https://i.pinimg.com/originals/27/13/9b/27139b573b1d9d5fe66e7e27e7127563.jpg";
    }
  };

  const createChatType = () => {
    axios
      .post(
        `https://testchatsioapi.globalxchange.io/create_chat_type`,
        {
          // chat_type: "Chats.io",
          // brand_name: "Chats.io",
          // coloured_logo: chatsio,
          // white_logo: chatsio,
          // coloured_icon: chatsio_icon,
          // white_icon: chatsio_icon,
          // description: "Chats.io Chats",
          // colour_code: ["#166AB3", "lightgray", "white"], //Array of strings
          chat_type: "teamforce",
          brand_name: "teamforce",
          coloured_logo:
            "https://drivetest.globalxchange.io/gxsharepublic/?full_link=ichiranjeeb2.brain.stream/teamforce_logo_color",
          white_logo:
            "https://drivetest.globalxchange.io/gxsharepublic/?full_link=ichiranjeeb2.brain.stream/teamforce_logo_white",
          coloured_icon:
            "https://drivetest.globalxchange.io/gxsharepublic/?full_link=ichiranjeeb2.brain.stream/teamforce_icon_color",
          white_icon:
            "https://drivetest.globalxchange.io/gxsharepublic/?full_link=ichiranjeeb2.brain.stream/teamforce_icon_white",
          description: "Teamforce Chats",
          colour_code: ["#F45D48", "lightgray", "white"],
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then();
  };

  const showNotification = (data) => {
    if (data.reply_thread !== true) {
      new Notification(`${data.sender.first_name} sent a message`, {
        body: data.message,
        icon: data.sender.avatar,
      });
    }
  };

  const showNotificationGroup = (data, groupData) => {
    if (groupData) {
      console.log(data, groupData, "notificationjjjj");
      if (data.reply_thread !== true) {
        new Notification(
          `${data.sender.first_name} sent a message in ${groupData.group_name}`,
          {
            body: data.message,
            icon: data.sender.avatar,
          }
        );
      }
    }
    // var foundIndex = groupReadUnreadList.findIndex(
    //   (x) => x.thread_id == data.thread_id
    // );
    // if (data.reply_thread !== true) {
    //   new Notification(
    //     `${data.sender.first_name} sent a message in ${groupData.group_name}`,
    //     {
    //       body: JSON.parse(data.message),
    //       icon: data.sender.avatar,
    //     }
    //   );
    // }
  };

  // useEffect(() => {
  //   if (messagearray.length > 0) {
  //     let tempArr = [];
  //     let tempArr1 = [];
  //     messagearray.map((item) => {
  //       var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  //       var hyperlink;
  //       item.message.replace(urlRegex, function (url) {
  //         hyperlink = url;
  //         console.log(hyperlink, "jhwejwhed");
  //       });
  //       if (hyperlink) {
  //         if (
  //           hyperlink.slice(hyperlink.lastIndexOf(".") + 1) === "png" ||
  //           hyperlink.slice(hyperlink.lastIndexOf(".") + 1) === "jpg" ||
  //           hyperlink.slice(hyperlink.lastIndexOf(".") + 1) === "jpeg"
  //         ) {
  //           if (!hyperlink.match("^https?://")) {
  //             hyperlink = "http://" + hyperlink;
  //           }
  //           tempArr1.push({ id: item._id, link: hyperlink });
  //         } else {
  //           if (!hyperlink.match("^https?://")) {
  //             hyperlink = "http://" + hyperlink;
  //           }
  //           // console.log(hyperlink, "khjvhkwve");
  //           tempArr.push({ id: item._id, link: hyperlink });
  //         }
  //       }
  //     });
  //     setImageLinkPreviews([...tempArr1]);
  //     console.log(tempArr, "jhwjhwfejw");
  //     socket.emit("get_url_data", tempArr, (res) => {
  //       if (res.status) {
  //         setWebLinkPreviews(res.payload);
  //       }
  //     });
  //   }
  // }, [messagearray]);

  const updateStorage = (data) => {
    console.log(data, "lwjHFIKWBFKWE");

    let tempStorage = JSON.parse(localStorage.getItem("storedChat"));
    if (tempStorage) {
      var foundStorageIndex = tempStorage.findIndex(
        (x) => x.chatObj.thread_id == data.thread_id
      );
      console.log(foundStorageIndex, "lwjHFIKWBFKWE");
      if (foundStorageIndex >= 0) {
        if (data.reply_thread) {
          var foundMsgIndex = tempStorage[foundStorageIndex].messages.findIndex(
            (o) => o._id === data._id
          );

          tempStorage[foundStorageIndex].messages[foundMsgIndex] = data;
          localStorage.setItem("storedChat", JSON.stringify([...tempStorage]));
          setmessagearray([
            ...tempStorage[foundStorageIndex].messages.reverse(),
          ]);
        } else {
          if (data.edit) {
            var foundMsgIndex = tempStorage[
              foundStorageIndex
            ].messages.findIndex((o) => o._id === data._id);

            tempStorage[foundStorageIndex].messages[foundMsgIndex] = data;
            localStorage.setItem(
              "storedChat",
              JSON.stringify([...tempStorage])
            );
            setmessagearray([
              ...tempStorage[foundStorageIndex].messages.reverse(),
            ]);
          } else {
            tempStorage[foundStorageIndex].messages.unshift(data);
            console.log(
              "storedchat",
              "lwjHFIKWBFKWE",
              tempStorage[foundStorageIndex].messages
            );
            localStorage.setItem(
              "storedChat",
              JSON.stringify([...tempStorage])
            );
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   groupReadUnreadList.sort(function (x, y) {
  //     return x.msg_timestamp - y.msg_timestamp;
  //   });
  // }, [messagearray]);

  useEffect(() => {
    socket.on("msg_notify", (data) => {
      console.log("dataaas", data);

      if (data.msgtype === "group") {
        updateStorage(data);
        console.log("dataaas", data);
        let tempList = groupReadUnreadList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == data.thread_id
        );
        console.log(
          foundIndex,
          tempList[foundIndex],
          selectedGroup,
          "kjbckjwebf"
        );
        if (foundIndex >= 0) {
          showNotificationGroup(data, tempList[foundIndex]);
          if (selectedGroup) {
            if (tempList[foundIndex].thread_id !== selectedGroup.thread_id) {
              if (tempList[foundIndex].unread_count > 0) {
                tempList[foundIndex].unread_count =
                  tempList[foundIndex].unread_count + 1;
              } else {
                tempList[foundIndex].unread_count = 1;
              }
              console.log("dataaafoundin", tempList);
              setgroupReadUnreadList([...tempList]);
            }
          } else {
            if (tempList[foundIndex].unread_count > 0) {
              tempList[foundIndex].unread_count =
                tempList[foundIndex].unread_count + 1;
            }
            console.log("dataaafoundin", tempList);
            setgroupReadUnreadList([...tempList]);
          }
        } else {
          let tempList = favList;
          var foundIndex = tempList.findIndex(
            (x) => x.thread_id == data.thread_id
          );
          if (foundIndex >= 0) {
            showNotificationGroup(data, tempList[foundIndex]);
            if (selectedGroup) {
              if (tempList[foundIndex].thread_id !== selectedGroup.thread_id) {
                if (tempList[foundIndex].unread_count > 0) {
                  tempList[foundIndex].unread_count =
                    tempList[foundIndex].unread_count + 1;
                } else {
                  tempList[foundIndex].unread_count = 1;
                }
                console.log("dataaafoundin", tempList);
                setFavList([...tempList]);
              }
            } else {
              if (tempList[foundIndex].unread_count > 0) {
                tempList[foundIndex].unread_count =
                  tempList[foundIndex].unread_count + 1;
              }
              console.log("dataaafoundin", tempList);
              setFavList([...tempList]);
            }
          }
          // else {
          //   groupLists.map((item) => {
          //     if (
          //       item.groups_details.findIndex(
          //         (x) => x.thread_id === data.thread_id
          //       )
          //     ) {
          //       item[foundIndex].unread_count =
          //         item[foundIndex].unread_count + 1;
          //       // item.groups_details.find((o) => o.unread_count > 0)
          //     }
          //   });

          //   // let tempList = favList;
          // }
        }
      } else if (data.msgtype === "support") {
        updateStorage(data);
        showNotification(data);
        console.log("dataaas", data);
        let tempList = unreadInteractionList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == data.thread_id
        );
        if (foundIndex >= 0) {
          if (tempList[foundIndex].unread_count > 0) {
            tempList[foundIndex].unread_count =
              tempList[foundIndex].unread_count + 1;
          } else {
            tempList[foundIndex].unread_count = 1;
          }
          // console.log("dataaa", tempList);

          setUnreadInteractionList([...tempList]);
        }
      } else if (data.msgtype === "onetoone") {
        updateStorage(data);
        showNotification(data);
        console.log("dataaas", data);
        let tempList = unreadInteractionList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == data.thread_id
        );
        if (foundIndex >= 0) {
          if (tempList[foundIndex].unread_count > 0) {
            tempList[foundIndex].unread_count =
              tempList[foundIndex].unread_count + 1;
          } else {
            tempList[foundIndex].unread_count = 1;
          }
          // console.log("dataaa", tempList);
          setUnreadInteractionList([...tempList]);
          // updateStorage(data);
        }
      }
      if (selectedFriend) {
        if (data.thread_id === selectedFriend.thread_id && !data.reply_thread) {
          setmessagearray([...messagearray, data]);
        }
      } else if (selectedGroup) {
        if (
          data.thread_id === selectedGroup.thread_id &&
          !data.reply_thread &&
          !data.edit
        ) {
          setmessagearray([...messagearray, data]);
        }
      } else {
        if (
          data.thread_id === selectedSupport.thread_id &&
          !data.reply_thread &&
          !data.edit
        ) {
          setmessagearray([...messagearray, data]);
        }
      }
    });
    return () => {
      socket.off("msg_notify");
    };
  });

  useEffect(() => {
    usersocket.on(`${app_id}_user_online_notification`, (res) => {
      // console.log(res, "online usersssx");
    });
  }, [runNotify]);

  useEffect(() => {
    if (userObj) {
      console.log("online usersss");
      usersocket.emit(
        "new_online_user_in_app",
        userObj.username,
        app_id,
        (res) => {
          setRunNotify(true);
          console.log(res, "online usersss");
        }
      );
    }
  }, [userObj]);

  useEffect(() => {
    usersocket.emit("get_online_user_list_in_app", app_id, (response) => {
      if (response.status) {
        console.log(response, "online usersss");
        setOnlineUsers(response.payload);
      }
    });
  }, []);

  useEffect(() => {
    socket.on("reaction_message", (data) => {
      var tempArr3 = messagearray;
      var foundIndex = tempArr3.findIndex((x) => x._id == data._id);
      tempArr3[foundIndex] = data;
      setmessagearray([...tempArr3]);
      console.log(data, "reaction notification");
    });
    return () => {
      socket.off("reaction_message");
    };
  });

  // Evenrything from all chats and chat menu

  // useEffect(() => {
  //   if (selectedFriend) {
  //     socket.emit(
  //       "get_user_interaction_list_read_unread_list",
  //       userObj ? userObj : JSON.parse(localStorage.getItem("userObj")),
  //       app_id,
  //       async (data) => {
  //         console.log("ddddddddddddd", data);
  //         setcurrentUserList(data.interacting_list);
  //         let tempArr = [];
  //         await Promise.all(
  //           data.read_unread_list.map((item) => {
  //             tempArr.push(item.sender);
  //           })
  //         );
  //         console.log("fffff", tempArr);
  //         setuserReadUnreadList(tempArr);
  //         setUnreadInteractionList(data.newInteractionList);
  //         // setmessagearray([]);
  //         // setVisibleListUsers(false);
  //       }
  //     );
  //   }
  // }, [selectedFriend]);

  useEffect(() => {
    if (selectedGroup) {
      if (selectedGroup.group_name) {
        socket.emit(
          "group_details",
          selectedGroup.thread_id,
          app_id,
          (resp) => {
            setgroupMembers([...resp.payload.group_details.participants_info]);
            var tempData = [];
            resp.payload.group_details.participants_info.map((item) => {
              tempData.push(item.username);
            });
            console.log(tempData, "jhjdwedfq");
            const index = tempData.indexOf(
              userObj
                ? userObj.username
                : JSON.parse(localStorage.getItem("userObj")).username
            );
            if (index > -1) {
              tempData.splice(index, 1);
            }
            console.log(tempData, "jhjdwedfq");
            setMemberUsernames([...tempData]);
            setRefetchData(false);
          }
        );

        // socket.emit("get_group_members", selectedGroup, app_id, (response) => {
        //   console.log(response, "resssss");
        //   setgroupMembers([...response]);
        //   var tempData = [];
        //   response.map((item) => {
        //     tempData.push(item.username);
        //   });
        //   console.log(tempData, "jhjdwedfq");
        //   const index = tempData.indexOf(
        //     userObj
        //       ? userObj.username
        //       : JSON.parse(localStorage.getItem("userObj")).username
        //   );
        //   if (index > -1) {
        //     tempData.splice(index, 1);
        //   }
        //   console.log(tempData, "jhjdwedfq");
        //   setMemberUsernames([...tempData]);
        //   setRefetchData(false);
        // });
      }
    }
  }, [activeTab]);

  useEffect(() => {
    console.log(
      "selected friend",
      selectedFriend,
      JSON.parse(localStorage.getItem("storedChat"))
    );
    if (selectedFriend) {
      setMessageLoading(true);
      var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
      if (tempStorage) {
        var foundObj = tempStorage.find(
          (o) => o.chatObj["email"] === selectedFriend.email
        );
      }

      if (foundObj) {
        setMessageLoading(false);
        setmessagearray([...foundObj.messages.reverse()]);
        setGoToBottom(true);
      } else {
        socket.emit(
          "direct_message_archive_history",
          { thread_id: selectedFriend.thread_id, page: 1, app_id },
          // userObj ? userObj : JSON.parse(localStorage.getItem("userObj")),
          // selectedFriend.username,
          // // selectedFriend.thread_id,
          // app_id,
          (response) => {
            setMessageLoading(false);
            console.log("jkfgkasbjgkaefjw", response);
            let chat_history = [...response];
            setmessagearray([...chat_history.reverse()]);
            setGoToBottom(true);
            handleStoreData("personal", response, selectedFriend);
            // setsetFlag(true);
            setRefetchData(false);
          }
        );
      }
    }
  }, [selectedFriend]);

  useEffect(() => {
    if (selectedGroup) {
      console.log("selected group", selectedGroup);

      setMessageLoading(true);

      var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
      var foundObj = tempStorage
        ? tempStorage.find(
            (o) => o.chatObj["group_name"] === selectedGroup.group_name
          )
        : null;
      console.log(foundObj, "kjgdckwjebkw");

      if (foundObj) {
        console.log(foundObj.chatObj, "kjgdckwjebkw");
        // setSelectedGroup(foundObj.chatObj);
        setMessageLoading(false);
        setmessagearray([...foundObj.messages.reverse()]);
        setGoToBottom(true);
        setBlankPage(false);

        // handleTabClick(0);
      } else {
        if (selectedGroup) {
          if (selectedGroup.thread_id) {
            console.log(selectedGroup.thread_id, app_id, "lkwjhekwkefj");
            socket.emit(
              "group_details",
              selectedGroup.thread_id,
              app_id,
              (resp) => {
                setMessageLoading(false);
                // let chat_history;
                // chat_history = resp;

                console.log(resp, "chat_history");

                //   setthreadId(item.thread_id);
                //   setchatsection(true);
                setmessagearray([...resp.payload.chat_history.reverse()]);

                handleStoreData(
                  "group",
                  resp.payload.chat_history,
                  selectedGroup
                );
                setGoToBottom(true);

                setgroupMembers([
                  ...resp.payload.group_details.participants_info,
                ]);
                var tempData = [];
                resp.payload.group_details.participants_info.map((item) => {
                  tempData.push(item.username);
                });
                console.log(tempData, "jhjdwedfq");
                const index = tempData.indexOf(
                  userObj
                    ? userObj.username
                    : JSON.parse(localStorage.getItem("userObj")).username
                );
                if (index > -1) {
                  tempData.splice(index, 1);
                }
                console.log(tempData, "jhjdwedfq");
                setMemberUsernames([...tempData]);
                setRefetchData(false);
              }
            );
            // socket.emit("group_details", selectedGroup, app_id, (response) => {
            //   console.log(response, "resssss");
            //   setgroupMembers([...response]);
            //   var tempData = [];
            //   response.map((item) => {
            //     tempData.push(item.username);
            //   });
            //   console.log(tempData, "jhjdwedfq");
            //   const index = tempData.indexOf(
            //     userObj
            //       ? userObj.username
            //       : JSON.parse(localStorage.getItem("userObj")).username
            //   );
            //   if (index > -1) {
            //     tempData.splice(index, 1);
            //   }
            //   console.log(tempData, "jhjdwedfq");
            //   setMemberUsernames([...tempData]);
            //   setRefetchData(false);
            // });
          }
        }
      }
    }
  }, [selectedGroup]);

  useEffect(() => {
    console.log(selectedFriend, "kjwbekdwkedf");
  }, [selectedFriend]);

  const handleStoreData = (type, resp, selectedItem) => {
    console.log(resp, "data to save");
    if (localStorage.getItem("storedChat")) {
      if (type === "group") {
        var tempArr = [
          ...JSON.parse(localStorage.getItem("storedChat")),
          { type: type, chatObj: selectedItem, messages: resp.reverse() },
        ];
      } else if (type === "personal") {
        var tempArr = [
          ...JSON.parse(localStorage.getItem("storedChat")),
          { type: type, chatObj: selectedItem, messages: resp },
        ];
      }

      localStorage.setItem("storedChat", JSON.stringify(tempArr));
    } else {
      if (type === "group") {
        localStorage.setItem(
          "storedChat",
          JSON.stringify([
            {
              type: type,
              chatObj: selectedItem,
              messages: resp.reverse(),
            },
          ])
        );
      } else {
        localStorage.setItem(
          "storedChat",
          JSON.stringify([
            {
              type: type,
              chatObj: selectedItem,
              messages: resp,
            },
          ])
        );
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("storedChat");
  }, []);

  // const checkStorage = () => {
  //   var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
  //   var foundObj = tempStorage
  //     ? tempStorage.find(
  //         (o) => o.chatObj["group_name"] === selectedGroup.group_name
  //       )
  //     : null;
  //   if (foundObj) {
  //     setMessageLoading(false);
  //     setmessagearray([...foundObj.messages.reverse()]);
  //     setGoToBottom(true);
  //   }
  // };

  useEffect(() => {
    console.log(adminUsernames, "qwertyu");
    if (userObj !== null) {
      getAdminSupportList();
      getGroups();
      getUsers();
    }
  }, [userObj, app_id]);

  const getUsers = () => {
    setFriendsLoading(true);
    console.log("jhadsj", userObj);
    socket.emit(
      "get_user_interaction_list_read_unread_list",
      userObj,
      app_id,
      async (data) => {
        setFriendsLoading(false);
        console.log(data, "jhadsjjsks");

        setcurrentUserList(data.interacting_list);
        let tempArr = [];
        await Promise.all(
          data.read_unread_list.map((item) => {
            tempArr.push(item.sender);
          })
        );
        setuserReadUnreadList(tempArr);
        setUnreadInteractionList(data.newInteractionList);
        // console.log("users", data.newInteractionList);
      }
    );
  };

  const getGroups = () => {
    setGroupsLoading(true);
    console.log(userObj, "getgroupss");
    if (userObj) {
      socket.emit(
        // "get_user_group_interaction_list",
        "new_user_group_interaction_list",
        userObj.email,
        app_id,
        (response) => {
          setGroupsLoading(false);
          console.log("get_user_group_interaction_list", response);
          setgroupReadUnreadList(response.interaction_list);
          setGroupLists(response.user_group_lists);
          const newArr = [];
          response.user_group_lists.map((item) => {
            item.groups_details.map((item1) => {
              newArr.push(item1);
            });
          });
          setAllGroups([response.interaction_list, ...newArr]);
        }
      );
    }
  };
  const getFavs = () => {
    socket.emit(
      `get_favourites_interactions_list`,
      userObj.id,
      app_id,
      (res) => {
        console.log(res, "bcjwgecwuecfuyef");
        setGroupFav(...res.payload.group);
        setFriendFav(...res.payload.direct);
        setSupportFav(...res.payload.support);
        setFavList([
          ...res.payload.group,
          ...res.payload.direct,
          ...res.payload.support,
        ]);
        // setFavList([...res.payload])
      }
    );
  };
  useEffect(() => {
    if (userObj) {
      getFavs();
    }
  }, [userObj]);

  useEffect(() => {
    console.log(
      userReadUnreadList,
      groupReadUnreadList,
      supportgroupReadUnreadList,
      "hwejwhef"
    );
    if (
      userReadUnreadList.length > 0 ||
      groupReadUnreadList.length > 0 ||
      supportgroupReadUnreadList.length > 0
    ) {
      setLoadAll(true);
    } else {
      setLoadAll(false);
    }
  }, [userReadUnreadList, groupReadUnreadList, supportgroupReadUnreadList]);

  // useEffect(() => {
  //   // console.log("dataaa");
  //   // let tempList = unreadInteractionList;
  //   // var foundIndex = tempList.findIndex((x) => x.thread_id == data.thread_id);
  //   // tempList[foundIndex].unread_count = tempList[foundIndex].unread_count + 1;
  //   // console.log("dataaa", tempList);
  //   // setUnreadInteractionList([...tempList]);

  //   socket.on("msg_notify", (data) => {
  //     console.log("kjwbcjwmhvcjwhe", data);
  //     let tempList1 = unreadInteractionList;
  //     var foundIndex1 = tempList1.findIndex(
  //       (x) => x.thread_id == data.thread_id
  //     );
  //     console.log(groupReadUnreadList, "kjwkwhwe");
  //     let tempList2 = groupReadUnreadList;
  //     var foundIndex2 = tempList2.findIndex(
  //       (x) => x.thread_id == data.thread_id
  //     );
  //     let tempList3 = supportgroupReadUnreadList;
  //     var foundIndex3 = tempList3.findIndex(
  //       (x) => x.thread_id == data.thread_id
  //     );
  //     // try {
  //     //   tempList1[foundIndex1].unread_count
  //     //     ? (tempList1[foundIndex1].unread_count =
  //     //         tempList1[foundIndex1].unread_count + 1)
  //     //     : "";
  //     //   console.log("dataaa", tempList1);
  //     //   setUnreadInteractionList([...tempList1]);
  //     // } catch (e) {
  //     //   console.log(e);
  //     // }

  //     // try {
  //     //   tempList2[foundIndex2].unread_count
  //     //     ? (tempList2[foundIndex2].unread_count =
  //     //         tempList2[foundIndex2].unread_count + 1)
  //     //     : "";
  //     //   console.log("dataaa", tempList2);
  //     //   setgroupReadUnreadList([...tempList2]);
  //     // } catch (e) {
  //     //   console.log(e);
  //     // }

  //     console.log("bwgdkwjejwd", foundIndex1, foundIndex2);
  //     if (foundIndex1 === 1) {
  //       tempList1[foundIndex1].unread_count =
  //         tempList1[foundIndex1].unread_count + 1;
  //       console.log("dataaa", tempList1);
  //       setUnreadInteractionList([...tempList1]);
  //     } else if (foundIndex2 === 1) {
  //       tempList2[foundIndex2].unread_count =
  //         tempList2[foundIndex2].unread_count + 1;
  //       console.log("dataaa", tempList2);
  //       setgroupReadUnreadList([...tempList2]);
  //     }
  //   });
  //   return () => {
  //     socket.off("msg_notify");
  //   };
  // });

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     tokenValidation();
  //   } else {
  //     setTokenValid(false);
  //   }
  // }, []);

  useEffect(() => {
    if (tokenValid) {
      // console.log("jhvcjdwhe");
      setLoading(true);
      if (localStorage.getItem("token")) {
        axios
          .get(`https://testchatsioapi.globalxchange.io/get_all_chat_types`, {
            headers: {
              email: localStorage.getItem("user_account"),
              token: localStorage.getItem("token"),
            },
          })
          .then((res) => {
            // console.log(res.data, "jhbcjwhefkw");
            setLoading(false);
            if (typeof res.data.payload === "string") {
              message.error("No chat_types created");
              // createChatType();
            } else {
              // console.log(
              //   res.data.payload,
              //   "No chat_types created",
              //   "blahblah"
              // );
              setChatTypes(res.data.payload);
              setSelectedChatType(res.data.payload[0]);
            }
          });
      }
    }
  }, [tokenValid]);

  useEffect(() => {
    if (tokenValid) {
      console.log(app_id, "kjwbfwkrf");
      if (localStorage.getItem("token") && app_id !== undefined) {
        axios
          .post(
            url + "get_admins",
            {
              app_id: app_id,
            },
            {
              headers: {
                email: localStorage.getItem("user_account"),
                token: localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data.payload, "all admins");
            setAdminUsernames(res.data.payload.emails);
            const isadmin = res.data.payload.emails.find(
              (o) => o === localStorage.getItem("user_account")
            );
            console.log(isadmin, app_id, "kjwbfkjwfkbfwfw");
            if (isadmin === undefined) {
              setIsAdmin(false);
            } else {
              setIsAdmin(true);
            }

            // if(isadmin){
            //   setIsAdmin(isadmin);
            // }
          });
      }
    }
  }, [app_id, tokenValid]);

  // useEffect(() => {
  //   console.log("change Organization");
  //   switchOrg();
  // }, [localStorage.getItem("org_name")]);

  // useEffect(() => {
  //   if (userObj.email) {
  //     // console.log("hhhhhhhh" + "start", currentUserObj);
  //     socket.emit(
  //       "get_admin_support_group_all_inactive_interaction_list",
  //       userObj,
  //       app_id,
  //       (response) => {
  //         // console.log("hhhhhhhh", response);
  //         setArchiveList([...response]);
  //       }
  //     );
  //   }
  // }, [userObj]);

  // Redirect if Token Expires
  // useEffect(() => {
  //   const handleRedirect = async () => {
  //     if (
  //       localStorage.getItem("token") &&
  //       localStorage.getItem("token").length > 0
  //     ) {
  //       axios
  //         .post(`https://gxauth.apimachine.com/gx/user/refresh/tokens`, {
  //           refreshToken: localStorage.getItem("refresh_token"),
  //           device_key: localStorage.getItem("device_key"),
  //         })
  //         .then((res) => {
  //           if (res.data.status) {
  //             localStorage.setItem("token", res.data.accessToken);
  //             // localStorage.setItem("refresh_token", res.data.refershToken);
  //           } else {
  //             localStorage.clear();
  //             window.location.reload();
  //           }
  //         });
  //       // // console.log("nulling");
  //       // localStorage.setItem("token", "");
  //       // // setRedirect(true);
  //       // localStorage.clear();
  //       // window.location.reload();
  //     }
  //   };
  //   handleRedirect();
  // }, []);

  const tokenValidation = () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token").length > 0
    ) {
      axios
        .post(`https://gxauth.apimachine.com/gx/user/refresh/tokens`, {
          refreshToken: localStorage.getItem("refresh_token"),
          device_key: localStorage.getItem("device_key"),
        })
        .then((res) => {
          console.log(res, "refrehtoken called");
          if (res.data.status) {
            if (localStorage.getItem("token") === res.data.idToken) {
              setTokenValid(true);
              console.log(res, "token is valid");
            } else {
              localStorage.setItem("token", res.data.idToken);
              localStorage.setItem("access_token", res.data.accessToken);
              setTokenValid(true);
              localStorage.setItem("validationTime", new Date().getTime());
            }
          } else {
            localStorage.clear();
            window.location.reload();
          }
        });
    } else {
      setTokenValid(false);
    }
  };

  //   useEffect(() => {}, []);

  // Redirect if Token Expires
  // useEffect(() => {
  //   const handleRedirect = async () => {
  //     if (
  //       localStorage.getItem("token") &&
  //       localStorage.getItem("token").length > 0
  //     ) {
  //       axios
  //         .post(`https://gxauth.apimachine.com/gx/user/refresh/tokens`, {
  //           refreshToken: localStorage.getItem("refresh_token"),
  //           device_key: localStorage.getItem("device_key"),
  //         })
  //         .then((res) => {
  //           if (res.data.status) {
  //             localStorage.setItem("token", res.data.accessToken);
  //             // localStorage.setItem("refresh_token", res.data.refershToken);
  //           } else {
  //             localStorage.clear();
  //             window.location.reload();
  //           }
  //         });
  //       // // console.log("nulling");
  //       // localStorage.setItem("token", "");
  //       // // setRedirect(true);
  //       // localStorage.clear();
  //       // window.location.reload();
  //     }
  //   };
  //   handleRedirect();
  // }, []);

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     tokenValidation();
  //   }, 60 * 1000 * 30);
  //   return () => clearInterval(id);
  // }, []);
  // useInterval(() => {
  //   tokenValidation();
  // }, 60 * 1000 * 30);

  useEffect(() => {
    tokenValidation();
  }, []);

  const getCUO = () => {
    let tok = localStorage.getItem("token");

    // setEmail(localStorage.getItem("user_account"));
    axios
      .post(
        url + "get_user",
        {
          app_id: app_id,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: tok,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          // console.log(res.data.payload);
          axios
            .post(
              url + "get_admins",
              {
                app_id: app_id,
              },
              {
                headers: {
                  email: localStorage.getItem("user_account"),
                  token: tok,
                },
              }
            )
            .then((resp) => {
              if (
                resp.data.payload.emails.indexOf(res.data.payload.email) > -1
              ) {
                setCurrentUserObj({
                  username: res.data.payload.username,
                  timestamp: res.data.payload.timestamp,
                  email: res.data.payload.email,
                  admin: res.data.payload.username,
                });
              } else {
                setCurrentUserObj({
                  username: res.data.payload.username,
                  timestamp: res.data.payload.timestamp,
                  email: res.data.payload.email,
                  admin: null,
                });
              }
            });
          // localStorage.setItem("chat_open", true);

          // setDirectoryId(res.data.payload.directory_id);

          // setCurrentUser(res.data.payload.username);

          // setregisteredFlag("Success");
        } else {
          // setregisteredFlag("Register");
          // message.error("Session Expired. Please Logout and Login again");
        }
      });
  };

  // useEffect(() => {
  //   // getCUO();
  //   if (!localStorage.getItem("mode")) {
  //     localStorage.setItem("mode", "light");
  //   }
  //   if (!localStorage.getItem("theme")) {
  //     localStorage.setItem("theme", "rocket-chat");
  //   }
  // }, []);

  const getSupportList = () => {
    if (userObj) {
      setSupportLoading(true);

      socket.emit(
        "get_user_support_group_interaction_list",
        userObj,
        app_id,
        (response) => {
          setSupportLoading(false);
          console.log("lll", response);
          setsupportgroupReadUnreadList([...response]);
        }
      );
    }
  };

  const getAdminSupportList = () => {
    setSupportLoading(true);
    // console.log(userObj, app_id, "kjgfkwjebfw");
    socket.emit(
      "get_admin_support_group_all_interaction_list",
      userObj,
      app_id,
      (response) => {
        setSupportLoading(false);
        console.log("lllist", response);
        if (response === "Access Denied") {
          getSupportList();
        } else {
          if (response.status === false) {
          } else {
            setsupportgroupReadUnreadList([...response]);
          }
        }
      }
    );
  };

  const getSupportList1 = () => {
    // console.log(userObj, "lknsdkjcvbwkjcvbe");
    socket.emit(
      "get_admin_support_group_all_interaction_list",
      userObj,
      app_id,
      (response) => {
        setSupportLoading(false);
        console.log(
          "get_admin_support_group_all_interaction_list",
          response,
          app_id
        );
        setsupportgroupReadUnreadList([...response]);
      }
    );
  };

  // useEffect(() => {
  //   if (bodyRef.current) {
  //     bodyRef.current.focus();
  //     console.log("focuseddddd");
  //   }
  // });

  //All User List
  useEffect(() => {
    socket.emit("get_all_user_list", app_id, (data) => {
      setallUserList(data);
      // bodyRef.current.focus();
      console.log("all userssss", data);
    });

    return () => {
      socket.off("get_all_user_list");
    };
  }, []);

  useEffect(() => {
    socket.on(`${app_id}_other_notifications`, (data) => {
      console.log("new_group_user", data);
      if (data.username === userObj.username) {
        if (data.new_group_user) {
          setgroupReadUnreadList([
            {
              avatar: data.avatar,
              creator_email: data.creator_email,
              group_name: data.group_name,
              sender: {
                avatar: data.avatar,
                username: data.username,
                first_name: "CJ",
                last_name: "Nvest",
              },
              new_group_user: data.new_group_user,
              thread_id: data.thread_id,
              user_id: data.user_id,
              who: data.who,
              _id: data._id,
            },
            ...groupReadUnreadList,
          ]);
        } else if (data.new_direct_user) {
          getUsers();
          // setUnreadInteractionList([data, ...unreadInteractionList]);
        }
      }
    });
    return () => {
      socket.off(`${app_id}_other_notifications`);
    };
  });

  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  // useEffect(() => {
  //   if (chatBoxArr.length < 1) {
  //     if (groupReadUnreadList.length > 0) {
  //       setSelectedGroup(groupReadUnreadList[0]);
  //     }
  //   }
  // }, [groupReadUnreadList]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("storedChat"))) {
      if (chatBoxArr.length != 0) {
        localStorage.setItem("chatboxArr", JSON.stringify(chatBoxArr));
        setActiveTab(chatBoxArr.length - 1);

        handleTabClick(chatBoxArr.length - 1);

        console.log(chatBoxArr, activeTab, "jkgfckjsfgkwef88");
      }
    } else {
      if (chatBoxArr.length != 0) {
        localStorage.setItem("chatboxArr", JSON.stringify(chatBoxArr));
        setActiveTab(localStorage.getItem("activeTab"));

        handleTabClick(localStorage.getItem("activeTab"));

        console.log(chatBoxArr, activeTab, "jkgfckjsfgkwef88");
      }
    }
  }, [chatBoxArr]);

  useEffect(() => {
    if (localStorage.getItem("chatboxArr")) {
      let sItem = JSON.parse(localStorage.getItem("chatboxArr"));
      setChatBoxArr(sItem);
      setActiveTab(localStorage.getItem("activeTab"));
      // handleTabClick(localStorage.getItem("activeTab"));
    }
  }, []);

  const removeItemFromChatboxArr = (item) => {
    let sItem = JSON.parse(localStorage.getItem("chatboxArr"));
    var tempArr = sItem.filter((o) => o.data._id !== item._id);

    console.log(tempArr, item, "kjwbjhwvckwhvef");

    setChatBoxArr([...tempArr]);
    localStorage.setItem("chatboxArr", JSON.stringify(tempArr));
  };

  // useEffect(() => {
  //   if (localStorage.getItem("selectedItem")) {
  //     let sItem = JSON.parse(localStorage.getItem("selectedItem"));
  //     if (sItem.group_name) {
  //       if (sItem.group_name.substring(0, 2) === "GX-") {
  //         if()
  //         setChatBoxArr([{ data: sItem, type: "support" }]);
  //         setSelectedSupport(sItem);
  //         setSelectedAdminSupport(sItem);
  //       } else {
  //         setChatBoxArr([{ data: sItem, type: "group" }]);
  //         setSelectedGroup(sItem);
  //       }
  //     } else if (sItem.username) {
  //       setChatBoxArr([{ data: sItem, type: "personal" }]);
  //       setSelectedFriend(sItem);
  //     }
  //   } else {
  //     setBlankPage(true);
  //     // setSelectedSupport(supportgroupReadUnreadList[0]);
  //     // setSelectedAdminSupport(supportgroupReadUnreadList[0]);
  //   }
  //   console.log(localStorage.getItem("selectedItem"), "kjwgfkweff");
  //   // if (chatBoxArr.length < 1) {
  //   //   if (supportgroupReadUnreadList.length > 0) {
  //   //     // setSelectedGroup(supportgroupReadUnreadList[0]);
  //   //     console.log(supportgroupReadUnreadList[0], "selectedddd");

  //   //     setSelectedGroup(null);
  //   //     setSelectedFriend(null);
  //   //     setVisibleUserInfo(false);
  //   //   }
  //   // }
  // }, [localStorage.getItem("selectedItem")]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">Upload</div>
    </div>
  );

  // const getOldData = () => {
  //   axios
  //     .post(
  //       `https://chatsapi.globalxchange.io/gxchat/get_user`,
  //       {
  //         email: localStorage.getItem("user_account"),
  //         token: localStorage.getItem("token"),
  //         app_id: "5f50cd3d03a3a304966bbb1f",
  //       },
  //       {
  //         headers: {
  //           email: localStorage.getItem("user_account"),
  //           // token: localStorage.getItem("token"),
  //           app_id: "5f50cd3d03a3a304966bbb1f",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("setolduserobj", res.data);
  //       if (res.data.status) {
  //         setOldUserObj(res.data.payload);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   // getNewData();
  //   // createDefaultApp();

  // }, []);

  useEffect(() => {
    axios
      .post(
        `https://testchatsioapi.globalxchange.io/get_user`,
        {
          email: localStorage.getItem("user_account"),
          app_id: app_id,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
            app_id: app_id,
          },
        }
      )
      .then((res) => {
        console.log("hahahhahahah", res);
        if (res.data.status === false) {
          if (localStorage.getItem("token")) {
            console.log("callingoldnew", res);
            // getOldData();
            getNewData();
          }
        } else {
          setUserObj(res.data.payload);
          localStorage.setItem("userObj", JSON.stringify(res.data.payload));
          console.log("userobjbvc", app_id, res.data.payload);

          socket.emit(
            "get_user_interaction_list_read_unread_list",
            res.data.payload,
            app_id,
            (data) => {
              console.log("interaction_list", data.interacting_list);
              setUnreadInteractionList(data.interacting_list);
            }
          );
        }
      });
  }, [app_id]);

  const getNewData = () => {
    console.log("hahahahahahahahhahaha app_code");
    axios
      .post(
        `https://testchatsioapi.globalxchange.io/get_user`,
        {
          email: localStorage.getItem("user_account"),
          // token: localStorage.getItem("token"),
          app_id: app_id,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
            app_id: app_id,
          },
        }
      )
      .then((res) => {
        if (!res.data.payload.app_code) {
          console.log("hahahahahahahahhahaha", res.data);
          getDefaultApp();
          setVisibleMoreInfo(true);
        } else {
          console.log("hahahahahahahahhahaha");
          setUserObj(res.data.payload);
          // console.log("userobjbvc", res.data.payload);
          socket.emit(
            "get_user_interaction_list_read_unread_list",
            res.data.payload,
            app_id,
            (data) => {
              console.log("interaction_list", data);
              setUnreadInteractionList(data.interacting_list);
              window.location.reload();
            }
          );
        }
      });
  };

  const registerUserToApp = (item) => {
    console.log("registeruser");
    axios
      .post(`https://testchatsioapi.globalxchange.io/register_user`, {
        email: localStorage.getItem("user_account"),
        app_id: item.id,
      })
      .then((res) => {
        if (res.data.status) {
          message.success("Profile Updation Complete");
          getNewData();
        }
      });
  };

  const getDefaultApp = () => {
    console.log("all_applications");
    axios
      .post(
        `https://testchatsioapi.globalxchange.io/all_applications`,
        {},
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data, "allapps");
        res.data.payload.map((item) => {
          if (item.id === constants.app_id) {
            setDefaultApp(item);
            registerUserToApp(item);
          }
        });
      });
  };

  const handleNewSignup = () => {
    if (firstName !== "" && lastName !== "" && imgUrl !== "") {
      axios
        .post(`https://testchatsioapi.globalxchange.io/register_with_chatsio`, {
          first_name: firstName, // required
          last_name: lastName,
          username: firstName, // required
          bio: bio,
          email: localStorage.getItem("user_account"), // required
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          avatar: imgUrl,
        })
        .then((res) => {
          if (res.data.status) {
            setVisibleMoreInfo(false);
            axios
              .post(`https://comms.globalxchange.com/user/details/update`, {
                email: localStorage.getItem("user_account"),
                first_name: firstName, // required
                last_name: lastName,
                bio: bio,
                profile_img: imgUrl,
                token: localStorage.getItem("token"),
              })
              .then((res) => {});
            getDefaultApp();
          } else {
            if (res.data.payload === "Email Already Registered") {
              setVisibleMoreInfo(false);
            } else {
              message.error("Profile Updation failed");
            }
          }
        });
    } else {
      message.error("Please fill all fields");
    }
  };
  const handleOnChangeProfile = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  // const conditionalView = () => {
  //   if (localStorage.getItem("token")) {
  //     return <Home />;
  //   } else {
  //     return <Login />;
  //   }
  // };

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const droppedImageHandler = (event) => {
    // use event.originalEvent.clipboard for newer chrome versions
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    // console.log(items);
    // console.log(JSON.stringify(items)); // will give you the mime types
    // find pasted image among pasted items
    var blob = null;

    for (var i = 0; i < items.length; i++) {
      // console.log("url obj", items[i]);
      if (items[i].type.indexOf("image") === 0) {
        blob = items[i].getAsFile();
      }
    }
    // load image if there is a pasted image
    if (blob !== null) {
      var reader = new FileReader();
      reader.onload = function (event) {
        // console.log(event.target.result); // data url!
        setClipImage(event.target.result);
        setVisibleDroppedImage(true);
        // document.getElementById("pastedImage").src = event.target.result;
      };
      reader.readAsDataURL(blob);
    }
  };

  const uploadImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    // console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      // console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedImage(false);
    } catch (e) {
      // console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      replied_msg: selectedReplyMessage !== null ? true : false,
      replied_to_data:
        selectedReplyMessage !== null
          ? {
              message: selectedReplyMessage.message,
              threadId: selectedReplyMessage.threadId,
              sender: selectedReplyMessage.sender,
            }
          : null,
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_message",
      {
        ...message_data,
      },
      app_id,
      (response) => {
        if (response === "success") {
          // console.log("sent to DB", response);
          setmessagearray([...messagearray, { ...message_data }]);

          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          // console.log(newArr);
        } else {
          // console.log(response);
        }
      }
    );
    // setselectedFile(null);
  };

  const uploadProfileImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data, "uploaded");
      setImgUrl(uploaded_data.location);
      message.destroy();
      message.success("File Upload Success", 2);
    } catch (e) {
      // console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  const conditionalFileUpload = () => {
    if (selectedFriend) {
      handleFileUpload();
    } else if (selectedGroup) {
      handleFileUploadGroup();
    }
  };

  const handleFileUpload = async () => {
    message.destroy();
    message.loading("Uploading File", 0);

    const S3Client = new S3(config);

    // let uploaded = await S3Client.uploadFile(selectedFile);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(droppedFile, droppedFile.name);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedFile(false);
      setDroppedFile(null);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: droppedFile.name,
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: droppedFile.name,
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_direct_chat_message",
      {
        ...message_data,
      },
      selectedFriend.username,
      app_id,
      (response) => {
        if (response.status) {
          console.log(response);
          setmessagearray([...messagearray, { ...message_data }]);
          updateStorage(response.payload);
          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          console.log(newArr);
        } else {
          console.log(response);
        }
      }
    );
  };

  const handleFileUploadGroup = async () => {
    message.destroy();
    message.loading("Uploading File", 0);

    const S3Client = new S3(config);

    // let uploaded = await S3Client.uploadFile(selectedFile);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(droppedFile, droppedFile.name);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedFile(false);
      setDroppedFile(null);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: droppedFile.name,
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: droppedFile.name,
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
    };

    usersocket.emit("new_message", notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedGroup.group_name;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_send_group_message",
      {
        ...message_data,
      },
      memberUsernames,
      app_id,
      (response) => {
        if (response.status) {
          console.log(response);
          setmessagearray([...messagearray, { ...message_data }]);
          updateStorage(response.payload);
          //for pushing msg =user to top
          let tempArr1 = [...groupReadUnreadList];
          let newArr = [
            ...tempArr1.filter(
              (o) => o.group_name === selectedGroup.group_name
            ),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.group_name !== selectedGroup.group_name
          );
          newArr = [...newArr, ...tempArr1];
          setgroupReadUnreadList([...newArr]);
          console.log(newArr);
        } else {
          setSubmitLoading(false);
          message.error(response.payload);
          console.log(response);
        }
      }
    );
  };

  // useEffect(() => {
  //   console.log(recipients, "resddd33");
  // });

  const handleForward = () => {
    console.log(recipients, "resddd");
    // if (selectedFriend) {
    //   console.log(item, selectedForwardMessages, "resddd", "friend");
    //   socket.emit(
    //     `forward_direct_chat_messages`,
    //     selectedForwardMessages,
    //     {
    //       thread_id: item.thread_id,
    //       type: "direct",
    //     },
    //     app_id,
    //     (res) => {
    //       if (res.status) {
    //         console.log(res, "resdddddddd");
    //         setShowForwardMessageUI(false);
    //         setSelectedForwardMessages([]);
    //         updateStorage(res.payload[0]);
    //       }
    //     }
    //   );
    // } else
    if (selectedGroup) {
      console.log(selectedForwardMessages, recipients, "resddd", "group");

      socket.emit(
        `forward_group_chat_messages_to_multiple_interactions_v2`,
        selectedGroup.thread_id,
        selectedForwardMessages,
        recipients,
        userObj,
        app_id,
        (res) => {
          console.log(res, "resdddddddd");
          if (res.status) {
            console.log(res, "resdddddddd");
            setShowForwardMessageUI(false);
            setSelectedForwardMessages([]);
            // updateStorage(res.payload[0]);
          }
        }
      );
    } else if (selectedFriend) {
      socket.emit(
        `forward_direct_chat_messages_to_multiple_interactions_v2`,
        selectedFriend.thread_id,
        selectedForwardMessages,
        recipients,
        userObj,
        app_id,
        (res) => {
          console.log(res, "resdddddddd");
          if (res.status) {
            console.log(res, "resdddddddd");
            setShowForwardMessageUI(false);
            setSelectedForwardMessages([]);
            // updateStorage(res.payload[0]);
          }
        }
      );
    } else if (selectedSupport) {
      socket.emit(
        `forward_support_group_chat_messages_to_multiple_interactions_v2`,
        selectedSupport.thread_id,
        selectedForwardMessages,
        recipients,
        userObj,
        app_id,
        (res) => {
          console.log(res, "resdddddddd");
          if (res.status) {
            console.log(res, "resdddddddd");
            setShowForwardMessageUI(false);
            setSelectedForwardMessages([]);
            // updateStorage(res.payload[0]);
          }
        }
      );
    }
  };

  const handleDroppedFile = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.files[0].type, "jwhvfjhwevfjwev");
    if (e.dataTransfer.files[0].type.indexOf("image") === 0) {
      var reader = new FileReader();
      reader.onload = function (event) {
        // console.log(event.target.result); // data url!
        setClipImage(event.target.result);
        // document.getElementById("pastedImage").src = event.target.result;
      };
      reader.readAsDataURL(e.dataTransfer.files[0]);
      setVisibleDroppedImage(true);
    } else if (e.dataTransfer.files[0].type.indexOf("video") === 0) {
      var reader = new FileReader();
      reader.onload = function (event) {
        // console.log(event.target.result); // data url!
        setClipVideo(event.target.result);
        // document.getElementById("pastedImage").src = event.target.result;
      };
      console.log(e.dataTransfer.files[0], "khvwkevkwe");
      setDroppedVideo(e.dataTransfer.files[0]);
      reader.readAsDataURL(e.dataTransfer.files[0]);
      setVisibleDroppedVideo(true);
    } else {
      console.log("myFile", e.dataTransfer.files[0]);
      setDroppedFile(e.dataTransfer.files[0]);
      setVisibleDroppedFile(true);
    }

    // let files = e.dataTransfer.files[0].type;
    // console.log(files);
  };

  const handleUploadFile = (file) => {
    if (file) {
      console.log(file.type, "jwhvfjhwevfjwev");
      if (file.type.indexOf("image") === 0) {
        var reader = new FileReader();
        reader.onload = function (event) {
          // console.log(event.target.result); // data url!
          setClipImage(event.target.result);
          // document.getElementById("pastedImage").src = event.target.result;
        };
        reader.readAsDataURL(file);
        setVisibleDroppedImage(true);
      } else if (file.type.indexOf("video") === 0) {
        var reader = new FileReader();
        reader.onload = function (event) {
          // console.log(event.target.result); // data url!
          setClipVideo(event.target.result);
          // document.getElementById("pastedImage").src = event.target.result;
        };
        console.log(file, "khvwkevkwe");
        setDroppedVideo(file);
        reader.readAsDataURL(file);
        setVisibleDroppedVideo(true);
      } else {
        console.log("myFile", file);
        setDroppedFile(file);
        setVisibleDroppedFile(true);
      }
    }

    // let files = e.dataTransfer.files[0].type;
    // console.log(files);
  };

  // useEffect(() => {
  //   console.log("interaction_list1");
  //   socket.emit(
  //     "get_user_interaction_list_read_unread_list",
  //     userObj,
  //     app_id,
  //     (data) => {
  //       console.log("interaction_list", data);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    if (Notification.permission === "granted") {
      console.log("granted", "kwjkwejf");
      // showNotification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        console.log(permission, "kwjkwejf");
        if (permission === "granted") {
          // showNotification();
        }
      });
    }
    // if (!("Notification" in window)) {
    //   console.log("This browser does not support desktop notification");
    // } else {

    // }
  }, []);

  const getLink = (message) => {
    if (!message) return;
    var newMsg = message.replace(/(\r\n|\n|\r)/gm, " ");
    // console.log(newMsg, "ljwefkgwekfgkwegfkwekkkiugiug");
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return newMsg.replace(urlRegex, function (url) {
      var hyperlink1 = url;
      var hyperlink = url.substring(0, url.indexOf("<br/>"));
      // var textafterlink = url.substring(url.indexOf(" ") + 1);
      var textafterlink = url.split("<br/>");
      // url.substring(0, url.indexOf("<br/>"));
      // console.log(textafterlink[0], textafterlink[1], "ljwefkgwekfgkwegfkwe");

      return `<div><a href=${
        hyperlink ? hyperlink : hyperlink1
      } target="_blank" rel="noopener noreferrer">${hyperlink ? hyperlink : hyperlink1}</a><br/>${textafterlink[1] ? textafterlink[1] : ""}</div>`;
    });
  };
  /* /(@\w+)/g */
  const validateJson = (str) => {
    try {
      const regex = new RegExp("(^\\@\\w+|(?<=\\s)\\@\\w+)", "g");
      // if (str.startsWith('"') && str.endsWith('"')) {
      //   var tempStr = JSON.parse(str);
      //   var res = tempStr.replace(
      //     /(^\@\w+|(?<=\s)\@\w+)/g,
      //     `<span style="color:#F35D48; font-weight: bold; background: rgba(243, 93, 72, 0.2); border-radius: 5px; padding:2px 3px">$1</span>`
      //   );
      //   return res;
      // } else {

      // var res = str.replace(
      //   /(^\@\w+|(?<=\s)\@\w+)/g,
      //   `<span style="color:#F35D48; font-weight: bold; background: rgba(243, 93, 72, 0.2); border-radius: 5px; padding:2px 3px">$1</span>`
      // );

      var res = str.replace(
        regex,
        `<span style="color:#F35D48; font-weight: bold; background: rgba(243, 93, 72, 0.2); border-radius: 5px; padding:2px 3px">$1</span>`
      );
      return res;
    } catch (error) {
      console.log("display error message", error, str);
      return str;

      // return <div dangerouslySetInnerHTML={{ __html: JSON.parse(str) }} />;
    }
  };

  const validateJsonUrl = (str) => {
    try {
      console.log("parsed val", JSON.parse(str).text());
      var span = document.createElement("span");
      span.innerHTML = JSON.parse(str);
      return (
        // <span onClick={(e) => window.open(url, "_blank")}>
        <span>{span.textContent || span.innerText}</span>
      );
      // return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };

  const videoPlayer = (url, thumb) => {
    // console.log(url, thumb, "kjwefkwjefgkw");

    return (
      <ReactPlayer
        width="350px"
        height="200px"
        // url={url}
        url={url}
        controls
        playing={true}
        // loop={true}
        light={thumb}
      />
    );
  };

  // const getMetadata = (item) => {
  //   urlMetadata(
  //     "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"
  //   ).then(
  //     function (metadata) {
  //       // success handler
  //       console.log(metadata, "this is metadata");
  //     },
  //     function (error) {
  //       // failure handler
  //       console.log(error, "this is metadata");
  //     }
  //   );
  // };

  const getPreview = (item) => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var hyperlink;
    item.message.replace(urlRegex, function (url) {
      hyperlink = url;
      // console.log(hyperlink, "jhwejwhed");
    });

    if (hyperlink) {
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          proxyUrl="https://nvest-cors-anywhere.herokuapp.com"
          maxLine={2}
          minLine={1}
          url={hyperlink}
        />
      );
      // socket.emit(
      //   "get_url_data",
      //   [{ id: item._id, link: hyperlink }],
      //   (res) => {
      //     if (res.status) {
      //       // console.log(res.payload[0].link_data, "kwgefwejdf");
      //       parsedData = res.payload[0].link_data;
      //       // return res.payload[0].link_data.title;
      //     }
      //   }
      // );
      // return <LinkPreview url={hyperlink} width="400px" />;
      // console.log(hyperlink, "khjvhkwve");
      // setLinkArr([...linkArr, { id: item._id, link: hyperlink }]);
      // return (
      //   <>
      //     <LinkPreview url={hyperlink} width="400px" />
      //   </>
      //   // <ReactTinyLink
      //   //   cardSize="small"
      //   //   showGraphic={true}
      //   //   proxyUrl="http://www.whateverorigin.org/get?url="
      //   //   maxLine={2}
      //   //   minLine={1}
      //   //   url={hyperlink}
      //   // />
      // );
    }
  };

  const renderMessage = (item) => {
    if (item.type === "file") {
      if (
        item.location.slice(item.location.lastIndexOf(".") + 1) === "png" ||
        item.location.slice(item.location.lastIndexOf(".") + 1) === "PNG" ||
        item.location.slice(item.location.lastIndexOf(".") + 1) === "jpg" ||
        item.location.slice(item.location.lastIndexOf(".") + 1) === "JPG" ||
        item.location.slice(item.location.lastIndexOf(".") + 1) === "jpeg" ||
        item.location.slice(item.location.lastIndexOf(".") + 1) === "JPEG" ||
        item.location.slice(item.location.lastIndexOf(".") + 1) === "gif" ||
        item.location.substring(0, 10) === "data:image"
      ) {
        return visibleShowImage ? (
          <Lightbox
            image={imageToShow}
            onClose={(e) => setVisibleShowImage(false)}
          />
        ) : (
          <div>
            <img
              onClick={(e) => {
                setImageToShow(item.location);
                setVisibleShowImage(true);
              }}
              className="light-shadow-bottom"
              src={item.location}
              style={{
                width: "350px",
                borderRadius: "10px",
                // opacity:
                //   item.location.substring(0, 10) === "data:image" ? 0.5 : 1,
              }}
              alt="item"
            />
          </div>
        );
      } else {
        return (
          <div>
            <div onClick={(e) => window.open(item.location, "_blank")}>
              <FileOutlined /> &nbsp;{" "}
              {item.message.length > 20 ? (
                <>
                  {item.message.substring(0, 10)}.......
                  {item.message.substring(
                    item.message.length - 10,
                    item.message.length
                  )}
                </>
              ) : (
                item.message
              )}
            </div>
            <div style={{ display: "flex" }}>
              {item.reactions
                ? item.reactions.map((item1) => {
                    if (item1.usernames.length > 0) {
                      return (
                        <Tooltip
                          color="rgba(69,90,100 ,1)"
                          title={item1.usernames.map((item3, index) => {
                            return allUserList.map((item4) => {
                              if (item4.username === item3) {
                                return (
                                  <span style={{ color: "white" }}>
                                    {"@" + item4.first_name}
                                    {index !== item1.usernames.length - 1
                                      ? ", "
                                      : ""}
                                  </span>
                                );
                              }
                            });
                          })}
                        >
                          <div
                            onClick={(e) => addToReaction(item1, item)}
                            style={{
                              background: "rgba(243, 93, 72, 0.2)",
                              padding: "0px 5px",
                              borderRadius: "10px",
                              display: "flex",
                              alignItems: "center",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <div style={{ paddingRight: "3px" }}>
                              {item1.name}
                            </div>
                            <small style={{ marginTop: "-2px" }}>
                              {item1.usernames.length}
                            </small>
                          </div>
                        </Tooltip>
                      );
                    }
                  })
                : ""}
            </div>
          </div>
        );
      }
    } else if (item.type === "transfer") {
      return (
        <>
          <Card bodyStyle={{ padding: "15px" }} className="shadow-sm">
            <div
              style={{
                fontSize: window.innerWidth > 600 ? "30px" : "20px",
                fontWeight: "bold",
              }}
            >
              {item.message}
            </div>
            <div>SENT</div>
          </Card>
        </>
      );
    }
    //  else if (item.type === "video") {
    // var url = getThumbnail(item.message);
    // return <div>{videoPlayer(item.message, url)}</div>;
    // return (
    //   <div>
    //     <LinkPreview url={item.message} width="400px" />
    //   </div>
    // );
    // return "hii";
    // <ReactTinyLink
    //   cardSize="small"
    //   showGraphic={true}
    //   proxyUrl="https://nvest-cors-anywhere.herokuapp.com"
    //   maxLine={2}
    //   minLine={1}
    //   url={item.message}
    // />
    // }
    else if (item.type === "uploadedvideo") {
      return (
        <ReactPlayer
          width="350px"
          height="200px"
          url={item.location}
          controls
          playing={true}
          // loop={true}
          light={
            "https://i.pinimg.com/originals/27/13/9b/27139b573b1d9d5fe66e7e27e7127563.jpg"
          }
        />
      );
    } else {
      return (
        <div
          style={{
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          <div
            // onClick={(e) => window.open(item.message, "_blank")}
            dangerouslySetInnerHTML={{
              __html: getLink(
                validateJson(item.message).replace(/\n/g, "<br/>")
              ),
            }}
          />

          <div>{getPreview(item)}</div>

          {/* {item.message} */}
          {/* {validateJson(item.message)} */}
          {/* {item._id === "5ecc8a706c7a7727dc2fbdbf" ? (
         
        ) : (
          item.message
        )} */}
        </div>
      );
    }
  };

  const showConditionalDate = (item, index, prevItem) => {
    if (index > 0) {
      if (
        new Date(item.timestamp).toDateString() !==
        new Date(prevItem.timestamp).toDateString()
      ) {
        return (
          <Divider
            style={{
              color: globalColors.darkMode ? "gray" : "lightgray",
            }}
          >
            {new Date(item.timestamp).toDateString()}
          </Divider>
        );
      } else {
        return null;
      }
    } else {
      return (
        <Divider
          className={globalColors.darkMode ? "divider-dark" : "divider-light"}
          style={{
            color: globalColors.darkMode ? "gray" : "lightgray",
          }}
        >
          {new Date(item.timestamp).toDateString()}
        </Divider>
      );
    }
  };

  const timeDifference = (item, index, arr) => {
    if (index !== 0) {
      var difference = item.timestamp - arr[index - 1].timestamp;
      // console.log(difference, "kjediwuediwuegdi");
      var minutesDifference = Math.floor(difference / 1000 / 60);
      // difference -= minutesDifference * 1000 * 60;
      // console.log(minutesDifference, "kjediwuediwuegdi");
      if (minutesDifference < 1) {
        if (item.sender.username === arr[index - 1].sender.username) {
          if (!item.type) {
            return true;
          }
        }
      }

      // return minutesDifference;
    }
  };

  const conditionalMessageView = (item) => {
    // console.log("messageview", item);
    if (!item.replied_msg) {
      return (
        <>
          {renderMessage(item)}
          <div style={{ display: "flex", paddingTop: "5px" }}>
            {item.reactions
              ? item.reactions.map((item1) => {
                  if (item1.usernames.length > 0) {
                    return (
                      <Tooltip
                        color="rgba(69,90,100 ,1)"
                        title={item1.usernames.map((item3, index) => {
                          return allUserList.map((item4) => {
                            if (item4.username === item3) {
                              return (
                                <span style={{ color: "white" }}>
                                  {"@" + item4.first_name}
                                  {index !== item1.usernames.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          });
                        })}
                      >
                        <div
                          onClick={(e) => addToReaction(item1, item)}
                          className="reaction-style"
                        >
                          <div style={{ paddingRight: "3px" }}>
                            {item1.name}
                          </div>
                          <small style={{ marginTop: "-2px" }}>
                            {item1.usernames.length}
                          </small>
                        </div>
                      </Tooltip>
                    );
                  }
                })
              : ""}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>{renderMessage(item)}</div>

          {item.replied_to_data ? (
            item.replied_to_data.msg_deleted ? (
              <div
                style={{
                  background: "#eeeeee",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                This message is no longer available
              </div>
            ) : (
              <div
                style={{
                  borderLeft: "2px solid gray",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  marginTop: "3px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Avatar
                    size="small"
                    src={item.replied_to_data.sender.avatar}
                  />
                  &nbsp;&nbsp;
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      {selectedSupport !== null
                        ? adminUsernames.length > 0
                          ? adminUsernames.indexOf(
                              item.replied_to_data.sender.first_name
                            ) > -1
                            ? "Support"
                            : item.replied_to_data.sender.first_name
                          : ""
                        : item.replied_to_data.sender.first_name}
                    </span>{" "}
                    &nbsp;
                    <span style={{ color: " #8D8D8D" }}>
                      <small>
                        {formatAMPM(new Date(item.timestamp))}
                        {/* {new Date(item.replied_to_data.timestamp).toLocaleString()}{" "} */}
                        &nbsp; {item.edit ? <EditOutlined /> : ""}
                      </small>
                    </span>
                  </div>
                </div>

                <div style={{ padding: "5px 0px" }}>
                  {renderMessage(item.replied_to_data)}
                </div>
              </div>
            )
          ) : (
            ""
          )}
          <div style={{ display: "flex" }}>
            {item.reactions
              ? item.reactions.map((item1) => {
                  if (item1.usernames.length > 0) {
                    return (
                      <Tooltip
                        color="rgba(69,90,100 ,1)"
                        title={item1.usernames.map((item3, index) => {
                          return allUserList.map((item4) => {
                            if (item4.username === item3) {
                              return (
                                <span style={{ color: "white" }}>
                                  {"@" + item4.first_name}
                                  {index !== item1.usernames.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          });
                        })}
                      >
                        <div
                          onClick={(e) => addToReaction(item1, item)}
                          className="reaction-style"
                        >
                          <div style={{ paddingRight: "3px" }}>
                            {item1.name}
                          </div>
                          <small style={{ marginTop: "-2px" }}>
                            {item1.usernames.length}
                          </small>
                        </div>
                      </Tooltip>
                    );
                  }
                })
              : ""}
          </div>
        </>
      );
    }
  };

  const conditionalRoutes = () => {
    if (tokenValid) {
      // console.log(tokenValid, "kjgwgekfwgef");
      return (
        <HashRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/home" component={Home} /> */}
            <Route exact path="/login" component={Login} />
          </Switch>
        </HashRouter>
      );
    } else if (tokenValid === false) {
      // console.log(tokenValid, "kjgwgekfwgeffff");
      return (
        <HashRouter>
          <Switch>
            <Route exact path="/" component={Splash} />
            {/* <Route exact path="/home" component={Home} /> */}
            <Route exact path="/login" component={Login} />
          </Switch>
        </HashRouter>
      );
    } else {
      // console.log(tokenValid, "kjgwgekfwgefggg");
      return (
        <div className="full-loading-wrapper">
          <img src={constants.logo} alt="" className="full-loading-logo" />
        </div>
      );
    }
  };

  const conditionalVideoUpload = () => {
    if (selectedGroup) {
      handleVideoUploadGroup();
    } else if (selectedFriend) {
      handleVideoUpload();
    }
  };

  // const conditionalVideoUpload = () => {
  //   setLoadingImage(true);
  //   if (selectedSupport === null) {
  //     if (selectedFriend !== null) {
  //       if (selectedReplyMessage) {
  //         uploadOneToOneReplyImage();
  //       } else {
  //         handleVideoUpload();
  //       }
  //     } else if (selectedGroup !== null) {
  //       console.log(selectedGroup, "kwkjc,wbjkwje");
  //       if (selectedReplyMessage) {
  //         console.log(selectedGroup, "kwkjc,wbjkwjegroupreply");
  //         uploadGroupReplyImage();
  //       } else {
  //         console.log(selectedGroup, "kwkjc,wbjkwjegroup");
  //         handleVideoUploadGroup();
  //       }
  //     }
  //   } else {
  //     if (selectedReplyMessage) {
  //       console.log(selectedGroup, "kwkjc,wbjkwjesupportreply");
  //       uploadSupportGroupReplyImage();
  //     } else {
  //       console.log(selectedGroup, "kwkjc,wbjkwjesupport");
  //       uploadSupportGroupImage();
  //     }
  //   }
  // };

  const handleVideoUpload = async () => {
    setSubmitLoading1(true);
    console.log(clipVideo, "kjqdkqjwbe");
    setVisibleDroppedVideo(false);
    setmessagearray([
      ...messagearray,
      {
        message: droppedVideo.name,
        thread_id: selectedFriend.thread_id,
        sender: userObj,
        timestamp: Date.now(),
        filename: droppedVideo.name,
        type: "uploadedvideo",
        location: clipVideo,
        msgtype: "group",
      },
    ]);
    message.destroy();
    // message.loading("Uploading Video", 0);

    const S3Client = new S3(config);
    console.log(droppedVideo, "khvwkevkwe");
    // let uploaded = await S3Client.uploadFile(selectedFile);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(
        droppedVideo,
        droppedVideo.name
      );
      console.log(uploaded_data, "ljqwkdjwkejdgkwgdeb");
      message.destroy();
      message.success("Video Upload Success", 2);
      // setVisibleDroppedVideo(false);
      setDroppedVideo(null);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("Video Upload Failed", 2);
    }

    let message_data = {
      message: droppedVideo.name,
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: droppedVideo.name,
      type: "uploadedvideo",
      location: uploaded_data.location,
      msgtype: "group",
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_direct_chat_message",
      {
        ...message_data,
      },
      selectedFriend.username,
      app_id,
      (response) => {
        if (response.status) {
          setSubmitLoading1(false);
          console.log(response, "lwjHFIKWBFKWE");
          // setmessagearray([...messagearray, { ...message_data }]);
          updateStorage(response.payload);
          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          console.log(newArr);
        } else {
          console.log(response);
        }
      }
    );
  };

  const handleVideoUploadGroup = async () => {
    setSubmitLoading1(true);
    console.log(clipVideo, "kjqdkqjwbe");
    setVisibleDroppedVideo(false);
    setmessagearray([
      ...messagearray,
      {
        message: droppedVideo.name,
        thread_id: selectedGroup.thread_id,
        sender: userObj,
        timestamp: Date.now(),
        filename: droppedVideo.name,
        type: "uploadedvideo",
        location: clipVideo,
        msgtype: "group",
      },
    ]);
    message.destroy();
    // message.loading("Uploading Video", 0);

    const S3Client = new S3(config);

    // let uploaded = await S3Client.uploadFile(selectedFile);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(
        droppedVideo,
        droppedVideo.name
      );
      message.destroy();
      message.success("File Upload Success", 2);
      // setVisibleDroppedVideo(false);
      setDroppedFile(null);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: droppedVideo.name,
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: droppedVideo.name,
      type: "uploadedvideo",
      location: uploaded_data.location,
      msgtype: "group",
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", notification_data);

    // let tempArr = [...userReadUnreadList];
    // tempArr = tempArr.filter(function (item) {
    //   return item !== selectedFriend.username;
    // });

    // setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_send_group_message",
      {
        ...message_data,
      },
      memberUsernames,
      app_id,
      (response) => {
        if (response.status) {
          setSubmitLoading1(false);
          console.log(response, "lwjHFIKWBFKWE");
          // setmessagearray([...messagearray, response.payload]);
          updateStorage(response.payload);
          let tempArr1 = [...groupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          if (tempArr1[objIndex] !== undefined) {
            tempArr1[objIndex].sender = userObj.username;

            let newArr = [
              ...tempArr1.filter(
                (o) => o.group_name === selectedGroup.group_name
              ),
            ];
            tempArr1 = tempArr1.filter(
              (obj) => obj.group_name !== selectedGroup.group_name
            );
            newArr = [...newArr, ...tempArr1];
            console.log(newArr);
            setgroupReadUnreadList([...newArr]);
          }
        } else {
          setSubmitLoading(false);
          message.error(response.payload);
          console.log(response);
        }
      }
    );
  };

  const conditionalImageUpload = () => {
    setLoadingImage(true);
    if (selectedSupport === null) {
      if (selectedFriend !== null) {
        if (selectedReplyMessage) {
          uploadOneToOneReplyImage();
        } else {
          uploadOneToOneImage();
        }
      } else if (selectedGroup !== null) {
        console.log(selectedGroup, "kwkjc,wbjkwje");
        if (selectedReplyMessage) {
          console.log(selectedGroup, "kwkjc,wbjkwjegroupreply");
          uploadGroupReplyImage();
        } else {
          console.log(selectedGroup, "kwkjc,wbjkwjegroup");
          uploadGroupImage();
        }
      }
    } else {
      if (selectedReplyMessage) {
        console.log(selectedGroup, "kwkjc,wbjkwjesupportreply");
        uploadSupportGroupReplyImage();
      } else {
        console.log(selectedGroup, "kwkjc,wbjkwjesupport");
        uploadSupportGroupImage();
      }
    }
  };

  const uploadOneToOneImage = async () => {
    setSubmitLoading1(true);
    console.log(clipImage, "kjqdkqjwbe");
    setVisiblePasteImage(false);
    setmessagearray([
      ...messagearray,
      {
        message: Date.now().toString(),
        thread_id: selectedFriend.thread_id,
        sender: userObj,
        timestamp: Date.now(),
        filename: Date.now().toString(),
        type: "file",
        location: clipImage,
        msgtype: "onetoone",
      },
    ]);
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", app_id, notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_direct_chat_message",
      {
        ...message_data,
      },
      selectedFriend.username,
      app_id,
      (response) => {
        if (response.status) {
          setSubmitLoading1(false);
          // console.log("sent to DB", response, message_data);
          // setmessagearray([...messagearray, response.payload]);
          updateStorage(response.payload);
          setGoToBottom(true);
          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          console.log(newArr);
        } else {
          console.log(response);
        }
      }
    );
    setSelectedReplyMessage(null);
    // setselectedFile(null);
  };

  const uploadOneToOneReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    const messageWithReply = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage.message,
        threadId: selectedReplyMessage.threadId,
        sender: selectedReplyMessage.sender,
        timestamp: selectedReplyMessage.timestamp,
        type: selectedReplyMessage.type ? selectedReplyMessage.type : null,
        location: selectedReplyMessage.location
          ? selectedReplyMessage.location
          : null,
      },
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", app_id, notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_reply_message",
      {
        ...message_data,
      },
      {
        message: selectedReplyMessage.message,
        threadId: selectedReplyMessage.threadId,
        sender: selectedReplyMessage.sender,
        timestamp: selectedReplyMessage.timestamp,
        type: selectedReplyMessage.type ? selectedReplyMessage.type : null,
        location: selectedReplyMessage.location
          ? selectedReplyMessage.location
          : null,
      },
      app_id,
      (response) => {
        if (response == "success") {
          setLoadingImage(false);
          console.log("sent to DB", response, messageWithReply);
          setmessagearray([...messagearray, { ...messageWithReply }]);

          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          console.log(newArr);
        } else {
          console.log(response);
        }
      }
    );
    setSelectedReplyMessage(null);
    // setselectedFile(null);
  };

  const uploadGroupImage = async () => {
    setSubmitLoading1(true);
    console.log(clipImage, "kjqdkqjwbe");
    setVisibleDroppedImage(false);
    setmessagearray([
      ...messagearray,
      {
        message: Date.now().toString(),
        thread_id: selectedGroup.thread_id,
        sender: userObj,
        timestamp: Date.now(),
        filename: Date.now().toString(),
        type: "file",
        location: clipImage,
        msgtype: "group",
      },
    ]);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      // setVisibleDroppedImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_send_group_message",
      message_data,
      memberUsernames,
      app_id,
      (response) => {
        console.log(response, "kwkjc,wbjkwje");
        if (!response.status) {
          // message.error("Error in sending message");
          setSubmitLoading(false);
          message.error(response.payload);
        } else {
          setLoadingImage(false);
          setSubmitLoading1(false);
          // setmessagearray([...messagearray, { ...message_data }]);
          // setInputText("");
          updateStorage(response.payload);
          let tempArr1 = [...groupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          if (objIndex) {
            tempArr1[objIndex].sender = userObj.username;

            let newArr = [
              ...tempArr1.filter(
                (o) => o.group_name === selectedGroup.group_name
              ),
            ];
            tempArr1 = tempArr1.filter(
              (obj) => obj.group_name !== selectedGroup.group_name
            );
            newArr = [...newArr, ...tempArr1];
            console.log(newArr);
            setgroupReadUnreadList([...newArr]);
          }
        }
      }
    );
    setSelectedReplyMessage(null);
  };

  const uploadGroupReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage.message,
        threadId: selectedReplyMessage.threadId,
        sender: selectedReplyMessage.sender,
        timestamp: selectedReplyMessage.timestamp,
        type: selectedReplyMessage.type ? selectedReplyMessage.type : null,
        location: selectedReplyMessage.location
          ? selectedReplyMessage.location
          : null,
      },
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_send_group_message",
      message_data,
      memberUsernames,
      app_id,
      (response) => {
        if (!response.status) {
          // message.error("Error in sending message");
          setSubmitLoading(false);
          message.error(response.payload);
        } else {
          setLoadingImage(false);
          setmessagearray([...messagearray, response.payload]);
          // setInputText("");
          updateStorage(response.payload);
          let tempArr1 = [...groupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          tempArr1[objIndex].sender = userObj.username;

          let newArr = [
            ...tempArr1.filter(
              (o) => o.group_name === selectedGroup.group_name
            ),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.group_name !== selectedGroup.group_name
          );
          newArr = [...newArr, ...tempArr1];
          console.log(newArr);
          setgroupReadUnreadList([...newArr]);
        }
      }
    );
    setSelectedReplyMessage(null);
  };

  const uploadSupportGroupImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      // replied_msg: false,
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedSupport.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", notification_data);

    socket.emit(
      "new_support_group_message_v2",
      message_data,
      selectedSupport.group_name.substring(
        11,
        selectedSupport.group_name.length
      ) === userObj.username
        ? "support"
        : selectedSupport.group_name.substring(
            11,
            selectedSupport.group_name.length
          ),
      app_id,
      (response) => {
        if (response !== "success") {
          message.error("Error in sending message");
        } else {
          setLoadingImage(false);
          setmessagearray([...messagearray, { ...message_data }]);
          // setInputText("");

          let tempArr1 = [...supportgroupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedSupport.group_name
          );
          tempArr1[objIndex].sender.username = userObj.username;

          let newArr = [
            ...tempArr1.filter(
              (o) => o.group_name === selectedSupport.group_name
            ),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.group_name !== selectedSupport.group_name
          );
          newArr = [...newArr, ...tempArr1];
          console.log(newArr);
          // setsupportgroupReadUnreadList([...newArr]);
        }
      }
    );
  };

  const uploadSupportGroupReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisibleDroppedImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    const messageWithReply = {
      message: Date.now().toString(),
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage.message,
        threadId: selectedReplyMessage.threadId,
        sender: selectedReplyMessage.sender,
        timestamp: selectedReplyMessage.timestamp,
        type: selectedReplyMessage.type ? selectedReplyMessage.type : null,
        location: selectedReplyMessage.location
          ? selectedReplyMessage.location
          : null,
      },
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedSupport.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_support_group_message_v2",
      messageWithReply,
      selectedSupport.group_name.substring(
        11,
        selectedSupport.group_name.length
      ) === userObj.username
        ? "support"
        : selectedSupport.group_name.substring(
            11,
            selectedSupport.group_name.length
          ),
      app_id,
      (response) => {
        if (response !== "success") {
          message.error("Error in sending message");
        } else {
          setLoadingImage(false);
          setmessagearray([...messagearray, { ...messageWithReply }]);
          // setInputText("");

          let tempArr1 = [...supportgroupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedSupport.group_name
          );
          tempArr1[objIndex].sender.username = userObj.username;

          let newArr = [
            ...tempArr1.filter(
              (o) => o.group_name === selectedSupport.group_name
            ),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.group_name !== selectedSupport.group_name
          );
          newArr = [...newArr, ...tempArr1];
          console.log(newArr);
          setsupportgroupReadUnreadList([...newArr]);
        }
      }
    );
    setSelectedReplyMessage(null);
  };

  const addToReaction = (rcObj, itemObj) => {
    if (selectedFriend) {
      if (rcObj.usernames.includes(userObj.username) === false) {
        socket.emit(
          `direct_message_add_reaction`,
          {
            msg_id: itemObj._id,
            reaction: rcObj.name,
            username: userObj.username,
          },
          app_id,
          (res) => {
            if (res.status) {
              console.log(res, "Reaction Added");
              var tempMsgArr = messagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == itemObj._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setmessagearray([...tempMsgArr]);
            }
          }
        );
      } else {
        socket.emit(
          `direct_chat_remove_reaction`,
          {
            msg_id: itemObj._id,
            reaction: rcObj.name,
            username: userObj.username,
          },
          app_id,
          (res) => {
            if (res.status) {
              if (threadItem) {
                var tempMsgArr = threadMessagearray;
                var foundIndex = tempMsgArr.findIndex(
                  (x) => x._id == selectedItem._id
                );
                console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
                tempMsgArr[foundIndex] = res.payload;
                setThreadMessagearray([...tempMsgArr]);
              } else {
                console.log(res, "Reaction Removed");
                var tempMsgArr = messagearray;
                var foundIndex = tempMsgArr.findIndex(
                  (x) => x._id == itemObj._id
                );
                console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
                tempMsgArr[foundIndex] = res.payload;
                setmessagearray([...tempMsgArr]);
              }
            }
          }
        );
      }
    } else if (selectedGroup) {
      if (rcObj.usernames.includes(userObj.username) === false) {
        socket.emit(
          `group_chat_add_reaction`,
          {
            msg_id: itemObj._id,
            reaction: rcObj.name,
            username: userObj.username,
          },
          app_id,
          (res) => {
            if (res.status) {
              console.log(res, "Reaction Added");
              var tempMsgArr = messagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == itemObj._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setmessagearray([...tempMsgArr]);
            }
          }
        );
      } else {
        socket.emit(
          `group_chat_remove_reaction`,
          {
            msg_id: itemObj._id,
            reaction: rcObj.name,
            username: userObj.username,
          },
          app_id,
          (res) => {
            if (res.status) {
              if (threadItem) {
                var tempMsgArr = threadMessagearray;
                var foundIndex = tempMsgArr.findIndex(
                  (x) => x._id == selectedItem._id
                );
                console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
                tempMsgArr[foundIndex] = res.payload;
                setThreadMessagearray([...tempMsgArr]);
              } else {
                console.log(res, "Reaction Removed");
                var tempMsgArr = messagearray;
                var foundIndex = tempMsgArr.findIndex(
                  (x) => x._id == itemObj._id
                );
                console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
                tempMsgArr[foundIndex] = res.payload;
                setmessagearray([...tempMsgArr]);
              }
            }
          }
        );
      }
    } else if (selectedSupport) {
      if (rcObj.usernames.includes(userObj.username) === false) {
        socket.emit(
          `support_group_chat_message_add_reaction`,
          {
            msg_id: itemObj._id,
            reaction: rcObj.name,
            username: userObj.username,
          },
          app_id,
          (res) => {
            if (res.status) {
              console.log(res, "Reaction Added");
              var tempMsgArr = messagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == itemObj._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setmessagearray([...tempMsgArr]);
            }
          }
        );
      } else {
        socket.emit(
          `support_group_chat_message_remove_reaction`,
          {
            msg_id: itemObj._id,
            reaction: rcObj.name,
            username: userObj.username,
          },
          app_id,
          (res) => {
            console.log(res, "Reaction removed");
            if (res.status) {
              if (threadItem) {
                var tempMsgArr = threadMessagearray;
                var foundIndex = tempMsgArr.findIndex(
                  (x) => x._id == selectedItem._id
                );
                console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
                tempMsgArr[foundIndex] = res.payload;
                setThreadMessagearray([...tempMsgArr]);
              } else {
                console.log(res, "Reaction Removed");
                var tempMsgArr = messagearray;
                var foundIndex = tempMsgArr.findIndex(
                  (x) => x._id == itemObj._id
                );
                console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
                tempMsgArr[foundIndex] = res.payload;
                setmessagearray([...tempMsgArr]);
              }
            }
          }
        );
      }
    }

    // selectedItem.reactions.map(item=> {
    //   if(item.usernames.includes(userObj.username))
    // })
  };

  const addReaction = (e) => {
    console.log(selectedItem, "khwvekfwvekfh");
    if (selectedFriend) {
      socket.emit(
        `direct_message_add_reaction`,
        {
          msg_id: selectedItem._id,
          reaction: e.native,
          username: userObj.username,
        },
        app_id,
        (res) => {
          if (res.status) {
            if (threadItem) {
              var tempMsgArr = threadMessagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == selectedItem._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setThreadMessagearray([...tempMsgArr]);
            } else {
              console.log(res, "Reaction Added");
              var tempMsgArr = messagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == selectedItem._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setmessagearray([...tempMsgArr]);
              setSelectedItem(null);
            }
          }
        }
      );

      setShowReactions(false);
    } else if (selectedGroup) {
      socket.emit(
        `group_chat_add_reaction`,
        {
          msg_id: selectedItem._id,
          reaction: e.native,
          username: userObj.username,
        },
        app_id,
        (res) => {
          if (res.status) {
            if (threadItem) {
              var tempMsgArr = threadMessagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == selectedItem._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setThreadMessagearray([...tempMsgArr]);
            } else {
              console.log(res, "Reaction Added");
              var tempMsgArr = messagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == selectedItem._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setmessagearray([...tempMsgArr]);
              setSelectedItem(null);
            }
          }
        }
      );

      setShowReactions(false);
    } else if (selectedSupport) {
      socket.emit(
        `support_group_chat_message_add_reaction`,
        {
          msg_id: selectedItem._id,
          reaction: e.native,
          username: userObj.username,
        },
        app_id,
        (res) => {
          console.log(
            res,
            selectedItem,
            e.native,
            userObj.username,
            "Reaction Added"
          );
          if (res.status) {
            if (threadItem) {
              var tempMsgArr = threadMessagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == selectedItem._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setThreadMessagearray([...tempMsgArr]);
            } else {
              console.log(res, "Reaction Added");
              var tempMsgArr = messagearray;
              var foundIndex = tempMsgArr.findIndex(
                (x) => x._id == selectedItem._id
              );
              console.log(tempMsgArr[foundIndex], res.payload, "jhwgeikfwe");
              tempMsgArr[foundIndex] = res.payload;
              setmessagearray([...tempMsgArr]);
              setSelectedItem(null);
            }
          }
        }
      );

      setShowReactions(false);
    }
  };

  // useEffect(() => {
  //   if (selectedItem !== null) {
  //     localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
  //   }

  //   console.log(selectedItem, "kjwgfkweff");
  // }, [selectedItem]);

  // useEffect(() => {
  //   setgroupReadUnreadList([
  //     ...groupReadUnreadList.sort((a, b) => b.msg_timestamp - a.msg_timestamp),
  //   ]);
  // }, [groupReadUnreadList]);

  // useEffect(() => {
  //   console.log(showUnread, "kjgkdwef");
  // });

  useEffect(() => {
    setUnreadList([...groupReadUnreadList, ...unreadInteractionList]);
  }, [groupReadUnreadList, unreadInteractionList]);

  useEffect(() => {
    if (localStorage.getItem("showUnread")) {
      setShowUnread(localStorage.getItem("showUnread"));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("showUnread")) {
      localStorage.setItem("showUnread", showUnread);
    }
  }, [showUnread]);

  useEffect(() => {
    if (localStorage.getItem("showFav")) {
      setShowFav(localStorage.getItem("showFav"));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("showFav")) {
      localStorage.setItem("showFav", showFav);
    }
  }, [showFav]);

  const getPinnedMessage = (item) => {
    if (selectedFriend) {
      socket.emit(
        "get_all_pinned_one_to_one_messages_chat_history",
        selectedFriend.thread_id,
        app_id,
        async (data) => {
          console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", data);
          if (data.status) {
            setPinnedMessages(data.payload);
          }
        }
      );
    } else if (selectedGroup) {
      socket.emit(
        "get_all_pinned_group_messages_chat_history",
        selectedGroup.thread_id,
        app_id,
        async (data) => {
          console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", data);
          if (data.status) {
            setPinnedMessages(data.payload);
          }
        }
      );
    }
  };

  const value = {
    app_id,
    getPinnedMessage,
    url,
    config,
    socket,
    usersocket,
    bodyRef,
    unreadInteractionList,
    setUnreadInteractionList,
    userReadUnreadList,
    setuserReadUnreadList,
    selectedGroup,
    setSelectedGroup,
    selectedFriend,
    setSelectedFriend,
    visibleUserInfo,
    setVisibleUserInfo,
    groupReadUnreadList,
    setgroupReadUnreadList,
    supportgroupReadUnreadList,
    setsupportgroupReadUnreadList,
    currentUserObj,
    setCurrentUserObj,
    selectedSupport,
    setSelectedSupport,
    messagearray,
    setmessagearray,
    groupMembers,
    setgroupMembers,
    droppedFile,
    setDroppedFile,
    clipImage,
    setClipImage,
    selectedReplyMessage,
    setSelectedReplyMessage,
    visibleDroppedImage,
    setVisibleDroppedImage,
    droppedImageHandler,
    uploadImage,
    handleDroppedFile,
    validateJson,
    renderMessage,
    allUserList,
    setallUserList,
    currentUserList,
    setcurrentUserList,
    messageLoading,
    setMessageLoading,
    setFriendsLoading,
    friendsLoading,
    groupsLoading,
    setGroupsLoading,
    supportLoading,
    setSupportLoading,
    newLogin,
    setNewLogin,
    accountArr,
    setAccountArr,
    getCUO,
    selectedAdminSupport,
    setSelectedAdminSupport,
    selectedAdminSupport1,
    setSelectedAdminSupport1,
    archiveList,
    setArchiveList,
    getSupportList,
    typingUser,
    setTypingUser,
    typingFlag,
    setTypingFlag,
    selectedType,
    setSelectedType,
    onlineUsers,
    setOnlineUsers,
    adminUsernames,
    setAdminUsernames,
    visibleVault,
    setVisibleVault,
    visibleProfile,
    setVisibleProfile,
    visibleMarket,
    setVisibleMarket,
    visibleActions,
    setVisibleActions,
    visibleControlPanel,
    setVisibleControlPanel,
    visibleSwitcher,
    setVisibleSwitcher,
    chatBoxArr,
    setChatBoxArr,
    activeTab,
    setActiveTab,
    visibleSetting,
    setVisibleSetting,
    visibleMailKings,
    setVisibleMailKings,
    userObj,
    setUserObj,
    refreshGroup,
    setRefreshGroup,
    selectedOrg,
    setSelectedOrg,
    switchOrg,
    adminEmails,
    isAdmin,
    setIsAdmin,
    getAdminSupportList,
    chatTypes,
    setChatTypes,
    selectedChatType,
    setSelectedChatType,
    loading,
    setLoading,
    allOrgs,
    setAllOrgs,
    tokenValid,
    setTokenValid,
    tokenValidation,
    constants,
    pinnedMessages,
    setPinnedMessages,
    showConditionalDate,
    conditionalMessageView,
    threadItem,
    setThreadItem,
    bodyRef1,
    selectedReplyMessage1,
    setSelectedReplyMessage1,
    memberUsernames,
    setMemberUsernames,
    selectedEditMessage,
    setSelectedEditMessage,
    formatAMPM,
    submitLoading,
    setSubmitLoading,
    threadMessagearray,
    setThreadMessagearray,
    editorText,
    setEditorText,
    editorTextThread,
    setEditorTextThread,
    timeDifference,
    showReactions,
    setShowReactions,
    addReaction,
    selectedItem,
    setSelectedItem,
    addToReaction,
    goToBottom,
    setGoToBottom,
    unreadCount,
    setUnreadCount,
    hideSidebar,
    setHideSidebar,
    hideSupport,
    setHideSupport,
    hidePrivate,
    setHidePrivate,
    hideGroup,
    setHideGroup,
    scrollRef,
    getGroups,
    getUsers,
    loadAll,
    setLoadAll,
    blankPage,
    setBlankPage,
    refetchData,
    setRefetchData,
    handleTabClick,
    foundSavedObj,
    setFoundSavedObj,
    updateStorage,
    handleStoreData,
    handleUploadFile,
    selectedForwardMessages,
    setSelectedForwardMessages,
    selectedDeleteMessages,
    setSelectedDeleteMessages,
    handleForward,
    showForwardMessageUI,
    setShowForwardMessageUI,
    showFullname,
    setShowFullname,
    selectedGroupChats,
    setSelectedGroupChats,
    selectedFriendChats,
    setSelectedFriendChats,
    changeMode,
    globalColors,
    setGlobalColors,
    showDisconnected,
    setShowDisconnected,
    recipients,
    setRecipients,
    groupLists,
    setGroupLists,
    allGroups,
    setAllGroups,
    unreadList,
    setUnreadList,
    showUnread,
    setShowUnread,
    showFav,
    setShowFav,
    favList,
    setFavList,
    groupFav,
    setGroupFav,
    friendFav,
    setFriendFav,
    supportFav,
    setSupportFav,
    getFavs,
    focusAssistant,
    timeLeft,
    setTimeLeft,
    allGroupRoles,
    setAllGroupRoles,
    sendMessageState,
    setSendMessageState,
    deleteMessageState,
    setDeleteMessageState,
    addUserState,
    setAddUserState,
    removeUserState,
    setRemoveUserState,
    addRoleState,
    setAddRoleState,
    removeRoleState,
    setRemoveRoleState,
    conditionalBadge,
    showEditUserInfo,
    setShowEditUserInfo,
    removeItemFromChatboxArr,
    visiblePasteImage,
    setVisiblePasteImage,
    submitLoading1,
    setSubmitLoading1,
  };

  return (
    <div className="App">
      <MyContext.Provider value={value}>
        {/* {conditionalView()} */}
        {conditionalRoutes()}
      </MyContext.Provider>

      {/* Show Dropped Image Modal */}

      {/* <Modal
        width={300}
        bodyStyle={{ padding: "0px" }}
        title="Upload Image?"
        visible={visibleDroppedImage}
        onOk={conditionalImageUpload}
        onCancel={(e) => setVisibleDroppedImage(false)}
      >
        <img src={clipImage} alt="" style={{ width: "100%" }} />
      </Modal> */}

      {/* <Modal
        width="auto"
        bodyStyle={{ padding: "0px" }}
        title="Upload Image?"
        visible={visibleDroppedImage}
        onOk={conditionalImageUpload}
        onCancel={(e) => setVisibleDroppedImage(false)}
        footer={[
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                conditionalImageUpload();
              }}
            >
              <input
                ref={hiddenRef}
                style={{
                  border: "solid 0px",
                  outline: "none",
                  // visibility: "hidden",
                }}
              />
            </form> 
          </div>,
          <Button type="danger" onClick={(e) => setVisibleDroppedImage(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={conditionalImageUpload}>
            Submit
          </Button>,
        ]}
      >
        {loadingImage ? (
          <div
            className="img-responsive"
            style={{
              backgroundColor: "gray",
              opacity: 0.5,
            }}
          >
            <img
              src={clipImage}
              alt=""
              width="auto"
              style={{ maxHeight: "70vh" }}
            />
          </div>
        ) : (
          <div className="img-responsive" style={{ maxHeight: "70vh" }}>
            <img
              src={clipImage}
              alt=""
              width="auto"
              style={{ maxHeight: "70vh" }}
            />
          </div>
        )}

       
        
      </Modal> */}

      <Modal
        // ref={hiddenRef}
        autoFocusButton="ok"
        centered
        width="auto"
        // height="600px !important"
        bodyStyle={{ padding: "0px" }}
        title="Upload Image?"
        visible={visibleDroppedImage}
        onOk={conditionalImageUpload}
        onCancel={(e) => setVisibleDroppedImage(false)}
        footer={[
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                conditionalImageUpload();
              }}
            >
              <input
                // ref={hiddenRef}
                style={{
                  border: "solid 0px",
                  outline: "none",
                  // visibility: "hidden",
                }}
              />
            </form>
          </div>,
          <Button type="danger" onClick={(e) => setVisibleDroppedImage(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={conditionalImageUpload}>
            Submit
          </Button>,
        ]}
      >
        {loadingImage ? (
          <div
            className="img-responsive"
            style={{
              backgroundColor: "gray",
              opacity: 0.5,
            }}
          >
            <img
              src={clipImage}
              alt=""
              width="auto"
              style={{ maxHeight: "70vh" }}
            />
          </div>
        ) : (
          <div className="img-responsive" style={{ maxHeight: "70vh" }}>
            <img
              src={clipImage}
              alt=""
              width="auto"
              style={{ maxHeight: "70vh" }}
            />
          </div>
        )}
      </Modal>

      {/* show dropped video */}
      <Modal
        width={350}
        bodyStyle={{ padding: "0px" }}
        title="Upload Video"
        visible={visibleDroppedVideo}
        onOk={conditionalVideoUpload}
        onCancel={(e) => setVisibleDroppedVideo(false)}
      >
        {loadingImage ? (
          <div
            style={{
              backgroundImage: `https://www.lifewire.com/thmb/2KYEaloqH6P4xz3c9Ot2GlPLuds=/1920x1080/smart/filters:no_upscale()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              height: "200px",
              opacity: "0.5",
            }}
          >
            <LoadingOutlined />
          </div>
        ) : (
          <ReactPlayer
            width="350px"
            height="200px"
            // url={url}
            url={clipVideo}
            // light={url}
            controls
            playing={true}
            // loop={true}
            light={
              "https://i.pinimg.com/originals/27/13/9b/27139b573b1d9d5fe66e7e27e7127563.jpg"
            }
          />
          // <div
          //   style={{
          //     backgroundImage: `url(${clipImage})`,
          //     backgroundPosition: "center",
          //     backgroundSize: "cover",
          //     backgroundRepeat: "no-repeat",
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     width: "300px",
          //     height: "200px",
          //     // opacity: "0.5",
          //   }}
          // ></div>
        )}
        {/* <div style={{ padding: "10px" }}>
          <Input
            type="text"
            placeholder="Name of the Image ??"
            value={imageName}
            onChange={e => setImageName(e.target.value)}
          />
        </div> */}
      </Modal>

      {/* Show Dropped File Modal */}
      <Modal
        width={300}
        bodyStyle={{ padding: "0px" }}
        title="Upload File?"
        visible={visibleDroppedFile}
        onOk={conditionalFileUpload}
        onCancel={(e) => setVisibleDroppedFile(false)}
      >
        <div style={{ padding: "10px" }}>
          <FileOutlined style={{ fontWeight: "bold" }} />
          &nbsp; {droppedFile !== null ? droppedFile.name : ""}
        </div>
      </Modal>

      {/* Show Chat Images on click - in a modal  */}
      {/* 
      <Modal
        centered
        width="auto"
        // title="full image"
        footer={null}
        visible={visibleShowImage}
        onCancel={(e) => setVisibleShowImage(false)}
      >
        <div className="img-responsive" style={{ maxHeight: "70vh" }}>
          <Lightbox
            image={imageToShow}
            width="100%"
            maxHeight="70vh"
            style={{ maxHeight: "70vh" }}
          />
        </div>
        {/* <div className="img-responsive" style={{ maxHeight: "70vh" }}>
          <img
            src={imageToShow}
            alt=""
            width="100%"
            style={{ maxHeight: "70vh" }}
          />
        </div> 
      </Modal> */}

      <Modal
        centered
        width={300}
        title="Add some more info"
        visible={visibleMoreInfo}
        // onOk={this.handleOk}
        footer={null}
        closable={false}
        onCancel={(e) => setVisibleMoreInfo(false)}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Upload
            style={{ width: "auto !important" }}
            accept="image/*"
            customRequest={uploadProfileImage}
            onChange={handleOnChangeProfile}
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            defaultFileList={defaultFileList}
          >
            {imgUrl ? (
              <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          placeholder="First Name"
          className="underline-input"
          style={{ margin: "20px 0px" }}
        />

        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          placeholder="Last Name"
          className="underline-input"
          style={{ margin: "20px 0px" }}
        />
        <TextArea
          className="underline-input"
          style={{ margin: "20px 0px" }}
          placeholder="Bio"
          allowClear
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        <Button block type="primary" size="large" onClick={handleNewSignup}>
          {loading ? (
            <>
              <LoadingOutlined />
              &nbsp; Processing...
            </>
          ) : (
            <>&nbsp;Submit</>
          )}
        </Button>
      </Modal>
    </div>
  );
};

export default App;

// function useInterval(callback, delay) {
//   const savedCallback = useRef();

//   // Remember the latest callback.
//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   // Set up the interval.
//   useEffect(() => {
//     let id = setInterval(() => {
//       savedCallback.current();
//     }, delay);
//     return () => clearInterval(id);
//   }, [delay]);
// }

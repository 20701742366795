import React, { useContext } from "react";
import { useHistory } from "react-router";
// import Images from "../../assets/0-exporter";
import "../css/splash.css";
import { MyContext } from "../Context";

export default function LandingPage() {
  const { constants } = useContext(MyContext);
  const history = useHistory();
  return (
    <div className="landing-page-main blah" style={{ height: "100vh" }}>
      <div className="landing-page-bg">
        <div />
        <img src={require("../images/splash.png")} />
      </div>
      <div className="landing-title">
        <img src={require("../images/logo_white.svg")} alt="accounts" />
        {/* <p>Simple . Social . Streamlined</p> */}
      </div>
      <br />
      <br />
      <br />
      <div className="landing-buttons">
        <button
          style={{
            background: "white",
            border: "solid 1px white",
            cursor: "pointer",
            width: "170px",
          }}
          onClick={() => history.push("/login")}
        >
          My Chats
        </button>
        <button
          style={{
            border: "solid 1px white",
            cursor: "pointer",
            width: "170px",
          }}
        >
          Join Team
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={require("../images/poweredby.png")}
          style={{ marginTop: "7px" }}
        />
        &nbsp;&nbsp;
        <img
          className="hoverzoom"
          src={require("../images/chatio_white.png")}
          style={{ cursor: "pointer" }}
          onClick={(e) => window.open("https://chats.io", "_blank")}
        />
      </div>
    </div>
  );
}

import React, { useState, useContext, useRef } from "react";
import urlParser from "js-video-url-parser";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import {
  Popover,
  message,
  Modal,
  Spin,
  Menu,
  Dropdown,
  Mentions,
  Button,
  Avatar,
  List,
  Tooltip,
} from "antd";
import {
  CloseOutlined,
  SmileOutlined,
  LoadingOutlined,
  PlusOutlined,
  PlusCircleFilled,
  CloudUploadOutlined,
  FileTwoTone,
  VideoCameraTwoTone,
  FileImageTwoTone,
  CloseCircleFilled,
} from "@ant-design/icons";
import S3 from "aws-s3";
import { MyContext } from "../../Context";
import { useEffect } from "react";
import Axios from "axios";
import loadingimg from "../../images/loading.gif";

const { Option } = Mentions;

const Editor = () => {
  const {
    socket,
    usersocket,
    groupReadUnreadList,
    setgroupReadUnreadList,
    supportgroupReadUnreadList,
    setsupportgroupReadUnreadList,
    userReadUnreadList,
    setuserReadUnreadList,
    currentUserObj,
    selectedFriend,
    // setSelectedFriend,
    selectedGroup,
    // setSelectedGroup,
    selectedReplyMessage,
    setSelectedReplyMessage,
    selectedSupport,
    // setSelectedSupport,
    selectedAdminSupport,
    messagearray,
    setmessagearray,
    unreadInteractionList,
    setUnreadInteractionList,
    renderMessage,
    bodyRef,
    getSupportList,
    selectedType,
    setSelectedType,
    activeTab,
    chatBoxArr,
    userObj,
    app_id,
    tokenValid,
    setTokenValid,
    groupMembers,
    memberUsernames,
    setMemberUsernames,
    selectedEditMessage,
    setSelectedEditMessage,
    submitLoading,
    setSubmitLoading,
    submitLoading1,
    setSubmitLoading1,
    allUserList,
    editorText,
    setEditorText,
    setallUserList,
    goToBottom,
    setGoToBottom,
    hideSidebar,
    visibleUserInfo,
    updateStorage,
    typingUser,
    setTypingUser,
    typingFlag,

    setTypingFlag,

    handleUploadFile,
    globalColors,
    favList,
    focusAssistant,
  } = useContext(MyContext);

  const uploadVideoRef = useRef();
  const uploadImageRef = useRef();
  const uploadFileRef = useRef();
  var usrs = [];
  const hiddenRef = useRef();
  const [clipImage1, setClipImage1] = useState("");

  const [emojiShow, setemojiShow] = useState(false);
  // const [selectedReplyMessage, setSelectedReplyMessage] = useState({});
  const [loadingImage, setLoadingImage] = useState(false);
  const [showMentions, setShowMentions] = useState(false);

  const [visiblePasteImage, setVisiblePasteImage] = useState(false);

  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  // const findMessageType = () => {
  //   let obj = urlParser.parse(bodyRef.current.innerHTML);
  //   console.log("mediatype", obj.mediaType);
  //   if (obj !== undefined) {
  //     if (obj.mediaType === "video") {
  //       return "video";
  //     }
  //   }
  // };

  // useeffect to notify that other party which the current user is interaction is typing
  useEffect(() => {
    if (selectedType) {
      socket.on("someone_typing", (typing_user, thread_id1) => {
        // console.log("typing thread: ", thread_id1, typing_user);
        if (thread_id1 === selectedType.thread_id) {
          console.log("typing");
          setTypingUser(typing_user);
          setTypingFlag(true);

          setTimeout(() => {
            setTypingFlag(false);
            // setTypingUser(null);
          }, 2000);
        } else {
          setTypingFlag(false);
        }
      });
    }

    return () => {
      socket.off("someone_typing");
    };
  }, [selectedType, selectedAdminSupport]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     bodyRef.current.focus();
  //   }, 500);

  //   console.log("focuseddddd");
  // });

  useEffect(() => {
    // setEditorText("");
    bodyRef.current.focus();

    console.log("focuseddddd444");
    // const x = document.getElementById("focushere");
    // x.focus();
    // bodyRef.current.innerHTML = "";
  }, [activeTab, submitLoading]);

  // useEffect(() => {
  //   var dummyEl = document.getElementById("focushere");

  //   // check for focus
  //   var isFocused = document.activeElement.id === dummyEl.id;

  //   if (isFocused === false) {
  //     // const x = document.getElementById("focushere");
  //     dummyEl.focus();
  //     bodyRef.current.focus();
  //     console.log(document.activeElement.id, "kjwbkjwbekfbewf");
  //     if (document.activeElement.id.includes("dontfocus")) {
  //       focusAssistant();
  //       // setTimeout(() => {
  //       //   document.getElementById("focushere").focus();
  //       //   bodyRef.current.focus();
  //       //   console.log(document.activeElement, activeTab, "kjwbkjwbekfbewf");
  //       // }, 5000);
  //     }
  //   }
  // }, [messagearray]);

  // useEffect(() => {
  //   console.log(
  //     document.activeElement,
  //     document.getElementById("focushere"),
  //     // activeTab,

  //     "kjwbkjwbekfbewf"
  //   );
  //   if (document.getElementById("focushere").id !== document.activeElement.id) {
  //     document.getElementById("focushere").focus();
  //   }
  // }, [document.getElementById("focushere")]);

  // socket.on("new_direct_chat_message", (input,username,app_id,response) => {
  //   direct_chat.new_direct_chat_message(input,username,app_id, socket, io, response);
  // });

  const handleSubmitPrivateChat = (e) => {
    console.log("hahah", userObj, selectedFriend);
    let obj = urlParser.parse(editorText);
    console.log(obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorText.length > 0) {
          let notification_data = {
            sender: userObj.username,
            receiver: selectedFriend.username,
          };

          usersocket.emit("new_message", app_id, notification_data);
          let tempArr = [...userReadUnreadList];
          tempArr = tempArr.filter(function (item) {
            return item !== selectedFriend.username;
          });
          setuserReadUnreadList([...tempArr]);
          setmessagearray([
            ...messagearray,
            {
              message: editorText,
              type: "video",
              thread_id: selectedFriend.thread_id,
              sender: userObj,
              timestamp: Date.now(),
              msgtype: "onetoone",
            },
          ]);
          setEditorText("");
          socket.emit(
            "new_direct_chat_message",
            {
              message: editorText,
              type: "video",
              thread_id: selectedFriend.thread_id,
              sender: userObj,
              timestamp: Date.now(),
              msgtype: "onetoone",
            },
            selectedFriend.username,
            app_id,
            (response) => {
              if (response.status) {
                // setEditorText("");
                // console.log(response, "jhkjfwekfjwekjf");
                setmessagearray([...messagearray, response.payload]);
                updateStorage(response.payload);
                setGoToBottom(true);

                setSubmitLoading(false);
                // setInputText("");
                //for pushing msg =user to top
                let tempArr1 = [...unreadInteractionList];
                let newArr = [
                  ...tempArr1.filter(
                    (o) => o.username === selectedFriend.username
                  ),
                ];
                tempArr1 = tempArr1.filter(
                  (obj) => obj.username !== selectedFriend.username
                );
                newArr = [...newArr, ...tempArr1];
                setUnreadInteractionList([...newArr]);
                console.log(newArr);
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorText.length > 0) {
        console.log("parsed val", bodyRef.current);
        // var span = document.createElement("span");
        // span.innerHTML = JSON.parse(str);
        // console.log(span, "lkjwhekjwekwge");

        let notification_data = {
          sender: userObj.username,
          receiver: selectedFriend.username,
        };

        usersocket.emit("new_message", app_id, notification_data);
        let tempArr = [...userReadUnreadList];
        tempArr = tempArr.filter(function (item) {
          return item !== selectedFriend.username;
        });
        setuserReadUnreadList([...tempArr]);
        setmessagearray([
          ...messagearray,
          {
            message: editorText,
            thread_id: selectedFriend.thread_id,
            sender: userObj,
            timestamp: Date.now(),
            msgtype: "onetoone",
          },
        ]);
        setEditorText("");
        socket.emit(
          "new_direct_chat_message",
          {
            message: editorText,
            thread_id: selectedFriend.thread_id,
            sender: userObj,
            timestamp: Date.now(),
            msgtype: "onetoone",
          },
          selectedFriend.username,
          app_id,
          (response) => {
            if (response.status) {
              // setEditorText("");
              console.log(response.payload, "jhkjfwekfjwekjf");
              // messagearray.pop();
              setmessagearray([...messagearray, response.payload]);
              updateStorage(response.payload);
              setGoToBottom(true);
              // setInputText("");
              // bodyRef.current.innerHTML = "";
              // bodyRef.current.focus();
              document.getElementById("focushere").focus();
              setSubmitLoading(false);
              //for pushing msg =user to top
              let tempArr1 = [...unreadInteractionList];
              let newArr = [
                ...tempArr1.filter(
                  (o) => o.username === selectedFriend.username
                ),
              ];
              tempArr1 = tempArr1.filter(
                (obj) => obj.username !== selectedFriend.username
              );
              newArr = [...newArr, ...tempArr1];
              setUnreadInteractionList([...newArr]);
              console.log(newArr);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
  };

  const handleSubmitReplyPrivateChat = (e) => {
    // e.preventDefault();

    let obj = urlParser.parse(editorText);

    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorText.length > 0) {
          let notification_data = {
            sender: userObj.username,
            receiver: selectedFriend.username,
          };

          usersocket.emit("new_message", app_id, notification_data);
          let tempArr = [...userReadUnreadList];
          tempArr = tempArr.filter(function (item) {
            return item !== selectedFriend.username;
          });
          setuserReadUnreadList([...tempArr]);

          socket.emit(
            "new_reply_message",
            {
              message: editorText,
              type: "video",
              thread_id: selectedFriend.thread_id,
              sender: userObj,
              timestamp: Date.now(),
            },
            selectedReplyMessage,
            app_id,
            (response) => {
              if (response.status) {
                console.log(response);
                setmessagearray([...messagearray, response.payload]);
                updateStorage(response.payload);
                setGoToBottom(true);
                // setmessagearray([
                //   ...messagearray,

                //   {
                //     message: JSON.stringify(bodyRef.current.innerHTML),
                //     type: "video",
                //     replied_msg: true,
                //     replied_to_data: {
                //       message: selectedReplyMessage.message,
                //       threadId: selectedReplyMessage.threadId,
                //       sender: selectedReplyMessage.sender,
                //       timestamp: selectedReplyMessage.timestamp,
                //       type: selectedReplyMessage.type
                //         ? selectedReplyMessage.type
                //         : null,
                //       location: selectedReplyMessage.location
                //         ? selectedReplyMessage.location
                //         : null,
                //     },
                //     threadId: selectedFriend.thread_id,
                //     sender: userObj,
                //     timestamp: Date.now(),
                //   },
                // ]);
                // bodyRef.current.innerHTML = "";
                setEditorText("");
                setSubmitLoading(false);
                // setInputText("");
                //for pushing msg =user to top
                let tempArr1 = [...unreadInteractionList];
                let newArr = [
                  ...tempArr1.filter(
                    (o) => o.username === selectedFriend.username
                  ),
                ];
                tempArr1 = tempArr1.filter(
                  (obj) => obj.username !== selectedFriend.username
                );
                newArr = [...newArr, ...tempArr1];
                setUnreadInteractionList([...newArr]);
                console.log(newArr);
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      console.log("please reply", selectedReplyMessage);
      if (editorText.length > 0) {
        let notification_data = {
          sender: userObj.username,
          receiver: selectedFriend.username,
        };

        usersocket.emit("new_message", app_id, notification_data);
        let tempArr = [...userReadUnreadList];
        tempArr = tempArr.filter(function (item) {
          return item !== selectedFriend.username;
        });
        setuserReadUnreadList([...tempArr]);
        socket.emit(
          "new_reply_message",
          {
            message: editorText,
            thread_id: selectedFriend.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          selectedReplyMessage,
          app_id,
          (response) => {
            console.log("please reply", response);
            if (response.status) {
              setmessagearray([...messagearray, response.payload]);
              updateStorage(response.payload);
              setGoToBottom(true);
              // setmessagearray([
              //   ...messagearray,
              //   {
              //     message: JSON.stringify(bodyRef.current.innerHTML),
              //     replied_msg: true,
              //     replied_to_data: {
              //       message: selectedReplyMessage.message,
              //       threadId: selectedReplyMessage.threadId,
              //       sender: selectedReplyMessage.sender,
              //       timestamp: selectedReplyMessage.timestamp,
              //       type: selectedReplyMessage.type
              //         ? selectedReplyMessage.type
              //         : null,
              //       location: selectedReplyMessage.location
              //         ? selectedReplyMessage.location
              //         : null,
              //     },
              //     thread_id: selectedFriend.thread_id,
              //     sender: userObj,
              //     timestamp: Date.now(),
              //   },
              // ]);
              setEditorText("");
              // bodyRef.current.innerHTML = "";
              setSubmitLoading(false);
              // setInputText("");
              //for pushing msg =user to top
              let tempArr1 = [...unreadInteractionList];
              let newArr = [
                ...tempArr1.filter(
                  (o) => o.username === selectedFriend.username
                ),
              ];
              tempArr1 = tempArr1.filter(
                (obj) => obj.username !== selectedFriend.username
              );
              newArr = [...newArr, ...tempArr1];
              setUnreadInteractionList([...newArr]);
              console.log(newArr);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
    setSelectedReplyMessage(null);
  };

  const handleSubmitGroupChat = (e) => {
    bodyRef.current.focus();

    console.log(memberUsernames, "hjkvckwjek");
    // e.preventDefault();
    console.log("inside submit group chat", selectedGroup, editorText);
    let obj = urlParser.parse(editorText);
    // console.log("url parsed", obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorText.length > 0) {
          let tempArr = [...groupReadUnreadList];
          const objIndex = tempArr.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          if (tempArr[objIndex] !== undefined) {
            tempArr[objIndex].last_messaged_user = userObj.username;
            console.log(tempArr);
            setgroupReadUnreadList(tempArr);
          }

          let notification_data = {
            sender: userObj.username,
            receiver: selectedGroup.group_name,
            group: true,
            group_type: "group",
          };

          usersocket.emit("new_message", app_id, notification_data);

          setmessagearray([
            ...messagearray,
            {
              message: editorText,
              type: "video",
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              timestamp: Date.now(),
              msgtype: "group",
            },
          ]);
          setEditorText("");

          socket.emit(
            "new_send_group_message",
            {
              message: editorText,
              type: "video",
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              timestamp: Date.now(),
              msgtype: "group",
            },
            memberUsernames,
            app_id,
            (response) => {
              console.log(response, "kjbwekjfwefh");
              if (response.status) {
                // setEditorText("");
                setmessagearray([...messagearray, response.payload]);
                updateStorage(response.payload);
                setGoToBottom(true);
                // setInputText("");
                // bodyRef.current.innerHTML = "";
                setSubmitLoading(false);
                const foundd = favList.find(
                  (o) => o.thread_id === selectedGroup.thread_id
                );

                if (foundd === undefined) {
                  let tempArr1 = [...groupReadUnreadList];

                  const objIndex = tempArr1.findIndex(
                    (obj) => obj.group_name === selectedGroup.group_name
                  );
                  tempArr1[objIndex].sender = userObj.username;

                  let newArr = [
                    ...tempArr1.filter(
                      (o) => o.group_name === selectedGroup.group_name
                    ),
                  ];
                  tempArr1 = tempArr1.filter(
                    (obj) => obj.group_name !== selectedGroup.group_name
                  );
                  newArr = [...newArr, ...tempArr1];
                  console.log(newArr, "jkgwegwe");

                  setgroupReadUnreadList([...newArr]);
                }
              } else {
                setSubmitLoading(false);
                message.error(response.payload);
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorText.length > 0) {
        console.log(groupReadUnreadList, selectedGroup, "kjshgfkwebfkwjebf");
        let tempArr = [...groupReadUnreadList];
        const objIndex = tempArr.findIndex(
          (obj) => obj.group_name === selectedGroup.group_name
        );
        if (tempArr[objIndex] !== undefined) {
          tempArr[objIndex].last_messaged_user = userObj.username;
          console.log(tempArr);
          setgroupReadUnreadList(tempArr);
        }

        let notification_data = {
          sender: userObj.username,
          receiver: selectedGroup.group_name,
          group: true,
          group_type: "group",
        };

        usersocket.emit("new_message", app_id, notification_data);

        setmessagearray([
          ...messagearray,
          {
            message: editorText,
            thread_id: selectedGroup.thread_id,
            sender: userObj,
            timestamp: Date.now(),
            msgtype: "group",
          },
        ]);
        setEditorText("");

        socket.emit(
          "new_send_group_message",
          {
            message: editorText,
            thread_id: selectedGroup.thread_id,
            sender: userObj,
            timestamp: Date.now(),
            msgtype: "group",
          },
          memberUsernames,
          app_id,
          (response) => {
            // console.log(response, "jhkjfwekfjwekjf");
            if (response.status) {
              // setEditorText("");
              console.log(response, "jhkjfwekfjwekjf");
              setmessagearray([...messagearray, response.payload]);
              updateStorage(response.payload);
              setGoToBottom(true);
              // setInputText("");
              bodyRef.current.focus();
              // document.getElementById("focushere").focus();
              // bodyRef.current.innerHTML = "";
              setSubmitLoading(false);

              const foundd = favList.find(
                (o) => o.thread_id === selectedGroup.thread_id
              );

              if (foundd === undefined) {
                let tempArr1 = [...groupReadUnreadList];

                const objIndex = tempArr1.findIndex(
                  (obj) => obj.group_name === selectedGroup.group_name
                );
                if (tempArr1[objIndex]) {
                  tempArr1[objIndex].sender = userObj.username;

                  let newArr = [
                    ...tempArr1.filter(
                      (o) => o.group_name === selectedGroup.group_name
                    ),
                  ];
                  tempArr1 = tempArr1.filter(
                    (obj) => obj.group_name !== selectedGroup.group_name
                  );
                  newArr = [...newArr, ...tempArr1];
                  console.log(newArr, "jkgwegwe");

                  setgroupReadUnreadList([...newArr]);
                }
              }
            } else {
              setSubmitLoading(false);
              message.error(response.payload);
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
  };

  // const handleSubmitGroupChatDemo = () => {
  //   socket.emit(
  //     "new_group_message",
  //     app_id,
  //     {
  //       message: JSON.stringify(
  //         `${userObj.username} added ${item.username} to the group`
  //       ),
  //       thread_id: selectedGroup.thread_id,
  //       sender: userObj,
  //       timestamp: Date.now(),
  //     },
  //     (response) => {
  //       if (response == "success") {
  //         console.log(response);
  //         setmessagearray([
  //           ...messagearray,
  //           {
  //             message: JSON.stringify(
  //               `${userObj.username} added ${item.username} to the group`
  //             ),
  //             threadId: selectedGroup.thread_id,
  //             sender: currentUserObj,
  //             timestamp: Date.now(),
  //           },
  //         ]);
  //         // setInputText("");
  //         bodyRef.current.innerHTML = "";

  //         let tempArr1 = [...groupReadUnreadList];

  //         const objIndex = tempArr1.findIndex(
  //           (obj) => obj.group_name === selectedGroup.group_name
  //         );
  //         tempArr1[objIndex].sender = currentUserObj.username;

  //         let newArr = [
  //           ...tempArr1.filter(
  //             (o) => o.group_name === selectedGroup.group_name
  //           ),
  //         ];
  //         tempArr1 = tempArr1.filter(
  //           (obj) => obj.group_name !== selectedGroup.group_name
  //         );
  //         newArr = [...newArr, ...tempArr1];
  //         console.log(newArr);
  //         setgroupReadUnreadList([...newArr]);
  //       } else {
  //         console.log(response);
  //       }
  //     }
  //   );
  // };

  // const handleSubmitReplyGroupChat = () => {
  //   console.log("what the hell is happening");
  // };

  const handleSubmitReplyGroupChat = (e) => {
    // e.preventDefault();

    let obj = urlParser.parse(editorText);
    console.log(obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorText.length > 0) {
          let tempArr = [...groupReadUnreadList];
          const objIndex = tempArr.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          tempArr[objIndex].last_messaged_user = userObj.username;
          console.log(tempArr);
          setgroupReadUnreadList([...tempArr]);

          let notification_data = {
            sender: userObj.username,
            receiver: selectedGroup.group_name,
            group: true,
            group_type: "group",
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "new_group_reply_message",
            {
              message: editorText,
              type: "video",
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              timestamp: Date.now(),
            },
            selectedReplyMessage,
            app_id,
            (response) => {
              if (response.status) {
                console.log(response);
                setmessagearray([...messagearray, response.payload]);
                updateStorage(response.payload);
                setGoToBottom(true);
                setEditorText("");
                // bodyRef.current.innerHTML = "";
                setSubmitLoading(false);

                let tempArr1 = [...groupReadUnreadList];

                const objIndex = tempArr1.findIndex(
                  (obj) => obj.group_name === selectedGroup.group_name
                );
                tempArr1[objIndex].sender = userObj.username;

                let newArr = [
                  ...tempArr1.filter(
                    (o) => o.group_name === selectedGroup.group_name
                  ),
                ];
                tempArr1 = tempArr1.filter(
                  (obj) => obj.group_name !== selectedGroup.group_name
                );
                newArr = [...newArr, ...tempArr1];
                console.log(newArr);
                setgroupReadUnreadList([...newArr]);
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorText.length > 0) {
        let tempArr = [...groupReadUnreadList];
        const objIndex = tempArr.findIndex(
          (obj) => obj.group_name === selectedGroup.group_name
        );
        if (tempArr[objIndex] !== undefined) {
          tempArr[objIndex].last_messaged_user = userObj.username;
          console.log(tempArr);
          setgroupReadUnreadList(tempArr);
        }

        let notification_data = {
          sender: userObj.username,
          receiver: selectedGroup.group_name,
          group: true,
          group_type: "group",
        };

        usersocket.emit("new_message", app_id, notification_data);

        socket.emit(
          "new_group_reply_message",
          {
            message: editorText,
            thread_id: selectedGroup.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          selectedReplyMessage,
          app_id,
          (response) => {
            if (response.status) {
              console.log(response);
              setmessagearray([...messagearray, response.payload]);
              updateStorage(response.payload);
              setGoToBottom(true);
              // setmessagearray([
              //   ...messagearray,
              //   {
              //     message: JSON.stringify(bodyRef.current.innerHTML),

              //     replied_msg: true,
              //     replied_to_data: {
              //       message: selectedReplyMessage.message,
              //       threadId: selectedReplyMessage.thread_id,
              //       sender: selectedReplyMessage.sender,
              //       timestamp: selectedReplyMessage.timestamp,
              //       type: selectedReplyMessage.type
              //         ? selectedReplyMessage.type
              //         : null,
              //       location: selectedReplyMessage.location
              //         ? selectedReplyMessage.location
              //         : null,
              //     },
              //     threadId: selectedGroup.thread_id,
              //     sender: userObj,
              //     timestamp: Date.now(),
              //   },
              // ]);
              // setInputText("");
              setEditorText("");
              // bodyRef.current.innerHTML = "";
              setSubmitLoading(false);

              let tempArr1 = [...groupReadUnreadList];

              const objIndex = tempArr1.findIndex(
                (obj) => obj.group_name === selectedGroup.group_name
              );
              if (tempArr1[objIndex] !== undefined) {
                tempArr1[objIndex].sender = userObj.username;

                let newArr = [
                  ...tempArr1.filter(
                    (o) => o.group_name === selectedGroup.group_name
                  ),
                ];
                tempArr1 = tempArr1.filter(
                  (obj) => obj.group_name !== selectedGroup.group_name
                );
                newArr = [...newArr, ...tempArr1];
                console.log(newArr);
                setgroupReadUnreadList([...newArr]);
              }
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
    setSelectedReplyMessage(null);
  };

  const handleSubmitSupportChat = (e) => {
    console.log(
      selectedSupport.group_name.substring(
        11,
        selectedSupport.group_name.length
      ),
      "jhwjhwjedwde"
    );
    console.log("Inside handle sumit support", editorText);
    let obj = urlParser.parse(editorText);
    // console.log("url parsed", obj);
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorText.length > 0) {
          let notification_data = {
            sender: userObj.username,
            receiver: selectedSupport.group_name,
            group: true,
            group_type: "group",
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "new_support_group_message_v2",

            {
              message: editorText,
              type: "video",
              thread_id: selectedSupport.thread_id,
              sender: userObj.username,
              timestamp: Date.now(),
            },
            selectedSupport.group_name.substring(
              11,
              selectedSupport.group_name.length
            ) === userObj.username
              ? "support"
              : selectedSupport.group_name.substring(
                  11,
                  selectedSupport.group_name.length
                ),
            app_id,
            (response) => {
              if (response == "success") {
                console.log(response);
                setmessagearray([
                  ...messagearray,
                  {
                    message: editorText,
                    type: "video",
                    threadId: selectedSupport.thread_id,
                    sender: userObj.username,
                    timestamp: Date.now(),
                  },
                ]);
                // setInputText("");
                setEditorText("");
                setSubmitLoading(false);
              } else {
                console.log(response);
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      if (editorText.length > 0) {
        let notification_data = {
          sender: userObj.username,
          receiver: selectedSupport.group_name,
          group: true,
        };

        usersocket.emit("new_group_message", app_id, notification_data);

        socket.emit(
          "new_support_group_message_v2",
          {
            message: editorText,
            thread_id: selectedSupport.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          selectedSupport.group_name.substring(
            11,
            selectedSupport.group_name.length
          ) === userObj.username
            ? "support"
            : selectedSupport.group_name.substring(
                11,
                selectedSupport.group_name.length
              ),
          app_id,
          (response) => {
            console.log(response, "Inside handle sumit support1");
            if (response == "success") {
              // getSupportList();
              console.log(response, "Inside handle sumit support2");
              setmessagearray([
                ...messagearray,
                {
                  message: editorText,
                  threadId: selectedSupport.thread_id,
                  sender: userObj,
                  timestamp: Date.now(),
                },
              ]);
              // setInputText("");
              setEditorText("");
              // bodyRef.current.innerHTML = "";
              setSubmitLoading(false);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
  };

  const handleSubmitReplySupportChat = (e) => {
    // e.preventDefault();

    let obj = urlParser.parse(editorText);
    console.log(obj, selectedReplyMessage, app_id, "kjhkdjfwbefkwjhefkwe");
    if (obj !== undefined) {
      if (obj.mediaType === "video") {
        if (editorText.length > 0) {
          let notification_data = {
            sender: userObj,
            receiver: selectedSupport.group_name,
          };

          usersocket.emit("new_message", app_id, notification_data);

          socket.emit(
            "reply_to_support_message_v2",

            {
              message: editorText,
              type: "video",
              thread_id: selectedSupport.thread_id,
              sender: userObj,
              timestamp: Date.now(),
            },
            selectedReplyMessage,
            selectedSupport.group_name.substring(
              11,
              selectedSupport.group_name.length
            ) === userObj.username
              ? "support"
              : selectedSupport.group_name.substring(
                  11,
                  selectedSupport.group_name.length
                ),
            app_id,
            (response) => {
              if (response.status) {
                getSupportList();
                console.log(response);
                setmessagearray([
                  ...messagearray,

                  {
                    message: editorText,
                    type: "video",
                    replied_msg: true,
                    replied_to_data: {
                      message: selectedReplyMessage.message,
                      threadId: selectedReplyMessage.threadId,
                      sender: selectedReplyMessage.sender,
                      timestamp: selectedReplyMessage.timestamp,
                      type: selectedReplyMessage.type
                        ? selectedReplyMessage.type
                        : null,
                      location: selectedReplyMessage.location
                        ? selectedReplyMessage.location
                        : null,
                    },
                    threadId: selectedSupport.thread_id,
                    sender: userObj,
                    timestamp: Date.now(),
                  },
                ]);
                // setInputText("");
                setEditorText("");
                // bodyRef.current.innerHTML = "";
                setSubmitLoading(false);
                //for pushing msg =user to top
              } else {
                console.log(response, "jkajkambjwhe");
              }
            }
          );
        } else {
          message.destroy();
          message.info("Please type something!", 2);
        }
      }
    } else {
      console.log("please reply", selectedReplyMessage);
      if (editorText.length > 0) {
        let notification_data = {
          sender: userObj,
          receiver: selectedSupport.group_name,
        };

        usersocket.emit("new_message", app_id, notification_data);
        let tempArr = [...supportgroupReadUnreadList];

        // tempArr = tempArr.filter(function (item) {
        //   return item !== selectedSupport; //Doubt
        // });
        // setsupportgroupReadUnreadList([...tempArr]);
        socket.emit(
          "reply_to_support_message_v2",

          {
            message: editorText,
            thread_id: selectedSupport.thread_id,
            sender: userObj,
            timestamp: Date.now(),
          },
          selectedReplyMessage,
          selectedSupport.group_name.substring(
            11,
            selectedSupport.group_name.length
          ) === userObj.username
            ? "support"
            : selectedSupport.group_name.substring(
                11,
                selectedSupport.group_name.length
              ),
          app_id,
          (response) => {
            console.log("please reply", response);
            if (response.status) {
              setmessagearray([
                ...messagearray,
                {
                  message: editorText,
                  replied_msg: true,
                  replied_to_data: {
                    message: selectedReplyMessage.message,
                    threadId: selectedReplyMessage.threadId,
                    sender: selectedReplyMessage.sender,
                    type: selectedReplyMessage.type
                      ? selectedReplyMessage.type
                      : null,
                    location: selectedReplyMessage.location
                      ? selectedReplyMessage.location
                      : null,
                    timestamp: selectedReplyMessage.timestamp,
                  },
                  threadId: selectedSupport.thread_id,
                  sender: userObj,
                  timestamp: Date.now(),
                },
              ]);
              // setInputText("");
              setEditorText("");
              // bodyRef.current.innerHTML = "";
              setSubmitLoading(false);
              //for pushing msg =user to top
              // console.log(supportgroupReadUnreadList, "ihiueyriuwy");
              // let tempArr1 = [...supportgroupReadUnreadList];
              // let newArr = [
              //   ...tempArr1.filter(
              //     (o) => o.username === selectedSupport.group_name
              //   ),
              // ];
              // tempArr1 = tempArr1.filter(
              //   (obj) => obj.username !== selectedSupport.group_name
              // );
              // newArr = [...newArr, ...tempArr1];
              // setsupportgroupReadUnreadList([...newArr]);
              // console.log(newArr);
            } else {
              console.log(response);
            }
          }
        );
      } else {
        message.destroy();
        message.info("Please type something!", 2);
      }
    }
    setSelectedReplyMessage(null);
  };

  const submitPrivateChatUpdate = (e) => {
    if (editorText.length > 0) {
      let notification_data = {
        sender: userObj.username,
        receiver: selectedFriend.username,
      };

      usersocket.emit("new_message", app_id, notification_data);
      let tempArr = [...userReadUnreadList];
      tempArr = tempArr.filter(function (item) {
        return item !== selectedFriend.username;
      });
      setuserReadUnreadList([...tempArr]);
      socket.emit(
        "edit_direct_message",
        selectedEditMessage._id,
        editorText,
        app_id,
        (response) => {
          if (response) {
            console.log(response, "sfsrf");
            var tempArr = messagearray;
            var foundIndex = tempArr.findIndex(
              (x) => x._id == selectedEditMessage._id
            );
            tempArr[foundIndex].message = editorText;
            messagearray[foundIndex].message = editorText;

            let tempStorage = JSON.parse(localStorage.getItem("storedChat"));
            if (tempStorage) {
              var foundStorageIndex = tempStorage.findIndex(
                (x) => x.chatObj.username == selectedFriend.username
              );

              tempStorage[foundStorageIndex].messages[foundIndex].message =
                editorText;
              localStorage.setItem(
                "storedChat",
                JSON.stringify([...tempStorage])
              );
            }

            //  updateStorage(response.payload);
            // setmessagearray([...tempArr]);

            // setInputText("");
            setEditorText("");
            // bodyRef.current.innerHTML = "";
            setSubmitLoading(false);
            //for pushing msg =user to top
            // let tempArr1 = [...unreadInteractionList];
            // let newArr = [
            //   ...tempArr1.filter((o) => o.username === selectedFriend.username),
            // ];
            // tempArr1 = tempArr1.filter(
            //   (obj) => obj.username !== selectedFriend.username
            // );
            // newArr = [...newArr, ...tempArr1];
            // setUnreadInteractionList([...newArr]);
            // console.log(newArr);
            setSelectedEditMessage(null);
          } else {
            console.log(response);
          }
        }
      );
    } else {
      message.destroy();
      message.info("Please type something!", 2);
    }
  };

  const submitGroupChatUpdate = (e) => {
    // e.preventDefault();
    console.log("inside submit group chat", selectedGroup);
    let obj = urlParser.parse(editorText);
    // console.log("url parsed", obj);

    if (editorText.length > 0) {
      let tempArr = [...groupReadUnreadList];
      const objIndex = tempArr.findIndex(
        (obj) => obj.group_name === selectedGroup.group_name
      );
      if (tempArr[objIndex] !== undefined) {
        tempArr[objIndex].last_messaged_user = userObj.username;
        console.log(tempArr);
        setgroupReadUnreadList(tempArr);
      }

      let notification_data = {
        sender: userObj.username,
        receiver: selectedGroup.group_name,
        group: true,
        group_type: "group",
      };

      usersocket.emit("new_message", app_id, notification_data);

      socket.emit(
        "edit_group_message",
        selectedEditMessage._id,
        editorText,
        app_id,
        (response) => {
          // console.log(response, "jhkjfwekfjwekjf");
          if (response.status) {
            // console.log(response, "jhkjfwekfjwekjf");
            console.log(response, "sfsrf");
            var tempArr = messagearray;
            var foundIndex = tempArr.findIndex(
              (x) => x._id == selectedEditMessage._id
            );
            tempArr[foundIndex].message = editorText;
            messagearray[foundIndex].message = editorText;

            let tempStorage = JSON.parse(localStorage.getItem("storedChat"));
            if (tempStorage) {
              var foundStorageIndex = tempStorage.findIndex(
                (x) => x.chatObj.thread_id == selectedGroup.thread_id
              );

              var foundmsgIndex = tempStorage[
                foundStorageIndex
              ].messages.findIndex((o) => o._id === selectedEditMessage._id);

              tempStorage[foundStorageIndex].messages[foundmsgIndex].message =
                editorText;
              localStorage.setItem(
                "storedChat",
                JSON.stringify([...tempStorage])
              );
            }
            // setInputText("");
            setEditorText("");
            // bodyRef.current.innerHTML = "";
            setSubmitLoading(false);

            // let tempArr1 = [...groupReadUnreadList];

            // const objIndex = tempArr1.findIndex(
            //   (obj) => obj.group_name === selectedGroup.group_name
            // );
            // tempArr1[objIndex].sender = userObj.username;

            // let newArr = [
            //   ...tempArr1.filter(
            //     (o) => o.group_name === selectedGroup.group_name
            //   ),
            // ];
            // tempArr1 = tempArr1.filter(
            //   (obj) => obj.group_name !== selectedGroup.group_name
            // );
            // newArr = [...newArr, ...tempArr1];
            // console.log(newArr);
            // setgroupReadUnreadList([...newArr]);
            setSelectedEditMessage(null);
          } else {
            console.log(response);
          }
        }
      );
    } else {
      message.destroy();
      message.info("Please type something!", 2);
    }
  };

  const conditionalSubmit = () => {
    console.log(
      "allselected",
      selectedFriend,
      selectedGroup,
      selectedSupport,
      selectedAdminSupport
    );
    if (tokenValid) {
      if (selectedSupport === null) {
        if (selectedFriend !== null) {
          if (selectedReplyMessage !== null) {
            console.log("1-2-1 reply fired");
            handleSubmitReplyPrivateChat();
          } else {
            if (selectedEditMessage) {
              submitPrivateChatUpdate();
            } else {
              handleSubmitPrivateChat();
            }
          }
        } else if (selectedGroup !== null) {
          if (selectedReplyMessage !== null) {
            handleSubmitReplyGroupChat();
          } else {
            if (selectedEditMessage) {
              submitGroupChatUpdate();
            } else {
              handleSubmitGroupChat();
            }
          }
        }
      } else {
        if (selectedReplyMessage !== null) {
          handleSubmitReplySupportChat();
        } else {
          handleSubmitSupportChat();
        }
      }
    }
  };

  const pluButtonList = [
    { icon: <VideoCameraTwoTone />, text: "Upload Video" },
    { icon: <FileImageTwoTone />, text: "Upload Image" },
    { icon: <FileTwoTone />, text: "Upload File" },
  ];

  const pasteImageHandler = (event) => {
    setLoadingImage(false);
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    console.log(JSON.stringify(items)); // might give you mime types
    var item = items[0];
    if (item.kind === "file") {
      var blob = item.getAsFile();
      var reader = new FileReader();
      reader.onload = function (event) {
        setClipImage1(event.target.result);
        setVisiblePasteImage(true);
        setLoadingImage(false);
        console.log(event.target.result, "jhgdjwegjfhwef"); // data url!
      };
      reader.readAsDataURL(blob);
    }
    // for (var index in items) {

    // }
  };

  // const pasteImageHandler1 = (event) => {
  //   setClipImage("");
  //   // setVisiblePasteImage(false);
  //   // use event.originalEvent.clipboard for newer chrome versions
  //   var items = (event.clipboardData || event.originalEvent.clipboardData)
  //     .items;

  //   // find pasted image among pasted items
  //   var blob = null;

  //   for (var i = 0; i < items.length; i++) {
  //     console.log("url obj", items[i]);
  //     if (items[i].type.indexOf("image") === 0) {
  //       blob = items[i].getAsFile();
  //     }
  //   }
  //   // load image if there is a pasted image
  //   if (blob !== null) {
  //     var reader = new FileReader();
  //     reader.onload = function (event) {
  //       console.log(event.target.result, "kjekwe"); // data url!
  //       setClipImage(event.target.result);
  //       // document.getElementById("pastedImage").src = event.target.result;
  //     };
  //     reader.readAsDataURL(blob);
  //     setVisiblePasteImage(true);
  //     setLoadingImage(false);
  //   }
  // };

  const uploadOneToOneImage = async () => {
    setSubmitLoading1(true);
    console.log(clipImage1, "kjqdkqjwbe");
    setVisiblePasteImage(false);
    setmessagearray([
      ...messagearray,
      {
        message: Date.now().toString(),
        thread_id: selectedFriend.thread_id,
        sender: userObj,
        timestamp: Date.now(),
        filename: Date.now().toString(),
        type: "file",
        location: clipImage1,
        msgtype: "onetoone",
      },
    ]);
    const base64Data = new Buffer.from(
      clipImage1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      msgtype: "onetoone",
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", app_id, notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_direct_chat_message",
      {
        ...message_data,
      },
      selectedFriend.username,
      app_id,
      (response) => {
        if (response.status) {
          setSubmitLoading1(false);
          // console.log("sent to DB", response, message_data);
          // setmessagearray([...messagearray, response.payload]);
          updateStorage(response.payload);
          setGoToBottom(true);
          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          console.log(newArr);
        } else {
          console.log(response);
        }
      }
    );
    setSelectedReplyMessage(null);
    // setselectedFile(null);
  };

  const uploadOneToOneReplyImage = async () => {
    // var file = new File([clipImage1], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedFriend.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedFriend.username,
    };

    usersocket.emit("new_message", app_id, notification_data);

    let tempArr = [...userReadUnreadList];
    tempArr = tempArr.filter(function (item) {
      return item !== selectedFriend.username;
    });

    setuserReadUnreadList([...tempArr]);

    socket.emit(
      "new_reply_message",
      {
        ...message_data,
      },
      {
        message: selectedReplyMessage.message,
        threadId: selectedReplyMessage.threadId,
        sender: selectedReplyMessage.sender,
        timestamp: selectedReplyMessage.timestamp,
        type: selectedReplyMessage.type ? selectedReplyMessage.type : null,
        location: selectedReplyMessage.location
          ? selectedReplyMessage.location
          : null,
      },
      app_id,
      (response) => {
        if (response.status) {
          setLoadingImage(false);

          setmessagearray([...messagearray, response.payload]);
          updateStorage(response.payload);
          setGoToBottom(true);
          //for pushing msg =user to top
          let tempArr1 = [...unreadInteractionList];
          let newArr = [
            ...tempArr1.filter((o) => o.username === selectedFriend.username),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.username !== selectedFriend.username
          );
          newArr = [...newArr, ...tempArr1];
          setUnreadInteractionList([...newArr]);
          console.log(newArr);
        } else {
          console.log(response);
        }
      }
    );
    setSelectedReplyMessage(null);
    // setselectedFile(null);
  };

  const uploadGroupImage = async () => {
    // setSubmitLoading(true);
    setSubmitLoading1(true);
    console.log(clipImage1, "kjqdkqjwbe");
    setVisiblePasteImage(false);
    setmessagearray([
      ...messagearray,
      {
        message: Date.now().toString(),
        thread_id: selectedGroup.thread_id,
        sender: userObj,
        timestamp: Date.now(),
        filename: Date.now().toString(),
        type: "file",
        location: clipImage1,
        msgtype: "group",
      },
    ]);
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );

    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      msgtype: "group",
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    console.log(memberUsernames, "hjkvckwjek");
    socket.emit(
      "new_send_group_message",
      message_data,
      memberUsernames,
      app_id,
      (response) => {
        console.log(response, "kwkjc,wbjkwje");

        if (response.status) {
          console.log(response, "kjbwekjfwefh");
          // setmessagearray([...messagearray, response.payload]);
          updateStorage(response.payload);
          setGoToBottom(true);
          // setInputText("");
          // bodyRef.current.innerHTML = "";
          // setEditorText("");
          // setSubmitLoading(false);
          setSubmitLoading1(false);
          let tempArr1 = [...groupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          if (tempArr1[objIndex] !== undefined) {
            tempArr1[objIndex].sender = userObj.username;

            let newArr = [
              ...tempArr1.filter(
                (o) => o.group_name === selectedGroup.group_name
              ),
            ];
            tempArr1 = tempArr1.filter(
              (obj) => obj.group_name !== selectedGroup.group_name
            );
            newArr = [...newArr, ...tempArr1];
            console.log(newArr);
            setgroupReadUnreadList([...newArr]);
            setClipImage1("");
          }
        } else {
          setSubmitLoading(false);
          message.error(response.payload);
          // message.error("Error in sending message");
        }
      }
    );
    setSelectedReplyMessage(null);
  };

  const uploadGroupReplyImage = async () => {
    // var file = new File([clipImage1], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedGroup.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedGroup.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_group_reply_message",
      {
        ...message_data,
      },
      selectedReplyMessage,
      app_id,
      (response) => {
        if (!response.status) {
          message.error("Error in sending message");
        } else {
          setLoadingImage(false);
          setmessagearray([...messagearray, response.payload]);
          updateStorage(response.payload);
          setGoToBottom(true);
          let tempArr1 = [...groupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedGroup.group_name
          );
          if (tempArr1[objIndex]) {
            tempArr1[objIndex].sender = userObj.username;

            let newArr = [
              ...tempArr1.filter(
                (o) => o.group_name === selectedGroup.group_name
              ),
            ];
            tempArr1 = tempArr1.filter(
              (obj) => obj.group_name !== selectedGroup.group_name
            );
            newArr = [...newArr, ...tempArr1];
            console.log(newArr);
            setgroupReadUnreadList([...newArr]);
          }
        }
      }
    );
    setSelectedReplyMessage(null);
  };

  const uploadSupportGroupImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      // replied_msg: false,
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedSupport.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", notification_data);

    socket.emit(
      "new_support_group_message_v2",
      message_data,
      selectedSupport.group_name.substring(
        11,
        selectedSupport.group_name.length
      ) === userObj.username
        ? "support"
        : selectedSupport.group_name.substring(
            11,
            selectedSupport.group_name.length
          ),
      app_id,
      (response) => {
        if (response !== "success") {
          message.error("Error in sending message");
        } else {
          setLoadingImage(false);
          setmessagearray([...messagearray, { ...message_data }]);
          // setInputText("");

          let tempArr1 = [...supportgroupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedSupport.group_name
          );
          tempArr1[objIndex].sender.username = userObj.username;

          let newArr = [
            ...tempArr1.filter(
              (o) => o.group_name === selectedSupport.group_name
            ),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.group_name !== selectedSupport.group_name
          );
          newArr = [...newArr, ...tempArr1];
          console.log(newArr);
          // setsupportgroupReadUnreadList([...newArr]);
        }
      }
    );
  };

  const uploadSupportGroupReplyImage = async () => {
    // var file = new File([clipImage], Date.now().toString().png);
    const base64Data = new Buffer.from(
      clipImage1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const file = new File([base64Data], "noname", { type: "image/png" });
    console.log("myfile", base64Data);

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data);
      message.destroy();
      message.success("File Upload Success", 2);
      setVisiblePasteImage(false);
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }

    let message_data = {
      message: Date.now().toString(),
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
    };

    const messageWithReply = {
      message: Date.now().toString(),
      thread_id: selectedSupport.thread_id,
      sender: userObj,
      timestamp: Date.now(),
      filename: Date.now().toString(),
      type: "file",
      location: uploaded_data.location,
      replied_msg: true,
      replied_to_data: {
        message: selectedReplyMessage.message,
        threadId: selectedReplyMessage.threadId,
        sender: selectedReplyMessage.sender,
        timestamp: selectedReplyMessage.timestamp,
        type: selectedReplyMessage.type ? selectedReplyMessage.type : null,
        location: selectedReplyMessage.location
          ? selectedReplyMessage.location
          : null,
      },
    };

    let notification_data = {
      sender: userObj.username,
      receiver: selectedSupport.group_name,
      group: true,
      group_type: "group",
    };

    usersocket.emit("new_message", app_id, notification_data);

    socket.emit(
      "new_support_group_message_v2",
      messageWithReply,
      selectedSupport.group_name.substring(
        11,
        selectedSupport.group_name.length
      ) === userObj.username
        ? "support"
        : selectedSupport.group_name.substring(
            11,
            selectedSupport.group_name.length
          ),
      app_id,
      (response) => {
        if (response !== "success") {
          message.error("Error in sending message");
        } else {
          setLoadingImage(false);
          setmessagearray([...messagearray, { ...messageWithReply }]);
          // setInputText("");

          let tempArr1 = [...supportgroupReadUnreadList];

          const objIndex = tempArr1.findIndex(
            (obj) => obj.group_name === selectedSupport.group_name
          );
          tempArr1[objIndex].sender.username = userObj.username;

          let newArr = [
            ...tempArr1.filter(
              (o) => o.group_name === selectedSupport.group_name
            ),
          ];
          tempArr1 = tempArr1.filter(
            (obj) => obj.group_name !== selectedSupport.group_name
          );
          newArr = [...newArr, ...tempArr1];
          console.log(newArr);
          setsupportgroupReadUnreadList([...newArr]);
        }
      }
    );
    setSelectedReplyMessage(null);
  };

  const conditionalImageUpload = () => {
    if (tokenValid) {
      setLoadingImage(true);
      if (selectedSupport === null) {
        if (selectedFriend !== null) {
          if (selectedReplyMessage) {
            uploadOneToOneReplyImage();
          } else {
            uploadOneToOneImage();
          }
        } else if (selectedGroup !== null) {
          console.log(selectedGroup, "kwkjc,wbjkwje");
          if (selectedReplyMessage) {
            console.log(selectedGroup, "kwkjc,wbjkwjegroupreply");
            uploadGroupReplyImage();
          } else {
            console.log(selectedGroup, "kwkjc,wbjkwjegroup");
            uploadGroupImage();
          }
        }
      } else {
        if (selectedReplyMessage) {
          console.log(selectedGroup, "kwkjc,wbjkwjesupportreply");
          uploadSupportGroupReplyImage();
        } else {
          console.log(selectedGroup, "kwkjc,wbjkwjesupport");
          uploadSupportGroupImage();
        }
      }
    }
  };

  const handleShiftEnter = (e) => {
    // console.log(e.keyCode, e.key, e.which, "kjwkfwef");
    var x = document.getElementById("demo");
    if (e.key == "Enter" && e.shiftKey) {
      // x.innerHTML = "SHIFT + ENTER key was pressed!";
      //   // alert("add new div");
    } else if (!submitLoading && e.key == "Enter") {
      console.log("got into submit");
      setSubmitLoading(true);
      e.preventDefault();
      conditionalSubmit();
      // setEditorText("");
    } else if (e.keyCode === 27 || e.key === "Escape" || e.key === "Esc") {
      e.preventDefault();
      console.log("Esc pressed");
      setSelectedEditMessage(null);
      setEditorText("");
    }
  };

  useEffect(() => {
    const handleEnter = (event) => {
      if (visiblePasteImage === true) {
        if (event.keyCode === 13) {
          event.preventDefault();

          conditionalImageUpload();
        }
      }
    };
    window.addEventListener("keydown", handleEnter);

    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [visiblePasteImage, clipImage1]);

  // useEffect(() => {
  //   console.log(visiblePasteImage, hiddenRef.current, "kjekwe");
  //   if (visiblePasteImage) {
  //     hiddenRef.current.focus();
  //   }
  // }, [visiblePasteImage]);

  const addEmoji = (e) => {
    //console.log(e.native)
    let emoji = e.native;
    setEditorText(editorText + " " + emoji);
    // bodyRef.current.innerHTML = bodyRef.current.innerHTML + " " + emoji;
    setemojiShow(false);
    // setInputText(inputText + " " + emoji);
  };

  return (
    <>
      <div
        style={{
          // paddingTop: 30,
          position: "fixed",
          bottom: 0,

          width:
            !hideSidebar && !visibleUserInfo
              ? "75%"
              : hideSidebar && !visibleUserInfo
              ? "96%"
              : !hideSidebar && visibleUserInfo
              ? "59%"
              : "79%",
        }}
        // className={chatBoxArr.length > 0 ? "myeditor-tab" : "myeditor"}
      >
        {selectedReplyMessage !== null ? (
          <div
            className="shadow"
            style={{
              padding: "10px",
              margin: "10px",

              background: globalColors.darkMode ? "#2B2B2B" : "#EEEEEE",

              color: globalColors.darkMode ? "lightgray" : "black",

              borderRadius: "10px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingBottom: "5px" }}>
                {selectedReplyMessage.sender.first_name}
              </div>
              <CloseOutlined
                style={{ cursor: "pointer" }}
                onClick={(e) => setSelectedReplyMessage(null)}
              />
            </div>
            <div>{renderMessage(selectedReplyMessage)}</div>
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            // background:
            //   localStorage.getItem("mode") === "light"
            //     ? "#EDEDED"
            //     : localStorage.getItem("mode") === "dark"
            //     ? "#121212"
            //     : "",

            // background: "#EDEDED",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
              paddingRight: "10px",
              color: "#8D8D8D",
            }}
          >
            <Popover
              placement="topLeft"
              // title="Emoji"
              content={
                <Picker onSelect={addEmoji} skin={2} title="Teamforce" />
              }
              trigger="click"
            >
              <SmileOutlined
                style={{
                  fontSize: "18px",
                }}
                onClick={(e) => setemojiShow(!emojiShow)}
              />
            </Popover>
          </div>

          {/* {typingFlag ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "#EEEEEE",
                padding: "5px 25px",
                // color: "white",
                borderRadius: "20px",
                textAlign: "justify",
                color: "gray",
              }}
            >
              {typingUser}&nbsp;is typing&nbsp;&nbsp;&nbsp;
              <div class="ticontainer">
                <div class="tiblock">
                  <div class="tidot"></div>
                  <div class="tidot"></div>
                  <div class="tidot"></div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}

          <Mentions
            ref={bodyRef}
            id="focushere"
            autoFocus
            // onBlur={(e) => {
            //   setTimeout(() => {
            //     bodyRef.current.focus();
            //     console.log(document.activeElement, "kjwbkjwbekfbewf");
            //   }, 1000);
            // }}
            // disabled={submitLoading ? true : false}
            autoSize={{ minRows: 1, maxRows: 6 }}
            onKeyPress={(e) => {
              if (!submitLoading) {
                handleShiftEnter(e);
              } else {
                return null;
              }
            }}
            style={{
              background: globalColors.editorBackground,
              borderColor: globalColors.editorBorder,
              color: globalColors.chatText,
            }}
            // className={globalColors.darkMode ? "editor-style" : ""}
            className="editor-style"
            // style={{
            //   backgroundColor: "transparent !important",
            //   background: "transparent !important",
            //   // background: submitLoading ? "lightgray" : "red",
            // }}
            // bodyStyle={{
            //   background: globalColors.editorBackground,
            // }}
            onPaste={pasteImageHandler}
            value={editorText}
            onChange={(value) => {
              setEditorText(value);

              socket.emit(
                "typing",
                // app_id,
                userObj.first_name,
                selectedType.thread_id,
                (res) => {
                  console.log(res, "typing in Editor");
                }
              );
            }}
            // onSelect={onSelect}
            // defaultValue="Type Something..."
            placeholder="Type Something..."
          >
            {selectedGroup
              ? groupMembers.map((item) => {
                  if (item.username !== userObj.username) {
                    return (
                      <Option value={item.first_name}>
                        <Avatar
                          src={item.avatar}
                          size="small"
                          style={{
                            color: "white",
                            backgroundColor: "white",
                            cursor: "pointer",
                            border: "solid 1px gray",
                          }}
                        />
                        &nbsp;&nbsp;{item.first_name}
                      </Option>
                    );
                  }
                })
              : allUserList.map((item) => {
                  return (
                    <Option value={item.first_name}>
                      <Avatar
                        src={item.avatar}
                        size="small"
                        style={{
                          color: "white",
                          backgroundColor: "white",
                          cursor: "pointer",
                          border: "solid 1px gray",
                        }}
                      />
                      &nbsp;&nbsp;{item.first_name}
                    </Option>
                  );
                })}
            <Option value="all">all</Option>
          </Mentions>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
              paddingRight: "5px",
              color: "#8D8D8D",
            }}
            className="popover-upload"
          >
            <input
              type="file"
              id="file"
              accept="video/*"
              ref={uploadVideoRef}
              style={{ display: "none" }}
              onChange={(e) =>
                handleUploadFile(uploadVideoRef.current.files[0])
              }
            />
            <input
              type="file"
              id="file"
              accept="image/*"
              ref={uploadImageRef}
              style={{ display: "none" }}
              onChange={(e) =>
                handleUploadFile(uploadImageRef.current.files[0])
              }
            />
            <input
              type="file"
              id="file"
              // accept="image/png, image/gif, image/jpeg"
              ref={uploadFileRef}
              style={{ display: "none" }}
              onChange={(e) => handleUploadFile(uploadFileRef.current.files[0])}
            />
            {selectedEditMessage ? (
              <Tooltip title="Get out of Edit Mode">
                <CloseCircleFilled
                  onClick={(e) => {
                    setSelectedEditMessage(null);
                    setEditorText("");
                  }}
                  style={{
                    paddingRight: "15px",
                    // fontWeight: "bold",
                    fontSize: "18px",
                    opacity: 0.8,
                  }}
                />
              </Tooltip>
            ) : (
              <Popover
                placement="topRight"
                title={null}
                content={
                  <List
                    style={{ width: "200px" }}
                    size="small"
                    header={null}
                    footer={null}
                    bordered
                    dataSource={pluButtonList}
                    // renderItem={(item) => <List.Item>{item}</List.Item>}
                    renderItem={(item, index) => (
                      <List.Item
                        onClick={(e) => {
                          if (index === 0) {
                            uploadVideoRef.current.click();
                          } else if (index === 1) {
                            uploadImageRef.current.click();
                          } else if (index === 2) {
                            uploadFileRef.current.click();
                          }
                        }}
                        // actions={[
                        //   <a key="list-loadmore-edit">edit</a>,
                        //   <a key="list-loadmore-more">more</a>,
                        // ]}
                      >
                        <List.Item.Meta
                          avatar={item.icon}
                          title={<div>{item.text}</div>}
                          // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                        />
                        {/* <div>content</div> */}
                      </List.Item>
                    )}
                  />
                }
                trigger="click"
              >
                <PlusCircleFilled
                  style={{
                    paddingRight: "15px",
                    // fontWeight: "bold",
                    fontSize: "18px",
                    opacity: 0.8,
                  }}
                />
              </Popover>
            )}
            <img
              style={{ cursor: "pointer" }}
              src={require("../../images/send.svg")}
              alt=""
              width="20px"
              onClick={conditionalSubmit}
            />
            {/* {submitLoading ? (
              <Spin />
            ) : (
              <img
                style={{ cursor: "pointer" }}
                src={require("../../images/send.svg")}
                alt=""
                width="20px"
                onClick={conditionalSubmit}
              />
            )} */}
          </div>
        </div>
      </div>

      {/* On Paste Image this appears */}
      <Modal
        destroyOnClose
        // ref={hiddenRef}
        // autoFocusButton="ok"
        centered
        width="auto"
        // height="600px !important"
        bodyStyle={{ padding: "0px" }}
        title="Upload Image?"
        visible={visiblePasteImage}
        // onOk={conditionalImageUpload}
        // onCancel={(e) => setVisiblePasteImage(false)}
        footer={[
          <Button type="danger" onClick={(e) => setVisiblePasteImage(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={conditionalImageUpload}>
            Submit
          </Button>,
        ]}
      >
        {loadingImage ? (
          <div
            className="img-responsive"
            style={{
              backgroundColor: "gray",
              opacity: 0.5,
            }}
          >
            <img
              src={clipImage1}
              alt=""
              width="auto"
              style={{ maxHeight: "70vh" }}
            />
          </div>
        ) : (
          <div className="img-responsive" style={{ maxHeight: "70vh" }}>
            <img
              src={clipImage1}
              alt=""
              width="auto"
              style={{ maxHeight: "70vh" }}
            />
          </div>
        )}
        {/* <div style={{ padding: "10px" }}>
          <Input
            type="text"
            placeholder="Name of the Image ??"
            value={imageName}
            onChange={e => setImageName(e.target.value)}
          />
        </div> */}
      </Modal>
    </>
  );
};

export default Editor;

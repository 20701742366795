import React, { useState, useContext } from "react";
import { Button, message, Row, Col, Modal, Input } from "antd";
import { LoadingOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../config";
import { MyContext } from "../Context";
import isEmail from "validator/lib/isEmail";
import Axios from "axios";
import Loading from "../lotties/LoadingAnimation";
import { useHistory } from "react-router";

const Splash = () => {
  const { accountArr, setAccountArr, socket, constants } =
    useContext(MyContext);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible2FA, setVisible2FA] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [resetPasswordStep1, setResetPasswordStep1] = useState(false);
  const [resetPasswordStep2, setResetPasswordStep2] = useState(false);
  const [resetPasswordStep3, setResetPasswordStep3] = useState(false);
  const [resetPasswordStep4, setResetPasswordStep4] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleLogin = () => {
    // e.preventDefault();

    if (isEmail(email) && password !== "") {
      // message.loading("Authenticating...");
      setLoading(true);

      Axios.post(`https://gxauth.apimachine.com/gx/user/login`, {
        email: email,
        password: password,
      }).then((res) => {
        console.log(res.data, "kjbwfkwef");
        if (res.data.status) {
          // console.log(res.data, "loggedin result");

          message.destroy();
          message.success("Login Successfull");
          localStorage.setItem("token", res.data.accessToken);
          localStorage.setItem("refresh_token", res.data.refreshToken);
          localStorage.setItem("device_key", res.data.device_key);
          localStorage.setItem("user_account", email.toLowerCase());
          localStorage.setItem("validationTime", new Date().getTime());
          var idtoken = res.data.idToken;

          localStorage.setItem("token", idtoken);
          if (accountArr.length > 0) {
            var foundIndex = accountArr.findIndex((x) => x.email == email);

            if (foundIndex === -1) {
              localStorage.setItem(
                "accountArr",
                JSON.stringify([
                  ...accountArr,
                  { email: email, token: idtoken },
                ])
              );
              // socket.connect();
            } else {
              localStorage.setItem(
                "accountArr",
                JSON.stringify([{ email: email, token: idtoken }])
              );
            }
          }

          history.push("/");
          window.location.reload();
          // setLoading(false);
        } else {
          if (res.data.mfa) {
            setVisible2FA(true);
            setLoading(false);
          } else if (res.data.message === "Reset User Password!") {
            setResetPasswordStep1(true);
            // alert("now the condition is applied... just need to show UI");
          } else if (res.data.message === "User is not confirmed.") {
            setVisible2FA(true);
            setLoading(false);
          } else {
            localStorage.removeItem("token");
            // console.log(err);
            //   setTimeout(() => {
            //     value.setLogin(false);
            //   }, 2000);
            setLoading(false);
            message.destroy();
            message.error(res.data.message, 2);
          }
        }
      });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const handleLogin1 = () => {
    // e.preventDefault();

    if (isEmail(email) && confirmPassword !== "") {
      // message.loading("Authenticating...");
      setLoading(true);

      Axios.post(`https://gxauth.apimachine.com/gx/user/login`, {
        email: email,
        password: confirmPassword,
      }).then((res) => {
        console.log(res.data, "kjbwfkwef");
        if (res.data.status) {
          // console.log(res.data, "loggedin result");

          message.destroy();
          message.success("Login Successfull");
          localStorage.setItem("token", res.data.accessToken);
          localStorage.setItem("refresh_token", res.data.refreshToken);
          localStorage.setItem("device_key", res.data.device_key);
          localStorage.setItem("user_account", email.toLowerCase());

          var idtoken = res.data.idToken;

          localStorage.setItem("token", idtoken);

          if (accountArr.length > 0) {
            localStorage.setItem(
              "accountArr",
              JSON.stringify([...accountArr, { email: email, token: idtoken }])
            );
            // socket.connect();
          } else {
            localStorage.setItem(
              "accountArr",
              JSON.stringify([{ email: email, token: idtoken }])
            );
          }
          history.push("/");
          window.location.reload();
          // setLoading(false);
        } else {
          if (res.data.mfa) {
            setVisible2FA(true);
            setLoading(false);
          } else if (res.data.message === "Reset User Password!") {
            setResetPasswordStep1(true);
            // alert("now the condition is applied... just need to show UI");
          } else if (res.data.message === "User is not confirmed.") {
            setVisible2FA(true);
            setLoading(false);
          } else {
            localStorage.removeItem("token");
            // console.log(err);
            //   setTimeout(() => {
            //     value.setLogin(false);
            //   }, 2000);
            setLoading(false);
            message.destroy();
            message.error(res.data.message, 2);
          }
        }
      });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const handleSignup = () => { };

  const conditionalLoginSignup = () => {
    if (!showLogin && !showSignup) {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../images/chats-icon-white.svg")}
            alt=""
            width="80"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
            }}
          >
            <Button
              onClick={(e) => setShowLogin(true)}
              style={{
                width: "200px",
                height: "40px",
                border: "2px solid #186AB4",
                borderRadius: "0",
                fontSize: "17px",
                color: "#166AB3",
                fontWeight: "bold",
              }}
            >
              Login
            </Button>
            <br />
            <Button
              onClick={(e) => setShowSignup(true)}
              type="primary"
              style={{
                width: "200px",
                height: "40px",
                background: "#166AB3",
                color: "white",
                border: "none",
                borderRadius: "0",
                fontSize: "17px",
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
      );
    } else if (showLogin && !showSignup) {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={require("../images/chat_name.png")} alt="" width="250" />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onSubmit={handleLogin}
          >
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="underline-input"
              placeholder={<span style={{ fontSize: "50px" }}>Email</span>}
              style={{ marginBottom: "20px" }}
            />

            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              className="underline-input"
            //   style={{ marginBottom: password === "" ? "10px" : "" }}
            />
            <button
              disabled={loading}
              type="submit"
              style={{
                width: "200px",
                height: "40px",
                border: "2px solid #186AB4",
                borderRadius: "0",
                fontSize: "17px",
                color: "#166AB3",
                marginTop: "30px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              {loading ? (
                <>
                  <LoadingOutlined />
                  &nbsp; Authenticating...
                </>
              ) : (
                <>&nbsp;Login</>
              )}
            </button>
          </form>
        </div>
      );
    } else if (!showLogin && showSignup) {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={require("../images/chat_name.png")} alt="" width="250" />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onSubmit={handleSignup}
          >
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              placeholder="First Name"
              className="underline-input"
              style={{ marginBottom: "20px" }}
            />

            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              placeholder="Last Name"
              className="underline-input"
              style={{ marginBottom: "20px" }}
            />

            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Email"
              className="underline-input"
              style={{ marginBottom: "20px" }}
            />

            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              className="underline-input"
            //   style={{ marginBottom: password === "" ? "10px" : "" }}
            />
            <button
              disabled={loading}
              type="submit"
              style={{
                width: "200px",
                height: "40px",
                border: "2px solid #186AB4",
                borderRadius: "0",
                fontSize: "17px",
                color: "#166AB3",
                marginTop: "30px",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <>
                  <LoadingOutlined />
                  &nbsp; Authenticating...
                </>
              ) : (
                <span className="hoverstyle" style={{ fontWeight: "bold" }}>
                  &nbsp;Login
                </span>
              )}
            </button>
          </form>
        </div>
      );
    }
  };

  const handle2FA = () => {
    setLoading(true);
    Axios.post(`https://gxauth.apimachine.com/gx/user/login`, {
      email: email,
      password: password,
      totp_code: verificationCode,
    }).then((res) => {
      setLoading(false);
      if (res.data.status) {
        message.success("Login Successfull");
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("refresh_token", res.data.refreshToken);
        localStorage.setItem("device_key", res.data.device_key);
        localStorage.setItem("user_account", email.toLowerCase());

        var idtoken = res.data.idToken;

        localStorage.setItem("token", idtoken);
        window.location.reload();
      }
    });
  };

  const handleResetPassword = () => {
    Axios.post(`https://gxauth.apimachine.com/gx/user/login`, {
      email: email,
      password: password,
      newPassword: confirmPassword,
    }).then((res) => {
      if (res.data.status) {
        setLoading(false);
        setResetPasswordStep4(true);

        setTimeout(() => {
          handleLogin1();
        }, 1000);
      } else {
        setLoading(false);
        setResetPasswordStep1(false);
        setResetPasswordStep2(false);
        setResetPasswordStep3(false);
        setResetPasswordStep4(false);
      }
    });
  };

  const conditionalLoginFlow = () => {
    if (resetPasswordStep1) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ border: "solid 1px lightgray", width: "300px" }}>
            <div
              style={{
                padding: "10px",
                backgroundColor: "#F45D48",
                display: "flex",
                justifyContent: "center",
                color: "white",
                fontWeight: 700,
              }}
            >
              Password Reset Required
            </div>
            <div
              style={{
                height: "180px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "20px",
              }}
            >
              In Order To Login, We Need You To Need Update Your Password In
              Accordance With Our Security Standards.
            </div>
            <button
              // disabled={loading}
              onClick={(e) => {
                setResetPasswordStep1(false);
                setResetPasswordStep2(true);
              }}
              type="submit"
              style={{
                // width: "200px",
                height: "50px",
                border: `2px solid ${constants.primary_color}`,
                borderRadius: "0",
                fontSize: "17px",
                color: "white",
                cursor: "pointer",
                background: constants.primary_color,
                width: "100%",
              }}
            >
              <span className="font-montserrat">Proceed</span>
            </button>
          </div>
        </div>
      );
    } else if (resetPasswordStep2) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ border: "solid 1px lightgray", width: "300px" }}>
            <div
              style={{
                padding: "10px",
                backgroundColor: "#F45D48",
                display: "flex",
                justifyContent: "center",
                color: "white",
                fontWeight: 700,
              }}
            >
              Password Reset Required
            </div>
            <div
              style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                // textAlign: "center",
                padding: "20px",
                color: "#F45D48",
              }}
            >
              New Password
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <small style={{ fontWeight: 600, paddingTop: "20px" }}>
                Password Requirements
              </small>
              <div>
                <div>
                  <small>- Password length Should be at least 8</small>
                </div>
                <div>
                  <small>- It Should have a Capital Letter</small>
                </div>
                <div>
                  <small>- It Should have a Number</small>
                </div>
                <div>
                  <small>- It Should have a Special Character</small>
                </div>
              </div>
            </div>
            <button
              onClick={(e) => {
                setResetPasswordStep2(false);
                setResetPasswordStep3(true);
              }}
              // disabled={loading}
              type="submit"
              style={{
                // width: "200px",
                height: "50px",
                border: `2px solid ${constants.primary_color}`,
                borderRadius: "0",
                fontSize: "17px",
                color: "white",
                cursor: "pointer",
                background: constants.primary_color,
                width: "100%",
              }}
            >
              <span className="font-montserrat">Enter</span>
            </button>
          </div>
        </div>
      );
    } else if (resetPasswordStep3) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ border: "solid 1px lightgray", width: "300px" }}>
            <div
              style={{
                padding: "10px",
                backgroundColor: "#F45D48",
                display: "flex",
                justifyContent: "center",
                color: "white",
                fontWeight: 700,
              }}
            >
              Password Reset Required
            </div>
            <div
              style={{
                height: "180px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                // textAlign: "center",
                padding: "20px",
                color: "#F45D48",
              }}
            >
              Confirm New Password
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <small>Password Requirements</small>
            </div>
            <button
              onClick={(e) => {
                if (newPassword === confirmPassword) {
                  setResetPasswordStep3(false);
                  setLoading(true);
                  handleResetPassword();
                }

                // setResetPasswordStep4(true);
              }}
              // disabled={loading}
              type="submit"
              style={{
                // width: "200px",
                height: "50px",
                border: `2px solid ${constants.primary_color}`,
                borderRadius: "0",
                fontSize: "17px",
                color: "white",
                cursor: "pointer",
                background: constants.primary_color,
                width: "100%",
              }}
            >
              <span className="font-montserrat">Enter</span>
            </button>
          </div>
        </div>
      );
    } else if (resetPasswordStep4) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div style={{ border: "solid 1px lightgray", width: "300px" }}>
            <div
              style={{
                padding: "0px 20px",
                backgroundColor: "#F45D48",
                display: "flex",
                justifyContent: "center",
                color: "white",
                fontWeight: 700,
              }}
            >
              <img
                src={require("../images/logo_white.svg")}
                alt=""
                width="100%"
              />
            </div>
            <div
              style={{
                height: "180px",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "20px",
                color: "#F45D48",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              You Have Successfully Reset Your Password
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <small>You Will Automatically Be Logged In</small>
            </div>
          </div>
        </div>
      );
    } else {
      if (!loading) {
        return (
          <Row
            style={{
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              background: constants.login_background,
              // background: "#6599C6",
              height: "100vh",
            }}
          >
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "6vh",
                fontWeight: "bold",
                fontSize: "20px",
                color: "white",
              }}
            >
              <span className="hoverstyle" onClick={() => history.push("/")}>
                Back
              </span>
            </Col>
            <Col span={18}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: constants.login_background,
                  // background: "#6599C6",
                  height: "100vh",
                }}
              >
                <div
                  style={{
                    width: "500px",
                    // window.innerWidth > 600
                    //   ? window.innerWidth / 3 - 100
                    //   : window.innerWidth,
                    height: "60vh",
                    background: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5vh",
                  }}
                >
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // marginTop: "40px",
                      justifyContent: "space-around",
                      height: "100%",
                      alignItems: window.innerWidth > 600 ? "left" : "center",
                      padding: "2vh 2vw",
                    }}
                    onSubmit={handleLogin}
                  >
                    <img src={constants.logo} alt="" width="100%" />
                    <div style={{ width: "100%" }}>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Email"
                        className="underline-input"
                        style={{
                          marginBottom: "20px",
                          padding: "10px 0px",
                          width: "100%",
                        }}
                      />
                      <br />
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        className="underline-input"
                        style={{
                          padding: "10px 0px",
                          marginTop: "30px",
                          width: "100%",
                        }}
                      //   style={{ marginBottom: password === "" ? "10px" : "" }}
                      />
                    </div>
                    <button
                      disabled={loading}
                      type="submit"
                      className="hoverstyle1"
                      style={{
                        // width: "200px",
                        height: "50px",
                        border: `2px solid ${constants.primary_color}`,
                        borderRadius: "0",
                        fontSize: "17px",
                        color: "white",
                        cursor: "pointer",
                        background: constants.primary_color,
                        width: "100%",
                      }}
                    >
                      {loading ? (
                        <>
                          <LoadingOutlined />
                          &nbsp; Authenticating...
                        </>
                      ) : (
                        <span style={{ fontWeight: "bold" }}>&nbsp;Login</span>
                      )}
                    </button>
                  </form>
                </div>
                {/* <div
      style={{
        background: constants.primary_color,
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        width:
          window.innerWidth > 600
            ? window.innerWidth / 4
            : window.innerWidth,
      }}
    >
      <img src={require("../images/poweredby.svg")} alt="" />
    </div> */}
              </div>
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "6vh",
                fontWeight: "bold",
                fontSize: "20px",
                color: "white",
              }}
            >
              <span className="hoverstyle">Admin</span>
            </Col>
          </Row>
        );
      } else {
        return (
          <div className="full-loading-wrapper">
            <img src={constants.logo} alt="" className="full-loading-logo" />
          </div>
        );
      }
    }
  };

  // return <>{conditionalLoginSignup()}</>;
  return (
    <>
      {conditionalLoginFlow()}
      <Modal
        centered
        closable={false}
        title={
          <>
            <SafetyCertificateOutlined
              style={{ fontWeight: "bold", color: "green" }}
            />
            &nbsp;&nbsp;2FA Verification
          </>
        }
        width={290}
        visible={visible2FA}
        onCancel={(e) => setVisible2FA(false)}
        footer={null}
      // bodyStyle={{ textAlign: "center" }}
      >
        {/* <h2>2FA Verification</h2> */}
        <p style={{ marginTop: "-10px" }}>
          Enter Code from google authenticator
        </p>

        <Input
          size="large"
          placeholder="Enter 2FA Code"
          style={{ marginBottom: "10px" }}
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button
          block
          type="primary"
          size="large"
          onClick={handle2FA}
          loading={loading ? true : false}
        >
          V E R I F Y
        </Button>
      </Modal>
    </>
  );
};

export default Splash;

import Axios from "axios";
import React, { useState, useEffect } from "react";
import App from "./App";
import constants from "./constants";

const Middleware = () => {
  const [selectedOrg, setSelectedOrg] = useState(constants.app_id);
  const [adminEmails, setAdminEmails] = useState([]);

  useEffect(() => {
    localStorage.setItem("org_name", "GX Chat");
  }, []);

  useEffect(() => {
    console.log("org changed", selectedOrg);
    switchOrg();
  }, [selectedOrg]);

  useEffect(() => {
    Axios.post(`https://chatsapi.globalxchange.io/gxchat/` + "get_admins").then(
      (res) => {
        setAdminEmails([...res.data.payload]);
      }
    );
  }, []);

  const switchOrg = () => {
    return (
      <App
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        switchOrg={switchOrg}
        app_id={constants.app_id}
        adminEmails={adminEmails}
      />
    );
  };

  return <>{switchOrg()}</>;
};

export default Middleware;

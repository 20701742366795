import React, { useState, useContext, useEffect } from "react";
import { Drawer, Card, Avatar, Input, Row, Col, Tooltip, Divider } from "antd";
import { MyContext } from "../Context";
import Axios from "axios";
const Switcher = () => {
  const {
    visibleSwitcher,
    setVisibleSwitcher,
    chatTypes,
    setChatTypes,
    setSelectedChatType,
    selectedChatType,
    setAllOrgs,
  } = useContext(MyContext);

  // useEffect(() => {
  //   Axios.post(
  //     `https://testchatsioapi.globalxchange.io/get_chat_type_apps`,
  //     { chat_type: selectedChatType.chat_type },
  //     {
  //       headers: {
  //         email: localStorage.getItem("user_account"),
  //         token: localStorage.getItem("token"),
  //       },
  //     }
  //   ).then((res) => {
  //     setAllOrgs(res.data.payload);
  //   });
  // }, [selectedChatType]);

  return (
    <>
      <Drawer
        bodyStyle={{ padding: "0px" }}
        // title="Basic Drawer"
        placement={"top"}
        closable={true}
        onClose={(e) => setVisibleSwitcher(false)}
        visible={visibleSwitcher}
        height="100vh"
        bodyStyle={{ padding: "30px" }}
      >
        <div
          className="font-montserrat"
          style={{ fontWeight: "bold", fontSize: "30px", color: "#166AB3" }}
        >
          Select Your Next Adventure
        </div>
        {chatTypes.length > 0
          ? chatTypes.map((item, index) => {
              return (
                <div
                  className="hover-shadow"
                  onClick={(e) => {
                    setSelectedChatType(item);
                    setVisibleSwitcher(false);
                  }}
                  style={{
                    display: "flex",
                    width: "18%",
                    marginTop: "20px",
                    cursor: "pointer",
                    // opacity: query ? (item._id.toLowerCase() === query ? 1 : 0.3) : 1,
                  }}
                >
                  <div
                    style={{
                      background: item.colour_code[0],
                      width: "12px",
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      // padding: "40px",
                      height: "70px",
                      textAlign: "center",
                      border: "solid 1px lightgray",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    <img
                      src={item.coloured_logo}
                      alt=""
                      width={index === 0 || index === 3 ? "40%" : "70%"}
                    />
                  </div>
                </div>
              );
            })
          : ""}

        {/* <div
          className="hover-shadow"
          // onClick={(e) => getTrackingDataDesktop(item)}
          style={{
            display: "flex",
            width: "18%",
            marginTop: "20px",
            cursor: "pointer",
            // opacity: query ? (item._id.toLowerCase() === query ? 1 : 0.3) : 1,
          }}
        >
          <div
            style={{
              background: "#F45D48",
              width: "12px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              // padding: "40px",
              height: "70px",
              textAlign: "center",
              border: "solid 1px lightgray",
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <img
              src={require("../images/teamforce_logo.svg")}
              alt=""
              style={{ width: "150px" }}
            />
          </div>
        </div>

        <div
          className="hover-shadow"
          // onClick={(e) => getTrackingDataDesktop(item)}
          style={{
            display: "flex",
            width: "18%",
            marginTop: "20px",
            cursor: "pointer",
            // opacity: query ? (item._id.toLowerCase() === query ? 1 : 0.3) : 1,
          }}
        >
          <div
            style={{
              background: "#555770",
              width: "12px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              // padding: "40px",
              height: "70px",
              textAlign: "center",
              border: "solid 1px lightgray",
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <img
              src={require("../images/classrooms.svg")}
              alt=""
              // style={{ width: "150px" }}
            />
          </div>
        </div>

        <div
          className="hover-shadow"
          // onClick={(e) => getTrackingDataDesktop(item)}
          style={{
            display: "flex",
            width: "18%",
            marginTop: "20px",
            cursor: "pointer",
            // opacity: query ? (item._id.toLowerCase() === query ? 1 : 0.3) : 1,
          }}
        >
          <div
            style={{
              background: "#166AB3",
              width: "12px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              // padding: "40px",
              height: "70px",
              textAlign: "center",
              border: "solid 1px lightgray",
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <img
              src={require("../images/chat_name.png")}
              alt=""
              style={{ width: "110px" }}
            />
          </div>
        </div>
      */}
      </Drawer>
    </>
  );
};

export default Switcher;

import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../Context";
import { Drawer, Input, Card, message, Dropdown, Menu } from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  SearchOutlined,
  UserOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

const SupportList = () => {
  const {
    socket,
    currentUserObj,
    setCurrentUserObj,
    supportgroupReadUnreadList,
    setsupportgroupReadUnreadList,
    supportLoading,
    setSupportLoading,
    selectedGroup,
    setSelectedGroup,
    setMessageLoading,
    setmessagearray,
    setVisibleUserInfo,
    setSelectedFriend,
    setSelectedSupport,
    selectedAdminSupport,
    setSelectedAdminSupport,
    allUserList,
    archiveList,
    setArchiveList,
    chatBoxArr,
    setChatBoxArr,
    selectedFriend,
    selectedSupport,

    activeTab,
    setActiveTab,
    userObj,
    app_id,
    hideSupport,
    setHideSupport,
    setBlankPage,
    globalColors,
  } = useContext(MyContext);
  const [visibleFindArchive, setVisibleFindArchive] = useState(false);

  const [query, setQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [itemType, setItemType] = useState("");

  useEffect(() => {
    if (selectedGroup !== null) {
      setSelectedItem(selectedGroup);
      setItemType("group");
    } else if (selectedFriend !== null) {
      setSelectedItem(selectedFriend);
      setItemType("friend");
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedItem(selectedAdminSupport);
      setItemType("adminSupport");
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      setSelectedItem(selectedSupport);
      setItemType("support");
    }
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  // useEffect(() => {
  //   setSelectedItem(selectedAdminSupport);
  //   setItemType("adminSupport");
  // }, []);

  // const handleFriendSelection = (item) => {
  //   socket.emit(
  //     "new_direct_message_read",
  //     userObj.username,
  //     item.thread_id,
  //     app_id,
  //     (response) => {
  //       console.log("jhsdjwhvedj", response);
  //       let tempList = unreadInteractionList;
  //       var foundIndex = tempList.findIndex(
  //         (x) => x.thread_id == item.thread_id
  //       );
  //       tempList[foundIndex].unread_count = 0;
  //       setUnreadInteractionList([...tempList]);
  //     }
  //   );

  //   if (chatBoxArr.length > 1) {
  //     if (chatBoxArr.find((o) => o.data["email"] === item.email)) {
  //     } else {
  //       console.log(item, "kjwbedkjwbefkwbef");
  //       // setSelectedFriend(item.data);
  //       setSelectedGroup(null);
  //       setSelectedSupport(null);
  //       setSelectedAdminSupport(null);
  //       setMessageLoading(true);
  //       setmessagearray([]);
  //       createNewTab(item);
  //     }
  //   } else {
  //     setSelectedSupport(null);
  //     setSelectedAdminSupport(null);
  //     setSelectedFriend(null);
  //     setSelectedGroup(item);
  //     setChatBoxArr([{ data: item, type: "personal" }]);
  //   }

  //   setBlankPage(false);
  // };

  const handleSupportSelection = (item) => {
    // socket.emit(
    //   "new_direct_message_read",
    //   userObj.username,
    //   item.thread_id,
    //   app_id,
    //   (response) => {
    //     console.log("jhsdjwhvedj", response);
    //     let tempList = unreadInteractionList;
    //     var foundIndex = tempList.findIndex(
    //       (x) => x.thread_id == item.thread_id
    //     );
    //     tempList[foundIndex].unread_count = 0;
    //     setUnreadInteractionList([...tempList]);
    //   }
    // );
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
    } else {
      console.log(item, "kjwbedkjwbefkwbef");
      // setSelectedFriend(item.data);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTab(item);
    }

    // if (chatBoxArr.length > 1) {

    // } else {
    //   setSelectedSupport(item);
    //   setSelectedAdminSupport(null);
    //   setSelectedFriend(null);
    //   setSelectedGroup(null);
    //   setChatBoxArr([{ data: item, type: "support" }]);
    // }

    setBlankPage(false);
    // console.log(activeTab, chatBoxArr[activeTab], item, "activetab");
    // if (selectedSupport !== item) {
    //   console.log(item, "selectedAdminSupport");
    //   setSelectedAdminSupport(item);
    //   setSelectedGroup(null);
    //   setSelectedFriend(null);
    //   setVisibleUserInfo(false);
    //   setSelectedSupport(item);
    //   setMessageLoading(true);
    //   setmessagearray([]);
    // }
  };

  const createNewTab = (item) => {
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      message.error("This User is already in one of your tabs.");
    } else {
      setSelectedSupport(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "support" }]);
    }
  };

  // const createNewTab = (item) => {
  //   console.log(item, "hahaha");
  //   console.log(selectedItem, item, "jbdjwbfkwbfkjwbefkjcbw");
  //   if (chatBoxArr.length < 1) {
  //     if (selectedItem !== item) {
  //       setChatBoxArr([
  //         { data: selectedItem, type: itemType },
  //         { data: item, type: "adminSupport" },
  //       ]);
  //     } else {
  //       message.error("You're trying to open the same thing again.");
  //     }
  //   } else {
  //     if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
  //       message.error("This group is already in one of your tabs.");
  //     } else {
  //       setChatBoxArr([...chatBoxArr, { data: item, type: "adminSupport" }]);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (currentUserObj.admin !== null) {
  // socket.emit(
  //   "get_admin_support_group_interaction_list",
  //   currentUserObj,
  //   (response) => {
  //     console.log("lllist", response);
  //     setsupportgroupReadUnreadList([...response]);
  //   }
  // );
  //   } else {
  //     socket.emit(
  //       "get_user_support_group_interaction_list",
  //       currentUserObj,
  //       (response) => {
  //         console.log("lll", response);
  //         setsupportgroupReadUnreadList([...response]);
  //       }
  //     );
  //   }
  // }, [currentUserObj]);

  let filteredArchiveSearch = archiveList.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      item.group_name
        .replace("GX-Support ", "")
        .toLowerCase()
        .indexOf(lowquery) >= 0
    );
  });

  const addToChat = (item) => {
    // console.log(item, "chosensupport");
    // // e.preventDefault();
    // setSelectedAdminSupport(item);
    // setSelectedGroup(null);
    // setSelectedFriend(null);
    // setVisibleFindArchive(false);
    // setSelectedSupport(item);
    // setMessageLoading(true);
    // setmessagearray([]);

    createNewTab(item);
    setVisibleFindArchive(false);
  };

  return (
    <>
      <div
        style={{
          // color: "#919191",
          color: globalColors.darkMode ? "#919191" : "lightgray",
          padding: "10px 0px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // marginTop: "-15px",
        }}
      >
        <span>
          SUPPORT{" "}
          {supportgroupReadUnreadList.length > 1 ? (
            <>( {supportgroupReadUnreadList.length})</>
          ) : (
            ""
          )}
        </span>
        <span>
          {/* {archiveList.length > 0 ? ( */}
          {supportgroupReadUnreadList.length > 1 ? (
            <>
              {hideSupport ? (
                <CaretDownOutlined
                  onClick={(e) => setHideSupport(!hideSupport)}
                  style={{ paddingRight: "10px", cursor: "pointer" }}
                />
              ) : (
                <CaretUpOutlined
                  onClick={(e) => setHideSupport(!hideSupport)}
                  style={{ paddingRight: "10px", cursor: "pointer" }}
                />
              )}
              <PlusOutlined onClick={(e) => setVisibleFindArchive(true)} />
            </>
          ) : (
            ""
          )}
        </span>
      </div>
      {!hideSupport ? (
        <div>
          {!supportLoading ? (
            supportgroupReadUnreadList.map((item, index) => {
              return (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1" onClick={(e) => createNewTab(item)}>
                        Open in new tab
                      </Menu.Item>
                      <Menu.Item key="2">Dismiss</Menu.Item>
                    </Menu>
                  }
                  trigger={["contextMenu"]}
                >
                  <p
                    key={index}
                    className={
                      selectedSupport === item || selectedAdminSupport === item
                        ? "selected-group"
                        : "group-name"
                    }
                    onClick={(e) => handleSupportSelection(item)}
                  >
                    {/* # {item.group_name} */}
                    {item.group_name.replace("GX-Support ", "").length > 15 ? (
                      <span>
                        {localStorage.getItem("mode") === "light"
                          ? "#"
                          : localStorage.getItem("mode") === "dark"
                          ? "#"
                          : ""}
                        &nbsp;&nbsp;
                        {item.group_name
                          .replace("GX-Support ", "")
                          .substring(0, 15)}
                        ...
                      </span>
                    ) : (
                      <>
                        {localStorage.getItem("mode") === "light"
                          ? "#"
                          : localStorage.getItem("mode") === "dark"
                          ? "#"
                          : ""}
                        &nbsp;&nbsp;
                        {item.group_name.replace("GX-Support ", "") +
                          " Support"}
                      </>
                    )}
                  </p>
                </Dropdown>
              );
            })
          ) : (
            <LoadingOutlined style={{ color: "white" }} />
          )}
        </div>
      ) : (
        ""
      )}
      {/* Archived Support Chat list draw */}

      <Drawer
        // bodyStyle={{ backgroundColor: "#196AB4" }}
        autoFocus
        // style={{ zIndex: "999" }}
        // closable={true}
        width={window.innerWidth > 600 ? 380 : window.innerWidth}
        placement="right"
        title="Search Support Chat"
        onClose={(e) => setVisibleFindArchive(false)}
        visible={visibleFindArchive}
      >
        {archiveList.length > 0 ? (
          <Input
            autoFocus
            size="large"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            style={{ marginBottom: "10px" }}
            placeholder="Type Username"
            suffix={<SearchOutlined style={{ color: "lightgray" }} />}
          />
        ) : (
          ""
        )}
        {filteredArchiveSearch.length > 0 && query.length > 1 ? (
          filteredArchiveSearch.map((item, index) => {
            if (index < 100 && query.length > 1)
              return (
                <>
                  <Card
                    onClick={(e) => addToChat(item)}
                    key={index}
                    // onClick={(e) => addToChat(item)}
                    hoverable
                    bodyStyle={{
                      padding: "10px",
                    }}
                    style={{ margin: "10px 0px", borderRadius: "5px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "8px",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {item.group_name.replace("GX-Support ", "")}
                        </span>{" "}
                        &nbsp;
                        {/* {onlineUsers.indexOf(item.username) !== -1 ? (
                          <i
                            class="fa fa-circle"
                            style={{ color: "#76FF03", fontSize: "8px" }}
                          ></i>
                        ) : (
                          <></>
                        )} */}
                      </p>

                      <p style={{ margin: "0px" }}>
                        <img src={require("../../images/all.png")} alt="" />
                      </p>
                    </div>
                  </Card>
                </>
              );
          })
        ) : archiveList.length > 0 ? (
          <p style={{ color: "lightgray" }}>
            <UserOutlined /> Search a user to start conversation
          </p>
        ) : (
          <p style={{ color: "lightgray" }}>
            <LoadingOutlined />
            &nbsp;Just a moment ..
          </p>
        )}
      </Drawer>
    </>
  );
};

export default SupportList;

import React, { useContext, useState } from "react";
import S3 from "aws-s3";
import {
  Row,
  Col,
  Badge,
  Input,
  Divider,
  Drawer,
  Popover,
  Avatar,
  message,
  Tabs,
  Button,
  Card,
  Menu,
  Dropdown,
  Modal,
  Form,
  Spin,
  Empty,
  Typography,
  Upload,
  Space,
  Alert,
  Checkbox,
  Tag,
} from "antd";
import {
  TeamOutlined,
  PushpinOutlined,
  StarOutlined,
  UserOutlined,
  MenuOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  BellOutlined,
  MessageOutlined,
  SmileOutlined,
  RollbackOutlined,
  CloseOutlined,
  SyncOutlined,
  DownloadOutlined,
  LoadingOutlined,
  DeleteFilled,
  ForwardOutlined,
  CloseCircleFilled,
  ArrowRightOutlined,
  StarFilled,
  CloseCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { MyContext } from "../../Context";

import ChatOrgList from "../ChatOrgList";
import ChatMenu from "../ChatMenu/ChatMenu";
import Editor from "./Editor";
import AllChats from "./AllChats";
import { useEffect } from "react";
import Loading from "../../lotties/LoadingAnimation";

import DraggableTabs from "./DraggableTabs";
import GroupList from "../ChatMenu/GroupList";
import FriendList from "../ChatMenu/FriendList";
import SupportList from "../ChatMenu/SupportList";
import Select from "rc-select";
import { getRoles } from "@testing-library/dom";

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const ListItem = ({
  index,
  handleForward,
  onlineUsers,
  item,
  active,
  setSelected,
  setHovered,
  setQuery,
  setShowFullname,
  showFullname,
  setShowForwardMessageUI,
  setSelectedForwardMessages,
  recipients,
  setRecipients,
  handleReciverArray,
}) => (
  <div
    onClick={(e) => handleReciverArray(item)}
    // onClick={(e) => {
    //   setQuery("");
    //   if (item.first_name) {
    //     handleForward(item, "friend");
    //     setQuery("");
    //     setShowForwardMessageUI(false);
    //     setSelectedForwardMessages([]);
    //   } else if (item.group_name) {
    //     if (item.group_name.includes("support")) {
    //       handleForward(item, "support");
    //       setQuery("");
    //       setShowForwardMessageUI(false);
    //       setSelectedForwardMessages([]);
    //       // handleSupportSelection(item);
    //     } else {
    //       handleForward(item, "group");
    //       setQuery("");
    //       setShowForwardMessageUI(false);
    //       setSelectedForwardMessages([]);
    //       // handleGroupSelection(item);
    //     }
    //   }
    // }}
    className={`item ${active ? "active" : ""}`}
    // onClick={() => setSelected(item)}
    onMouseEnter={() => setHovered(item)}
    onMouseLeave={() => setHovered(undefined)}
    key={index}
    // className={
    //   selectedFriend
    //     ? selectedFriend.email === item.email ||
    //       selectedHighlight.email === item.email
    //       ? "selected-friend"
    //       : "friend-name"
    //     : "friend-name"
    // }

    // style={{ display: "flex" }}
  >
    <Space
      style={{ paddingRight: "5px" }}
      onMouseEnter={(e) => setShowFullname(index)}
      onMouseLeave={(e) => setShowFullname(null)}
    >
      {item.avatar ? (
        <Avatar src={item.avatar} />
      ) : (
        <Avatar>{item.group_name ? item.group_name[0] : ""}</Avatar>
      )}
      {/* {item.username ? (
        onlineUsers.indexOf(item.username) !== -1 ? (
          <Badge color={"#70CC16"} />
        ) : (
          <Badge color={"lightgray"} />
        )
      ) : (
        ""
      )} */}
      {showFullname === index ? (
        item.first_name ? (
          item.first_name
        ) : (
          item.group_name
        )
      ) : item.first_name ? (
        <span>
          {item.first_name.length > 18 ? (
            <span>{item.first_name.substring(0, 18)}...</span>
          ) : (
            item.first_name
          )}
        </span>
      ) : (
        <span>
          {item.group_name.length > 18 ? (
            <span>{item.group_name.substring(0, 18)}...</span>
          ) : (
            item.group_name
          )}
        </span>
      )}
      {/* <span>
{item.first_name.length > 12 ? (
  <>{item.first_name.substring(0, 12)}...</>
) : (
  item.first_name
)}
</span> */}
    </Space>
    &nbsp;{" "}
    <Badge
      count={item.unread_count ? item.unread_count : 0}
      style={{
        backgroundColor: "red",
        color: "white",
        // boxShadow: "0 0 0 1px #d9d9d9 inset",
      }}
    />
  </div>
);

const ListItem1 = ({
  index,
  handleFriendSelection,
  handleGroupSelection,
  handleSupportSelection,
  setShowFullname,
  showFullname,
  onlineUsers,
  item,
  active,
  setSelected,
  setHovered,
  setQuery,
  filteredUsersSearch,
  addUserToGroup,
  cursor,
  globalColors,
}) => (
  <Card
    key={index}
    onClick={(e) => addUserToGroup(item)}
    className={`item ${active ? "active" : "group-name"}`}
    // onClick={() => setSelected(item)}
    onMouseEnter={() => setHovered(item)}
    onMouseLeave={() => setHovered(undefined)}
    hoverable
    bodyStyle={{
      padding: "10px",
      background: globalColors.darkMode ? globalColors.chatMenuBackground : "",
      color: globalColors.chatText,
    }}
    style={{
      margin: "10px 0px",
      borderRadius: "5px",
      borderColor: globalColors.darkMode
        ? globalColors.chatMenuBackground
        : "lightgray",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "8px",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          size="large"
          src={item.avatar}
          style={{
            backgroundColor: "lightgray",
          }}
        />
        {/* <img src={item.avatar} alt="" width="40px" /> */}
        &nbsp;&nbsp;
        <div>
          <div style={{ fontWeight: "bold" }}>
            {item.first_name}&nbsp;{item.last_name}
          </div>
          <small>{item.username}</small>
        </div>
      </div>

      <p style={{ margin: "0px" }}>
        <PlusOutlined style={{ color: "#196AB4" }} />
      </p>
    </div>
  </Card>
);

const ChatBox = () => {
  const {
    socket,
    allUserList,
    selectedSupport,
    selectedAdminSupport,
    selectedGroup,
    selectedFriend,
    visibleUserInfo,
    setVisibleUserInfo,
    setVisibleSidebar,
    groupMembers,
    handleDroppedFile,
    visibleSidebar,
    onlineUsers,
    setSelectedFriend,
    setSelectedGroup,
    setSelectedSupport,
    messageLoading,
    setMessageLoading,
    setmessagearray,
    messagearray,
    currentUserObj,
    chatBoxArr,
    setChatBoxArr,
    setSelectedAdminSupport,
    activeTab,
    setActiveTab,
    userObj,
    app_id,
    setgroupReadUnreadList,
    groupReadUnreadList,
    refreshGroup,
    setRefreshGroup,
    selectedChatType,
    allOrgs,
    pinnedMessages,
    setPinnedMessages,
    conditionalMessageView,
    showConditionalDate,
    adminUsernames,
    memberUsernames,
    setMemberUsernames,
    setgroupMembers,
    submitLoading,
    setSubmitLoading,
    unreadCount,
    setUnreadCount,
    setGoToBottom,
    goToBottom,
    timeDifference,
    formatAMPM,
    setThreadItem,
    showThread,
    setShowThread,

    setShowReactions,
    bodyRef,
    setEditorText,
    pinMessage,
    setSelectedReplyMessage,
    selectedEditMessage,
    setSelectedEditMessage,
    conditionalDeleteChat,
    hideSidebar,
    setHideSidebar,
    blankPage,
    setBlankPage,
    refetchData,
    setRefetchData,
    handleTabClick,
    handleStoreData,
    loading,
    setLoading,
    selectedForwardMessages,
    setSelectedForwardMessages,
    selectedDeleteMessages,
    setSelectedDeleteMessages,
    getUsers,
    unreadInteractionList,
    supportgroupReadUnreadList,
    showForwardMessageUI,
    setShowForwardMessageUI,
    handleForward,
    showFullname,
    setShowFullname,
    typingFlag,
    typingUser,
    globalColors,
    recipients,
    setRecipients,
    groupLists,
    setGroupLists,
    favList,
    setFavList,
    getFavs,
    getPinnedMessage,
    allGroupRoles,
    setAllGroupRoles,
    sendMessageState,
    setSendMessageState,
    deleteMessageState,
    setDeleteMessageState,
    addUserState,
    setAddUserState,
    removeUserState,
    setRemoveUserState,
    addRoleState,
    setAddRoleState,
    removeRoleState,
    setRemoveRoleState,
  } = useContext(MyContext);
  const { Option } = Select;
  const [visibleGroupMembers, setVisibleGroupMembers] = useState(false);
  const [visibleAddMembers, setVisibleAddMembers] = useState(false);
  const [dragEntered, setDragEntered] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [itemType, setItemType] = useState("");
  const [query, setQuery] = useState("");
  const [visiblePinnedMessages, setVisiblePinnedMessages] = useState(false);

  const [disableAddToGroup, setDisableAddToGroup] = useState(false);
  const [showEditGroupName, setShowEditGroupName] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMessageSearch, setShowMessageSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [groupAvatarUrl, setGroupAvatarUrl] = useState("");
  const [defaultFileList, setDefaultFileList] = useState([]);

  const [searchUI, setSearchUI] = useState(false);

  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");

  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [allList, setAllList] = useState([]);

  const [visibleCreateRole, setVisibleCreateRole] = useState(false);
  const [visibleSetUserRoles, setVisibleSetUserRoles] = useState(false);

  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [sendMessage, setSendMessage] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [removeUser, setRemoveUser] = useState(false);

  const [allRoles, setAllRoles] = useState([]);

  const [updatePermission, setUpdatePermission] = useState(false);

  const [userRoles, setUserRoles] = useState([
    {
      roleName: "abc",
      addPost: true,
      deletePost: true,
      addUser: true,
      removeUser: false,
    },
  ]);

  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  const handleReciverArray = (item) => {
    if (recipients.find((o) => o.thread_id === item.thread_id)) {
      var newArr = recipients.filter((o) => o !== item.thread_id);
      setRecipients([...newArr]);
    } else {
      if (item.group_name) {
        socket.emit(
          "group_details",
          selectedGroup.thread_id,
          app_id,
          (response) => {
            var tempData = [];
            response.payload.group_details.participants_info.map((item) => {
              tempData.push(item.username);
            });

            console.log(tempData, "jhjdwedfq");
            const index = tempData.indexOf(
              userObj
                ? userObj.username
                : JSON.parse(localStorage.getItem("userObj")).username
            );
            if (index > -1) {
              tempData.splice(index, 1);
            }
            // console.log(tempData, "jhjdwedfq");
            // setMemberUsernames([...tempData]);
            setRecipients([
              ...recipients,
              {
                thread_id: item.thread_id,
                type: "group",
                users: tempData,
              },
            ]);
          }
        );
      } else if (item.username) {
        setRecipients([
          ...recipients,
          {
            thread_id: item.thread_id,
            type: "direct",
            users: item.username,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setSearchUI(true);
    } else {
      setSearchUI(false);
    }
  }, [query]);

  useEffect(() => {
    setAllList([
      ...unreadInteractionList,
      ...groupReadUnreadList,
      ...supportgroupReadUnreadList,
    ]);
  }, [unreadInteractionList, groupReadUnreadList, supportgroupReadUnreadList]);

  let filteredUsers = allList.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.first_name
        ? item.first_name
        : "" + item.group_name
        ? item.group_name
        : ""
      )
        .toLowerCase()
        .indexOf(lowquery) >= 0
    );
  });

  useEffect(() => {
    if (!visibleAddMembers && downPress) {
      setCursor((prevState) =>
        prevState < filteredUsers.length - 1 ? prevState + 1 : prevState
      );
    } else if (visibleAddMembers && downPress) {
      setCursor((prevState1) =>
        prevState1 < filteredUsersSearch.length - 1
          ? prevState1 + 1
          : prevState1
      );
    }
  }, [downPress]);
  useEffect(() => {
    if (!visibleAddMembers && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else if (visibleAddMembers && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (selectedForwardMessages.length > 0) {
      if (query.length > 0) {
        if (filteredUsers.length && enterPress) {
          setSelected(filteredUsers[cursor]);
          if (filteredUsers[cursor].first_name) {
            handleForward(filteredUsers[cursor], "friend");
            setQuery("");
            setShowForwardMessageUI(false);
            setSelectedForwardMessages([]);
            // handleFriendSelection(filteredUsers[cursor]);
          } else if (filteredUsers[cursor].group_name) {
            if (filteredUsers[cursor].group_name.includes("Support")) {
              handleForward(filteredUsers[cursor], "support");
              setQuery("");
              setShowForwardMessageUI(false);
              setSelectedForwardMessages([]);
              // handleSupportSelection(filteredUsers[cursor]);
            } else {
              handleForward(filteredUsers[cursor], "group");
              setQuery("");
              setShowForwardMessageUI(false);
              setSelectedForwardMessages([]);
              // handleGroupSelection(filteredUsers[cursor]);
            }
          }
        }
      }
    } else if (visibleAddMembers && enterPress) {
      addUserToGroup(filteredUsersSearch[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (filteredUsers.length && hovered) {
      setCursor(filteredUsers.indexOf(hovered));
    }
  }, [hovered]);

  let filteredUsersSearch = allUserList.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.username + item.first_name).toLowerCase().indexOf(lowquery) >= 0
    );
  });

  // let filteredUsers = unreadInteractionList.filter((item) => {
  //   const lowquery = query.toLowerCase();
  //   return item.first_name.toLowerCase().indexOf(lowquery) >= 0;
  // });
  // console.log(supportgroupReadUnreadList, "sKDJVBfjhks");

  // let filteredGroups = groupReadUnreadList.filter((item) => {
  //   const lowquery = query.toLowerCase();
  //   return item.group_name.toLowerCase().indexOf(lowquery) >= 0;
  // });

  // let filteredSupport = supportgroupReadUnreadList.filter((item) => {
  //   const lowquery = query.toLowerCase();
  //   // console.log(supportgroupReadUnreadList, "klwejnfk");
  //   return item.group_name.toLowerCase().indexOf(lowquery) >= 0;
  // });

  // const handleKeyDown = (e) => {
  //   console.log(document.querySelectorAll("#res"), "kwqdkwed");
  //   // arrow up/down button should select next/previous list element
  //   if (e.keyCode === 40 && cursorLocation == 0) {
  //     setCursorLocation(cursorLocation + 1);
  //     setSelectedHighlight(filteredUsers[0]);
  //   } else if (e.keyCode === 40 && cursorLocation > 0) {
  //     setCursorLocation(cursorLocation + 1);
  //     setSelectedHighlight(filteredUsers[cursorLocation]);
  //   } else if (e.keyCode === 38 && cursorLocation < filteredUsers.length - 1) {
  //     setCursorLocation(cursorLocation - 1);
  //     setSelectedHighlight(filteredUsers[cursorLocation]);
  //   }
  // };

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.focus();
      // console.log("focuseddddd", activeTab);
    }
  }, [submitLoading, activeTab, chatBoxArr, messagearray]);

  const deleteMultipleMessages = () => {
    setLoading(true);
    if (selectedGroup) {
      socket.emit(
        `delete_group_chat_multiple_messages`,
        {
          thread_id: selectedGroup.thread_id,
          sender: userObj,
          ids: selectedDeleteMessages,
        },
        selectedDeleteMessages[0],
        app_id,
        (res) => {
          setLoading(false);
          setSelectedDeleteMessages([]);
          handleRefetchData();
        }
      );
    } else if (selectedFriend) {
      socket.emit(
        `delete_direct_chat_multiple_messages`,
        {
          thread_id: selectedFriend.thread_id,
          sender: userObj,
          ids: selectedDeleteMessages,
        },
        selectedDeleteMessages[0],
        app_id,
        (res) => {
          setLoading(false);
          setSelectedDeleteMessages([]);
          handleRefetchData();
        }
      );
    }
  };

  const getGroups = () => {
    // setGroupsLoading(true);
    console.log(userObj, "getgroupss");
    socket.emit(
      // "get_user_group_interaction_list",
      "new_user_group_interaction_list",
      userObj.email,
      app_id,
      (response) => {
        // setGroupsLoading(false);
        console.log("get_user_group_interaction_list", response);
        setgroupReadUnreadList(response.interaction_list);
        setGroupLists(response.user_group_lists);
      }
    );
  };

  const editGroupDetails = () => {
    console.log(selectedGroup, userObj, "ressssffffffs");

    socket.emit(
      "edit_group_details",
      userObj,
      selectedGroup.thread_id,
      {
        group_name: newGroupName ? newGroupName : selectedGroup.group_name,
        avatar: groupAvatarUrl ? groupAvatarUrl : selectedGroup.avatar,
      },
      app_id,
      (response) => {
        console.log(response, "ressssffffffs");
        // setgroupMembers([...response]);
        let sItem = JSON.parse(localStorage.getItem("chatboxArr"));
        var foundIndex = sItem.findIndex(
          (o) => o.data.thread_id === response.payload.thread_id
        );
        if (foundIndex >= 0) {
          let newArr = [
            ...sItem.filter(
              (o) => o.data.thread_id !== response.payload.thread_id
            ),
          ];
          sItem[foundIndex].data = response.payload;
          localStorage.setItem("chatboxArr", JSON.stringify([...newArr]));
          setChatBoxArr([...sItem]);
          getGroups();
        } else {
          getGroups();
        }

        setShowEditGroupName(false);
        setGroupAvatarUrl("");
        setNewGroupName("");
      }
    );
  };

  // useEffect(() => {
  //   getPinnedMessage();
  // }, [selectedGroup, selectedFriend]);

  const unpinMessage = (item) => {
    console.log("jhvdjwevfjhwf", item);
    if (item.msgtype === "group") {
      socket.emit("unpin_group_message", item._id, app_id, async (data) => {
        console.log("ddddddddddddd", data);
        if (data.status) {
          getPinnedMessage();
          setVisiblePinnedMessages(false);
        }
      });
    } else {
      socket.emit(
        "unpin_one_to_one_message",
        item._id,
        app_id,
        async (data) => {
          console.log("ddddddddddddd", data);
          if (data.status) {
            getPinnedMessage();
            setVisiblePinnedMessages(false);
          }
        }
      );
    }
  };

  // const getPinnedMessage = () => {
  //   if (selectedFriend) {
  //     socket.emit(
  //       "get_all_pinned_one_to_one_messages_chat_history",
  //       selectedItem.thread_id,
  //       app_id,
  //       async (data) => {
  //         console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", data);
  //         if (data.status) {
  //           setPinnedMessages(data.payload);
  //         }
  //       }
  //     );
  //   } else if (selectedGroup) {
  //     socket.emit(
  //       "get_all_pinned_group_messages_chat_history",
  //       selectedItem.thread_id,
  //       app_id,
  //       async (data) => {
  //         console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", data);
  //         if (data.status) {
  //           setPinnedMessages(data.payload);
  //         }
  //       }
  //     );
  //   }
  // };

  const getGroupMembers = () => {
    console.log(selectedGroup, app_id, ":kjgedhwedkjekdew");
    socket.emit(
      "group_details",
      selectedGroup.thread_id,
      app_id,
      (response) => {
        console.log(response, "resssss");
        setgroupMembers([...response.payload.group_details.participants_info]);
        if (response.payload.group_details.roles) {
          setAllGroupRoles([response.payload.group_details.roles]);
        }
      }
    );
  };

  useEffect(() => {
    // let chatArr = JSON.parse(localStorage.getItem("chatboxArr"));
    if (selectedGroup !== null && chatBoxArr) {
      // getGroupMembers();
      // find the logged in user's role
      let loggedinUser = groupMembers.find((o) => o.email === userObj.email);
      // console.log(loggedinUser.role, "jhvcjwved");
      // then match that role with role list
      let foundRole = allGroupRoles.find((o) => o.name === loggedinUser.role);
      console.log(foundRole, loggedinUser, "jhvcjwveddd");
      if (foundRole) {
        foundRole.permissions.map((item) => {
          if (item === "F59fgHkBR9ekWhLu") {
            setSendMessageState(true);
          } else if (item === "AG69GGkqrDMDuuN5") {
            setDeleteMessageState(true);
          } else if (item === "8C68TS9KuXmU8NfD") {
            setAddUserState(true);
          } else if (item === "WZAsQy2UTct79nPc") {
            setRemoveUserState(true);
          } else if (item === "ZEM29PBKHs95UARf") {
            setAddRoleState(true);
          } else if (item === "YhNtvMu7CdsqY5UD") {
            setRemoveRoleState(true);
          }
        });
      } else if (loggedinUser) {
        if (loggedinUser.role === "user") {
          if (
            selectedGroup.group_type === "794b72dd10987ae959dda9895070c336" ||
            selectedGroup.group_type === "2c2d1bcedf239daa36709899ec735c47"
          ) {
            setSendMessageState(false);
            setDeleteMessageState(false);
            setAddUserState(false);
            setRemoveUserState(false);
            setAddRoleState(false);
            setRemoveRoleState(false);
          } else {
            console.log("i am inside user", selectedGroup);
            setSendMessageState(true);
            setDeleteMessageState(true);
            setAddUserState(false);
            setRemoveUserState(false);
            setAddRoleState(false);
            setRemoveRoleState(false);
          }
        } else if (loggedinUser.role === "creator") {
          setSendMessageState(true);
          setDeleteMessageState(true);
          setAddUserState(true);
          setRemoveUserState(true);
          setAddRoleState(true);
          setRemoveRoleState(true);
        }
      }
      // else {
      //   setSendMessageState(false);
      //   setDeleteMessageState(false);
      //   setAddUserState(false);
      //   setRemoveUserState(false);
      //   setAddRoleState(false);
      //   setRemoveRoleState(false);
      // }

      console.log(
        sendMessageState,
        deleteMessageState,
        addUserState,
        removeUserState,
        addRoleState,
        removeRoleState,
        "djhwjedhwed"
      );
    }
  }, [selectedGroup, selectedFriend, selectedSupport, groupMembers]);

  useEffect(() => {
    if (selectedGroup !== null) {
      setSelectedItem(selectedGroup);
      setItemType("group");
    } else if (selectedFriend !== null) {
      setSelectedItem(selectedFriend);
      setItemType("personal");
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedItem(selectedAdminSupport);
      setItemType("adminSupport");
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      setSelectedItem(selectedSupport);
      setItemType("support");
    }
    console.log(pinnedMessages, "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu");
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  const conditionalChatTitle = () => {
    if (selectedGroup !== null) {
      return (
        <>
          <TeamOutlined style={{ color: "gray" }} />
          &nbsp;
          {selectedGroup.group_name}&nbsp;{" "}
          {pinnedMessages.length > 0 ? (
            <span
              className="hover-underline"
              onClick={(e) => setVisiblePinnedMessages(!visiblePinnedMessages)}
              style={{
                // fontWeight: "normal",
                fontSize: "14px",
                color: "#EC407A",
              }}
            >
              <PushpinOutlined />
              &nbsp; {pinnedMessages.length} Pinned Messages
            </span>
          ) : (
            ""
          )}
        </>
      );
    } else if (selectedFriend !== null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Avatar
            src={selectedFriend.avatar}
            onClick={(e) => setVisibleUserInfo(!visibleUserInfo)}
          />
          &nbsp;&nbsp;&nbsp;
          <span onClick={(e) => setVisibleUserInfo(!visibleUserInfo)}>
            {selectedFriend.first_name}&nbsp;{selectedFriend.last_name}{" "}
            &nbsp;&nbsp;
          </span>
          {pinnedMessages.length > 0 ? (
            <span
              className="hover-underline"
              onClick={(e) => setVisiblePinnedMessages(!visiblePinnedMessages)}
              style={{
                // fontWeight: "normal",
                fontSize: "14px",
                color: "#EC407A",
              }}
            >
              <PushpinOutlined />
              &nbsp; {pinnedMessages.length} Pinned Messages
            </span>
          ) : (
            ""
          )}
        </div>
      );
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      // console.log(selectedAdminSupport, "jijijij");
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img src={require("../../images/chat.png")} alt="" width="30px" />{" "}
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <div>
              {selectedAdminSupport.group_name.replace("GX-Support ", "")} -
              Support&nbsp;&nbsp;&nbsp;
              <StarOutlined
                style={{
                  color:
                    localStorage.getItem("mode") === "light"
                      ? "black"
                      : localStorage.getItem("mode") === "dark"
                      ? "#A4A4A4"
                      : "",
                }}
              />
              {pinnedMessages.length > 0 ? (
                <span
                  className="hover-underline"
                  onClick={(e) =>
                    setVisiblePinnedMessages(!visiblePinnedMessages)
                  }
                  style={{
                    // fontWeight: "normal",
                    fontSize: "14px",
                    color: "#EC407A",
                  }}
                >
                  <PushpinOutlined />
                  &nbsp; {pinnedMessages.length} Pinned Messages
                </span>
              ) : (
                ""
              )}
            </div>
            <div style={{ marginTop: "-10px", fontWeight: "300" }}>
              <small>{selectedAdminSupport.who}</small>
            </div>
          </div>
        </div>
      );
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      return (
        <>
          <img src={require("../../images/chat.png")} alt="" width="30px" />{" "}
          &nbsp;&nbsp;&nbsp;<span>Support Chat</span>&nbsp;
          {pinnedMessages.length > 0 ? (
            <span
              className="hover-underline"
              onClick={(e) => setVisiblePinnedMessages(!visiblePinnedMessages)}
              style={{
                // fontWeight: "normal",
                fontSize: "14px",
                color: "#EC407A",
              }}
            >
              <PushpinOutlined />
              &nbsp; {pinnedMessages.length} Pinned Messages
            </span>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  const handleSearch = () => {
    if (searchQuery.length > 0) {
      if (selectedGroup !== null) {
        console.log(selectedGroup, "kjqdked");
        socket.emit(
          "search_group_messages",
          app_id,
          selectedGroup.thread_id,
          searchQuery,
          (res) => {
            if (res.status) {
              if (res.payload.length > 0) {
                setSearchResults(res.payload);
              } else {
                setSearchResults(null);
              }
            }
            console.log(res, "search resultsss");
          }
        );
      } else if (selectedFriend !== null) {
        console.log(selectedFriend, "kjqdked");
        socket.emit(
          "search_one_to_one_messages_chat_history",
          app_id,
          selectedFriend.thread_id,
          searchQuery,
          (res) => {
            if (res.status) {
              if (res.payload) {
                setSearchResults(res.payload);
              } else {
                setSearchResults(null);
              }
            }
            console.log(res, "search resultsss");
          }
        );
      }
    }
  };

  const removeTab = (targetKey, action) => {
    let tempArr = chatBoxArr;
    tempArr.splice(targetKey, 1);
    setChatBoxArr([...tempArr]);
  };

  const showMultipleChat = () => {
    // console.log("inside multiple chat");
    return (
      <div
        className={globalColors.darkMode ? "dark-mode-tabs" : "light-mode-tabs"}
      >
        <DraggableTabs
          id="dontfocus"
          showAction={["focus", "click"]}
          className="myTabs"
          style={{ margin: "0px" }}
          hideAdd
          onChange={handleTabClick}
          activeKey={activeTab !== null ? activeTab.toString() : 0}
          type="editable-card"
          onEdit={removeTab}
          // onTabClick={(e) => bodyRef.current.focus()}
          // onFocus={(e) => {
          //   setTimeout(() => {
          //     const x = document.getElementById("focushere");
          //     x.focus();
          //   }, 1000);
          // }}
        >
          {chatBoxArr.map((item, index) => {
            return (
              <TabPane
                onFocus={(e) => {
                  document.activeElement.blur();
                  bodyRef.current.focus();
                }}
                id="dontfocus"
                disabled={messageLoading ? true : false}
                tab={
                  <>
                    {item.type === "personal" ? (
                      <Avatar
                        src={item.data.avatar}
                        size="small"
                        style={{
                          color: "white",
                          backgroundColor: "white",
                          cursor: "pointer",
                          border: "solid 1px gray",
                        }}
                      />
                    ) : item.type === "group" ? (
                      item.data.avatar ? (
                        <Avatar
                          src={item.data.avatar}
                          size="small"
                          style={{
                            color: "white",
                            backgroundColor: "white",
                            cursor: "pointer",
                            border: "solid 1px gray",
                          }}
                        />
                      ) : (
                        <Avatar
                          // style={{ border: "solid 1px #F35D48" }}
                          size="small"
                          style={{ backgroundColor: "#F35D48" }}
                          icon={<TeamOutlined />}
                        />
                      )
                    ) : (
                      // <Avatar
                      //   size="small"
                      //   style={{ color: "white", backgroundColor: "#F35D48" }}
                      // >
                      //   {item.data.group_name[0].toUpperCase()}
                      // </Avatar>
                      // <TeamOutlined />
                      <img
                        src={require("../../images/chat.png")}
                        alt=""
                        width="20px"
                        style={{ paddingRight: "5px" }}
                      />
                    )}
                    &nbsp;&nbsp;
                    {item.data.username
                      ? item.data.first_name
                      : item.data.group_name}
                  </>
                }
                key={index}
              >
                <div>{wholeChatBox(item.data)}</div>
              </TabPane>
            );
          })}
        </DraggableTabs>
      </div>
    );
    // if (chatBoxArr.length > 0) {
    //   return (
    //     <div
    //       className={
    //         localStorage.getItem("mode") === "light"
    //           ? ""
    //           : localStorage.getItem("mode") === "dark"
    //           ? "dark-tabs"
    //           : ""
    //       }
    //     >
    //       <Tabs
    //         style={{ margin: "0px" }}
    //         hideAdd
    //         onChange={handleTabClick}
    //         activeKey={activeTab.toString()}
    //         type="editable-card"
    //         onEdit={removeTab}
    //       >
    //         {chatBoxArr.map((item, index) => {
    //           return (
    //             <TabPane
    //               tab={
    //                 <>
    //                   {item.type === "personal" ? (
    //                     <UserOutlined />
    //                   ) : item.type === "group" ? (
    //                     <TeamOutlined />
    //                   ) : (
    //                     <img
    //                       src={require("../../images/chat.png")}
    //                       alt=""
    //                       width="20px"
    //                       style={{ paddingRight: "5px" }}
    //                     />
    //                   )}

    //                   {item.data.username
    //                     ? item.data.first_name
    //                     : item.data.group_name}
    //                 </>
    //               }
    //               key={index}
    //             >
    //               <div>{wholeChatBox(item.data)}</div>
    //             </TabPane>
    //           );
    //         })}
    //       </Tabs>
    //     </div>
    //   );
    // } else {
    //   return wholeChatBox(selectedItem);
    // }
  };

  const getCreator = (item) => {
    if (selectedGroup) {
      if (selectedGroup.group_name.includes("Support")) {
        return "Teamforce";
      } else {
        if (allUserList.length > 0) {
          const foundObj = allUserList.find(
            (o) => o.email === item.creator_email
          );
          // console.log(foundObj, allUserList, "kjwjhwejfewj");
          console.log();
          if (foundObj !== null && foundObj !== undefined) {
            return foundObj.first_name;
          }
        }
      }
    } else {
      return "Teamforce";
    }
  };

  const handleFavorite = () => {
    socket.emit(
      `add_a_favourite_interaction`,
      selectedGroup ? "group" : selectedFriend ? "direct" : "support", // should be "direct" or "group"
      selectedGroup
        ? selectedGroup.thread_id
        : selectedSupport
        ? selectedSupport.thread_id
        : selectedFriend.thread_id,
      userObj.id,
      app_id,
      (res) => {
        if (res.status) {
          // console.log(res, "successfully made favorite");
          getFavs();
        }
      }
    );
  };

  const handleUnfavorite = () => {
    socket.emit(
      `remove_a_favourite_interaction`,
      selectedGroup ? "group" : "direct", // should be "direct" or "group"
      selectedGroup ? selectedGroup.thread_id : selectedFriend.thread_id,
      userObj.id,
      app_id,
      (res) => {
        if (res.status) {
          // console.log(res, "successfully made unfavorite");
          getFavs();
        }
      }
    );
  };

  const handleRefetchData = () => {
    setMessageLoading(true);
    var tempStorage = JSON.parse(localStorage.getItem("storedChat"));
    if (tempStorage) {
      if (selectedFriend) {
        var foundObj = tempStorage.find(
          (o) => o.chatObj["email"] === selectedFriend.email
        );
        if (foundObj) {
          var tempdata = tempStorage.filter(
            (item) => item.chatObj.username !== selectedFriend.username
          );
          localStorage.setItem("storedChat", JSON.stringify(tempdata));
        }

        socket.emit(
          "check_user_interaction_list",
          userObj ? userObj : JSON.parse(localStorage.getItem("userObj")),
          selectedFriend.username,
          // selectedFriend.thread_id,
          app_id,
          (response) => {
            setMessageLoading(false);
            // console.log("jkfgkasbjgkaefjw", response);
            let chat_history = [...response];
            setmessagearray([...chat_history.reverse()]);
            setGoToBottom(true);
            handleStoreData("personal", response, selectedFriend);
            // setsetFlag(true);
          }
        );
      } else if (selectedGroup) {
        var foundObj = tempStorage.find(
          (o) => o.chatObj["group_name"] === selectedGroup.group_name
        );
        if (foundObj) {
          var tempdata = tempStorage.filter(
            (item) => item.chatObj.group_name !== selectedGroup.group_name
          );
          localStorage.setItem("storedChat", JSON.stringify(tempdata));
        }

        socket.emit(
          "group_details",
          selectedGroup.thread_id,
          app_id,
          (resp) => {
            setMessageLoading(false);
            console.log(resp, "chat_history");
            setmessagearray([...resp.payload.chat_history.reverse()]);

            handleStoreData("group", resp.payload.chat_history, selectedGroup);
            setGoToBottom(true);
            setgroupMembers([...resp.payload.group_details.participants_info]);
            var tempData = [];
            resp.payload.group_details.participants_info.map((item) => {
              tempData.push(item.username);
            });
            console.log(tempData, "jhjdwedfq");
            const index = tempData.indexOf(
              userObj
                ? userObj.username
                : JSON.parse(localStorage.getItem("userObj")).username
            );
            if (index > -1) {
              tempData.splice(index, 1);
            }
            console.log(tempData, "jhjdwedfq");
            setMemberUsernames([...tempData]);
            setRefetchData(false);
          }
        );
      }
    }
  };

  // useEffect(() => {
  //   console.log(selectedGroup, selectedItem, selectedFriend, "jbqdkjwbdkwbd");
  //   if (selectedGroup !== null) {
  //     conditionalEditor();
  //   } else if (selectedFriend) {
  //     return (
  //       <div
  //         style={{
  //           background: "lightgray",
  //           padding: "15px",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         This is a&nbsp;
  //         <span style={{ color: "#EC5A45", fontWeight: "bold" }}>
  //           read-only
  //         </span>
  //         &nbsp;group for you.
  //       </div>
  //     );
  //   }
  // }, [activeTab]);

  // const conditionalEditor = () => {
  //   console.log(sendMessageState, "kjhwvcjhwvfjwhvf");
  //   if (selectedFriend) {
  //     return <Editor />;
  //   } else {
  //     if (sendMessageState) {
  //       return <Editor />;
  //     } else {
  //       return (
  //         <div
  //           style={{
  //             background: "lightgray",
  //             padding: "15px",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           This is a&nbsp;
  //           <span style={{ color: "#EC5A45", fontWeight: "bold" }}>
  //             read-only
  //           </span>
  //           &nbsp;group for you.
  //         </div>
  //       );
  //     }
  //   }
  // };

  const wholeChatBox = (item) => {
    return (
      <>
        <div
          // className="neumorph-noround"
          style={{
            padding: "0px 20px",
            paddingBottom: "7px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "0px",
            width: "100%",
            paddingTop: "5px",
            // backgroundColor: "gray",
            // backgroundColor: "white",
            // background:
            //   localStorage.getItem("mode") === "light"
            //     ? "white"
            //     : localStorage.getItem("mode") === "dark"
            //     ? "#2E2E2E"
            //     : "",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <div>
              {item.avatar ? (
                <Avatar
                  src={item.avatar}
                  size={40}
                  style={{
                    color: "white",
                    backgroundColor: "white",
                    cursor: "pointer",
                    border: "solid 1px gray",
                  }}
                  onClick={(e) => setVisibleUserInfo(!visibleUserInfo)}
                />
              ) : (
                <Avatar
                  onClick={(e) => setVisibleUserInfo(!visibleUserInfo)}
                  size={40}
                  style={{ color: "white", backgroundColor: "#F35D48" }}
                >
                  {item.group_name ? item.group_name[0].toUpperCase() : "#"}
                </Avatar>
              )}
            </div>
            <div
              style={{
                paddingLeft: "10px",
                marginTop: "-7px",
                color: globalColors.chatText,
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {item.username ? (
                    <span>
                      {item.first_name}&nbsp;{item.last_name}
                    </span>
                  ) : (
                    item.group_name
                  )}{" "}
                  &nbsp;
                  {favList.find((o) => o.thread_id === item.thread_id) ? (
                    <StarFilled onClick={handleUnfavorite} />
                  ) : (
                    <StarOutlined onClick={handleFavorite} />
                  )}
                </span>
              </div>
              <div
                style={{
                  margin: "-5px",
                  paddingLeft: "6px",
                }}
              >
                {typingFlag ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flexend",
                      // backgroundColor: "white",
                      // padding: "5px 25px",
                      // color: "white",
                      // borderRadius: "20px",
                      textAlign: "justify",
                      color: "gray",
                    }}
                  >
                    {typingUser}&nbsp;is typing&nbsp;&nbsp;&nbsp;
                    <div class="ticontainer">
                      <div class="tiblock">
                        <div class="tidot"></div>
                        <div class="tidot"></div>
                        <div class="tidot"></div>
                      </div>
                    </div>
                  </div>
                ) : item.group_name ? (
                  `Created By ${getCreator(item)}`
                ) : (
                  <Paragraph
                    style={{ margin: "0px", color: globalColors.chatText }}
                    copyable
                  >
                    {item.email}
                  </Paragraph>
                )}
              </div>
            </div>
            {/* <span className="show-menubar">
              <MenuOutlined
                style={{ cursor: "pointer" }}
                onClick={(e) => setVisibleSidebar(true)}
              />
            </span>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color:
                  localStorage.getItem("mode") === "light"
                    ? "black"
                    : localStorage.getItem("mode") === "dark"
                    ? "lightgray"
                    : "",
              }}
            >
              {item.username ? item.username : item.group_name}{" "}
            </span>
            &nbsp;&nbsp;&nbsp;
            <StarOutlined /> */}
          </div>

          {selectedDeleteMessages.length > 0 ||
          selectedForwardMessages.length > 0 ? (
            <Space>
              {selectedDeleteMessages.length > 0 ? (
                <Button
                  loading={loading ? true : false}
                  onClick={deleteMultipleMessages}
                  type="danger"
                  icon={<DeleteFilled />}
                  style={{ fontWeight: "bold" }}
                >
                  Delete {selectedDeleteMessages.length}{" "}
                  {selectedDeleteMessages.length > 1 ? "Messages" : "Message"}
                </Button>
              ) : (
                ""
              )}
              {selectedForwardMessages.length > 0 ? (
                <Button
                  onClick={(e) => setShowForwardMessageUI(true)}
                  type="primary"
                  icon={<ForwardOutlined />}
                  style={{ fontWeight: "bold" }}
                >
                  Forward {selectedForwardMessages.length}{" "}
                  {selectedForwardMessages.length > 1 ? "Messages" : "Message"}
                </Button>
              ) : (
                ""
              )}
              <Button
                ghost
                type="primary"
                onClick={(e) => {
                  setSelectedDeleteMessages([]);
                  setSelectedForwardMessages([]);
                }}
                icon={<CloseCircleFilled />}
                style={{ fontWeight: "bold" }}
              >
                Dismis Selection
              </Button>
              {/* <CloseCircleFilled
                style={{ color: "#F35D48", fontSize: "20px" }}
              /> */}
            </Space>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <DownloadOutlined
              onClick={(e) => setGoToBottom(!goToBottom)}
              style={{
                padding: "0px 15px",
                color: "lightgray",
                fontSize: "18px",
                cursor: "pointer",
              }}
            /> */}
              {pinnedMessages.length > 0 ? (
                <span
                  className="hover-underline"
                  onClick={(e) =>
                    setVisiblePinnedMessages(!visiblePinnedMessages)
                  }
                  style={{
                    // fontWeight: "normal",
                    fontSize: "14px",
                    color: "#EC407A",
                  }}
                >
                  <PushpinOutlined />
                  &nbsp; {pinnedMessages.length} Pinned Messages
                </span>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              <SyncOutlined
                onClick={(e) => handleRefetchData()}
                style={{
                  padding: "0px 15px",
                  color: "lightgray",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "white",
                  padding: "15px",
                }}
              >
                <SearchOutlined
                  style={{ color: "lightgray", fontSize: "18px" }}
                  onClick={(e) => setShowMessageSearch(true)}
                />
              </div>
              {/* <Badge count={5}>
              <BellOutlined style={{ color: "#f5222d" }} />
            </Badge> */}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {selectedGroup !== null ? (
                <div
                  style={{
                    color: "lightgray",
                    cursor: "pointer",
                    paddingRight: "18px",
                  }}
                  onClick={(e) => setVisibleAddMembers(true)}
                >
                  <PlusOutlined
                    style={{ color: "lightgray", fontSize: "18px" }}
                  />
                </div>
              ) : (
                ""
              )}
              {selectedGroup !== null ? (
                <>
                  <div
                    style={{
                      color: "lightgray",
                      cursor: "pointer",
                      paddingRight: "15px",
                    }}
                    onClick={(e) => setVisibleGroupMembers(true)}
                  >
                    <UserOutlined
                      style={{
                        color: "lightgary",
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                    />
                    &nbsp;{groupMembers.length}
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <span>
              <PushpinOutlined />
              &nbsp; {pinnedMessages.length} Pinned Messages
            </span> */}
              {/* {console.log(selectedGroup, userObj, "kjgwekdwed")} */}
              {selectedGroup && userObj ? (
                selectedGroup.creator_email === userObj.email ? (
                  <Popover
                    placement="bottomRight"
                    // title={text}
                    content={
                      <div style={{ padding: "5px" }}>
                        <div style={{ padding: "5px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setShowEditGroupName(true);
                              // localStorage.removeItem("token");
                              // window.location.reload();
                            }}
                          >
                            <EditOutlined />
                            &nbsp;Edit Group Name
                          </span>
                        </div>
                        <div style={{ padding: "5px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setVisibleCreateRole(true);
                              // localStorage.removeItem("token");
                              // window.location.reload();
                            }}
                          >
                            <UserOutlined />
                            &nbsp;Create User Roles
                          </span>
                        </div>
                        <div style={{ padding: "5px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setVisibleSetUserRoles(true);
                              // localStorage.removeItem("token");
                              // window.location.reload();
                            }}
                          >
                            <UserOutlined />
                            &nbsp;Set User Roles
                          </span>
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <MoreOutlined
                      style={{
                        fontSize: "20px",
                        color: "#A4A4A4",
                        cursor: "pointer",
                      }}
                    />
                  </Popover>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <Divider
          style={{
            margin: "0px",
            background:
              localStorage.getItem("mode") === "light"
                ? "lightgray"
                : localStorage.getItem("mode") === "dark"
                ? "#2E2E2E"
                : "",
          }}
        />
        <div
          style={{
            border: !dragEntered ? "none" : "2px dashed lightgray",
          }}
          onDragEnter={(e) => setDragEntered(true)}
          onDragLeave={(e) => setDragEntered(false)}
          // onDragCapture={(e) => setDragEntered(false)}
          onDropCapture={(e) => {
            setDragEntered(false);
            // handleDroppedFile();
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={handleDroppedFile}
        >
          {dragEntered ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
                color: "lightgray",
                height: "78vh",
              }}
            >
              <b>Drop</b>&nbsp;the file here
            </div>
          ) : (
            <div>
              <AllChats />
            </div>
          )}
        </div>
        {selectedFriend ? (
          <Editor />
        ) : sendMessageState ? (
          <Editor />
        ) : (
          <div
            style={{
              background: "lightgray",
              padding: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            This is a&nbsp;
            <span style={{ color: "#EC5A45", fontWeight: "bold" }}>
              read-only
            </span>
            &nbsp;group for you.
          </div>
        )}
      </>
    );
  };

  const addUserToGroup = (item) => {
    setDisableAddToGroup(true);
    console.log(item, selectedGroup, "params");
    socket.emit(
      "new_user_to_group",
      {
        id: item.id,
        role: "user",
      },
      selectedGroup.thread_id,
      userObj.id,
      app_id,
      (response) => {
        console.log("kjabchvjhwvjhdvwejdhvwe", response);
        if (response.status) {
          console.log(response, "userAdded");
          setVisibleAddMembers(false);
          setRefreshGroup(!refreshGroup);
          setDisableAddToGroup(false);
          setQuery("");
          socket.emit(
            "new_send_group_message",
            {
              message: JSON.stringify(
                `${userObj.first_name} added ${item.first_name} to the group`
              ),
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              timestamp: Date.now(),
              msgtype: "group",
            },
            memberUsernames,
            app_id,
            (response) => {
              console.log(response, "new group message after adding an user");
              if (response.status) {
                getGroupMembers();
                console.log(response, "new group message after adding an user");
                setRefreshGroup(!refreshGroup);
                setmessagearray([
                  ...messagearray,
                  {
                    message: JSON.stringify(
                      `${userObj.first_name} added ${item.first_name} to the group `
                    ),
                    threadId: selectedGroup.thread_id,
                    sender: userObj,
                    timestamp: Date.now(),
                    msgtype: "group",
                  },
                ]);

                let tempArr1 = [...groupReadUnreadList];

                const objIndex = tempArr1.findIndex(
                  (obj) => obj.group_name === selectedGroup.group_name
                );
                if (tempArr1[objIndex]) {
                  tempArr1[objIndex].sender = userObj.username;

                  let newArr = [
                    ...tempArr1.filter(
                      (o) => o.group_name === selectedGroup.group_name
                    ),
                  ];
                  tempArr1 = tempArr1.filter(
                    (obj) => obj.group_name !== selectedGroup.group_name
                  );
                  newArr = [...newArr, ...tempArr1];
                  console.log(newArr);
                  setgroupReadUnreadList([...newArr]);
                }
              } else {
                console.log(response);
                setSubmitLoading(false);
                message.error(response.payload);
              }
            }
          );
        } else {
          message.error("Could not add user to the group");
        }

        // setSelectedGroup(setSelectedGroup);
      }
    );
    // socket.emit(
    //   "add_user_to_group",
    //   selectedGroup.thread_id,
    //   item.username,
    //   app_id,
    //   (response) => {
    //     if (response.status) {
    //       console.log(response, "userAdded");
    //       setVisibleAddMembers(false);
    //       setRefreshGroup(!refreshGroup);
    //       setDisableAddToGroup(false);
    //       setQuery("");
    //       socket.emit(
    //         "new_send_group_message",
    //         {
    //           message: JSON.stringify(
    //             `${userObj.first_name} added ${item.first_name} to the group`
    //           ),
    //           thread_id: selectedGroup.thread_id,
    //           sender: userObj,
    //           timestamp: Date.now(),
    //           msgtype: "group",
    //         },
    //         memberUsernames,
    //         app_id,
    //         (response) => {
    //           console.log(response, "new group message after adding an user");
    //           if (response.status) {
    //             console.log(response, "new group message after adding an user");
    //             setRefreshGroup(!refreshGroup);
    //             setmessagearray([
    //               ...messagearray,
    //               {
    //                 message: JSON.stringify(
    //                   `${userObj.first_name} added ${item.first_name} to the group `
    //                 ),
    //                 threadId: selectedGroup.thread_id,
    //                 sender: userObj,
    //                 timestamp: Date.now(),
    //                 msgtype: "group",
    //               },
    //             ]);

    //             let tempArr1 = [...groupReadUnreadList];

    //             const objIndex = tempArr1.findIndex(
    //               (obj) => obj.group_name === selectedGroup.group_name
    //             );
    //             tempArr1[objIndex].sender = userObj.username;

    //             let newArr = [
    //               ...tempArr1.filter(
    //                 (o) => o.group_name === selectedGroup.group_name
    //               ),
    //             ];
    //             tempArr1 = tempArr1.filter(
    //               (obj) => obj.group_name !== selectedGroup.group_name
    //             );
    //             newArr = [...newArr, ...tempArr1];
    //             console.log(newArr);
    //             setgroupReadUnreadList([...newArr]);
    //             getGroupMembers();
    //           } else {
    //             console.log(response);
    //           }
    //         }
    //       );
    //     } else {
    //       message.error("Could not add user to the group");
    //     }

    //     // setSelectedGroup(setSelectedGroup);
    //   }
    // );
  };

  const handleRemoveUser = (item) => {
    console.log(item, userObj, "kjwgkwekdwefhd");
    socket.emit(
      "new_remove_user_from_group",
      // selectedGroup.group_name,
      item.id,
      userObj.id,
      selectedGroup.thread_id,
      app_id,
      (response) => {
        console.log(response, "kjhwbedhwvjhw");
        if (response.status) {
          socket.emit(
            "new_send_group_message",
            {
              message: JSON.stringify(
                `${userObj.first_name} removed ${item.first_name} from the group`
              ),
              thread_id: selectedGroup.thread_id,
              sender: userObj,
              timestamp: Date.now(),
              msgtype: "group",
            },
            memberUsernames,
            app_id,
            (response) => {
              console.log(response, "new group message after adding an user");
              if (response.status) {
                // console.log(response, "new group message after adding an user");
                setRefreshGroup(!refreshGroup);
                setmessagearray([
                  ...messagearray,
                  {
                    message: JSON.stringify(
                      `${userObj.first_name} removed ${item.first_name} from the group`
                    ),
                    threadId: selectedGroup.thread_id,
                    sender: userObj,
                    timestamp: Date.now(),
                    msgtype: "group",
                  },
                ]);

                let tempArr1 = [...groupReadUnreadList];

                const objIndex = tempArr1.findIndex(
                  (obj) => obj.group_name === selectedGroup.group_name
                );
                if (tempArr1[objIndex] !== undefined) {
                  tempArr1[objIndex].sender = userObj.username;

                  let newArr = [
                    ...tempArr1.filter(
                      (o) => o.group_name === selectedGroup.group_name
                    ),
                  ];
                  tempArr1 = tempArr1.filter(
                    (obj) => obj.group_name !== selectedGroup.group_name
                  );
                  newArr = [...newArr, ...tempArr1];
                  console.log(newArr);
                  setgroupReadUnreadList([...newArr]);
                  getGroupMembers();
                }
              } else {
                setSubmitLoading(false);
                message.error(response.payload);
                console.log(response);
              }
            }
          );
          getGroupMembers();
          setVisibleGroupMembers(false);
        }
      }
    );
  };

  // const handleRemoveUser = (item) => {
  //   console.log(selectedGroup, item, "kjwjdhwedwjehd");
  //   socket.emit(
  //     "new_remove_user_from_group_v2",
  //     // selectedGroup.group_name,
  //     item,
  //     // userObj.username,
  //     selectedGroup,
  //     app_id,
  //     (response) => {
  //       console.log(response, "kjhwbedhwvjhw");
  //       if (response.status) {
  //         socket.emit(
  //           "new_send_group_message",
  //           {
  //             message: JSON.stringify(
  //               `${userObj.first_name} removed ${item.first_name} from the group`
  //             ),
  //             thread_id: selectedGroup.thread_id,
  //             sender: userObj,
  //             timestamp: Date.now(),
  //             msgtype: "group",
  //           },
  //           memberUsernames,
  //           app_id,
  //           (response) => {
  //             console.log(response, "new group message after adding an user");
  //             if (response.status) {
  //               // console.log(response, "new group message after adding an user");
  //               setRefreshGroup(!refreshGroup);
  //               setmessagearray([
  //                 ...messagearray,
  //                 {
  //                   message: JSON.stringify(
  //                     `${userObj.first_name} removed ${item.first_name} from the group`
  //                   ),
  //                   threadId: selectedGroup.thread_id,
  //                   sender: userObj,
  //                   timestamp: Date.now(),
  //                   msgtype: "group",
  //                 },
  //               ]);

  //               let tempArr1 = [...groupReadUnreadList];

  //               const objIndex = tempArr1.findIndex(
  //                 (obj) => obj.group_name === selectedGroup.group_name
  //               );
  //               if (tempArr1[objIndex] !== undefined) {
  //                 tempArr1[objIndex].sender = userObj.username;

  //                 let newArr = [
  //                   ...tempArr1.filter(
  //                     (o) => o.group_name === selectedGroup.group_name
  //                   ),
  //                 ];
  //                 tempArr1 = tempArr1.filter(
  //                   (obj) => obj.group_name !== selectedGroup.group_name
  //                 );
  //                 newArr = [...newArr, ...tempArr1];
  //                 console.log(newArr);
  //                 setgroupReadUnreadList([...newArr]);
  //                 getGroupMembers();
  //               }
  //             } else {
  //               setSubmitLoading(false);
  //               message.error(response.payload);
  //               console.log(response);
  //             }
  //           }
  //         );
  //         getGroupMembers();
  //         setVisibleGroupMembers(false);
  //       }
  //     }
  //   );
  // };

  const conditionalWidth = () => {
    if (visibleUserInfo) {
      return 14;
    } else {
      return 18;
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const uploadGroupAvatar = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data, "uploaded");
      setGroupAvatarUrl(uploaded_data.location);
      message.destroy();
      message.success("File Upload Success", 2);
    } catch (e) {
      // console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  const handleOnChangeGroupAvatar = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const changeUserRole = (item, index, checkBox) => {
    var tempData = userRoles;
    if (checkBox === "SendMessage") {
      tempData[index].addPost = !tempData[index].addPost;
    } else if (checkBox === "DeleteMessage") {
      tempData[index].deletePost = !tempData[index].deletePost;
    } else if (checkBox === "AddUser") {
      tempData[index].addUser = !tempData[index].addUser;
    } else if (checkBox === "RemoveUser") {
      tempData[index].removeUser = !tempData[index].removeUser;
    } else if (checkBox === "RoleName") {
    }
    setUserRoles([...tempData]);
  };

  const getInputValue = (index, e) => {
    var tempData = userRoles;
    tempData[index].roleName = e.target.value;
  };

  const removeUserRole = (index) => {
    var tempData = userRoles;
    tempData.splice(index, 1);
    setUserRoles([...tempData]);
  };

  useEffect(() => {
    getRoles();
  }, [selectedGroup]);

  const getRoles = () => {
    if (selectedGroup) {
      socket.emit(
        `get_all_group_roles`,
        selectedGroup.thread_id,
        app_id,
        (res) => {
          if (res.status) {
            if (res.payload.length > 0) {
              console.log(res.payload, "wjhvefjwebf");
              setAllRoles(res.payload);
            }
          }
        }
      );
    }
  };

  const handleAddRole = () => {
    socket.emit(
      "add_group_role",
      userObj.id,
      selectedGroup.thread_id,
      {
        name: roleName,
        permissions: permissions,
      },
      app_id,
      (res) => {
        if (res.status) {
          socket.emit(
            // "get_user_group_interaction_list",
            "new_user_group_interaction_list",
            userObj.email,
            app_id,
            (response) => {
              //   setGroupsLoading(false);
              console.log("get_user_group_interaction_list", response);
              getRoles();
              setRoleName("");
              setPermissions([]);
            }
          );
          // console.log(res, selectedGroup.roles, "kjwdgwqkdfgwqkd");
        }
      }
    );
  };

  useEffect(() => {
    console.log(roleName, "kjwkjwbefkjwe");
  }, [roleName]);

  const handleUpdateRole = () => {
    socket.emit(
      "update_role_permissions",

      selectedGroup.thread_id,
      userObj.id,
      {
        name: roleName,
        permissions: permissions,
      },
      app_id,
      (res) => {
        console.log(res, "kjwkjwbefkjwe");
        getRoles();
        setRoleName("");
        setPermissions([]);
        setUpdatePermission(false);
      }
    );
  };

  const handleSetRole = (user, role) => {
    socket.emit(
      `add_participant_role_in_group`,
      selectedGroup.thread_id,
      user.id,
      userObj.id,
      role.name,
      app_id,
      (res) => {
        console.log(res, "ressssssss");
        if (res.status) {
          getGroupMembers();
        }
      }
    );
    console.log(user, role, "kjbcdkwbek");
  };

  const handleRemoveRole = (user) => {
    socket.emit(
      `remove_participant_role_in_group`,
      selectedGroup.thread_id,
      user.id,
      userObj.id,
      app_id,
      (res) => {
        if (res.status) {
          getGroupMembers();
        }
      }
    );
  };

  useEffect(() => {
    if (selectedGroup) {
      getGroupMembers();
    }
  }, []);

  const handleChange = (e, key) => {
    if (e.target.checked === true) {
      if (permissions.length > 0) {
        if (permissions.find((o) => o !== key)) {
          setPermissions([...permissions, key]);
        }
      } else {
        setPermissions([...permissions, key]);
      }
    } else {
      if (permissions.find((o) => o === key)) {
        setPermissions(permissions.filter((o) => o !== key));
      }
    }
  };

  return (
    <>
      <Col
        className="hidden-sidebar gray-scroll"
        xs={23}
        sm={22}
        md={23}
        lg={
          !visibleUserInfo && !hideSidebar && window.innerWidth > 900
            ? 18
            : !visibleUserInfo && hideSidebar
            ? 23
            : visibleUserInfo && hideSidebar
            ? 19
            : 14
        }
        style={{
          textAlign: "left",
          background: globalColors.chatBackground,
          color: globalColors.chatText,
        }}
      >
        <div>{showMultipleChat()}</div>
        {/* {chatBoxArr.length > 0 ? (
          <div>{showMultipleChat()}</div>
        ) : (
          <>
            <div
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
                background:
                  localStorage.getItem("mode") === "light"
                    ? "white"
                    : localStorage.getItem("mode") === "dark"
                    ? "#2E2E2E"
                    : "",
                height: "70px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="show-menubar">
                  <MenuOutlined
                    style={{ cursor: "pointer" }}
                    onClick={(e) => setVisibleSidebar(true)}
                  />
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color:
                      localStorage.getItem("mode") === "light"
                        ? "black"
                        : localStorage.getItem("mode") === "dark"
                        ? "lightgray"
                        : "",
                  }}
                >
                  {selectedChatType !== null ? conditionalChatTitle() : ""}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SyncOutlined
                  onClick={(e) => setRefetchData(true)}
                  style={{
                    padding: "0px 15px",
                    color: "#A4A4A4",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
                {selectedGroup ? (
                  <Badge count={selectedGroup ? selectedGroup.unread_count : 0}>
                    <BellOutlined
                      style={{ color: "#A4A4A4", fontSize: "18px" }}
                      onClick={(e) => {
                        socket.emit(
                          "new_group_chat_message_read",
                          userObj.username,
                          selectedGroup.thread_id,
                          app_id,
                          (response) => {
                            setGoToBottom(true);
                            console.log("jhsdjwhvedj", response);
                          }
                        );
                      }}
                    />
                  </Badge>
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "white",
                    padding: "15px",
                  }}
                >
                  <SearchOutlined
                    style={{ color: "#A4A4A4", fontSize: "18px" }}
                    onClick={(e) => setShowMessageSearch(true)}
                  />
                </div>
                {selectedGroup !== null ? (
                  <div
                    style={{
                      color: "#A4A4A4",
                      cursor: "pointer",
                    }}
                    onClick={(e) => setVisibleAddMembers(true)}
                  >
                    <PlusOutlined
                      style={{ color: "#A4A4A4", fontSize: "18px" }}
                    />
                  </div>
                ) : (
                  ""
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {selectedGroup !== null ? (
                  <div
                    style={{ color: "#A4A4A4", cursor: "pointer" }}
                    onClick={(e) => setVisibleGroupMembers(true)}
                  >
                    <UserOutlined />
                    &nbsp;{groupMembers ? groupMembers.length : 0}
                  </div>
                ) : (
                  ""
                )}
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                {selectedGroup && userObj ? (
                  selectedGroup.creator_email === userObj.email ? (
                    <Popover
                      placement="bottomRight"
                      // title={text}
                      content={
                        <>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setShowEditGroupName(true);
                              // localStorage.removeItem("token");
                              // window.location.reload();
                            }}
                          >
                            <EditOutlined />
                            &nbsp;&nbsp; Edit Group Name
                          </span>
                        </>
                      }
                      trigger="click"
                    >
                      <MoreOutlined
                        style={{
                          fontSize: "20px",
                          color: "#A4A4A4",
                          cursor: "pointer",
                        }}
                      />
                    </Popover>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <Divider
              style={{
                margin: "0px",
                background:
                  localStorage.getItem("mode") === "light"
                    ? "lightgray"
                    : localStorage.getItem("mode") === "dark"
                    ? "#2E2E2E"
                    : "",
              }}
            />

            <div
              style={{
                border: !dragEntered ? "none" : "2px dashed lightgray",
              }}
              onDragEnter={(e) => setDragEntered(true)}
              onDragLeave={(e) => setDragEntered(false)}
              // onDragCapture={(e) => setDragEntered(false)}
              onDropCapture={(e) => {
                setDragEntered(false);
                // handleDroppedFile();
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={handleDroppedFile}
            >
              {dragEntered ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: "lightgray",
                    height: "78vh",
                  }}
                >
                  <b>Drop</b>&nbsp;the file here
                </div>
              ) : (
                <div>
                  <AllChats />
                </div>
              )}
            </div>
            <div style={{ width: "auto" }}>
              <Editor />
            </div>
          </>
        )} */}
        {/* Toggle Hidden Menu in mobile view */}
        <Drawer
          bodyStyle={{ padding: "0px" }}
          // title="Basic Drawer"
          placement={"left"}
          closable={false}
          onClose={(e) => setVisibleSidebar(false)}
          visible={visibleSidebar}
          height="100vh"
          // bodyStyle={{ backgroundColor: "#192A50" }}
        >
          <Row>
            <Col
              span={4}
              style={{
                height: "100vh",
              }}
            >
              {selectedChatType !== null ? <ChatOrgList /> : ""}
            </Col>
            <Col
              span={20}
              style={{ background: "black", padding: "10px", height: "100vh" }}
            >
              {selectedChatType !== null ? <ChatMenu /> : ""}
            </Col>
          </Row>
        </Drawer>

        <Drawer
          width={300}
          bodyStyle={{
            padding: "0px",
            background: globalColors.drawerBackground,
          }}
          headerStyle={{
            background: globalColors.drawerBackground,
          }}
          title={
            <span style={{ color: globalColors.chatText }}>Group Members</span>
          }
          placement={"right"}
          closable={false}
          onClose={(e) => setVisibleGroupMembers(false)}
          visible={visibleGroupMembers}
          height="100vh"
          // bodyStyle={{ backgroundColor: "#192A50" }}
        >
          {/* {console.log(groupMembers, "hjhwfjwh")} */}

          {groupMembers.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: globalColors.chatText,
                }}
                key={index}
                // className={
                //   selectedFriend === item.username
                //     ? "selected-friend"
                //     : "friend-name"
                // }
                className={
                  globalColors.darkMode ? "friend-name-dark" : "friend-name"
                }
                // onClick={(e) => addUserToGroup(item)}
                // onClick={(e) => {
                //   if (item !== userObj.username) {
                //     setmessagearray([]);
                //     setSelectedFriend({
                //       username: item,
                //     });
                //     setSelectedGroup(null);
                //     setSelectedSupport(null);
                //     setMessageLoading(true);
                //     setVisibleGroupMembers(false);
                //   } else {
                //     message.warning("You wanna talk to yourself ??? Weired!!!");
                //   }
                // }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   setSelectedFriend(item);
                //   setSelectedGroup(null);
                //   setSelectedSupport(false);
                // }}
              >
                {/* <img
              src={require(`../../images/user.jpg`)}
              alt=""
              style={{ width: "30px", borderRadius: "5px" }}
            /> */}
                <div>
                  <Avatar src={item.avatar} />
                  &nbsp;&nbsp;
                  {onlineUsers.indexOf(item) !== -1 ? (
                    <Badge color={"#70CC16"} />
                  ) : (
                    <Badge color={"lightgray"} />
                  )}
                  <span>
                    {item.first_name}&nbsp;{item.last_name}
                  </span>
                </div>
                {selectedGroup && userObj ? (
                  selectedGroup.creator_email === userObj.email ? (
                    <Button
                      size="small"
                      type="danger"
                      onClick={(e) => handleRemoveUser(item)}
                    >
                      Remove
                    </Button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </Drawer>

        {/* Add User to Group */}

        <Drawer
          bodyStyle={{
            padding: "0px",
            background: globalColors.drawerBackground,
          }}
          headerStyle={{
            background: globalColors.drawerBackground,
          }}
          title={
            <span style={{ color: globalColors.chatText }}>
              Add Member To Group
            </span>
          }
          placement={"right"}
          closable={false}
          onClose={(e) => setVisibleAddMembers(false)}
          visible={visibleAddMembers}
          height="100vh"
          // bodyStyle={{ backgroundColor: "#192A50" }}
        >
          {/* {console.log(groupMembers, "hjhwfjwh")} */}

          {groupMembers.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  selectedFriend === item.username
                    ? "selected-friend"
                    : "friend-name"
                }
                // onClick={(e) => addUserToGroup(item)}
              >
                <Avatar src={item.avatar} />
                &nbsp;&nbsp;
                {onlineUsers.indexOf(item) !== -1 ? (
                  <Badge color={"#70CC16"} />
                ) : (
                  <Badge color={"lightgray"} />
                )}
                <span>
                  {item.first_name}&nbsp;{item.last_name}
                </span>
              </div>
            );
          })}
        </Drawer>

        <Drawer
          bodyStyle={{
            // padding: "0px",
            background: globalColors.drawerBackground,
          }}
          headerStyle={{
            background: globalColors.drawerBackground,
          }}
          title={
            <span style={{ color: globalColors.chatText }}>
              Add User to Group
            </span>
          }
          // bodyStyle={{ backgroundColor: "#196AB4" }}
          autoFocus
          // style={{ zIndex: "999" }}
          // closable={true}
          width={window.innerWidth > 600 ? 380 : window.innerWidth}
          placement="right"
          onClose={(e) => setVisibleAddMembers(false)}
          visible={visibleAddMembers}
        >
          <Input
            className={
              globalColors.darkMode ? "search-input-dark" : "search-input-light"
            }
            autoFocus
            size="large"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            style={{ marginBottom: "10px" }}
            placeholder="Search User"
            suffix={<SearchOutlined style={{ color: "lightgray" }} />}
          />
          {filteredUsersSearch.length > 0 && query.length > 1 ? (
            filteredUsersSearch.map((item, index) => {
              if (
                item.username !== userObj.username &&
                index < 100 &&
                query.length > 1
              )
                return (
                  <>
                    {!disableAddToGroup ? (
                      <ListItem1
                        index={index}
                        addUserToGroup={addUserToGroup}
                        // handleFriendSelection={handleFriendSelection}
                        // handleGroupSelection={handleGroupSelection}
                        // handleSupportSelection={handleSupportSelection}
                        setShowFullname={setShowFullname}
                        showFullname={showFullname}
                        onlineUsers={onlineUsers}
                        key={item.id}
                        active={index === cursor}
                        item={item}
                        setSelected={setSelected}
                        setHovered={setHovered}
                        setQuery={setQuery}
                        filteredUsersSearch={filteredUsersSearch}
                        cursor={cursor}
                        globalColors={globalColors}
                      />
                    ) : (
                      <Loading />
                    )}
                  </>
                );
            })
          ) : (
            <p style={{ color: "lightgray" }}>
              <TeamOutlined /> Search a user to Add to Group
            </p>
          )}
        </Drawer>
      </Col>

      {/* Pinned Messages */}
      <Drawer
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Pinned Messages</span>
        }
        placement="right"
        width={600}
        closable={true}
        onClose={(e) => setVisiblePinnedMessages(false)}
        visible={visiblePinnedMessages}
      >
        {pinnedMessages.length > 0
          ? pinnedMessages.map((item, index) => {
              return (
                <div key={index} style={{ color: globalColors.chatText }}>
                  {showConditionalDate(item, index, pinnedMessages[index - 1])}
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="0"
                          onClick={(e) => {
                            unpinMessage(item);
                          }}
                        >
                          <PushpinOutlined />
                          &nbsp;UnPin Message
                        </Menu.Item>
                        {/* <Menu.Item
                          key="1"
                          onClick={(e) => {
                            setSelectedReplyMessage(item);
                            bodyRef.current.focus();
                          }}
                        >
                         <RollbackOutlined/>
                          &nbsp;Quote & Reply
                        </Menu.Item>

                        <Menu.Item
                          key="2"
                          onClick={(e) =>
                            conditionalDeleteChat(
                              item,
                              messagearray.length > 1
                                ? messagearray[index - 1]
                                : messagearray[index]
                            )
                          }
                        >
                          <DeleteOutlined/>
                          &nbsp;Delete
                        </Menu.Item> */}

                        <Menu.Divider />
                        <Menu.Item key="3">Dismiss</Menu.Item>
                      </Menu>
                    }
                    trigger={["contextMenu"]}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className={
                        globalColors.darkMode ? "each-chat-dark" : "each-chat"
                      }
                    >
                      <div style={{ display: "flex", padding: "10px 0px" }}>
                        <div>
                          {typeof item.sender === "object" &&
                          item.sender !== null ? (
                            <Avatar src={item.sender.avatar} />
                          ) : (
                            <Avatar
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                                // margin: "10px",
                                cursor: "pointer",
                              }}
                            >
                              S
                            </Avatar>
                          )}
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%  ",
                            }}
                          >
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    localStorage.getItem("mode") === "dark"
                                      ? "white"
                                      : "",
                                }}
                              >
                                {selectedSupport !== null
                                  ? adminUsernames.length > 0
                                    ? adminUsernames.indexOf(
                                        typeof item.sender === "object" &&
                                          item.sender !== null
                                          ? item.sender.username
                                          : item.sender
                                      ) > -1
                                      ? "Support"
                                      : typeof item.sender === "object" &&
                                        item.sender !== null
                                      ? item.sender.username
                                      : "Support"
                                    : ""
                                  : item.sender.username}
                              </span>{" "}
                              &nbsp;
                              <span style={{ color: " #8D8D8D" }}>
                                <small>
                                  {new Date(item.timestamp).toLocaleString()}
                                </small>
                              </span>
                              &nbsp;&nbsp;
                            </div>
                            <div
                              className="hover-underline"
                              onClick={(e) => unpinMessage(item)}
                              style={{
                                // fontWeight: "normal",
                                fontSize: "14px",
                                color: "#EC407A",
                              }}
                            >
                              <DeleteOutlined />
                              &nbsp; Unpin Messages
                            </div>
                          </div>
                          <div
                            style={{
                              paddingTop: "5px",
                              color:
                                localStorage.getItem("mode") === "dark"
                                  ? "white"
                                  : "",
                            }}
                          >
                            {conditionalMessageView(item)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              );
            })
          : ""}
      </Drawer>

      {/* Edit Group Name */}
      <Modal
        width={400}
        footer={null}
        title="Edit Group Details"
        visible={showEditGroupName}
        onCancel={() => {
          setShowEditGroupName(false);
          setGroupAvatarUrl("");
          setNewGroupName("");
        }}
      >
        <Form>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Upload
              accept="image/*"
              customRequest={uploadGroupAvatar}
              onChange={handleOnChangeGroupAvatar}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              defaultFileList={defaultFileList}
            >
              {groupAvatarUrl ? (
                <img
                  src={groupAvatarUrl}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : selectedGroup ? (
                selectedGroup.avatar ? (
                  <img
                    src={selectedGroup.avatar}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )
              ) : (
                ""
              )}
            </Upload>
            <div>
              <Input
                autoFocus
                size="large"
                value={
                  newGroupName
                    ? newGroupName
                    : selectedGroup
                    ? selectedGroup.group_name
                    : ""
                }
                placeholder="Type new group name here..."
                onChange={(e) => setNewGroupName(e.target.value)}
              />
              <Button
                onClick={editGroupDetails}
                type="primary"
                ghost
                size="large"
                block
                style={{ marginTop: "20px" }}
              >
                UPDATE GROUP DETAILS
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Drawer
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Search Messages</span>
        }
        // bodyStyle={{ backgroundColor: "#196AB4" }}
        autoFocus
        // style={{ zIndex: "999" }}
        // closable={true}
        width={window.innerWidth > 600 ? 460 : window.innerWidth}
        placement="right"
        // title="Search Messages"
        onClose={(e) => {
          setShowMessageSearch(false);
          setSearchResults([]);
          setSearchQuery("");
        }}
        visible={showMessageSearch}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <Input
            className={
              globalColors.darkMode ? "search-input-dark" : "search-input-light"
            }
            autoFocus
            size="large"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            type="text"
            style={{ marginBottom: "10px" }}
            placeholder="Search Messages..."
            suffix={
              <SearchOutlined
                style={{ color: "lightgray" }}
                onClick={handleSearch}
              />
            }
          />
        </form>
        {searchResults ? (
          searchResults.length > 0 ? (
            searchResults.map((item, index) => {
              return (
                <div key={index}>
                  {showConditionalDate(item, index, searchResults[index - 1])}
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="0"
                          onClick={(e) => {
                            setSelectedItem(item);
                            // pinMessage(item);
                            setShowReactions(true);
                          }}
                        >
                          <SmileOutlined />
                          &nbsp;React
                        </Menu.Item>

                        <Menu.Item
                          key="1"
                          onClick={(e) => {
                            setSelectedReplyMessage(item);
                            bodyRef.current.focus();
                          }}
                        >
                          <RollbackOutlined />
                          &nbsp;Quote & Reply
                        </Menu.Item>

                        <Menu.Item
                          key="2"
                          onClick={(e) => {
                            setThreadItem(item);
                            setShowThread(true);
                            // bodyRef1.current.focus();
                          }}
                        >
                          <MessageOutlined />
                          &nbsp;Reply in thread
                        </Menu.Item>
                        {!item.type ? (
                          item.sender.username ? (
                            item.sender.username === userObj.username ? (
                              <Menu.Item
                                key="3"
                                onClick={(e) => {
                                  bodyRef.current.focus();
                                  setEditorText(item.message);
                                  // bodyRef.current.innerHTML = JSON.parse(
                                  //   item.message
                                  // );
                                  setSelectedEditMessage(item);
                                }}
                              >
                                <EditOutlined />
                                &nbsp;Edit Message
                              </Menu.Item>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <Menu.Item
                          key="4"
                          onClick={(e) => {
                            pinMessage(item);
                          }}
                        >
                          <PushpinOutlined />
                          &nbsp;Pin Message
                        </Menu.Item>
                        {item.sender.username ? (
                          item.sender.username === userObj.username ? (
                            <Menu.Item
                              style={{ color: "red" }}
                              key="5"
                              onClick={(e) =>
                                conditionalDeleteChat(
                                  item,
                                  messagearray.length > 1
                                    ? messagearray[index - 1]
                                    : messagearray[index]
                                )
                              }
                            >
                              <DeleteOutlined />
                              &nbsp;Delete Message
                            </Menu.Item>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        <Menu.Divider />
                        <Menu.Item key="6">
                          <CloseOutlined />
                          &nbsp;Dismiss
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["contextMenu"]}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: globalColors.chatText,
                      }}
                      className={
                        globalColors.darkMode
                          ? "each-chat-dark"
                          : selectedEditMessage
                          ? selectedEditMessage.timestamp === item.timestamp
                            ? "selected-chat"
                            : "each-chat"
                          : "each-chat"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          padding: timeDifference(item, index, searchResults)
                            ? "2px 0px"
                            : "10px 0px",
                          marginTop: timeDifference(item, index, searchResults)
                            ? "10px"
                            : "10px",
                        }}
                      >
                        <div>
                          {typeof item.sender === "object" &&
                          item.sender !== null ? (
                            <Avatar src={item.sender.avatar} />
                          ) : (
                            <Avatar
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                                // margin: "10px",
                                cursor: "pointer",
                              }}
                            >
                              S
                            </Avatar>
                          )}
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                          <div>
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  localStorage.getItem("mode") === "dark"
                                    ? "white"
                                    : "",
                              }}
                            >
                              {selectedSupport !== null
                                ? adminUsernames.length > 0
                                  ? adminUsernames.indexOf(
                                      typeof item.sender === "object" &&
                                        item.sender !== null
                                        ? item.sender.first_name
                                        : item.sender
                                    ) > -1
                                    ? "Support"
                                    : typeof item.sender === "object" &&
                                      item.sender !== null
                                    ? item.sender.first_name
                                    : "Support"
                                  : ""
                                : item.sender.first_name}
                            </span>{" "}
                            &nbsp;
                            <span style={{ color: " #8D8D8D" }}>
                              <small>
                                {formatAMPM(new Date(item.timestamp))}
                                {/* {new Date(item.timestamp).toLocaleString()} */}
                                &nbsp; {item.edit ? <EditOutlined /> : ""}
                              </small>
                            </span>
                          </div>

                          <div
                            style={{
                              paddingTop: "5px",
                              display: "flex",
                            }}
                          >
                            {conditionalMessageView(item)}
                            {timeDifference(item, index, searchResults) ? (
                              <div
                                style={{
                                  color: globalColors.darkMode
                                    ? "#505050"
                                    : "white",
                                }}
                              >
                                &nbsp;&nbsp;
                                <small className="hidden-timestamp">
                                  {formatAMPM(new Date(item.timestamp))}
                                </small>
                                &nbsp;&nbsp;
                                {item.edit ? (
                                  <>
                                    <EditOutlined className="hidden-timestamp" />
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {item.thread_messages_count > 0 ? (
                              <div
                                style={{
                                  paddingTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  type="primary"
                                  onClick={(e) => {
                                    setThreadItem(item);
                                    setShowThread(!showThread);
                                  }}
                                >
                                  Reply
                                </Button>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div>
                                  <MessageOutlined />
                                  &nbsp; {item.thread_messages_count}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              );
            })
          ) : (
            <p style={{ color: "lightgray" }}>
              <MessageOutlined /> Search for a message
            </p>
          )
        ) : (
          <Empty />
        )}
      </Drawer>
      <Drawer
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
          padding: "0px",
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Forward Message</span>
        }
        width={300}
        placement="right"
        // closable={false}
        onClose={(e) => setShowForwardMessageUI(false)}
        visible={showForwardMessageUI}
      >
        <div
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "space-between",
          //   color: "white",
          //   padding: "15px",
          // }}
          style={{ padding: "10px" }}
        >
          <Input
            className={
              globalColors.darkMode ? "search-input-dark" : "search-input-light"
            }
            // onKeyDown={(e) => handleKeyDown(e)}
            // width="100%"
            // autoFocus
            // onFocus={(e) => setSearchUI(true)}
            value={query}
            placeholder="Search..."
            onChange={(e) => setQuery(e.target.value)}
            prefix={<SearchOutlined style={{ color: "lightgray" }} />}
          />
        </div>
        {recipients.length > 0 ? (
          <Space style={{ padding: "5px 10px", paddingBottom: "10px" }}>
            <Button
              onClick={handleForward}
              size="small"
              type="primary"
              ghost
              icon={<ArrowRightOutlined />}
              shape="round"
            >
              Forward Message
            </Button>
            <Button
              size="small"
              shape="round"
              type="danger"
              ghost
              icon={<CloseOutlined />}
              onClick={(e) => setRecipients([])}
            >
              Dismiss
            </Button>
            {/* <BellOutlined style={{ fontSiz: "20px" }} /> */}
          </Space>
        ) : (
          ""
        )}

        <div style={{ padding: "15px", marginTop: "-20px" }}>
          <div>
            {filteredUsers.map((item, index) => {
              return (
                <div
                  style={{ color: globalColors.chatText }}
                  className={
                    recipients.length > 0
                      ? recipients.find((o) => o.thread_id === item.thread_id)
                        ? "selected-recipient"
                        : ""
                      : ""
                  }
                >
                  <ListItem
                    index={index}
                    handleForward={handleForward}
                    key={item.id}
                    active={index === cursor}
                    item={item}
                    setSelected={setSelected}
                    setHovered={setHovered}
                    setQuery={setQuery}
                    setShowFullname={setShowFullname}
                    showFullname={showFullname}
                    setShowForwardMessageUI={setShowForwardMessageUI}
                    setSelectedForwardMessages={setSelectedForwardMessages}
                    recipients={recipients}
                    setRecipients={setRecipients}
                    handleReciverArray={handleReciverArray}
                  />
                </div>
              );
            })}
            {/* {query.length > 1
                ? filteredUsers.map((item, index) => {
                    return (
                      <div
                        id="res"
                        key={index}
                        className={
                          selectedFriend
                            ? selectedFriend.email === item.email
                              ? "selected-friend"
                              : "friend-name"
                            : "friend-name"
                        }
                        onClick={(e) => handleForward(item, "friend")}
                        // style={{ display: "flex" }}
                      >
                        <Avatar src={item.avatar} />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {onlineUsers.indexOf(item.username) !== -1 ? (
                          <Badge color={"#70CC16"} />
                        ) : (
                          <Badge color={"lightgray"} />
                        )}
                        <span>
                          {showFullname === index ? (
                            item.first_name
                          ) : (
                            <span>
                              {item.first_name.length > 18 ? (
                                <span>
                                  {item.first_name.substring(0, 18)}...
                                </span>
                              ) : (
                                item.first_name
                              )}
                            </span>
                          )}
                        </span>
                        &nbsp;{" "}
                      </div>
                    );
                  })
                : ""} */}
          </div>
          {/* <div>
              {query.length > 1
                ? filteredGroups.map((item, index) => {
                    return (
                      <p
                        id="res"
                        className="group-name"
                        onClick={(e) => handleForward(item, "group")}
                      >
                     
                        {item.group_name.length > 18 ? (
                          <>
                            {item.avatar ? (
                              <Avatar src={item.avatar} size="small" />
                            ) : (
                              <Avatar
                                size="small"
                                style={{
                                  color: "white",
                                  backgroundColor: "#F35D48",
                                }}
                              >
                                {item.group_name[0].toUpperCase()}
                              </Avatar>
                            )}
                            <span>
                              &nbsp;
                              {item.group_name.substring(0, 18)}...
                            </span>
                          </>
                        ) : (
                          <>
                            {item.avatar ? (
                              <Avatar src={item.avatar} size="small" />
                            ) : (
                              <Avatar
                                size="small"
                                style={{
                                  color: "white",
                                  backgroundColor: "#F35D48",
                                }}
                              >
                                {item.group_name[0].toUpperCase()}
                              </Avatar>
                            )}
                            &nbsp;
                            {item.group_name}
                          </>
                        )}
                      </p>
                    );
                  })
                : ""}
            </div>
            <div>
              {query.length > 1
                ? filteredSupport.map((item, index) => {
                    return (
                      <p
                        id="res"
                        className="group-name"
                        onClick={(e) => handleForward(item, "support")}
                      >
                       
                        {item.group_name.replace("GX-Support ", "").length >
                        15 ? (
                          <span>
                            # &nbsp;&nbsp;
                            {item.group_name
                              .replace("GX-Support ", "")
                              .substring(0, 15)}
                            ...
                          </span>
                        ) : (
                          <>
                            # &nbsp;&nbsp;
                            {item.group_name.replace("GX-Support ", "")}
                          </>
                        )}
                      </p>
                    );
                  })
                : ""}
            </div>
          */}
        </div>
      </Drawer>

      {/* Create Role Drawer */}

      <Drawer
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Create Roles</span>
        }
        placement="right"
        width="100%"
        closable={true}
        onClose={(e) => setVisibleCreateRole(false)}
        visible={visibleCreateRole}
      >
        <Row style={{ fontWeight: "bold", color: globalColors.chatText }}>
          <Col span={4}>User Roles</Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            Send Message
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            Delete Message
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            Add User
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            Remove User
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            Add Role
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            Remove Role
          </Col>
          <Col span={2}>&nbsp;</Col>
          {/* <Col
            span={20}
            style={{ display: "flex", justifyContent: "space-around" }}
          ></Col> */}
        </Row>

        {allRoles
          ? allRoles.length > 0
            ? allRoles.map((item) => {
                return (
                  <Card
                    style={{
                      marginTop: "20px",
                      borderRadius: "10px",
                      boxShadow: `5px 8px 24px 5px ${
                        globalColors.darkMode
                          ? globalColors.chatBackground
                          : "rgba(208, 216, 243, 0.6)"
                      }`,
                    }}
                    bodyStyle={{
                      padding: "10px",
                      background: globalColors.chatMenuBackground,
                      borderRadius: "10px",
                      borderColor: globalColors.chatMenuBackground,
                    }}
                  >
                    <Row
                      style={{
                        fontWeight: "bold",
                        color: globalColors.chatText,
                      }}
                    >
                      <Col span={4}>{item.name}</Col>
                      <Col
                        span={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          checked={item.permissions.find(
                            (o) => o === "F59fgHkBR9ekWhLu"
                          )}
                        />
                      </Col>
                      <Col
                        span={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          checked={item.permissions.find(
                            (o) => o === "AG69GGkqrDMDuuN5"
                          )}
                        />
                      </Col>
                      <Col
                        span={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          checked={item.permissions.find(
                            (o) => o === "8C68TS9KuXmU8NfD"
                          )}
                        />
                      </Col>
                      <Col
                        span={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          checked={item.permissions.find(
                            (o) => o === "WZAsQy2UTct79nPc"
                          )}
                        />
                      </Col>
                      <Col
                        span={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          checked={item.permissions.find(
                            (o) => o === "ZEM29PBKHs95UARf"
                          )}
                        />
                      </Col>
                      <Col
                        span={3}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          checked={item.permissions.find(
                            (o) => o === "YhNtvMu7CdsqY5UD"
                          )}
                        />
                      </Col>
                      <Col
                        span={2}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <EditOutlined
                          onClick={(e) => {
                            setAllRoles(
                              allRoles.filter((o) => o.name !== item.name)
                            );
                            setUpdatePermission(true);
                            setRoleName(item.name);
                            setPermissions([...item.permissions]);
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                );
              })
            : ""
          : ""}

        {updatePermission ? (
          <h4
            style={{
              margin: "20px 0px",
              color: globalColors.chatText,
              fontWeight: "bold",
            }}
          >
            Update Selected Role: "{roleName}"
          </h4>
        ) : (
          ""
        )}

        <Card
          style={{ marginTop: "20px", borderRadius: "10px" }}
          bodyStyle={{
            padding: "10px",
            background: globalColors.chatMenuBackground,
            borderRadius: "10px",
            boxShadow: `5px 8px 24px 5px ${
              globalColors.darkMode
                ? globalColors.chatBackground
                : "rgba(208, 216, 243, 0.6)"
            }`,
          }}
        >
          <Row>
            <Col
              span={4}
              style={{ color: globalColors.chatText, fontWeight: "bold" }}
            >
              {!updatePermission ? (
                <Input
                  style={{
                    borderRadius: "10px",
                  }}
                  value={roleName}
                  type="text"
                  placeholder="Role Name"
                  onChange={(e) => setRoleName(e.target.value)}
                  // onChange={(e) => getInputValue(index, e)}
                  // onChange={(e) => changeUserRole(item, index, "RoleName")}
                />
              ) : (
                roleName
              )}
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox onChange={(e) => handleChange(e, "F59fgHkBR9ekWhLu")} />
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox onChange={(e) => handleChange(e, "AG69GGkqrDMDuuN5")} />
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox onChange={(e) => handleChange(e, "8C68TS9KuXmU8NfD")} />
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox onChange={(e) => handleChange(e, "WZAsQy2UTct79nPc")} />
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox onChange={(e) => handleChange(e, "ZEM29PBKHs95UARf")} />
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox onChange={(e) => handleChange(e, "YhNtvMu7CdsqY5UD")} />
            </Col>
          </Row>
        </Card>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          {updatePermission ? (
            <Button type="primary" onClick={handleUpdateRole}>
              UPDATE ROLE
            </Button>
          ) : (
            <Button type="primary" onClick={handleAddRole}>
              ADD ROLE
            </Button>
          )}
        </div>
      </Drawer>
      <Drawer
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headerStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Create Roles</span>
        }
        placement="right"
        width="50%"
        closable={true}
        onClose={(e) => setVisibleSetUserRoles(false)}
        visible={visibleSetUserRoles}
      >
        {groupMembers.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: globalColors.chatText,
                paddingBottom: "10px",
                padding: "10px",
              }}
              key={index}
              className={
                globalColors.darkMode ? "friend-name-dark" : "friend-name"
              }
            >
              <div>
                <Avatar src={item.avatar} />
                &nbsp;&nbsp;
                {onlineUsers.indexOf(item) !== -1 ? (
                  <Badge color={"#70CC16"} />
                ) : (
                  <Badge color={"lightgray"} />
                )}
                <span>
                  {item.first_name}&nbsp;{item.last_name}&nbsp;
                  <Tag color="magenta">{item.role ? item.role : ""}</Tag>
                </span>
              </div>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    {allRoles.map((item1) => {
                      return (
                        <Menu.Item
                          key="1"
                          icon={<UserOutlined />}
                          onClick={(e) => handleSetRole(item, item1)}
                        >
                          {item1.name}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
              >
                <Space>
                  <Button>
                    Change Role <DownOutlined />
                  </Button>
                  <CloseCircleOutlined
                    style={{ fontSize: "20px" }}
                    onClick={(e) => handleRemoveRole(item)}
                  />
                </Space>
              </Dropdown>

              {/* <Select
                defaultValue="lucy"
                style={{ width: 120 }}
                // onChange={handleChange}
              >
                <Option value="jack">User</Option>
                <Option value="lucy">Maintainer</Option>
                <Option value="Yiminghe">Admin</Option>
              </Select> */}
            </div>
          );
        })}
      </Drawer>
    </>
  );
};

export default ChatBox;

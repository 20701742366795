import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Input,
  message,
  Avatar,
  Popover,
  Dropdown,
  Menu,
  Col,
  Divider,
  Empty,
  Space,
  Affix,
  Button,
  Switch,
} from "antd";
import {
  SearchOutlined,
  LoadingOutlined,
  TeamOutlined,
  BellOutlined,
  CloseOutlined,
  CheckOutlined,
  SettingOutlined,
  BulbFilled,
  BulbOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SwitcherTwoTone,
  CheckSquareTwoTone,
  DeleteTwoTone,
  CloseSquareTwoTone,
  UnorderedListOutlined,
} from "@ant-design/icons";

import axios from "axios";

import { MyContext } from "../../Context";
import GroupList from "./GroupList";
import FriendList from "./FriendList";
import SupportList from "./SupportList";
import UnreadList from "./UnreadList";
import FavoriteList from "./FavoriteList";

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const ListItem = ({
  index,
  handleFriendSelection,
  handleGroupSelection,
  handleSupportSelection,
  setShowFullname,
  showFullname,
  onlineUsers,
  item,
  active,
  setSelected,
  setHovered,
  setQuery,
  selectedSupport,
  selectedFriend,
  selectedGroup,
  conditionalBadge,
}) => (
  <div
    onClick={(e) => {
      setQuery("");
      if (item.first_name) {
        handleFriendSelection(item);
      } else if (item.group_name) {
        if (item.group_name.includes("support")) {
          handleSupportSelection(item);
        } else {
          handleGroupSelection(item);
        }
      }
    }}
    className={`item ${active ? "active" : "group-name"}`}
    // onClick={() => setSelected(item)}
    onMouseEnter={() => setHovered(item)}
    onMouseLeave={() => setHovered(undefined)}
    key={index}

    // className={
    //   selectedFriend
    //     ? selectedFriend.email === item.email ||
    //       selectedHighlight.email === item.email
    //       ? "selected-friend"
    //       : "friend-name"
    //     : "friend-name"
    // }

    // style={{ display: "flex" }}
  >
    <Space
      style={{ paddingRight: "5px" }}
      onMouseEnter={(e) => setShowFullname(index)}
      onMouseLeave={(e) => setShowFullname(null)}
    >
      {item.avatar ? (
        <Avatar src={item.avatar} />
      ) : (
        <Avatar>{item.group_name ? item.group_name[0] : ""}</Avatar>
      )}
      {item.username ? (
        onlineUsers.indexOf(item.username) !== -1 ? (
          <Badge color={"#70CC16"} />
        ) : (
          <Badge color={"lightgray"} />
        )
      ) : (
        ""
      )}
      {showFullname === index ? (
        item.first_name ? (
          item.first_name
        ) : (
          item.group_name
        )
      ) : item.first_name ? (
        <span>
          {item.first_name.length > 18 ? (
            <span>{item.first_name.substring(0, 18)}...</span>
          ) : (
            item.first_name
          )}
        </span>
      ) : (
        <span>
          {item.group_name.length > 18 ? (
            <span>{item.group_name.substring(0, 18)}...</span>
          ) : (
            item.group_name
          )}
        </span>
      )}
      {/* <span>
{item.first_name.length > 12 ? (
  <>{item.first_name.substring(0, 12)}...</>
) : (
  item.first_name
)}
</span> */}
    </Space>
    &nbsp; {conditionalBadge(item)}
  </div>
);

const ChatMenu = () => {
  const {
    url,
    socket,
    usersocket,
    currentUserObj,
    setCurrentUserObj,
    // currentUserList,
    setgroupReadUnreadList,
    setcurrentUserList,
    setUnreadInteractionList,
    setuserReadUnreadList,
    supportgroupReadUnreadList,
    setsupportgroupReadUnreadList,

    setSelectedFriend,
    setSelectedGroup,
    setSelectedSupport,
    setVisibleUserInfo,
    setMessageLoading,
    setGroupsLoading,
    setFriendsLoading,
    setmessagearray,
    unreadInteractionList,
    groupReadUnreadList,
    setSelectedAdminSupport,
    // selectedGroup,
    selectedFriend,
    chatBoxArr,
    setChatBoxArr,
    selectedSupport,
    selectedAdminSupport,
    selectedGroup,
    activeTab,
    setActiveTab,
    getSupportList,
    userObj,
    setUserObj,
    app_id,
    getAdminSupportList,
    adminUsernames,
    isAdmin,
    selectedChatType,
    setSelectedChatType,
    selectedOrg,
    allOrgs,
    constants,
    onlineUsers,
    setGoToBottom,
    getUsers,
    getGroups,
    handleTabClick,
    showFullname,
    setShowFullname,
    selectedGroupChats,
    setSelectedGroupChats,
    selectedFriendChats,
    setSelectedFriendChats,
    changeMode,
    globalColors,
    setGlobalColors,
    showUnread,
    setShowUnread,
    showFav,
    setShowFav,
    favList,
    conditionalBadge,
    removeItemFromChatboxArr,
  } = useContext(MyContext);

  // const [email, setEmail] = useState("");
  // const [directoryId, setDirectoryId] = useState("");
  // const [currentUser, setCurrentUser] = useState("");
  const [searchUI, setSearchUI] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [itemType, setItemType] = useState("");

  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");

  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [allList, setAllList] = useState([]);

  useEffect(() => {
    setQuery("");
  }, [chatBoxArr]);

  useEffect(() => {
    if (filteredUsers.length && downPress) {
      setCursor((prevState) =>
        prevState < filteredUsers.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);
  useEffect(() => {
    if (filteredUsers.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (query.length > 0) {
      if (filteredUsers.length && enterPress) {
        setSelected(filteredUsers[cursor]);
        if (filteredUsers[cursor].first_name) {
          handleFriendSelection(filteredUsers[cursor]);
        } else if (filteredUsers[cursor].group_name) {
          if (filteredUsers[cursor].group_name.includes("Support")) {
            handleSupportSelection(filteredUsers[cursor]);
          } else {
            handleGroupSelection(filteredUsers[cursor]);
          }
        }
      }
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (filteredUsers.length && hovered) {
      setCursor(filteredUsers.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    if (selectedGroup !== null) {
      setSelectedItem(selectedGroup);
      setItemType("group");
    } else if (selectedFriend !== null) {
      setSelectedItem(selectedFriend);
      setItemType("personal");
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedItem(selectedAdminSupport);
      setItemType("adminSupport");
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      setSelectedItem(selectedSupport);
      setItemType("support");
    }
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  // let filteredSearch = unreadInteractionList.filter((item) => {
  //   const lowquery = query.toLowerCase();
  //   return item.username.toLowerCase().indexOf(lowquery) >= 0;
  // });

  // useEffect(() => {
  //   conditionalSupportChat();
  // }, [currentUserObj]);
  // console.log(userObj, "userobj");

  const handleGroupSelection = (item) => {
    setQuery("");
    socket.emit(
      "new_group_chat_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedj", response);
        let tempList = groupReadUnreadList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        tempList[foundIndex].unread_count = 0;
        setgroupReadUnreadList([...tempList]);
      }
    );
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["group_name"] === item.group_name)
      );
    } else {
      setSelectedFriend(null);
      setVisibleUserInfo(false);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTabGroup(item);
    }
  };

  const createNewTab = (item) => {
    console.log(item, chatBoxArr, "hahaha");
    if (chatBoxArr.length < 1) {
      setChatBoxArr([
        { data: selectedItem, type: itemType },
        { data: item, type: "personal" },
      ]);
    } else {
      if (chatBoxArr.find((o) => o.data["username"] === item.username)) {
        message.error("This User is already in one of your tabs.");
      } else {
        setChatBoxArr([...chatBoxArr, { data: item, type: "personal" }]);
      }
    }
  };

  const handleSupportSelection = (item) => {
    setQuery("");
    console.log(activeTab, chatBoxArr[activeTab], item, "activetab");
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["group_name"] === item.group_name)
      );
    } else {
      console.log(item, "kjwbedkjwbefkwbef");
      // setSelectedFriend(item.data);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTabSupport(item);
    }
  };

  const handleFriendSelection = (item) => {
    setQuery("");
    socket.emit(
      "new_direct_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedj", response);
        let tempList = unreadInteractionList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        tempList[foundIndex].unread_count = 0;
        setUnreadInteractionList([...tempList]);
      }
    );

    if (chatBoxArr.find((o) => o.data["email"] === item.email)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["username"] === item.username)
      );
    } else {
      console.log(item, "kjwbedkjwbefkwbef");
      // setSelectedFriend(item.data);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTabPersonal(item);
    }
  };

  const createNewTabPersonal = (item) => {
    if (chatBoxArr.find((o) => o.data["username"] === item.username)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["username"] === item.username)
      );
    } else {
      setSelectedFriend(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "personal" }]);
    }
  };

  useEffect(() => {
    console.log(chatBoxArr, "oiuoiyiutyt");
  }, [chatBoxArr]);

  const createNewTabGroup = (item) => {
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["group_name"] === item.group_name)
      );
    } else {
      setSelectedGroup(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "group" }]);

      console.log(chatBoxArr.length, item, "jkgfckjsfgkwef");
    }
  };

  const createNewTabAdminSupport = (item) => {
    // console.log(item, "hahaha");
    // console.log(selectedItem, item, "jbdjwbfkwbfkjwbefkjcbw");
    console.log(
      chatBoxArr.find((o) => o.data["group_name"] === item.group_name),
      "hahahahah"
    );
    if (chatBoxArr.length < 1) {
      console.log(selectedItem, item, "jbdjwbfkwbfkjwbefkjcbw");
      if (selectedItem !== item) {
        setChatBoxArr([
          { data: selectedItem, type: itemType },
          { data: item, type: "adminSupport" },
        ]);
      } else {
        message.error("This group is already in one of your tabs.");
      }
    } else {
      if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
        message.error("This group is already in one of your tabs.");
      } else {
        setChatBoxArr([...chatBoxArr, { data: item, type: "adminSupport" }]);
      }
    }
  };

  const createNewTabSupport = (item) => {
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["group_name"] === item.group_name)
      );
    } else {
      setSelectedSupport(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "support" }]);
    }
  };

  const conditionalSupportChat = () => {
    console.log("currentuuuuu", currentUserObj);
    if (currentUserObj.admin !== null) {
      return <SupportList />;
    } else {
      return (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={(e) =>
                  createNewTabSupport(supportgroupReadUnreadList[0])
                }
              >
                Open in new tab
              </Menu.Item>
              <Menu.Item key="2">Dismiss</Menu.Item>
            </Menu>
          }
          trigger={["contextMenu"]}
        >
          <div
            style={{
              color:
                localStorage.getItem("mode") === "light"
                  ? "#808080"
                  : localStorage.getItem("mode") === "dark"
                  ? "#808080"
                  : "",
              padding: "20px 0px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setSelectedSupport(supportgroupReadUnreadList[0]);
              setSelectedFriend(null);
              setSelectedGroup(null);
              setVisibleUserInfo(false);
              setMessageLoading(true);
              setmessagearray([]);
            }}
          >
            <img src={require("../../images/all.png")} alt="" />{" "}
            &nbsp;&nbsp;&nbsp;<span>Support Chat</span>
          </div>
        </Dropdown>
      );
    }
  };

  useEffect(() => {
    if (query.length > 0) {
      setSearchUI(true);
    } else {
      setSearchUI(false);
    }
  }, [query]);

  useEffect(() => {
    setAllList([
      ...unreadInteractionList,
      ...groupReadUnreadList,
      ...supportgroupReadUnreadList,
    ]);
  }, [unreadInteractionList, groupReadUnreadList, supportgroupReadUnreadList]);

  let filteredUsers = allList.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.first_name
        ? item.first_name
        : "" + item.group_name
        ? item.group_name
        : ""
      )
        .toLowerCase()
        .indexOf(lowquery) >= 0
    );
  });

  const MarkAsRead = (item) => {
    socket.emit(
      "new_group_chat_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedjkk", response);
        let tempList = groupReadUnreadList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        tempList[foundIndex].unread_count = 0;
        setgroupReadUnreadList([...tempList]);
      }
    );
  };

  const deleteGroup = (item) => {
    // console.log(item, "hjkgwkjfwkef");
    socket.emit(
      "delete_a_group",
      item.thread_id,
      userObj.username,
      app_id,
      (res) => {
        // console.log(res, "hjkgwkjfwkef");
        if (res.status) {
          getGroups();
          removeItemFromChatboxArr(item);
        }
      }
    );
  };

  // console.log(supportgroupReadUnreadList, "sKDJVBfjhks");

  // let filteredGroups = groupReadUnreadList.filter((item) => {
  //   const lowquery = query.toLowerCase();
  //   return item.group_name.toLowerCase().indexOf(lowquery) >= 0;
  // });

  // let filteredSupport = supportgroupReadUnreadList.filter((item) => {
  //   const lowquery = query.toLowerCase();
  //   // console.log(supportgroupReadUnreadList, "klwejnfk");
  //   return item.group_name.toLowerCase().indexOf(lowquery) >= 0;
  // });

  const changeStatus = (status) => {
    if (status === "online") {
      usersocket.emit("new_online_user", currentUserObj.username);
    } else if (status === "offline") {
      usersocket.emit("user_left", currentUserObj.username);
    } else {
      usersocket.emit("user_left", currentUserObj.username);
    }
  };

  // const handleKeyDown = (e) => {
  //   // console.log(e.keyCode, "jhvjevfwvfewj");
  //   console.log(document.querySelectorAll("#res"), "kwqdkwed");
  //   // arrow up/down button should select next/previous list element
  //   if (e.keyCode === 40 && cursorLocation == 0) {
  //     setCursorLocation(cursorLocation + 1);
  //     setSelectedHighlight(filteredUsers[0]);
  //     console.log(selectedHighlight, cursorLocation, "jhvjevfwvfewj");
  //   } else if (
  //     e.keyCode === 40 &&
  //     cursorLocation > 0 &&
  //     cursorLocation <= filteredUsers.length
  //   ) {
  //     setCursorLocation(cursorLocation + 1);
  //     setSelectedHighlight(filteredUsers[cursorLocation]);
  //     console.log(selectedHighlight, cursorLocation, "jhvjevfwvfewj");
  //   } else if (e.keyCode === 38 && cursorLocation <= filteredUsers.length) {
  //     setCursorLocation(cursorLocation - 1);
  //     setSelectedHighlight(filteredUsers[cursorLocation]);
  //     console.log(selectedHighlight, cursorLocation, "jhvjevfwvfewj");
  //   }
  // };

  return (
    <Col
      // className={
      //   localStorage.getItem("mode") === "dark"
      //     ? "hidden-sidebar black-scroll1"
      //     : "hidden-sidebar white-scroll menu-border"
      // }
      className="menu-border hidechatmenu"
      span={5}
      style={{
        background: globalColors.chatMenuBackground,
        height: "100vh",
        textAlign: "left",
        // borderStyle: "solid",
        // borderWidth: "0px 1px 0px 0px",
        // borderColor: "lightgray",

        // width: "240px",
        overflowY: "scroll",
        width: "300px",
      }}
    >
      {selectedChatType !== null || selectedChatType !== undefined ? (
        <div>
          <Affix offsetTop={0.1} style={{ background: "white" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color:
                  localStorage.getItem("mode") === "light"
                    ? "#131927"
                    : localStorage.getItem("mode") === "dark"
                    ? "white"
                    : "",
                // height: "90px",
                paddingLeft: "20px",
                paddingRight: "15px",
                paddingTop: "25px",
                paddingBottom: "20px",

                background: globalColors.chatMenuBackground,
              }}
            >
              <img src={constants.logo} alt="" width="200px" />
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="0" onClick={changeMode}>
                      {globalColors.darkMode ? (
                        <BulbOutlined style={{ fontSize: "15px" }} />
                      ) : (
                        <BulbFilled style={{ fontSize: "15px" }} />
                      )}{" "}
                      &nbsp;
                      {globalColors.darkMode
                        ? "Disable Dark Mode"
                        : "Enable Dark Mode"}
                    </Menu.Item>
                    <Menu.Item
                      key="1"
                      onClick={(e) => setShowUnread(!showUnread)}
                    >
                      {showUnread === true ? (
                        <>
                          <EyeInvisibleOutlined style={{ fontSize: "15px" }} />
                          &nbsp;&nbsp;Hide Unread Section
                        </>
                      ) : (
                        <>
                          <EyeOutlined style={{ fontSize: "15px" }} />
                          &nbsp;&nbsp;Show Unread Section
                        </>
                      )}
                    </Menu.Item>
                    <Menu.Item key="2" onClick={(e) => setShowFav(!showFav)}>
                      {showFav === true ? (
                        <>
                          <EyeInvisibleOutlined style={{ fontSize: "15px" }} />
                          &nbsp;&nbsp;Hide Favorites
                        </>
                      ) : (
                        <>
                          <EyeOutlined style={{ fontSize: "15px" }} />
                          &nbsp;&nbsp;Show Favorites
                        </>
                      )}
                    </Menu.Item>
                    {/* <Menu.Divider />
                    <Menu.Item key="3">3rd menu item</Menu.Item> */}
                  </Menu>
                }
                trigger={["click"]}
              >
                <SettingOutlined
                  style={{ fontSize: "20px", color: globalColors.chatText }}
                />
              </Dropdown>
              {/* <Switch
                defaultChecked={globalColors.darkMode}
                onChange={changeMode}
                // style={{ marginRight: "5px" }}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                // defaultChecked
              /> */}
              {/* <img
                src={selectedChatType ? selectedChatType.coloured_logo : ""}
                width="150px"
              /> */}
            </div>
            {/* <Divider style={{ margin: "0px" }} /> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
                padding: "10px 15px",
                paddingBottom: "30px",
                background: globalColors.chatMenuBackground,
              }}
            >
              <Input
                bordered
                style={{
                  backgroundColor: "transparent !important",
                  background: "transparent !important",
                  color: globalColors.darkMode
                    ? "white !important"
                    : "black !important",
                }}
                className={
                  globalColors.darkMode
                    ? "search-input-dark"
                    : "search-input-light"
                }
                // onKeyDown={(e) => handleKeyDown(e)}
                // width="100%"
                // autoFocus
                // onFocus={(e) => setSearchUI(true)}
                value={query}
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value)}
                prefix={<SearchOutlined style={{ color: "lightgray" }} />}
              />
            </div>
          </Affix>
          {!searchUI ? (
            <div style={{ padding: "0px 15px" }}>
              <div>
                {/* {console.log(showUnread, "3456789")} */}
                {showUnread === true ? <UnreadList /> : ""}
                {favList.length > 0 ? <FavoriteList /> : ""}
                <GroupList />
                <FriendList getUsers={getUsers} />
                <SupportList />
              </div>
            </div>
          ) : (
            <div style={{ padding: "15px", marginTop: "-20px" }}>
              <div>
                {query.length > 1
                  ? filteredUsers.map((item, index) => {
                      return (
                        <Dropdown
                          overlay={
                            <Menu
                              // theme="dark"
                              style={{
                                border: "solid 1px rgba(128, 128, 128, 0.1)",
                                borderRadius: "10px",
                              }}
                            >
                              <Menu.Item
                                key="1"
                                onClick={(e) => createNewTab(item)}
                              >
                                <SwitcherTwoTone />
                                &nbsp;&nbsp;Open in new tab
                              </Menu.Item>
                              <Menu.Divider style={{ margin: "0px" }} />
                              {item.unread_count > 0 ? (
                                <>
                                  <Menu.Item
                                    key="2"
                                    onClick={(e) => MarkAsRead(item)}
                                  >
                                    <CheckSquareTwoTone />
                                    &nbsp;&nbsp;Mark as Read
                                  </Menu.Item>
                                  <Menu.Divider style={{ margin: "0px" }} />
                                </>
                              ) : (
                                ""
                              )}
                              {/* <Menu.Item
                                key="3"
                                onClick={(e) => removeFromList(item)}
                              >
                                <CloseSquareTwoTone />
                                &nbsp;&nbsp;Remove from List
                              </Menu.Item> */}
                              <Menu.Divider style={{ margin: "0px" }} />
                              {item.creator_email === userObj.email ? (
                                <>
                                  <Menu.Item
                                    key="4"
                                    onClick={(e) => deleteGroup(item)}
                                  >
                                    <DeleteTwoTone />
                                    &nbsp;&nbsp;Delete Group
                                  </Menu.Item>
                                  <Menu.Divider style={{ margin: "0px" }} />
                                </>
                              ) : (
                                ""
                              )}
                              {/* <Menu.Item
                                key="5"
                                onClick={(e) => handleSelection(item)}
                              >
                                <UnorderedListOutlined />
                                &nbsp;&nbsp;Select Chat
                              </Menu.Item> */}
                            </Menu>
                          }
                          trigger={["contextMenu"]}
                        >
                          <div>
                            <ListItem
                              index={index}
                              handleFriendSelection={handleFriendSelection}
                              handleGroupSelection={handleGroupSelection}
                              handleSupportSelection={handleSupportSelection}
                              setShowFullname={setShowFullname}
                              showFullname={showFullname}
                              onlineUsers={onlineUsers}
                              key={item.id}
                              active={index === cursor}
                              item={item}
                              setSelected={setSelected}
                              setHovered={setHovered}
                              setQuery={setQuery}
                              selectedSupport={selectedSupport}
                              selectedFriend={selectedFriend}
                              selectedGroup={selectedGroup}
                              conditionalBadge={conditionalBadge}
                            />
                          </div>
                        </Dropdown>
                      );
                    })
                  : ""}
              </div>

              {/* <div>
                {query.length > 1
                  ? filteredGroups.map((item, index) => {
                      return (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="1"
                                onClick={(e) => createNewTabGroup(item)}
                              >
                                Open in new tab
                              </Menu.Item>
                              <Menu.Item key="2">Dismiss</Menu.Item>
                            </Menu>
                          }
                          trigger={["contextMenu"]}
                        >
                          <p
                            id="res"
                            className="group-name"
                            onClick={(e) => handleGroupSelection(item)}
                          >
                            <Space
                              style={{ paddingRight: "5px" }}
                              onMouseEnter={(e) => setShowFullname(index)}
                              onMouseLeave={(e) => setShowFullname(null)}
                            >
                              {item.avatar ? (
                                <div
                                  style={{
                                    border:
                                      item.creator_email === userObj.email
                                        ? "solid 2px rgb(236, 64, 122)"
                                        : "solid 2px lightgray",
                                    padding: "2px",
                                    borderRadius: "100%",
                                  }}
                                >
                                  <Avatar
                                    size="small"
                                    src={item.avatar}
                                    style={{ border: "solid 1px lightgray" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    border:
                                      item.creator_email === userObj.email
                                        ? "solid 2px rgb(236, 64, 122)"
                                        : "solid 2px lightgray",
                                    padding: "2px",
                                    borderRadius: "100%",
                                  }}
                                >
                                  <Avatar
                                    size="small"
                                    style={{
                                      color: "white",
                                      backgroundColor: "lightgray",
                                    }}
                                  >
                                    {item.group_name[0].toUpperCase()}
                                  </Avatar>
                                </div>
                              )}
                              <div>
                                {showFullname === index ? (
                                  item.group_name
                                ) : (
                                  <span>
                                    {item.group_name.length > 18 ? (
                                      <span>
                                        {item.group_name.substring(0, 18)}...
                                      </span>
                                    ) : (
                                      item.group_name
                                    )}
                                  </span>
                                )}
                              </div>
                            </Space>
                          </p>
                        </Dropdown>
                      );
                    })
                  : ""}
              </div>
              <div>
                {query.length > 1
                  ? filteredSupport.map((item, index) => {
                      return (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="1"
                                onClick={(e) => createNewTabAdminSupport(item)}
                              >
                                Open in new tab
                              </Menu.Item>
                              <Menu.Item key="2">Dismiss</Menu.Item>
                            </Menu>
                          }
                          trigger={["contextMenu"]}
                        >
                          <p
                            id="res"
                            className="group-name"
                            onClick={(e) => handleSupportSelection(item)}
                          >
                          
                            {item.group_name.replace("GX-Support ", "").length >
                            15 ? (
                              <span>
                                # &nbsp;&nbsp;
                                {item.group_name
                                  .replace("GX-Support ", "")
                                  .substring(0, 15)}
                                ...
                              </span>
                            ) : (
                              <>
                                # &nbsp;&nbsp;
                                {item.group_name.replace("GX-Support ", "")}
                              </>
                            )}
                          </p>
                        </Dropdown>
                      );
                    })
                  : ""}
              </div>
           */}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </Col>
  );
};

export default ChatMenu;

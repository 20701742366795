import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../../Context";
import S3 from "aws-s3";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Menu,
  Dropdown,
  Badge,
  Avatar,
  Space,
  Upload,
  Divider,
  Col,
  Row,
  Select,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  TeamOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  SwitcherOutlined,
  CloseOutlined,
  CheckOutlined,
  CrownTwoTone,
  SwitcherTwoTone,
  CheckSquareTwoTone,
  DeleteTwoTone,
  CloseSquareTwoTone,
  BellOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  FolderFilled,
  FolderTwoTone,
  CloseCircleTwoTone,
  StarOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const FavoriteList = () => {
  const {
    socket,
    groupReadUnreadList,
    selectedGroup,
    setSelectedFriend,
    setSelectedGroup,
    setSelectedSupport,
    setVisibleUserInfo,
    currentUserObj,
    setgroupReadUnreadList,
    setMessageLoading,
    setmessagearray,
    messageLoading,
    groupsLoading,
    chatBoxArr,
    setChatBoxArr,
    selectedFriend,
    selectedSupport,
    selectedAdminSupport,
    setSelectedAdminSupport,
    activeTab,
    setActiveTab,
    userObj,
    app_id,
    loading,
    setLoading,
    hideGroup,
    setHideGroup,
    setBlankPage,
    handleTabClick,
    foundSavedObj,
    setFoundSavedObj,
    selectedForwardMessages,
    handleForward,
    showFullname,
    setShowFullname,
    selectedGroupChats,
    setSelectedGroupChats,
    globalColors,
    groupLists,
    setGroupLists,
    unreadInteractionList,
    unreadList,
    setUnreadList,
    favList,
    setFavList,
    groupFav,
    setGroupFav,
    friendFav,
    setFriendFav,
    supportFav,
    setSupportFav,
    setUnreadInteractionList,
    // setGroupsLoading,
  } = useContext(MyContext);
  const [visibleCreateGroup, setVisibleCreateGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");

  const [selectedItem, setSelectedItem] = useState({});
  const [itemType, setItemType] = useState("");
  const [groupAvatarUrl, setGroupAvatarUrl] = useState("");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [visibleManageList, setVisibleManageList] = useState(false);
  const [choosenAction, setChoosenAction] = useState("");
  const [listName, setListName] = useState("");
  const [selectedList, setSelectedList] = useState(null);
  const [selectedList2Add, setSelectedList2Add] = useState("");

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const uploadGroupAvatar = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const S3Client = new S3(config);
    let uploaded_data;
    try {
      uploaded_data = await S3Client.uploadFile(file, Date.now().toString());
      console.log(uploaded_data, "uploaded");
      setGroupAvatarUrl(uploaded_data.location);
      message.destroy();
      message.success("File Upload Success", 2);
    } catch (e) {
      // console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };
  const config = {
    bucketName: "chatsgx",
    dirName: localStorage.getItem("user_account"),
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFWWSTGE64",
    secretAccessKey: "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",
  };

  const handleOnChangeGroupAvatar = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  useEffect(() => {
    if (selectedGroup !== null) {
      setSelectedItem(selectedGroup);
      setItemType("group");
    } else if (selectedFriend !== null) {
      setSelectedItem(selectedFriend);
      setItemType("personal");
    } else if (selectedAdminSupport !== null && selectedSupport !== null) {
      setSelectedItem(selectedAdminSupport);
      setItemType("adminSupport");
    } else if (selectedSupport !== null && selectedAdminSupport === null) {
      setSelectedItem(selectedSupport);
      setItemType("support");
    }
  }, [selectedFriend, selectedGroup, selectedSupport, selectedAdminSupport]);

  // const registerGroup = async (e) => {
  //   e.preventDefault();
  //   let group_data = {
  //     group_name: newGroupName,
  //     timestamp: Date.now(),
  //     creator: userObj.username,
  //     avatar: groupAvatarUrl ? groupAvatarUrl : "",
  //   };

  //   await socket.emit(
  //     "new_group_registration",
  //     group_data,
  //     userObj,
  //     app_id,
  //     (response) => {
  //       console.log(response, "newgroup");
  //       if (response === "success") {
  //         setVisibleCreateGroup(false);

  //         socket.emit(
  //           "new_user_group_interaction_list",
  //           userObj.email,
  //           app_id,
  //           (response) => {
  //             console.log("groupsssssssss", response.interaction_list[0]);
  //             console.log(
  //               "groupsssssssss",
  //               response.interaction_list[response.interaction_list.length]
  //             );

  //             createNewTab(
  //               response.interaction_list[response.interaction_list.length - 1]
  //             );
  //             setgroupReadUnreadList([...response.interaction_list]);
  //             setGroupLists(response.user_group_lists);
  //             setVisibleCreateGroup(false);
  //             // setmessagearray([]);
  //           }
  //         );

  //         setNewGroupName("");
  //       } else {
  //         message.error("Group Creation Failed");
  //       }
  //     }
  //   );
  // };

  // const checkStorage = (item) => {
  //   if (item.unread_count > 0) {
  //     // if (selectedGroup.group_name !== item.group_name) {
  //     setFoundSavedObj(null);
  //     if (chatBoxArr.length > 1) {
  //       if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
  //       } else {
  //         setSelectedFriend(null);
  //         setVisibleUserInfo(false);
  //         setSelectedSupport(null);
  //         setSelectedAdminSupport(null);
  //         setMessageLoading(true);
  //         setmessagearray([]);
  //         createNewTab(item);
  //         setSelectedGroup(item);
  //       }
  //     } else {
  //       setSelectedGroup(item);
  //       setChatBoxArr([{ data: item, type: "group" }]);
  //     }

  //     setBlankPage(false);
  //     // }
  //   } else {
  //     var tempStorage = JSON.parse(localStorage.getItem("storedChat"));

  //     var foundObj = tempStorage
  //       ? tempStorage.find((o) => o.chatObj["group_name"] === item.group_name)
  //       : null;
  //     if (foundObj) {
  //       if (foundObj.group_name !== item.group_name) {
  //         setFoundSavedObj(foundObj);
  //         console.log(foundObj, "jhwjdhwved");
  //         setmessagearray(foundObj.messages.reverse());
  //       }
  //     } else {
  //       setFoundSavedObj(null);
  //       if (chatBoxArr.length > 1) {
  //         if (
  //           chatBoxArr.find((o) => o.data["group_name"] === item.group_name)
  //         ) {
  //         } else {
  //           setSelectedFriend(null);
  //           setVisibleUserInfo(false);
  //           setSelectedSupport(null);
  //           setSelectedAdminSupport(null);
  //           setMessageLoading(true);
  //           setmessagearray([]);
  //           createNewTab(item);
  //           setSelectedGroup(item);
  //         }
  //       } else {
  //         setSelectedGroup(item);
  //         setChatBoxArr([{ data: item, type: "group" }]);
  //       }

  //       setBlankPage(false);
  //     }
  //   }
  // };

  const MarkAsRead = (item) => {
    if (item.group_name) {
      if (item.group_name.includes("support")) {
      } else {
        socket.emit(
          "new_group_chat_message_read",
          userObj.username,
          item.thread_id,
          app_id,
          (response) => {
            console.log("jhsdjwhvedj", response);
            let tempList = favList;
            var foundIndex = tempList.findIndex(
              (x) => x.thread_id == item.thread_id
            );
            tempList[foundIndex].unread_count = 0;
            setFavList([...tempList]);
          }
        );
      }
    } else {
      socket.emit(
        "new_direct_message_read",
        userObj.username,
        item.thread_id,
        app_id,
        (response) => {
          console.log("jhsdjwhvedj", response);
          let tempList = unreadInteractionList;
          var foundIndex = tempList.findIndex(
            (x) => x.thread_id == item.thread_id
          );
          tempList[foundIndex].unread_count = 0;
          setUnreadInteractionList([...tempList]);
        }
      );
    }
  };

  const handleGroupSelection = (item) => {
    socket.emit(
      "new_group_chat_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedj", response, groupReadUnreadList);
        let tempList = groupReadUnreadList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        if (tempList[foundIndex] !== undefined) {
          console.log(tempList[foundIndex], "jhsdjwhvedj");
          tempList[foundIndex].unread_count = 0;
          setgroupReadUnreadList([...tempList]);
        }
      }
    );
    // vat foundGroupIndex =
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["group_name"] === item.group_name)
      );
    } else {
      setSelectedFriend(null);
      setVisibleUserInfo(false);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTabGroup(item);
    }
    // if (chatBoxArr.length > 1) {

    // } else {
    //   setSelectedFriend(null);
    //   setSelectedSupport(null);
    //   setSelectedAdminSupport(null);
    //   setSelectedGroup(item);
    //   setChatBoxArr([{ data: item, type: "group" }]);
    // }

    setBlankPage(false);
  };

  const handleFriendSelection = (item) => {
    socket.emit(
      "new_direct_message_read",
      userObj.username,
      item.thread_id,
      app_id,
      (response) => {
        console.log("jhsdjwhvedj", response);
        let tempList = unreadInteractionList;
        var foundIndex = tempList.findIndex(
          (x) => x.thread_id == item.thread_id
        );
        if (tempList[foundIndex]) {
          tempList[foundIndex].unread_count = 0;
          setUnreadInteractionList([...tempList]);
        }
      }
    );

    if (chatBoxArr.find((o) => o.data["email"] === item.email)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["username"] === item.username)
      );
    } else {
      console.log(item, "kjwbedkjwbefkwbef");
      // setSelectedFriend(item.data);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTabFriend(item);
    }

    // if (chatBoxArr.length > 1) {

    // } else {
    //   setSelectedSupport(null);
    //   setSelectedAdminSupport(null);
    //   setSelectedFriend(null);
    //   setSelectedGroup(item);
    //   setChatBoxArr([{ data: item, type: "personal" }]);
    // }

    setBlankPage(false);
  };

  const handleSupportSelection = (item) => {
    // socket.emit(
    //   "new_direct_message_read",
    //   userObj.username,
    //   item.thread_id,
    //   app_id,
    //   (response) => {
    //     console.log("jhsdjwhvedj", response);
    //     let tempList = unreadInteractionList;
    //     var foundIndex = tempList.findIndex(
    //       (x) => x.thread_id == item.thread_id
    //     );
    //     tempList[foundIndex].unread_count = 0;
    //     setUnreadInteractionList([...tempList]);
    //   }
    // );
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
    } else {
      console.log(item, "kjwbedkjwbefkwbef");
      // setSelectedFriend(item.data);
      setSelectedGroup(null);
      setSelectedSupport(null);
      setSelectedAdminSupport(null);
      setMessageLoading(true);
      setmessagearray([]);
      createNewTabSupport(item);
    }

    // if (chatBoxArr.length > 1) {

    // } else {
    //   setSelectedSupport(item);
    //   setSelectedAdminSupport(null);
    //   setSelectedFriend(null);
    //   setSelectedGroup(null);
    //   setChatBoxArr([{ data: item, type: "support" }]);
    // }

    setBlankPage(false);
    // console.log(activeTab, chatBoxArr[activeTab], item, "activetab");
    // if (selectedSupport !== item) {
    //   console.log(item, "selectedAdminSupport");
    //   setSelectedAdminSupport(item);
    //   setSelectedGroup(null);
    //   setSelectedFriend(null);
    //   setVisibleUserInfo(false);
    //   setSelectedSupport(item);
    //   setMessageLoading(true);
    //   setmessagearray([]);
    // }
  };

  const handleAllSelection = (item) => {
    if (item.group_name) {
      if (item.group_name.includes("Support")) {
        handleSupportSelection(item);
      } else {
        handleGroupSelection(item);
      }
    } else if (item.first_name) {
      handleFriendSelection(item);
    }
  };

  const createNewTabGroup = (item) => {
    console.log(item, "jkgfckjsfgkwefss");
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      handleTabClick(
        chatBoxArr.findIndex((o) => o.data["group_name"] === item.group_name)
      );
    } else {
      setSelectedGroup(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "group" }]);

      console.log(chatBoxArr.length, item, "jkgfckjsfgkwefss");
    }
  };

  const createNewTabFriend = (item) => {
    if (chatBoxArr.find((o) => o.data["username"] === item.username)) {
      message.error("This User is already in one of your tabs.");
    } else {
      setSelectedFriend(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "personal" }]);
    }
  };

  const createNewTabSupport = (item) => {
    if (chatBoxArr.find((o) => o.data["group_name"] === item.group_name)) {
      message.error("This User is already in one of your tabs.");
    } else {
      setSelectedSupport(item);
      setChatBoxArr([...chatBoxArr, { data: item, type: "support" }]);
    }
  };

  // const getGroups = () => {
  //   // setGroupsLoading(true);
  //   console.log(userObj, "getgroupss");
  //   socket.emit(
  //     "get_user_group_interaction_list",
  //     userObj,
  //     app_id,
  //     (response) => {
  //       // setGroupsLoading(false);
  //       console.log("get_user_group_interaction_list", response);
  //       setgroupReadUnreadList(response.interaction_list);
  //     }
  //   );
  // };

  const getGroups = () => {
    // setGroupsLoading(true);
    console.log(userObj, "getgroupss");
    socket.emit(
      // "get_user_group_interaction_list",
      "new_user_group_interaction_list",
      userObj.email,
      app_id,
      (response) => {
        // setGroupsLoading(false);
        console.log("get_user_group_interaction_list", response);
        setgroupReadUnreadList(response.interaction_list);
        setGroupLists(response.user_group_lists);
      }
    );
  };

  const deleteGroup = (item) => {
    // console.log(item, "hjkgwkjfwkef");
    socket.emit(
      "delete_a_group",
      item.thread_id,
      userObj.username,
      app_id,
      (res) => {
        // console.log(res, "hjkgwkjfwkef");
        if (res.status) {
          getGroups();
        }
      }
    );
  };

  const handleSelection = (item) => {
    if (selectedGroupChats.find((o) => o === item.thread_id)) {
      var newArr = selectedGroupChats.filter((o) => o !== item.thread_id);
      setSelectedGroupChats([...newArr]);
    } else {
      setSelectedGroupChats([...selectedGroupChats, item.thread_id]);
    }
  };

  const MarkAllAsRead = () => {
    var groupArr = [];
    groupFav.map((item) => {
      groupArr.push(item.thread_id);
    });

    var supportArr = [];
    supportFav.map((item) => {
      supportArr.push(item.thread_id);
    });

    socket.emit(
      "mark_multiple_group_chats_as_read",
      userObj.username,
      groupArr,
      app_id,
      (response) => {
        console.log("jhsdjwhvedjgroup", response);
      }
    );
    socket.emit(
      "mark_multiple_support_group_chats_as_read",
      userObj.username,
      supportArr,
      app_id,
      (response) => {
        console.log("jhsdjwhvedjgroup", response);
      }
    );
  };

  const createGroupList = () => {
    socket.emit(
      `create_group_list`,
      {
        list_name: listName,
        avatar: "",
      },
      userObj.id,
      selectedGroupChats,
      app_id,
      (res) => {
        console.log(res, "created List");
        setListName("");
        setVisibleManageList(false);
        setSelectedGroupChats([]);
        getGroups();
      }
    );
  };

  const addToGroupList = () => {
    socket.emit(
      `add_group_interaction_to_list`,
      selectedList2Add,
      userObj.id,
      selectedGroupChats,
      app_id,
      (res) => {
        console.log(res, "added to List");
        setVisibleManageList(false);
        setSelectedGroupChats([]);
        getGroups();
      }
    );
  };

  useEffect(() => {
    setgroupReadUnreadList(
      groupReadUnreadList.sort((a, b) => b.msg_timestamp - a.msg_timestamp)
    );
  }, []);

  const conditionalListAction = () => {
    if (choosenAction !== "") {
      if (choosenAction === "new") {
        return (
          <div
            style={{
              padding: "30px",
              background: globalColors.drawerBackground,
            }}
          >
            <Input
              type="text"
              placeholder="Type List Name"
              value={listName}
              onChange={(e) => {
                setListName(e.target.value);
              }}
            />
            <Button
              type="primary"
              style={{ marginTop: "10px" }}
              onClick={createGroupList}
            >
              Create List
            </Button>
          </div>
        );
      } else if (choosenAction === "existing") {
        return (
          <div
            style={{
              padding: "30px",
              background: globalColors.drawerBackground,
            }}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a List"
              optionFilterProp="children"
              onChange={(value) => setSelectedList2Add(value)}
              // onFocus={onFocus}
              // onBlur={onBlur}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {groupLists.map((item, index) => {
                return (
                  <Option value={item.list_id}>
                    <div className="group-name">
                      <Space
                        style={{ paddingRight: "5px" }}
                        onMouseEnter={(e) => setShowFullname(index)}
                        onMouseLeave={(e) => setShowFullname(null)}
                      >
                        {item.avatar && item.avatar !== "" ? (
                          <div
                            style={{
                              border:
                                item.creator_email === userObj.email
                                  ? "solid 2px rgb(236, 64, 122)"
                                  : "solid 2px lightgray",
                              padding: "2px",
                              // borderRadius: "100%",
                            }}
                          >
                            <Avatar
                              shape="square"
                              size="small"
                              src={item.avatar}
                              style={{ border: "solid 1px lightgray" }}
                            />
                          </div>
                        ) : (
                          <Avatar
                            // shape="square"
                            // size="large"
                            icon={<FolderFilled />}
                            style={{
                              color: "white",
                              backgroundColor: "gray",
                            }}
                          />
                        )}
                        <div>{item.list_name}</div>
                      </Space>
                    </div>
                  </Option>
                );
              })}
            </Select>
            <br />
            <Button
              type="primary"
              style={{ marginTop: "10px" }}
              onClick={addToGroupList}
            >
              Add To The List
            </Button>
          </div>
        );
      }
    } else {
      return (
        <Row>
          <Col
            onClick={(e) => setChoosenAction("new")}
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="action-dark"
          >
            CREATE NEW LIST
          </Col>
          <Col
            onClick={(e) => setChoosenAction("existing")}
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="action-light"
          >
            ADD TO EXISTING LIST
          </Col>
        </Row>
      );
    }
  };

  const removeFromList = (item) => {
    socket.emit(
      `remove_group_interaction_to_list`,
      selectedList.list_id,
      userObj.id,
      [item.thread_id],
      app_id,
      (res) => {
        console.log(res, "removeddddd");
        getGroups();
      }
    );
  };

  return (
    <>
      <div
        style={{
          color: globalColors.darkMode ? "#919191" : "lightgray",
          padding: "10px 0px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          <StarOutlined /> &nbsp;FAVORITES&nbsp;&nbsp;&nbsp;
          <Tooltip title="Mark All Unread Messages as Read">
            <CheckOutlined onClick={(e) => MarkAllAsRead()} />
          </Tooltip>
        </span>
        {/* {groupReadUnreadList.find((o) => o.unread_count > 0) ? ( */}

        {/* ) : (
          ""
        )} */}
        {/* <span>
          {hideGroup ? (
            <CaretDownOutlined
              onClick={(e) => setHideGroup(!hideGroup)}
              style={{ paddingRight: "10px", cursor: "pointer" }}
            />
          ) : (
            <CaretUpOutlined
              onClick={(e) => setHideGroup(!hideGroup)}
              style={{ paddingRight: "10px", cursor: "pointer" }}
            />
          )}

          <PlusOutlined
            onClick={(e) => {
              setGroupAvatarUrl("");
              setVisibleCreateGroup(true);
            }}
          />
        </span> */}
      </div>
      {/* {selectedGroupChats.length > 0 ? (
        <>
          <Space style={{ padding: "5px 10px", paddingBottom: "10px" }}>
            <Button
              onClick={MarkAllAsRead}
              size="small"
              type="primary"
              ghost
              icon={<BellOutlined />}
              shape="round"
            >
              Mark as read
            </Button>
            <Button
              size="small"
              shape="round"
              type="primary"
              ghost
              icon={<OrderedListOutlined />}
              onClick={(e) => setVisibleManageList(true)}
            >
              Manage Lists
            </Button>

            
          </Space>
          <Space style={{ padding: "5px 10px", paddingBottom: "10px" }}>
            <Button
              size="small"
              shape="round"
              type="danger"
              ghost
              icon={<CloseOutlined />}
              onClick={(e) => setSelectedGroupChats([])}
            >
              Dismiss
            </Button>
          </Space>
        </>
      ) : (
        ""
      )} */}

      {favList.length > 0 ? (
        favList.map((item, index) => {
          return (
            <Dropdown
              overlay={
                <Menu
                  // theme="dark"
                  style={{
                    border: "solid 1px rgba(128, 128, 128, 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  {/* <Menu.Item key="1" onClick={(e) => createNewTab(item)}>
                    <SwitcherTwoTone />
                    &nbsp;&nbsp;Open in new tab
                  </Menu.Item> */}
                  <Menu.Divider style={{ margin: "0px" }} />
                  {item.unread_count > 0 ? (
                    <>
                      <Menu.Item key="2" onClick={(e) => MarkAsRead(item)}>
                        <CheckSquareTwoTone />
                        &nbsp;&nbsp;Mark as Read
                      </Menu.Item>
                      <Menu.Divider style={{ margin: "0px" }} />
                    </>
                  ) : (
                    ""
                  )}
                  {item.creator_email === userObj.email ? (
                    <>
                      <Menu.Item key="3" onClick={(e) => deleteGroup(item)}>
                        <DeleteTwoTone />
                        &nbsp;&nbsp;Delete Group
                      </Menu.Item>
                      <Menu.Divider style={{ margin: "0px" }} />
                    </>
                  ) : (
                    ""
                  )}
                  <Menu.Item key="4" onClick={(e) => handleSelection(item)}>
                    <UnorderedListOutlined />
                    &nbsp;&nbsp;Select Chat
                  </Menu.Item>
                </Menu>
              }
              trigger={["contextMenu"]}
            >
              <p
                key={index}
                className={
                  foundSavedObj
                    ? foundSavedObj.chatObj === item
                      ? "selected-group"
                      : "group-name"
                    : selectedGroup === item
                    ? "selected-group"
                    : selectedGroupChats.length > 0
                    ? selectedGroupChats.find((o) => o === item.thread_id)
                      ? "selected-chat1"
                      : "group-name"
                    : "group-name"
                }
                onClick={(e) =>
                  selectedGroupChats.length > 0
                    ? handleSelection(item)
                    : chatBoxArr.length > 0
                    ? !messageLoading
                      ? handleAllSelection(item)
                      : ""
                    : handleAllSelection(item)
                }
                // onClick={(e) => handleAllSelection(item)}
                // onClick={(e) => {
                //   selectedGroupChats.length > 0
                //     ? handleSelection(item)
                //     : !messageLoading
                //     ? handleGroupSelection(item)
                //     : "";
                // }}
              >
                {/* # {item.group_name} */}

                <Space
                  style={{ paddingRight: "5px" }}
                  onMouseEnter={(e) => setShowFullname(index)}
                  onMouseLeave={(e) => setShowFullname(null)}
                >
                  {item.avatar ? (
                    <div
                      style={{
                        border:
                          item.creator_email === userObj.email
                            ? "solid 2px rgb(236, 64, 122)"
                            : "solid 2px lightgray",
                        padding: "2px",
                        borderRadius: "100%",
                      }}
                    >
                      <Avatar
                        size="small"
                        src={item.avatar}
                        style={{ border: "solid 1px lightgray" }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        border:
                          item.creator_email === userObj.email
                            ? "solid 2px rgb(236, 64, 122)"
                            : "solid 2px lightgray",
                        padding: "2px",
                        borderRadius: "100%",
                      }}
                    >
                      {item.group_name ? (
                        <Avatar
                          size="small"
                          style={{
                            color: "white",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {item?.group_name[0]?.toUpperCase()}
                        </Avatar>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div>
                    {showFullname === index ? (
                      item.group_name ? (
                        item.group_name
                      ) : (
                        item.first_name
                      )
                    ) : (
                      <span>
                        {item.group_name ? (
                          item.group_name.length > 18 ? (
                            <span>{item.group_name.substring(0, 18)}...</span>
                          ) : (
                            item.group_name
                          )
                        ) : item.first_name.length > 18 ? (
                          <span>{item.first_name.substring(0, 18)}...</span>
                        ) : (
                          item.first_name
                        )}
                      </span>
                    )}
                  </div>
                </Space>

                <Badge
                  count={item.unread_count ? item.unread_count : 0}
                  style={{
                    paddingLeft: "8px",
                    backgroundColor: "red",
                    color: "white",
                    // boxShadow: "0 0 0 1px #d9d9d9 inset",
                  }}
                />
              </p>
            </Dropdown>
          );
        })
      ) : (
        <LoadingOutlined style={{ color: "white" }} />
      )}

      {/* Create Group Chat */}
      {/* <Modal
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
        }}
        headStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>Create Group</span>
        }
        footer={null}
        // title="Create Group"
        visible={visibleCreateGroup}
        onCancel={() => setVisibleCreateGroup(false)}
      >
        <Form>
          <Upload
            accept="image/*"
            customRequest={uploadGroupAvatar}
            onChange={handleOnChangeGroupAvatar}
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            defaultFileList={defaultFileList}
          >
            {groupAvatarUrl ? (
              <img
                src={groupAvatarUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          <Input
            className={
              globalColors.darkMode ? "search-input-dark" : "search-input-light"
            }
            autoFocus
            size="large"
            value={newGroupName}
            placeholder="Type new group name here..."
            onChange={(e) => setNewGroupName(e.target.value)}
          />

          <Button
            onClick={registerGroup}
            type="primary"
            // ghost
            size="large"
            block
            style={{ marginTop: "20px", borderRadius: "20px" }}
          >
            CREATE GROUP
          </Button>
        </Form>
      </Modal>
      */}
      <Modal
        bodyStyle={{
          // padding: "0px",
          background: globalColors.drawerBackground,
          padding: "0px",
        }}
        headStyle={{
          background: globalColors.drawerBackground,
        }}
        title={
          <span style={{ color: globalColors.chatText }}>
            Choose List Action
          </span>
        }
        visible={visibleManageList}
        onCancel={(e) => {
          setVisibleManageList(false);
          setChoosenAction("");
        }}
        footer={null}
      >
        {conditionalListAction()}
      </Modal>
    </>
  );
};

export default FavoriteList;

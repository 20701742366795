import React, { useContext } from "react";
import { MyContext } from "../Context";
import { Badge, Button } from "antd";
import { CaretDownOutlined, RightOutlined } from "@ant-design/icons";
const UserInfo = () => {
  const {
    selectedGroup,
    selectedFriend,
    setVisibleUserInfo,
    onlineUsers,
    globalColors,
  } = useContext(MyContext);

  const showUserInfo = () => {
    return (
      <>
        <img src={selectedFriend.avatar} alt="" width="100%" height="200px" />
        {selectedFriend !== null ? (
          <div style={{ padding: "20px 15px" }}>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              {selectedFriend !== null ? (
                <>
                  {selectedFriend.first_name}&nbsp;
                  {selectedFriend.last_name}
                </>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              {onlineUsers.indexOf(selectedFriend.username) !== -1 ? (
                <Badge color={"#70CC16"} />
              ) : (
                <Badge color={"lightgray"} />
              )}
              {/* <Badge
              color={
                selectedFriend.status === "online" ? "#70CC16" : "#666666"
              }
            /> */}
            </div>
            <div style={{ color: globalColors.chatText, margin: "0px" }}>
              {/* {selectedFriend.position} */}
              Developer
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/facebook.png")}
                  alt=""
                  width="30px"
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/twitter.png")}
                  alt=""
                  width="30px"
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/instagram.png")}
                  alt=""
                  width="30px"
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/linkedin.png")}
                  alt=""
                  width="30px"
                />
              </div>
            </div>
            <div>
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  width: "140px",
                }}
              >
                Message
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <CaretDownOutlined />
              </Button>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div style={{ color: globalColors.chatText, fontSize: "12px" }}>
                Username
              </div>
              <div style={{ fontSize: "14px" }}>{selectedFriend.username}</div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div style={{ color: globalColors.chatText, fontSize: "12px" }}>
                Email
              </div>
              <div style={{ fontSize: "14px" }}>{selectedFriend.email}</div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div style={{ color: globalColors.chatText, fontSize: "12px" }}>
                Skype
              </div>
              <div style={{ fontSize: "14px" }}>{selectedFriend.username}</div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div style={{ color: globalColors.chatText, fontSize: "12px" }}>
                Timezone
              </div>
              <div style={{ fontSize: "14px" }}>{selectedFriend.timezone}</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            position: "fixed",
            bottom: "10px",
            right: "5px",
          }}
        >
          <RightOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={(e) => setVisibleUserInfo(false)}
          />
        </div>
      </>
    );
  };

  const showGroupInfo = () => {
    return (
      <>
        {selectedGroup.avatar ? (
          <img src={selectedGroup.avatar} alt="" width="100%" height="200px" />
        ) : (
          <div
            style={{
              height: "200px",
              width: "100%",
              backgroundColor: "#F25D48",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "50px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {selectedGroup.group_name[0].toUpperCase()}
          </div>
        )}

        {selectedGroup !== null ? (
          <div style={{ padding: "20px 15px" }}>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              {selectedGroup !== null ? <>{selectedGroup.group_name}</> : ""}
              &nbsp;&nbsp;
              {/* <Badge
            color={
              selectedFriend.status === "online" ? "#70CC16" : "#666666"
            }
          /> */}
            </div>
            <div style={{ color: globalColors.chatText, margin: "0px" }}>
              {/* {selectedFriend.position} */}
              {selectedGroup.creator_email}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/facebook.png")}
                  alt=""
                  width="30px"
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/twitter.png")}
                  alt=""
                  width="30px"
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/instagram.png")}
                  alt=""
                  width="30px"
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  padding: "3px 10px",
                }}
              >
                <img
                  src={require("../images/social/linkedin.png")}
                  alt=""
                  width="30px"
                />
              </div>
            </div>
            <div>
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  width: "140px",
                }}
              >
                Message
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <CaretDownOutlined />
              </Button>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div style={{ color: globalColors.chatText, fontSize: "12px" }}>
                Created By
              </div>
              <div style={{ fontSize: "14px" }}>
                {selectedGroup.creator_email}
              </div>
            </div>
            {/* <div style={{ margin: "10px 0px" }}>
              <div style={{ color: "#5F6164", fontSize: "12px" }}>Crea</div>
              <div style={{ fontSize: "14px" }}>
                {selectedGroup.creator_email}
              </div>
            </div> */}
            {/* <div style={{ margin: "10px 0px" }}>
              <div style={{ color: "#5F6164", fontSize: "12px" }}>Skype</div>
              <div style={{ fontSize: "14px" }}>{selectedFriend.username}</div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div style={{ color: "#5F6164", fontSize: "12px" }}>Timezone</div>
              <div style={{ fontSize: "14px" }}>{selectedFriend.timezone}</div>
            </div> */}
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            position: "fixed",
            bottom: "10px",
            right: "5px",
          }}
        >
          <RightOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={(e) => setVisibleUserInfo(false)}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {selectedGroup ? showGroupInfo() : showUserInfo()}
      {/* <div
        style={{
          backgroundColor: "#" + (((1 << 24) * Math.random()) | 0).toString(16),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "50px",
          fontWeight: "bold",
          height: "200px",
          color: "white",
          background:
        }}
      >
        {selectedFriend.username[0].toUpperCase()}
      </div> */}
    </>
  );
};

export default UserInfo;
